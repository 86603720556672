import axios from 'axios';
import {getBloxAPIUrl} from '../App/Configuration'

var APIBaseURL = getBloxAPIUrl()

function bloxTrack(type:string, url:string, action:string, data={}){

  return new Promise(function(resolve, reject) {
    // Send a tracking request
    if (localStorage.getItem('token')) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
    axios({
      method: 'post',
      url: APIBaseURL + '/' + 'tracking/',
      data: {
        type: type,
        url: url,
        action: action,
        token: localStorage.getItem('token'),
        date: new Date(),
        location: window.location,
        referrer: document.referrer,
        referrer_is_external: !document.referrer.indexOf(window.location.protocol + "//" + window.location.host) === false,
        language: navigator.language
      }
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.error("BloxOffice API Error in tracking:\n", err.response)
        reject(err)
      })
    }
  )
}

export {
  bloxTrack
}
