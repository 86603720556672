import { Box, Grid } from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import { getAdminUserDetails, getCurrentUserOrders } from "../../Lib/RESTBlox";
import HeaderBox from "./common/HeaderBox";
import OrdersList from "./common/OrderList";
import Loader from "../../Components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMediaQuery } from "react-responsive";

const Orders: React.FC<any> = (props) => {
  const [orders, setOrders] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [loading, setLoading] = React.useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const loadData = () => {
    setLoading(true);
    getCurrentUserOrders(page + 1)
      .then((data: any) => {
        setLoading(false);
        setOrders(orders.concat(data?.results));
        setDataCount(data.count);
        setHasMore(data?.next ? true : false);
        setPage(page + 1);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(
    (page = 1) => {
      setIsLoading(true);
      if (props?.user?.pk) {
        getCurrentUserOrders(page)
          .then((data: any) => {
            setDataCount(data.count);
            setOrders(data.results);
            setIsLoading(false);
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    },
    [props?.user]
  );

  const loaderStyle: CSSProperties = {
    margin: 0,
    position: "relative",
    left: 0,
    display: "flex",
    justifyContent: "center",
  };

  // return isLoading ? (
  //   <Loader loading={true} color="#000" />
  // ) : (
  return (
    <Grid
      container
      justifyContent="center"
      fontFamily="Titillium Web"
      style={{
        height: "100%",
        maxHeight: "100%",
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "95%" : "63%",
          fontSize: "1.5rem",
          fontWeight: 700,
          color: "#FFFFFF",
          marginTop: "8%",
        }}
      >
        <HeaderBox leftText="YOUR ORDERS" rightText={dataCount.toString()} />
        <InfiniteScroll
          dataLength={orders?.length}
          next={loadData}
          hasMore={hasMore}
          loader={isLoading ? <Loader loading={loading} color="#000" style={loaderStyle} /> : <></>}
        >
          <Grid container style={{ marginTop: "15px" }}>
            <Grid container className="user-table-header-row">
              <Grid item xs={5} xl={5} md={5} lg={5}>
                <p className="table-header">EVENT</p>
              </Grid>
              <Grid
                item
                xs={1}
                xl={1}
                md={1}
                lg={1}
                container
                justifyContent="center"
              >
                <p className="table-header">ORDER DATE</p>
              </Grid>
              <Grid
                item
                xs={2}
                xl={2}
                md={2}
                lg={2}
                container
                justifyContent="center"
              >
                <p className="table-header">TOTAL</p>
              </Grid>
              <Grid
                item
                xs={2}
                xl={2}
                md={2}
                lg={2}
                container
                justifyContent="center"
              >
                <p className="table-header">TICKETS</p>
              </Grid>
              <Grid
                item
                xs={2}
                xl={2}
                md={2}
                lg={2}
                container
                justifyContent="center"
              >
                <p className="table-header">ORDER #</p>
              </Grid>
            </Grid>
            {orders &&
              orders.length > 0 ?
              orders.map((order: any, index: any) => (
                <OrdersList
                  eventTitle={order.event?.title ?? ""}
                  eventDescription={order.event?.venue ?? ""}
                  name={order.title ?? ""}
                  num_tickets_issued={order.tickets_count ?? ""}
                  status={order.status ?? ""}
                  date={order.date_created?.trim().split("T")[0] ?? ""}
                  // time={order.date_created?.trim().split(",")[1] ?? ""}
                  time={""}
                  id={order.id ?? ""}
                  number={order.number ?? ""}
                  total={order.total ?? ""}
                />
              ))
              :
                isLoading ? <></> : <p className="table-header" style={{textAlign:'center', flex:1, marginTop:'2%'}}>You don't have any order</p>
            }
          </Grid>
        </InfiniteScroll>
      </Box>
    </Grid>
  );
};

export default Orders;
