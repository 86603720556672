import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import AccountButtonGroup from '../Components/AccountButtonGroup'
import PasswordChangeForm from '../Components/Forms/PasswordChangeForm'

type Props = {
  postPasswordChange: any;
};

const PasswordChange: React.FC<Props> = (props) => {
    return (
      <div>
        <Row className="clearHeaderAndButtonGroup">
          <Col>
            <AccountButtonGroup />
          </Col>
        </Row>
        <Row>
          <Col>
            <PasswordChangeForm postPasswordChange={props.postPasswordChange} />
          </Col>
        </Row>
      </div>
    );
}

export default PasswordChange;
