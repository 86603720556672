import React from 'react';
import ButtonLink from '../ButtonLink'

export default class TicketTableRow extends React.Component {
  toLocalTimeString = function(timestamp) {
    let date = new Date(timestamp);
    let month = '' + (date.getMonth() + 1);
    let day = '' + (date.getDate() + 1);
    let year = date.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return `${month}.${day}.${date.getFullYear()}`
  };
  render() {
    return (
      <tr>
        <td className="left-align">
          {this.props.ticket.event.title}
          <span className="description">{this.toLocalTimeString(this.props.ticket.event.date)} &middot; {this.props.ticket.event.venue.name}</span>
        </td>
        <td className="left-align">
            {`${this.props.ticket.holder.first_name} ${this.props.ticket.holder.last_name}`}
            <span className="description">{this.props.ticket.holder.email}</span>
        </td>
        <td className="d-none d-md-table-cell left-align">
          {`${this.props.ticket.purchaser.first_name} ${this.props.ticket.purchaser.last_name}`}

          <span className="description">{this.props.ticket.purchaser.email}</span>
        </td>
        <td className="d-none d-md-table-cell">{this.props.ticket.type}</td>
        <td className="right-align">
          {!this.props.ticket.was_used && !this.props.ticket.has_pending_transfer &&
            <ButtonLink
              className="hvr-grow-shadow"
              to={"/tickets/" + this.props.ticket.id}
              size="sm"
              text="Transfer"
            />
          }
          {this.props.ticket.was_used &&
            <span>Used</span>
          }
          {this.props.ticket.has_pending_transfer && 
            <span>Pending transfer to {this.props.ticket.pending_transfer_to_email}</span>
          }
        </td>
      </tr>
    );
  }
}
