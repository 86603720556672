import React from "react";
import { getAdminUsers } from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";
import { IUser } from "../../interfaces/user/user";
import TableSearchForm from "../../Components/Forms/TableSearchForm";
import UsersTable from "./components/UsersTable/UsersTable";
import { useMediaQuery } from "react-responsive";

type Props = {};

const UserList: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [hasMore, setHasMore] = React.useState<boolean>(true)
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  React.useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    getAdminUsers(page+1 as any)
      .then((data) => {
        setDataCount(data.count);
        setHasMore(data?.next?true:false)
        setPage(page + 1);
        setUsers(users.concat(data.results));
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };


  const handleSearchChange = (searchTerm: string) => {
    setLoading(true);
    getAdminUsers(1 as any, searchTerm as any, null, null)
      .then((data) => {
        setDataCount(data?.count);
        setHasMore(data?.next?true:false)
        setUsers(data?.results);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <AdminLayout title="Users" isWhite={true}>
      <div className="BloxAdminContentContainer">
        <div className="tableHeader">
          <p className="tableHeader-Name">Users</p>
          <p className="tableHeader-Name">{dataCount}</p>
        </div>
        <TableSearchForm handleSearchChange={handleSearchChange} />
        <UsersTable users={users} loadData={loadData} loading={loading} hasMore={hasMore} isMobile={isMobile}/>
      </div>
    </AdminLayout>
  );
};

export default UserList;
