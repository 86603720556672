import React from 'react';
import { Table } from "reactstrap";
import EventPurchaseTableRow from './EventPurchaseTableRow'
import EventPurchaseTableDetailsRow from './EventPurchaseTableDetailsRow'

function EventPurchaseTable(props) {

  const tableHead = (
    <thead style={{borderBottom:"1px solid #d7d7d7"}}>
      <tr>
        <th scope="col" className="left-align purchase-header">Ticket type</th>
        <th scope="col" className="right-align purchase-header">Quantity</th>
        <th scope="col" className="right-align purchase-header">Price</th>
      </tr>
    </thead>
  )

  const tableRows = (
    props.order && props.order.tickets_requested?.map((request, i) => {
      return <tbody className="inner" key={i}>
        <EventPurchaseTableRow
          inventory = {request.inventory }
          key={request.id}
          orderComplete = {props.orderComplete}
          orderInitiated = {props.orderInitiated}
          quantity = {request.req_quantity}
          price = {request.req_price_str}
          order = {props.order}
        />
        <EventPurchaseTableDetailsRow
          inventory={request.inventory}
          key={"EventPurchaseTableRowDetailsRow" + i}
        />
      </tbody>
    })
  )

  const tablePromoCodeRows = (
    props.order && props.order.promo_code_uses?.map((promo_code_use, i) => {
      return <tbody className="inner" key={i}>
        <tr className="EventPurchaseTableRowPromoCode">
          <td colSpan={2}>
            Promo Code: <span><p>{promo_code_use.code.code}</p></span>
          </td>
          <td className="right-align">-{promo_code_use.discount_applied_str}</td>
        </tr>
      </tbody>
    })
  )

  const tableFoot = (
    props.order &&
      <tfoot>
        <tr>
          <td colSpan="2" className="left-align subtotal">Subtotal</td>
          <td className="subtotal right-align">{props.order.subtotal_str}</td>
        </tr>
        <tr>
          <td colSpan="2" className="left-align fee">
            Service fee &nbsp;
            {props.order.has_fan_campiagn_response &&
              <span style={{color: '#47AF0B'}}>(50% off)</span>
            }
          </td>
          <td className="fee right-align">{props.order.fee_str}</td>
        </tr>
        <tr>
          <td colSpan="2" className="left-align total">Total</td>
          <td className="total right-align">{props.order.total_str}</td>
        </tr>
      </tfoot>
  )

  return (
    <Table size="sm" responsive={true} className="BloxEventPurchaseTable">
      {tableHead}
      {tableRows}
      {tablePromoCodeRows}
      {tableFoot}
    </Table>
  )
}

export default EventPurchaseTable
