import { Box, Grid } from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import { getCurrentUserTickets } from "../../Lib/RESTBlox";
import HeaderBox from "./common/HeaderBox";
import TicketList from "./common/TicketList";
import Loader from "../../Components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import QRCode from "react-qr-code";
import { getCurrentUser } from "../../Lib/RESTAuth";
import { useMediaQuery } from "react-responsive";

const Tickets: React.FC<any> = (props) => {
  const [user, setUser] = useState<any>();
  const [userTickets, setUserTickets] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [loading, setLoading] = React.useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const loadData = () => {
    setLoading(true);
    getCurrentUserTickets(page + 1)
      .then((dataTickets: any) => {
        console.log(dataTickets);
        setUserTickets(userTickets.concat(dataTickets?.results));
        setLoading(false);
        setDataCount(dataTickets.count);
        setHasMore(dataTickets?.next ? true : false);
        setPage(page + 1);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (props?.user?.pk) {
      getCurrentUser()
        .then((data: any) => {
          setUser(data);
          getCurrentUserTickets()
            .then((dataTickets: any) => {
              setUserTickets(dataTickets?.results);
              setIsLoading(false);
              setLoading(false);
              setDataCount(dataTickets.count);
            })
            .catch((err: any) => {
              console.log(err);
              setIsLoading(false);
              setLoading(false);
            });
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [props.user]);

  const loaderStyle: CSSProperties = {
    margin: 0,
    position: "relative",
    left: 0,
    display: "flex",
    justifyContent: "center",
  };

  // return
  // isLoading ? (
  //   <Loader loading={true} color="#000" />
  // ) : (
  return (
    <Grid
      container
      justifyContent="center"
      fontFamily="Titillium Web"
      style={{
        height: "100%",
        maxHeight: "100%",
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "95%" : "63%",
          fontSize: "1.5rem",
          fontWeight: 700,
          color: "#FFFFFF",
          marginTop: "100px",
        }}
      >
        <Box alignSelf={"center"} mb={6}>
          {user && user.profile && (
            <QRCode value={user.email} />
          )}
        </Box>

        <HeaderBox leftText="YOUR TICKETS" rightText={dataCount.toString()} />
        <InfiniteScroll
          dataLength={userTickets?.length}
          next={loadData}
          hasMore={hasMore}
          loader={<Loader loading={loading} color="#000" style={loaderStyle} />}
        >
          <Grid container style={{ marginTop: "15px" }}>
            <Grid container className="user-table-header-row">
              <Grid item xs={6} xl={5} md={5} lg={5}>
                <p className="table-header">EVENT</p>
              </Grid>
              <Grid
                item
                xs={4}
                xl={3}
                md={3}
                lg={3}
                container
                justifyContent="center"
              >
                <p className="table-header">DATE</p>
              </Grid>
              {!isMobile?<Grid
                item
                xs={2}
                xl={2}
                md={2}
                lg={2}
                container
                justifyContent="center"
              >
                <p className="table-header">PURCHASER</p>
              </Grid>:<></>}
              <Grid
                item
                xs={2}
                xl={2}
                md={2}
                lg={2}
                container
                justifyContent="center"
              >
                <p className="table-header">TICKET #</p>
              </Grid>
            </Grid>
            {userTickets?.length > 0 ? (
              userTickets.map((tiket: any, index: number) => (
                <TicketList
                  image={tiket?.event?.image ?? ""}
                  eventId={tiket?.event?.id ?? ""}
                  event={tiket?.event?.title ?? ""}
                  date={tiket?.event?.date ?? ""}
                  type={tiket?.type ?? ""}
                  purchaser={tiket?.purchaser}
                  ticketId={tiket?.id ?? ""}
                  ticketNumber={tiket?.number ?? ""}
                  isMobile={isMobile}
                />
              ))
            ) : loading ? (
              <></>
            ) : (
              <p
                className="table-header"
                style={{ textAlign: "center", flex: 1, marginTop: "2%" }}
              >
                You don't have any ticket
              </p>
            )}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Grid>
  );
  // );
};

export default Tickets;
