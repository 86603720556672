import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Formik, Field, Form } from 'formik';
import { FormField } from './Fields'
import {changePassword} from '../../Lib/RESTAuth'
import FlashAlert from '../FlashAlert'

type Props = {
  postPasswordChange: any;
};

const PasswordChangeForm : React.FC<Props> = (props) => {
  
  const [alert, setAlert] = React.useState<any>({type:'',message:''});

  const handleChangePassword = (oldPassword:any, newPassword:any) => {
    changePassword(oldPassword, newPassword)
      .then(json => {
          props.postPasswordChange(json)
          setAlert({
            type: 'success',
            message: 'The password has been changed.'
          });
      })
      .catch(err => {
        console.log(err)
        setAlert({
          type: 'danger',
          message: 'There was an error changing your password.'
        });
      })
  }

  const validate = (values:any) => {
    interface LooseObject {
      [key: string]: any
    }
    let errors: LooseObject = {};

    if (!values.old_password) {
      errors.old_password = 'Required';
    }

    if (!values.new_password1) {
      errors.new_password1 = 'Required';
    }

    if (!values.new_password2) {
      errors.new_password2 = 'Required';
    }

    if (values.new_password1 !== values.new_password2) {
      errors.new_password2 = 'New passwords do not match';
    }
    console.log(errors)
    return errors;
  };

    return (
      <div>
        { alert.message &&
          <Row>
            <Col>
              <FlashAlert color={alert.type} text={alert.message} />
            </Col>
          </Row>
        }
        <Formik
          initialValues={{ old_password: '', new_password1: '', new_password2: '' }}
          onSubmit={(values, actions) => {
            handleChangePassword(values.old_password, values.new_password1)
            actions.setSubmitting(false);
          }}
          validate = {validate}
          render={(props ) => (
            <Form>
              <Row>
                <Col>
                  <Field
                    label="Old password"
                    type="password"
                    name="old_password"
                    placeholder="Old password..."
                    component={FormField}
                    required={true}
                    autoComplete="current-password"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="pr-1">
                  <Field
                    label="New password"
                    type="password"
                    name="new_password1"
                    placeholder="New password..."
                    component={FormField}
                    required={true}
                    autoComplete="new-password"
                  />
                </Col>
                <Col className="pl-1">
                  <Field
                    label="Confirm new password"
                    type="password"
                    name="new_password2"
                    placeholder="New password again..."
                    component={FormField}
                    required={true}
                    autoComplete="new-password"
                  />
                </Col>
              </Row>
              <Row className="buttonRow">
                <Col>
                  <Button className="hvr-grow-shadow" block color="primary" type="submit">Change password</Button>{' '}
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    );
}

export default PasswordChangeForm;
