import React, {useMemo} from "react";

type Props = {
  order: any;
};
const CardPay: React.FC<Props> = ({ order }) => {
  const lastFour = useMemo(() => {
    if(order && order?.length && order[0]?.token){
      const str = order[0]?.token.replace(/'/g, '"').replaceAll(': None', ': ""').replaceAll("True", "true").replaceAll("False", "false")

      const token = JSON.parse(str);
      return token?.card?.last4
    }
    return ""
  }, [order])

  if(lastFour) return (
    <p style={{ width: "100%", textAlign: "center" }}>**** **** **** {lastFour}</p>
  );
  return <></>
};
export default CardPay;
