import { Grid } from "@mui/material";
import React from "react";
import { SortableElement } from "react-sortable-hoc";

type Props = {
  key: any;
  index: any;
  value: any;
  isMobile: boolean;
  isMobileSmart: boolean;
};

const SortableItem = (props: Props) => {
  return (
    <Grid
      container
      item
      alignItems="center"
      lg={props.isMobileSmart ? 8.625 : 10}
      xl={props.isMobileSmart ? 8.625 : 10}
      md={props.isMobileSmart ? 8.625 : 10}
      xs={props.isMobileSmart ? 8.625 : 10}
    >
      <Grid
        item
        lg={props.isMobileSmart ? 5.8 : 1.75}
        xl={props.isMobileSmart ? 5.8 : 1.75}
        md={props.isMobileSmart ? 5.8 : 1.75}
        xs={props.isMobileSmart ? 5.8 : 1.75}
      >
        {props.value?.type.name ?? "NO-TYPE"}
      </Grid>
      <Grid
        item
        lg={props.isMobileSmart ? 3 : 2.4}
        xl={props.isMobileSmart ? 3 : 2.4}
        md={props.isMobileSmart ? 3 : 2.4}
        xs={props.isMobileSmart ? 3 : 2.4}
        className="soldAndQuantity"
      >
        <span className="totalSold">{props.value?.quantity - props.value?.quantity_available}</span>/
        <span>{props.value?.quantity}</span>
      </Grid>
      <Grid
        item
        lg={props.isMobileSmart ? 3.2 : 1.25}
        xl={props.isMobileSmart ? 3.2 : 1.25}
        md={props.isMobileSmart ? 3.2 : 1.25}
        xs={props.isMobileSmart ? 3.2 : 1.25}
        style={{ textAlign: "center" }}
      >
        {props.value?.price_str}
      </Grid>
      {!props.isMobileSmart && (
        <Grid
          item
          lg={3.3}
          xl={3.3}
          md={3.3}
          xs={3.3}
          style={{ textAlign: "center" }}
        >
          {props.value?.sale_start_date_display ?? "Immediately"}
        </Grid>
      )}
      {!props.isMobileSmart && (
        <Grid
          item
          lg={3.3}
          xl={3.3}
          md={3.3}
          xs={3.3}
          style={{ textAlign: "center" }}
        >
          {props.value?.sale_end_date_display ?? "Indefinitely"}
        </Grid>
      )}
    </Grid>
  );
};

export default SortableElement(SortableItem);
