import { CSSProperties } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../Components/Loader";
import { Row, Col, Table, Button, ButtonGroup, Input, Label } from "reactstrap";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { FaTicketAlt, FaCheckCircle } from "react-icons/fa";

export const buildTable = (
  tab: string,
  loading: boolean,
  hasMore: boolean,
  getGuestList: any,
  getCompList:any,
  guestListStatistic: any,
  tableData: any,
  user: any,
  sortBy: string,
  TICKET_TYPES: any,
  selectedColors: any,
  checkInTicket: (item: any, ticketId: any, event: any) => void,
  handleSort: (ordering: string) => void
) => {
  const loaderStyle: CSSProperties = {
    margin: 0,
    position: "relative",
    left: 0,
    display: "flex",
    justifyContent: "center",
  };

  let TicketIDStr = "";
  switch (tab) {
    case "guestlist":
      TicketIDStr = "TicketButton-";
      break;
    case "comp":
      TicketIDStr = "CompTicketButton-";
      break;
  }

  return (
    <InfiniteScroll
      dataLength={tableData.length}
      next={tab === "guestlist"? getGuestList: getCompList}
      hasMore={hasMore}
      scrollThreshold={0.2}
      loader={<Loader loading={loading} color="#000" style={loaderStyle} />}
    >
      <Table
        size="sm"
        responsive={true}
        className="BloxEventPurchaseTable BloxEventGuestListTable"
      >
        <thead>
          <tr>
            <th
              scope="col"
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("-username")}
            >
              Holder{" "}
              {sortBy === "desc" ? <FaSortAmountDown /> : <FaSortAmountUp />}
            </th>
            <th scope="col">Type</th>
            <th scope="col" className="left-align">
              #
            </th>
            {/* {user.profile.permissions_list.includes(
              "check-in-event-attendees"
            ) && ( */}
              <th scope="col" className="right-align noPrint">
                Check in
              </th>
            {/* )} */}
          </tr>
        </thead>
        <tbody>
          {tableData &&
            tableData.map((item: any, row: number) => {
              return item.ticket_types?.map((type: any, index: number) => {
                return (
                  <tr key={index} style={{borderBottom: "solid 1px #d0d0d0"}}>
                    <td className="left-align guestTableCell" style={{paddingBottom:"15px !important"}}>{item.user_name}</td>
                    <td className="left-align guestTableCell">{type.type}</td>
                    <td className="left-align guestTableCell">{type?.tickets?.length}</td>
                    {/* {user.profile.permissions_list.includes(
                      "check-in-event-attendees"
                    ) && ( */}
                      <td className="right-align guestListCheckIn noPrint guestTableCell">
                        {type.tickets.map((ticket: any, i: number) => {
                          let colors =
                            tab === "guestlist" ? selectedColors : "white"; //IS WAS selectedCompColors
                          let ticketTypes = TICKET_TYPES;
                          let styles = {
                            color: "white",
                          };
                          //this is for styling depending of tab type and ETC WORK ON THIS
                          // if (tab === "comp_list") {
                          //   switch (type.type) {
                          //     case "Comp":
                          //       styles.backgroundColor =
                          //         selectedColorsCompTickets[0];
                          //       styles.borderColor =
                          //         selectedColorsCompTickets[0];
                          //       break;
                          //     case "VIP":
                          //       styles.backgroundColor =
                          //         selectedColorsCompTickets[1];
                          //       styles.borderColor =
                          //         selectedColorsCompTickets[1];
                          //       break;
                          //   }
                          // } else {
                          //   styles = {
                          //     backgroundColor: `${
                          //       colors[ticketTypes.indexOf(type.type)]
                          //     }`,
                          //     borderColor: `${
                          //       colors[ticketTypes.indexOf(type.type)]
                          //     }`,
                          //     color: "white",
                          //   };
                          // }

                          return ticket.was_used ? (
                            <Button
                              id={TicketIDStr + ticket.id.toString()}
                              key={ticket.id}
                              color="secondary"
                              className="TicketButton disabled"
                              onClick={(e: any) =>
                                checkInTicket(item, ticket.id, e)
                              }
                            >
                              <FaCheckCircle />
                              <span className="guestlistTicketBadge">
                                {i + 1}
                              </span>
                            </Button>
                          ) : (
                            <Button
                              id={TicketIDStr + ticket.id.toString()}
                              key={ticket.id}
                              onClick={(e: any) =>
                                checkInTicket(item, ticket.id, e)
                              }
                              className={`TicketButton hvr-grow-shadow`}
                              color="link"
                              style={styles}
                            >
                              <FaTicketAlt />
                              <span className="guestlistTicketBadge">
                                {i + 1}
                              </span>
                            </Button>
                          );
                        })}
                      </td>
                    {/* )} */}
                  </tr>
                );
              });
            })}
        </tbody>
      </Table>
    </InfiniteScroll>
  );
};
