import React, {useCallback, useEffect} from "react";
import { Row, Col, Button } from "reactstrap";
import {
  getAdminEvent,
  getEventGuestList,
  checkInCompTicket,
  checkInTicket as checkInTicketService,
  addCompTickets,
  getEventCompList,
  getEventTypes, 
  fetchGuestListCsv,
  getTerminals
} from "../../Lib/RESTBlox";
import Loader from "../../Components/Loader";
import { Formik, Field, Form } from "formik";
import { FormField } from "../../Components/Forms/Fields";
import "../../Stylesheets/Components/Events/_BtnColor.scss";
import GuessAdminLayout from "./components/AdminLayout/GuessAdminLayout";
import { useParams } from "react-router-dom";
import { buildTable } from "./components/GuestTable/GuestTable";
import GuestListTabs from "./components/GuestListTabs/GuestListTabs";
import { useMediaQuery } from "react-responsive";
import { QrCodeScanner } from "./components/QrCodeScanner";
import {DoorOrderProcessor, ITerminal} from "../../Components/Modals/DoorOrderProcessor";

type Props = {
  user: any;
};

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}


const EventGuestListNew: React.FC<Props> = ({ user }) => {
  const [tab, setTab] = React.useState("guestlist");
  const [TICKET_TYPES, setTICKET_TYPES] = React.useState([]);
  const [pallette, setPallette] = React.useState([
    "#315B23",
    "#1B3A43",
    "#302049",
    "#6B5D29",
    "#40DC0E",
    "#1086A8",
    "#571BB5",
    "#FFCA10",
    "#6D9960",
    "#486770",
    "#61517B",
    "#B4A571",
    "#87DD6C",
    "#5698AB",
    "#8462B8",
    "#FFE27D",
    "#2A8C0B",
    "#0B5267",
    "#371270",
    "#A5830D",
  ]);
  const [selectedColors, setSelectedColors] = React.useState<string[]>([]);
  const [selectedColorsCompTickets, setSelectedColorsCompTickets] =
    React.useState([]);
  const [guestListTableData, setGuestListTableData] = React.useState<any>([]);
  const [compListTableData, setCompListTableData] = React.useState<any>([]);
  const [filteredGuest, setFilteredGuest] = React.useState<any>([]);
  const [searching, setSearching] = React.useState(true)
  const [tableData, setTableData] = React.useState<any>([]);
  const [guestListStatistic, setGuestListStatistic] = React.useState({
    total: 0,
    check_in: 0,
    count: 0,
    prev: "",
    next: "",
  });
  const [compListStatistic, setCompListStatistic] = React.useState({
    total: 0,
    check_in: 0,
    count: 0,
    prev: "",
    next: "",
  });
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVip, setIsVip] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("desc");
  const [search, setSearch] = React.useState("");
  const [ordering, setOrdering] = React.useState("");
  const [page, setPage] = React.useState<number>(0);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState(true);
  const [query, setQuery] = React.useState("");

  const [terminals, setTerminals] = React.useState<ITerminal[]>([]);
  useEffect(() => {
    getTerminals().then((res) => {
      console.log(res)
        setTerminals(res);
    })
  }, [])

  const [guestListQuery, setGuestListQuery] = React.useState({
    search: "",
    ordering: "",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [compListQuery, setCompListQuery] = React.useState({
    search: "",
    ordering: "",
  });

  const getColorsByLength = (length: any) => {
    let colorsArray = [];
    let min = 1;
    let max = pallette.length;
    let indexes = [];
    for (let i = 0; indexes.length < length; i++) {
      let index = Math.floor(Math.random() * max) + min;
      if (indexes.indexOf(index) === -1) {
        indexes.push(index);
      }
    }

    for (let i = 0; i < indexes.length; i++) {
      colorsArray.push(pallette[indexes[i]]);
    }

    return colorsArray;
  };

  const getGuestList = async (ordering = "", search = "", filterPage = -1) => {
    if (filterPage === 1) setPage(1);
    setIsLoading(true);
    if (eventId) {
      getEventGuestList(
        eventId,
        filterPage != -1 ? filterPage : page + 1,
        ordering,
        search
      )
        .then((data: any) => {
          setGuestListStatistic({
            total: data.total_tickets,
            check_in: data.checked_in_tickets,
            count: data.count,
            prev: "",
            next: data.next,
          });
          filterPage !== -1
            ? setTableData(data.results)
            : setTableData(tableData.concat(data.results));
          filterPage !== -1
            ? setGuestListTableData(data.results)
            : setGuestListTableData(guestListTableData.concat(data.results));
          // !searching ? setFilteredGuest(data.results) : setFilteredGuest([]);
          setGuestListTableData(tableData.concat(data.results));
          setIsLoading(false);
          setDataCount(data.count);
          setHasMore(data?.next ? true : false);
          if (filterPage === -1) setPage(page + 1);
          setLoading(false);
          setSearching(false);
          if (TICKET_TYPES.length === 0) getTypes();
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  };

  const getCompList = (ordering = "", search = "", filterPage = -1) => {
    if (filterPage === 1) setPage(1);
    setIsLoading(true);
    if (eventId) {
      getEventCompList(
        eventId,
        filterPage != -1 ? filterPage : page + 1,
        ordering,
        search
      )
        .then((data: any) => {
          setCompListStatistic({
            total: data.total_tickets,
            check_in: data.checked_in_tickets,
            count: data.count,
            prev: "",
            next: data.next,
          });
          filterPage !== -1
            ? setTableData(data.results)
            : setTableData(tableData.concat(data.results));
          filterPage !== -1
            ? setCompListTableData(data.results)
            : setCompListTableData(compListTableData.concat(data.results));
          setCompListTableData(tableData.concat(data.results));
          setIsLoading(false);
          setDataCount(data.count);
          setHasMore(data?.next ? true : false);
          if (filterPage === -1) setPage(page + 1);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  };

  const getTypes = () => {
    if (eventId) {
      getEventTypes(eventId).then((response: any) => {
        let ticketTypes = response.results.map((item: any) => item.name);
        setSelectedColors(getColorsByLength(ticketTypes.length));
      });
    }
  };

  const handleFilterChange = (value: any) => {
    switch (tab) {
      case "guestlist":
        if (value === "") {
          setSearching(true)
          setFilteredGuest([]);
        }else{
          setSearching(true)
        }
        getGuestList("", value, 1);
        break;
      case "comp_list":
        getCompList("", value, 1);
        break;
    }
  };

  React.useEffect(() => {
    const timeOutId = setTimeout(() => {
      handleFilterChange(query);
    }, 400);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const handleTabChange = (tab: any) => {
    setTab(tab);
    setTableData([]);
    switch (tab) {
      case "guestlist":
        // setTableData(guestListTableData);
        getGuestList("", "", 1);
        setSortBy(guestListQuery.ordering.length > 0 ? "asc" : "desc");
        setOrdering(
          guestListQuery.ordering.length > 0 ? guestListQuery.ordering : ""
        );
        break;
      case "comp_list":
        // setTableData(compListTableData);
        getCompList("", "", 1);
        setSortBy(compListQuery.ordering.length > 0 ? "asc" : "desc");
        setOrdering(
          compListQuery.ordering.length > 0 ? compListQuery.ordering : ""
        );
        setSearch(
          compListQuery.ordering.length > 0 ? compListQuery.search : ""
        );
        break;
    }
  };

  const handleChecked = () => {
    setIsVip(!isVip);
  };

  const handleAddCompTickets = (values: any) => {
    values.is_vip = isVip;
    if (eventId) {
      addCompTickets(values, eventId)
        .then((data: any) => {
          getCompList("", "", 1);
        })
        .catch((err) => console.error(err));
    }
  };

  React.useEffect(() => {

    if (eventId) {
      // getAdminEvent(eventId, false)
      //   .then((event) => {
      getGuestList();
      // })
      // .catch((err) => console.error(err));
    }
  }, []);

  const checkInTicket = (item: any, ticketId: any, event: any) => {
    let LocalTableData = JSON.parse(JSON.stringify(tableData));
    let button = event.currentTarget;
    if (button.classList.contains("disabled")) {
      var r = window.confirm(
        "Are you sure you want to undo this ticket check-in?"
      );
      if (!r) {
        return;
      }
    }
    LocalTableData.map((data: any) => {
      data.ticket_types.map((type: any) => {
        type.tickets.map((ticket: any) => {
          if (ticket.id === ticketId)
            return (ticket.was_used = !ticket.was_used);
        });
      });
    });

    switch (tab) {
      case "guestlist":
        setGuestListTableData(LocalTableData);
        setTableData(LocalTableData);
        break;
      case "comp_list":
        setCompListTableData(LocalTableData);
        setTableData(LocalTableData);
    }

    // If this is a checked-in ticket, confirm they want to continue.
    if (button.classList.contains("disabled")) {
      if (true) {
        switch (tab) {
          case "guestlist":
            setGuestListStatistic({
              ...guestListStatistic,
              check_in: guestListStatistic.check_in - 1,
            });
            break;
          case "comp_list":
            setCompListStatistic({
              ...compListStatistic,
              check_in: compListStatistic.check_in - 1,
            });
            break;
        }
      }
    } else {
      switch (tab) {
        case "guestlist":
          setGuestListStatistic({
            ...guestListStatistic,
            check_in: guestListStatistic.check_in + 1,
          });
          break;
        case "comp_list":
          setCompListStatistic({
            ...compListStatistic,
            check_in: compListStatistic.check_in + 1,
          });
      }
    }

    switch (tab) {
      case "guestlist":
        checkInTicketService(ticketId).catch((err) => console.error(err));
        checkInCompTicket(ticketId).catch((err) => console.error(err));
        break;
      case "comp_list":
        checkInCompTicket(ticketId).catch((err) => console.error(err));
        break;
    }
  };

  const handleSort = (ordering: string) => {
    if (sortBy == "desc") {
      setSortBy("asc");
    } else {
      setSortBy("desc");
      ordering = "";
    }
    setOrdering(ordering);
    switch (tab) {
      case "guestlist":
        setGuestListQuery({ ...guestListQuery, ordering });
        getGuestList(ordering);
        break;
      case "comp_list":
        getCompList(ordering);
        setCompListQuery({ ...compListQuery, ordering });
        break;
    }
  };

  const fetchCsv = useCallback(async () => {
    const response = await fetchGuestListCsv(parseInt(eventId + '', 10))

    if(window.navigator.msSaveBlob) {
      // IE 10 and later, and Edge.
      var blobObject = new Blob([response], {type: 'text/csv'});
      window.navigator.msSaveBlob(blobObject, 'guestlist.csv');
    } else {
      // Everthing else (except old IE).
      // Create a dummy anchor (with a download attribute) to click.
      var anchor = document.createElement('a');
      anchor.download = 'guestlist.csv';
      if(window.URL.createObjectURL) {
        // Everything else new.
        var blobObject = new Blob([response], {type: 'text/csv'});
        anchor.href = window.URL.createObjectURL(blobObject);
      } else {
        // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
        // Load up the data into the URI for "download."
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response);
      }
      // Now, click it.
      if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        anchor.dispatchEvent(event);
      }
      else {
        anchor.click();
      }
    }
  }, [])

  const filterForm = (
    <Formik
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
      initialValues={null}
      render={(props) => (
        <form className="guestlistFilterForm">
          <Row style={{gap: '0.4rem'}}>
            <Col
            >
              <FormField
                  field={{
                    name:"search",
                    type:"search",
                    value:query,
                    onChange:(e: any) => setQuery(e.target.value),
                  }}
                form={{ touched: false, errors: false}}
                label="Filter"
                placeholder="Filter list by guest name or email..."
                required={false}
                id="searchFilterField"
              />
            </Col>
              {tab === "guestlist" && (
                <>
                  <Col xs="12" md="3" className="align-self-end">
                </Col>
                  <QrCodeScanner onResult={result => {
                    console.log('setting query', result);
                    setQuery(result);
                    setSearch(result);
                    if(tab === 'guestlist') {
                      setGuestListQuery({...guestListQuery, search: result});
                    } else {
                      setCompListQuery({...compListQuery, search: result});
                    }
                  }} />
              </>
              )}
          </Row>
        </form>
      )}
    />
  );
  return (
    // <>
    <GuessAdminLayout isWhite>
      <div className="BloxAdminContentContainer" id="ifmcontentstoprint" style={{display: 'flex', flexDirection: 'column'}}>
        <button
          className="tableHeader-Name"
          style={{
            marginLeft: 'auto',
            border: 'none',
            textAlign: "end",
            cursor: "pointer",
            fontSize: "1.1rem",
            color: "gray",
            fontWeight: "600",
            marginTop: "40px",
            marginBottom: '20px',
            background: 'none'
          }}
          onClick={fetchCsv}
        >
          Print 🖨
        </button>
        <div className="tableHeader">
          <p className="tableHeader-Name">guestlist</p>
          {tab === "guestlist" ? (
            <p className="tableHeader-Name">
              {guestListStatistic.check_in}/{guestListStatistic.total}
            </p>
          ) : (
            <p className="tableHeader-Name">
              {compListStatistic.check_in}/{compListStatistic.total}
            </p>
          )}
        </div>
        {/* <Loader loading={isLoading} color="#000" /> */}
        <div className="BloxAdminContentContainer BloxAdminGuestlistContainer">
          <GuestListTabs
            handleTabChange={handleTabChange}
            tab={tab}
            isMobile={isMobile}
          />

          {tab === "comp_list" && (
            <div>
              {/* {user.profile.permissions_list.includes("add-comp-tickets") && */}
              <Formik
                initialValues={{ quantity: 1 }}
                onSubmit={(values, actions) => {
                  setCompListStatistic({
                    ...compListStatistic,
                    check_in: compListStatistic.total + values.quantity,
                  });
                  handleAddCompTickets(values);
                  actions.setSubmitting(false);
                }}
                render={(props) => (
                  <Form className="addCompTicketForm">
                    <Row>
                      <Col xs={12} md={3}>
                        <Field
                          label="First Name"
                          type="text"
                          name="first_name"
                          placeholder="First name..."
                          component={FormField}
                          required={true}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        <Field
                          label="Last Name"
                          type="text"
                          name="last_name"
                          placeholder="Last name..."
                          component={FormField}
                          required={true}
                        />
                      </Col>
                      <Col xs={12} md={2}>
                        <Field
                          label="Quantity"
                          type="number"
                          name="quantity"
                          component={FormField}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        className="align-self-center text-center"
                      >
                        <label
                          style={{
                            verticalAlign: "top",
                            lineHeight: "0.9",
                            textAlign: "center",
                          }}
                        >
                          VIP
                        </label>
                        <Field
                          type="checkbox"
                          name="is_vip"
                          value={false}
                          onChange={handleChecked}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        <label>&nbsp;</label>
                        <Button type="submit" color="primary" block>
                          Add {isVip ? "vip" : "comp"} tickets
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              />
              {/* )}  */}
            </div>
          )}
          {filterForm}

          <div>
            {buildTable(
              tab,
              loading || searching,
              hasMore,
              getGuestList,
              getCompList,
              guestListStatistic,
              tableData,
              // tab === "guestlist"
              //   ? filteredGuest.length === 0
              //     ? guestListTableData
              //     : filteredGuest
              //   : compListTableData,
              user,
              sortBy,
              TICKET_TYPES,
              selectedColors,
              checkInTicket,
              handleSort
            )}
          </div>

        </div>
      </div>
    </GuessAdminLayout>
    //    <div style={{position: "absolute",zIndex: "1",left: "2vh",bottom: "3vh"}}>
    //     <CameraAltIcon />
    //   </div> 
    //  </> 
  );
};

export default EventGuestListNew;
