import React, {useState, useEffect} from 'react'
import { Alert } from 'reactstrap'
import MaterialIcon from 'material-icons-react'
import CloseIcon from '@mui/icons-material/Close';

function FlashAlert(props) {
  const [visible, setVisible] = useState(false)
  const [icon, setIcon] = useState('')

  // SHOW/HIDE THE ALERT
  useEffect(() => {
    // Show the Alert if it's not already visible.
    setVisible(true)
    // If this alert is dismissable, set a timer to dismis it.
    if (!props.dontDismiss) {
      let dismissTimer = setTimeout(() => setVisible(false), 3000)
      // Clear the timer so it only runs once.
      return () => {
        clearTimeout(dismissTimer)
      }
    }
  }, [props])

  // SET ICON FOR THE ALERT
  useEffect(() => {
    if (props.color === 'success') setIcon('check_circle_outline')
    if (props.color === 'danger') setIcon('danger')
  }, [props.color])

  return (
    <Alert
      color={props.color }
      isOpen={visible}
      className={ props.dontDismiss? '' : 'dismiss'}
      style={{ top: '0px', marginTop: 0, zIndex: 100000000, width: '100vw', left: 0, position: 'fixed', paddingRight: '3.5rem' }}
      >
        { !props.dontDismiss &&
          <MaterialIcon
            icon="check_circle_outline"
            color='#fff'
            size="small"
          />
        }
        { props.text }
      <button type="button" style={{background: 'none', cursor: "pointer", border: 'none', position: 'absolute', right: '1rem'}} onClick={() => setVisible(false)}><CloseIcon style={{color: 'white'}} /></button>
    </Alert>
  )
}

export default FlashAlert
