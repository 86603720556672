import React from "react";

type Props = {
  rigthText: string;
  leftText: string;
};
const SectionHeader: React.FC<Props> = ({ rigthText, leftText }) => {
  return (
    <div className="tableHeader">
      <p className="tableHeader-Name">{rigthText}</p>
      <p className="tableHeader-Name">{leftText}</p>
    </div>
  );
};
export default SectionHeader;
