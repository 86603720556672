import React, { useState } from "react";
import {OnResultFunction, QrReader } from 'react-qr-reader';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

interface QrCodeScannerProps {
    onResult: (result: string) => void;
}

const QrCodeScanner = ({onResult}: QrCodeScannerProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const toggleModal = () => setModalIsOpen(prev => !prev);

    const onScan: OnResultFunction = (result, error) => {
        if (!!result) {
            onResult(result?.getText());
            setModalIsOpen(false);
        }

        if (!!error) {
            console.info(error);
        }
    }

    return (
        <>
            <Button
                type="button"
                block
                color="secondary"
                className="hvr-grow-shadow"
                onClick={() => setModalIsOpen(true)}>Scan QR Code</Button>
            <Modal isOpen={modalIsOpen} toggle={toggleModal} >
                <ModalHeader toggle={toggleModal}>Scan a QR Code</ModalHeader>
                <ModalBody>
                    <p>Instruct your guest to open up their QR code and focus on it using the camera viewport below.</p>
                    <p>Ticket holders can find their universal QR code by navigating to the "Tickets" link/button that appears on top of The Blox Office website after logging in.</p>
                    <QrReader
                        constraints={{ facingMode: "environment" }}
                        onResult={onScan}
                        // style={{ width: '100%' }}
                        />
                    </ModalBody>
            </Modal>
            </>
    )
}

export {QrCodeScanner}