import { Component } from "react";
import Loader from "../../../../Components/Loader";
import TableSearchForm from "../../../../Components/Forms/TableSearchForm";

import {
  FaAngleRight,
  FaAngleLeft,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
} from "react-icons/fa";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

class SmartTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataPage: 0,
      dataSizePerPage: 20,
      loading: true,
      searchTerm: ""
    };
    this.loadData = this.props.loadData.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.noDataIndication = this.noDataIndication.bind(this);
  }

  _isMounted = false;

  setStateFromNewData(data, page, sizePerPage) {
    if (this._isMounted) {
      this.setState({
        data: data.results,
        dataPage: page,
        dataCount: data.count,
        dataNextPageURL: data.next,
        dataPreviousPageURL: data.previous,
        loading: false,
        dataSizePerPage: sizePerPage,
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData(1)
      .then((data) => {
        this.setStateFromNewData(data, 1, 20);
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filter, sortField, sortOrder }
  ) => {
    if (type === "search" || type === "sort") {
      page = 1;
      sizePerPage = 20;
    }

    // First, clear the table so that a loader will display while loading.
    this.setState({
      data: [],
      loading: true,
      sortField: sortField,
      sortOrder: sortOrder,
    });

    // Then, load the data from the remote server.
    this.loadData(page, this.state.searchTerm, sortField, sortOrder)
      .then((data) => {
        this.setStateFromNewData(data, page, sizePerPage);
      })
      .catch((err) => console.error(err));
  };

  handleSearchChange(searchTerm) {
    if(searchTerm !== this.state.searchTerm){
      this.setState({
        searchTerm: searchTerm,
      });
      this.handleTableChange("search", 1, 1);
    }
  }

  noDataIndication() {
    if (this.state.loading) {
      return <Loader color="#000" />;
    } else {
      return "No results";
    }
  }

  render() {
    const columns = this.props.columns;

    var page = this.state.dataPage;
    var sizePerPage = this.state.dataSizePerPage;
    var totalSize = this.state.dataCount;
    var data = this.state.data;
    return (
      <PaginationProvider
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          custom: true,
          hideSizePerPage: true,
          firstPageText: <FaAngleDoubleLeft />,
          lastPageText: <FaAngleDoubleRight />,
          prePageText: <FaAngleLeft />,
          nextPageText: <FaAngleRight />,
          nextPageTitle: "Next page",
          prePageTitle: "Previous page",
          firstPageTitle: "First page",
          lastPageTitle: "Last page",
          showTotal: true,
          alwaysShowAllBtns: true, // Always show next and previous button
          withFirstAndLast: false, // Hide the going to First and Last page button
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <div className="tableHeader">
              <p className="tableHeader-Name">{this.props.objectTitle}</p>
              <p className="tableHeader-Name">{this.state.dataCount}</p>
            </div>
            {this.props.search && (
              <TableSearchForm
                handleSearchChange={this.handleSearchChange}
              />
            )}
            <div className="table-responsive table">
              <BootstrapTable
                remote
                keyField="id"
                data={data}
                columns={columns}
                bordered={false}
                condensed={true}
                onTableChange={this.handleTableChange}
                noDataIndication={this.noDataIndication}
                {...paginationTableProps}
              />
            </div>
            <PaginationListStandalone {...paginationProps} />
          </div>
        )}
      </PaginationProvider>
    );
  }
}

export default SmartTable
