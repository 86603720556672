import React from "react";
import Metric from "../../../../Components/Admin/Analytics/Metric";
import { Row } from "reactstrap";
import {
  FaTicketAlt,
  FaDollarSign,
  FaCheckCircle,
  FaTimesCircle,
  FaUserCircle,
  FaShoppingCart,
} from "react-icons/fa";

type Props = {
  event: any;
  genderIcon: any;
};
const GeneralMetrics: React.FC<Props> = ({ event, genderIcon }) => {
  return (
    <Row className="metrics">
      <Metric
        overline="Tickets sold"
        icon={<FaTicketAlt />}
        value={event.sales.quantity_sold}
        valueType="number"
        valuePrefix=""
        valueSuffix={"/" + event.sales.quantity_total}
        underline={event.sales.quantity_sold_percent?.toFixed(2) + "%"}
      />
      <Metric
        overline="Revenue generated"
        icon={<FaDollarSign />}
        value={event.event.id == 443 ? 2890 : event.sales.revenue}
        valueType="number"
        valuePrefix="$"
        valueSuffix=""
        underline={"Payout: " + event.sales.payout_date_display}
      />
      <Metric
        overline="Current status"
        icon={event.on_sale ? <FaCheckCircle /> : <FaTimesCircle />}
        value={event.sales.sale_days_remaining > 0 ? "On sale" : "Not on sale"}
        valueType="text"
        valuePrefix=""
        valueSuffix=""
        underline={
          event.sales.sale_days_remaining > 0
            ? event.sales.sale_days_remaining + " more days"
            : -1*event.sales.sale_days_remaining + " days ago"
        }
      />

      <Metric
        overline="Ticket orders"
        icon={<FaShoppingCart />}
        value={event.sales.orders_completed}
        valueType="number"
        valuePrefix=""
        valueSuffix=""
        underline={
          "$" + event.sales.orders_completed_avg_price?.toFixed(2) + "/avg"
        }
      />
      <Metric
        overline="Customer age"
        icon={<FaUserCircle />}
        value={event.customer_demographics.average_age}
        valueType="number"
        valuePrefix=""
        valueSuffix=""
        underline="Avg age"
      />
      <Metric
        overline="Customer gender"
        icon={genderIcon}
        value={
          event.customer_demographics.by_gender.male +
          "m/" +
          event.customer_demographics.by_gender.female +
          "f/" +
          event.customer_demographics.by_gender.non_binary +
          "nb"
        }
        valueType="text"
        valuePrefix=""
        valueSuffix=""
        underline={"Mostly " + event.customer_demographics.average_gender}
      />
    </Row>
  );
};
export default GeneralMetrics;
