import React from "react";
import PromoCodeForm from "../Forms/PromoCodeForm/PromoCodeForm";
import AdminLayout from "../../Layouts/AdminLayout";
import { getPromoCode } from "../../Lib/RESTBlox";

type Props = {
  user: any;
};

const PromoCodeChange: React.FC<Props> = ({ user }) => {
  const [promoCodeId, setPromoCodeId] = React.useState<string | null>(
    window.location.pathname.split("/").pop() || null
  );
  const [promoCode, setPromoCode] = React.useState<any>();

  React.useEffect(() => {
    if (promoCodeId) {
      getPromoCode(promoCodeId)
        .then((data) => {
          setStateFromNewData(data);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  const setStateFromNewData = (data: any) => {
    setPromoCode(data);
  };

  return (
    <AdminLayout title="Change promo code">
      {promoCode && <PromoCodeForm promoCode={promoCode} user={user} />}
    </AdminLayout>
  );
};

export default PromoCodeChange;
