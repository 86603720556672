import React from "react";
import { getAdminEvents } from "../../Lib/RESTBlox";
import Loader from "../../Components/Loader";
import EventTile from "../../Components/Admin/EventTile";
import AdminLayout from "../../Layouts/AdminLayout";
import InfiniteScroll from "react-infinite-scroll-component";
import { Typography } from "@mui/material";
import { IEventIn } from "../../interfaces/events/events";
import EventTileNew from "../../Components/Admin/EventTileNew";

type Props = {
  eventType: string;
  user: any;
};

const EventList: React.FC<Props> = ({ eventType, user }) => {
  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState<IEventIn[]>([]);
  const [page, setPage] = React.useState<number>(0);

  const setStateFromNewData = (data: IEventIn[]) => {
    setEvents(events.concat(data));
    setLoading(false);
  };

  const loadData = () => {
    getAdminEvents(eventType, page + 1)
      .then((data) => {
        setPage(page + 1);
        setStateFromNewData(data.results);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getAdminEvents(eventType, 1)
      .then((data: any) => {
        setEvents(data.results);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [eventType]);

  return (
    <AdminLayout
      title={eventType + " events"}
      linkText={
        eventType === "Past" ? "View Upcoming Events" : "View Past Events"
      }
      to={
        eventType === "Past" ? "/admin/events/upcoming" : "/admin/events/past"
      }
    >
      {loading ? (
        <Loader loading={loading} color="#000" />
      ) : (
        <div className="BloxAdminContentContainer noPadding">
          {events?.length > 0 ? (
            <InfiniteScroll
              dataLength={events?.length}
              next={loadData}
              hasMore={true}
              loader={<h4>Loading...</h4>}
            >
              {eventType === "Upcoming" && <EventTileNew />}
              {events?.map((event, index: number) => {
                return (
                  <EventTile
                    event={event}
                    key={"EventTile" + event?.id + index}
                    user={user}
                  />
                );
              })}
            </InfiniteScroll>
          ) : (
            <Typography variant="h6">No events</Typography>
          )}
        </div>
      )}
    </AdminLayout>
  );
};

export default EventList;
