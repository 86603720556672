import { Button, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import BodyBox from "./BodyBox";

type Props = {
  name: string;
  status: string;
  date: string;
  time: string;
  id: string;
  number: string;
  total: number;
  eventTitle: string;
  eventDescription: string;
  num_tickets_issued: string;
};

export default function OrdersTable(props: Props) {
  const {
    name,
    status,
    date,
    time,
    id,
    number,
    total,
    eventTitle,
    eventDescription,
    num_tickets_issued,
  } = props;
  const navigate = useNavigate();
  return (
    <Grid container key={id} style={{borderBottom: "solid 1px #e0e0e0"}}>
      <Grid item xs={4} xl={4} md={4} lg={4}>
        <TableCell  style={{border:"none", paddingLeft:0,paddingTop: "20px"}}>
          <BodyBox
            texts={[eventTitle, eventDescription]}
            styles={[
              { fontWeight: 500, color: "#222222", alignSelf: "flex-start", textTransform: "uppercase", fontSize:"initial" },
              { fontSize: ".8rem", color: "#99abaa", alignSelf: "flex-start" },
            ]}
          />
        </TableCell>
      </Grid>
      <Grid item xs={2} xl={2} md={2} lg={2} container justifyContent="end">
        <TableCell align="center" style={{border:"none", paddingRight:0}}>
          <BodyBox
            texts={[date, time]}
            styles={[
              { fontWeight: 500, color: "#000", alignSelf: "flex-end",fontSize:"initial"  },
              { fontSize: ".8rem", color: "#99abaa", alignSelf: "flex-end" },
            ]}
          />
        </TableCell>
      </Grid>
      <Grid item xs={2} xl={2} md={2} lg={2} container justifyContent="center">
        <TableCell align="right" style={{border:"none"}}>
          <BodyBox
            texts={["$" + total]}
            styles={[
              { fontWeight: 500, fontSize: "initial", color: "#000", alignSelf: "center" },
            ]}
          />
        </TableCell>
      </Grid>
      <Grid item xs={2} xl={2} md={2} lg={2} container justifyContent="center">
        <TableCell align="right" style={{border:"none"}}>
          <BodyBox
            texts={[num_tickets_issued]}
            styles={[
              { fontWeight: 500, fontSize: "initial", color: "#000", alignSelf: "center" },
            ]}
          />
        </TableCell>
      </Grid>
      <Grid item xs={2} xl={2} md={2} lg={2} container justifyContent="center">
        <TableCell align="center" style={{border:"none"}}>
          <Button
              style={{padding: 0}}
            onClick={() => {
              navigate("/orders/" + id);
            }}
          >
            <BodyBox
              texts={[number]}
              styles={[{ fontWeight: 600, color: "#007bff", opacity: "0.75" }]}
            />
          </Button>
        </TableCell>
      </Grid>
    </Grid>
  );
}
