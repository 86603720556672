import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { getArtist } from '../Lib/RESTBlox'

type Props = {
  // history: any
};

const ArtistList: React.FC<Props> = (props) => {

const [artists, setArtists] = React.useState<any>([]);
const [loading, setLoading] = React.useState<boolean>(true);

  const setStateFromNewData = (data:any) => {
    setArtists(data);
    setLoading(false);
  }

  const loadData = () => {
    getArtist(window.location.pathname.split("/").pop() ?? "")
      .then(data => setStateFromNewData(data))
      .catch(err => console.error(err.toString()))
  }

  React.useEffect(() => {
    loadData()
  }, []);

    return (
      <Row className="ArtistDetail clearHeaderPlus">
        <Col>
          <p>Artist detail page.</p>
        </Col>
      </Row>
    )
}

export default ArtistList;
