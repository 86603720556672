import React from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import { FormField } from '../Forms/Fields'
import { validateEmail } from '../../Lib/FormValidation'
import FlashAlert from '../FlashAlert'
import PasswordForgotModal from './PasswordForgotModal'
import { signIn } from '../../Lib/RESTAuth'
import SignUpModal from '../Modals/SignUpModal'


class SignInModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      buttonText: this.props.buttonText || "Sign in",
      block: this.props.block || false,
      outline: this.props.outline || false,
      alert: {
        type: '',
        message: ''
      },
      preventLogin:false
    };
    this.toggle = this.toggle.bind(this)
    this.setpreventLogin = this.setpreventLogin.bind(this)
    this.handleSignIn = this.handleSignIn.bind(this)
    this.postSignIn = this.props.postSignIn.bind(this)
    this.postSignUp = this.props.postSignUp.bind(this)
    this.postPasswordForgot = this.props.postPasswordForgot.bind(this)
    if ('closeNavbar' in this.props) {
      this.closeNavbar = this.props.closeNavbar.bind(this)
    }
  }

  _isMounted = false

  toggle() {
    if (this._isMounted) {
      this.setState({
        modal: !this.state.modal
      });
    }
  }

  setpreventLogin(value){
    this.setState({
      preventLogin:value
    })
  }

  handleSignIn(email, password) {
    if (!this.state.preventLogin){
      signIn(email, password)
        .then(json => {
          this.postSignIn(json)
          this.toggle()
          if ('closeNavbar' in this.props) { this.closeNavbar() }
        })
        .catch(err => {
          console.log(err)
          this.setState({
            alert: {
              type: 'danger',
              message: err.response.errorMessage
            }
          })
        })
    }
  }

  componentDidMount() {
    this._isMounted = true
    // if (this.props.onOpen) {
    //   this.props.onOpen()
    // }
    if (this.props.open) {
      this.toggle()
      this.setState({
        alert: {
          type: 'info',
          message: 'You must sign in to view this.'
        }
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <div>
        {this.props.displayButton !== false &&
          <Button
            className="hvr-grow-shadow"
            color="primary"
            outline={this.state.outline}
            size="sm"
            block={this.state.block}
            onClick={this.toggle}
          >
              { this.state.buttonText }
          </Button>
        }
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values, actions) => {
            this.handleSignIn(values.email, values.password)
            actions.setSubmitting(false);
          }}
          render={(props ) => (

            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
              <ModalHeader toggle={this.toggle}>
                Sign in or
                <SignUpModal
                  postSignIn = {this.postSignIn}
                  postSignUp = {this.postSignUp}
                  postPasswordForgot = {this.postPasswordForgot}
                  closeNavbar = {this.closeNavbar}
                  outline
                  onOpen = {() => this.setState({
                    modal: false
                  })}
                />
              </ModalHeader>
              <ModalBody>
                <Form>
                  { this.state.alert.message && (this.state.alert.type === 'info' ||  this.state.alert.type === 'danger') &&
                    <Row>
                      <Col>
                        <FlashAlert
                          color={this.state.alert.type}
                          text={this.state.alert.message}
                          dontDismiss
                        />
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col>
                      <Field
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Your email address..."
                        component={FormField}
                        required={true}
                        validate={validateEmail}
                        autoComplete="username"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        label="Password"
                        type="password"
                        name="password"
                        placeholder="Your password..."
                        component={FormField}
                        required={true}
                        autoComplete="current-password"
                      />
                    </Col>
                  </Row>
                  <Row noGutters className="buttonRow">
                    <Col className="pr-2">
                      <PasswordForgotModal postPasswordForgot = { this.postPasswordForgot } toggleParent= {this.toggle} setpreventLogin={this.setpreventLogin}/>
                    </Col>
                    <Col className="p1-2">
                      <Button className="hvr-grow-shadow" block color="primary" type="submit">Sign in</Button>{' '}
                    </Col>
                  </Row>
                </Form>
            </ModalBody>
          </Modal>
          )}
        />
      </div>
    );
  }
}

export default SignInModal
