import React from "react";
import Metric from "../../../Components/Admin/Analytics/Metric";
import { Row } from "reactstrap";
import { FaTrafficLight, FaFacebookSquare } from "react-icons/fa";
import { ReactComponent as RALogo } from "../../../Components/Admin/Analytics/images/ra_logo.svg";
type Props = {
  event: any;
  eventSubpromoterData:any
};
const TraficMetrics: React.FC<Props> = ({ event,eventSubpromoterData }) => {
  console.log(event.traffic.page_views)
  console.log(eventSubpromoterData.views)
  return (
    <Row className="metrics">
      <Metric
        overline="Web page traffic"
        icon={<FaTrafficLight />}
        value={eventSubpromoterData.views}
        valueType="number"
        valuePrefix=""
        valueSuffix=""
        underline="Views"
      />
      <Metric
        overline="Traffic from Facebook"
        icon={<FaFacebookSquare />}
        value={event.traffic.page_views_from_facebook}
        valueType="number"
        valuePrefix=""
        valueSuffix=""
        underline="Views"
      />
      <Metric
        overline="Traffic from RA"
        icon={<RALogo />}
        value={event.traffic.page_views_from_ra}
        valueType="number"
        valuePrefix=""
        valueSuffix=""
        underline="Views"
      />
    </Row>
  );
};
export default TraficMetrics;
