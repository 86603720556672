import React, { Component } from "react"
import VenueForm from '../Forms/VenueForm'
import AdminLayout from '../../Layouts/AdminLayout'

class VenueAdd extends Component {
  _isMounted = false;

  render() {
    return (
      <AdminLayout title="Add venue">
        <VenueForm />
      </AdminLayout>
    );
  }
}

export default VenueAdd
