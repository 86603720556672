import React, { Component } from "react";
import { useParams } from "react-router-dom";
import {Row, Col, ButtonGroup} from "reactstrap";
import ButtonLink from '../Components/ButtonLink'
import {getPage} from '../Lib/RESTBlox'

type Props = {
};

const Page : React.FC<Props> = (props) => {

  const [page, setPage] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const params = useParams();
  const setStateFromNewData = (data:any) => {
    setPage(data);
    setLoading(false);
  }

  const loadData = (pageSlug:any) => {
    getPage(pageSlug)
      .then(data => setStateFromNewData(data))
      .catch(err => console.error(err.toString()))
  }

  React.useEffect(() => {
    loadData(params.slug)
  }, [params.slug]);

    return (
      <Row className="clearHeaderAndButtonGroup">
        <Col>
          { page &&
            <div className="buttonGroupContainer">
              <ButtonGroup>
                <ButtonLink
                  to="/page/website-terms"
                  text="Terms"
                  disabled={page.slug === 'website-terms' ? true : false}
                  size="md"
                />
                <ButtonLink
                  to="/page/privacy-policy"
                  text="Privacy"
                  disabled={page.slug === 'privacy-policy' ? true : false}
                  size="md"
                />
                <ButtonLink
                  to="/page/purchase-terms"
                  text="Purchase Terms"
                  disabled={page.slug === 'purchase-terms' ? true : false}
                  size="md"
                />
              </ButtonGroup>
            </div>
          }
          <Row>
            { page &&
              <Col
                md={{ size: 6, offset: 3 }}
                dangerouslySetInnerHTML={{__html: page.content_html}}
              />
            }
          </Row>
        </Col>
      </Row>
    );
}

export default Page
