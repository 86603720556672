import React, { useEffect } from "react";
import EventForm from "../Forms/EventForm/EventForm";
import { getAdminEvent } from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";

type Props = {
  user: any;
};

const EventChange: React.FC<Props> = ({ user }) => {
  const [event, setEvent] = React.useState<any>();

  useEffect(() => {
    getAdminEvent(window.location.pathname?.split("/")?.pop() as string, true)
      .then((data) => setEvent(data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <AdminLayout title="Change event">
      {event && (
        <EventForm
          event={event.event}
          ticket_inventories={event.ticket_inventories}
          user={user}
        />
      )}
    </AdminLayout>
  );
};

export default EventChange;
