import { Box } from "@mui/material";

type Props = {
    texts: string[],
    styles: React.CSSProperties[],
    boxStyle?: React.CSSProperties,
};

const BodyBox = (props: Props) => {
    const { texts, styles, boxStyle } = props;

    return (
        <Box sx={ boxStyle ?? {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {texts.map((item, index) => {
                return (
                    <Box sx={styles[index]}>
                        {item}
                    </Box>
                )
            })}
        </Box>
    );
};

export default BodyBox;