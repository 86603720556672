import React from "react";
import { getAdminUsers, getOrdersByEvent } from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";
import { IUser } from "../../interfaces/user/user";
import TableSearchForm from "../../Components/Forms/TableSearchForm";
import OrdersTable from "./components/OrdersTable/OrdersTable";
import { IOrdersIn } from "../../interfaces/orders/orders";

type Props = {};

const OrderByEvent: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState(true);
  const [orders, setOrders] = React.useState<IOrdersIn[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [hasMore, setHasMore] = React.useState<boolean>(true);

  React.useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    const eventId = window.location.pathname.split("/").pop();
    getOrdersByEvent(+(eventId as string),page+1)
      .then((data: any) => {
        setDataCount(data.count);
        setHasMore(data?.next ? true : false);
        setPage(page + 1);
        setOrders(orders.concat(data.results));
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const handleSearchChange = (searchTerm: string) => {
    setLoading(true);
    const eventId = window.location.pathname.split("/").pop();
    getOrdersByEvent(+(eventId as string),1 as any, searchTerm as any, null, null)
      .then((data) => {
        setDataCount(data?.count);
        setHasMore(data?.next ? true : false);
        setOrders(data?.results);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <AdminLayout title="Users" isWhite={true}>
      <div className="BloxAdminContentContainer">
        <div className="tableHeader">
          <p className="tableHeader-Name">Orders</p>
          <p className="tableHeader-Name">{dataCount}</p>
        </div>
        <TableSearchForm handleSearchChange={handleSearchChange} />
        <OrdersTable orders={orders} loadData={loadData} loading={loading} hasMore={hasMore}/>
      </div>
    </AdminLayout>
  );
};

export default OrderByEvent;
