import React, {MouseEventHandler, useCallback, useEffect, useState} from "react";
import { Row, Col, Table, Button, Input } from "reactstrap";
import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
} from "react-select";
import ContentLayout from "../../Layouts/ContentLayout";
import {getRoles, getStaff, addStaffMember, deleteStaffMember} from "../../Lib/RESTBlox";
import {SortableElement, SortableHandle} from "react-sortable-hoc";
import Loader from "../../Components/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import FlashAlert from "../../Components/FlashAlert";
import {ConfirmModal} from "../../Components/Admin/Modals/ConfirmModal";


const SortableMultiValue = SortableElement((props: MultiValueProps) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(
    (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);


type Props = {
  user: any;
};
const StaffManagement: React.FC<Props> = ({ user }) => {
  const [staff, setStaff] = useState<unknown[]>([]);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState<unknown[]>([]);
  const [error, setError] = useState('');

  const [newEmail, setNewEmail] = useState("");
  const [newRole, setNewRole] = useState<{value: string, label: string}>({value: "", label: ""});

  useEffect(() => {
    console.log(staff)
  }, [staff])

  const handleAddStaff = useCallback((e) => {
    e.preventDefault();
    addStaffMember({email: newEmail, role: newRole.value}).then((newMember) => {
      setStaff(prevStaff => [...prevStaff, newMember].sort((a, b) => a.first_name.localeCompare(b.first_name)));
      setNewEmail("");
      setNewRole({value: "", label: ""});
      getStaff().then((staff) => {
        setStaff(staff);
      });
    }).catch((err: any) => {
      console.log(err);
      setError(err.detail || 'An error occurred while adding the staff member');
    });
  }, [newEmail, newRole]);

  const handleDeleteStaff = useCallback(async (id) => {
    try {
      await deleteStaffMember(id);
      setStaff(prevStaff => prevStaff.filter((staffMember: any) => staffMember.pk != id));
      return
    } catch (err: any) {
        console.error(err);
        setError(err.detail || 'An error occurred while deleting the staff member.');
        return
    } finally {
    const newStaff = await getStaff();
    setStaff(newStaff);
    }
  }, [])

  useEffect(() => {
      let dismissTimer = setTimeout(() => setError(''), 5000)
      // Clear the timer so it only runs once.
      return () => {
        clearTimeout(dismissTimer)
      }

  }, [error])

  useEffect(() => {
    const loadData = async () => {
      try {
      const data = await getStaff();
      const rolesData = await getRoles();
      setStaff(data);
      setRoles(rolesData.map((role: any) => ({ value: role.slug, label: role.name })));
      } catch(err: any) {
        console.log(err);
        setError(err.detail || 'An error occurred while loading the staff');
      }


      setLoading(false)
    }
    loadData();
  }, [])

  return (
      <ContentLayout title={<div style={{display: 'flex', gap: '0.5rem'}}>Staff Management <div style={{fontSize: '10px', fontWeight: 700, backgroundColor: '#cacaca', color: 'black', padding: '4px', textTransform: 'uppercase', borderRadius: '4px', alignSelf: 'flex-start'}}>Beta</div></div>}>
      {loading ? <Loader color='#000' loading={loading} /> :
      (<div style={{backgroundColor: '#fff', width: '100%', minHeight: '100vh', padding: '1rem'}}>
      <Table size="sm" responsive={true} className="">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col" className="right-align">role</th>
            <th scope="col" className="right-align noPrint">Actions</th>
          </tr>
        </thead>
        <tbody className="inner">
        {staff.map((item: any, index: number) => (
            <tr key={index}>
              <td style={{verticalAlign: 'middle', textTransform: 'none'}} className="left-align">{item.first_name} {item.last_name}</td>
              <td style={{verticalAlign: 'middle', textTransform: 'none'}} className="left-align">{item.email}</td>
              <td style={{verticalAlign: 'middle', textTransform: 'none'}} className="right-align">{item?.profile?.roles[0]?.name}</td>
              <td style={{verticalAlign: 'middle', textTransform: 'none'}} className="right-align">
                { user.pk !== item.pk && user.profile.permissions_list?.includes("edit-staff") && (<ConfirmModal
                    buttonProps={{style: {
                      background: "#eb0014",
                      padding: "3px",
                      borderRadius: '4px',
                      color: "white",
                      border: 'none',
                      margin: '4px'

                    }}}
                    bodyText={`Are you sure you want to remove ${item.first_name} ${item.last_name} from your staff?`}
                    confirmLabel="Delete"
                    onConfirm={() => handleDeleteStaff(item.pk)}
                >
                  <DeleteIcon/>
                </ConfirmModal>) }
              </td>
            </tr>
        ))}
        </tbody>
      </Table>
      { user.profile.permissions_list?.includes("edit-staff") && (
        <form onSubmit={handleAddStaff} style={{padding: '1rem'}}>
          <h3 style={{
            color: 'black',
            fontSize: '1.3rem',
            fontWeight: '700',
            textTransform: 'uppercase',
            margin: 0,
            padding: 0,
            marginBottom: '1rem'
          }}>Add a Staff Member</h3>
          <p style={{color: 'black'}}>
            Enter the email address of the staff member you wish to add, and select their role.&nbsp;
            <strong>
              Note that they must already have a Blox Office account before you can add them to your staff.
            </strong>
          </p>
          <Row>
            <Col sm={12} md={6}>
              <label>Email
                <Input value={newEmail} onChange={(e: any) => setNewEmail(e.target.value)} required type="email"/>
              </label>
            </Col>
            <Col sm={12} md={6}>
              <label>Role
                <Select
                    options={roles}
                    value={newRole}
                    onChange={(e: any) => setNewRole(e)}
                    components={{
                      MultiValue: SortableMultiValue,
                      MultiValueLabel: SortableMultiValueLabel,
                    }}
                    closeMenuOnSelect={false}

                />
              </label>
            </Col>
          </Row>
          <Row>
            <Button
                style={{
                  width: "50%",
                  background: newEmail === "" || newRole.value === "" ? '#c3c3c3' : "#17a2b8",
                  margin: 'auto',
                  marginTop: '1rem'
                }}
                variant="contained"
                disabled={newEmail === "" || newRole.value === ""}
            >
              Add Staff
            </Button>
          </Row>
        </form>
      )}
      </div>)}
      {error && <FlashAlert text={error} color="danger"  />}
    </ContentLayout>
  );
};

export default StaffManagement
