import React from 'react';

function EventPurchaseTableDetailsRow(props) {
  return (
    <tr>
      <td colSpan="3" className="inventoryDetails">
        <span dangerouslySetInnerHTML={{__html: props.inventory.description_html}} />
      </td>
    </tr>
  )
}

export default EventPurchaseTableDetailsRow
