import React from "react";
import SortableItem from "./SortableItem";
import { SortableContainer } from "react-sortable-hoc";
import { Grid } from "@mui/material";
import { Button } from "reactstrap";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import ButtonLink from "../../../../../Components/ButtonLink";

type Props = {
  items: any;
  handleDeleteTicketInventory: any;
  onSortEnd: any;
  isMobile: boolean;
  isMobileSmart: boolean;
};

const SortableList = (props: Props) => {
  return (
    <ul
      style={{ listStyleType: "none", paddingInlineStart: "0", width: "100%" }}
    >
      {props.items?.map((value: any, index: any) => {
        return (
          <Grid container className="ticketRow">
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={value}
              isMobile={props.isMobile}
              isMobileSmart={props.isMobileSmart}
            />
            <Grid
              container
              justifyContent="center"
              justifyItems="center"
              item
              lg={props.isMobileSmart ? 3.375 : 2}
              xl={props.isMobileSmart ? 3.375 : 2}
              md={props.isMobileSmart ? 3.375 : 2}
              xs={props.isMobileSmart ? 3.375 : 2}
              spacing={1}
            >
              <Grid item lg={6} xl={6} md={6} xs={6}>
                <ButtonLink
                  block
                  to={"/admin/events/ticket-inventories/edit/" + value.id}
                  text={<FaEdit style={{ marginRight: 0 }} />}
                  size="sm"
                  color="secondary"
                />
              </Grid>
              <Grid item lg={6} xl={6} md={6} xs={6}>
                <Button
                  block
                  size="sm"
                  color="danger"
                  disabled={value?.quantity !== value?.quantity_available}
                  onClick={() => props.handleDeleteTicketInventory(value.id)}
                >
                  <FaTrashAlt style={{ marginRight: 0 }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </ul>
  );
};

export default SortableContainer(SortableList);
