import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { Box, Grid } from "@mui/material";
import { Column, Area } from "@ant-design/plots";
import {
  DayliRevenueFetch,
  DayliTicketsFetch,
  totalRevenuesFetch,
  totalTicketsFetch,
  getTotalRevenueByTypePIE,
  getTotalTicketsByTypePIE,
} from "../../../Lib/Helpers";
import {
  getDailyRevenueConfig,
  getDailyTicketsConfig,
  getTotalRevenueConfig,
  getTotalTicketsConfig,
} from "../../../Lib/GraphConfig";
import { IAdminEventDetailsIn } from "../../../interfaces/events/adminEventDetails";

type Props = {
  event: IAdminEventDetailsIn;
};

const EventAnalyticsPanel: React.FC<Props> = ({ event }) => {
  const [dailyRevenue, setDailyRevenue] = React.useState<any>([]);
  const [DayliTickets, setDayliTickets] = React.useState<any>([]);
  const [totalRrevenue, setTotalRrevenue] = React.useState<any>([]);
  const [totalTickets, setTotalTickets] = React.useState<any>([]);

  React.useEffect(() => {
    DayliRevenueFetch(event, setDailyRevenue);
    DayliTicketsFetch(event, setDayliTickets);
    totalRevenuesFetch(event, setTotalRrevenue);
    totalTicketsFetch(event, setTotalTickets);
  }, []);

  const colors = [
    "#517396",
    "#976e3d",
    "#263556",
    "#6A8835",
    "#9a171a",
    "#bc467a",
    "#767676",
    "#d1d00c",
    "#287787",
    "#4e9dba",
    "#7e7b6f",
    "#5c4fe3",
    "#bfd334",
    "#211881",
    "#33ffbc",
    "#585f5f"
  ];

  return (
    event && (
      <Grid container className="eventAnalytics">
        <Grid container alignItems="start" justifyContent="center">
          <Grid
            item
            lg={2.5}
            xl={2.5}
            md={2.5}
            xs={6}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <p className="chart-title">Total Revenue By Type</p>
            <PieChart
              radius={50}
              style={{ width: "55%" }}
              data={getTotalRevenueByTypePIE(event.sales.by_inventory, colors)}
            />
          </Grid>
          <Grid
            item
            lg={2.5}
            xl={2.5}
            md={2.5}
            xs={6}
            style={{ textAlign: "center" }}
          >
            <p className="chart-title">Total Tickets By Type</p>
            <PieChart
              radius={50}
              style={{ width: "55%" }}
              data={getTotalTicketsByTypePIE(event.sales.by_inventory, colors)}
            />
          </Grid>
          <Grid item lg={7} xl={7} md={7} xs={12}>
            <table className="tickets-table">
              <thead>
                <tr>
                  <th className="analitics-tickets-name">Ticket Type</th>
                  <th
                    className="analitics-qty-name"
                    style={{ textAlign: "right", padding: "0 !important" }}
                  >
                    Tickets
                  </th>
                  <th
                    className="analitics-price-name"
                    style={{ textAlign: "right" }}
                  >
                    Revenue
                  </th>
                </tr>
              </thead>
              <tbody>
                {event?.sales?.by_inventory &&
                  event.sales.by_inventory.map((sale: any, index: number) => (
                    <tr className="analitics-table-row">
                      <td className="analitics-tickets-type">
                        <Box
                          sx={{
                            width: 30,
                            height: 30,
                            backgroundColor: colors[index],
                            marginRight: "10px",
                          }}
                        />
                        {sale.label}
                      </td>
                      <td>{sale.quantity_sold}</td>
                      <td>${sale.revenue}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            lg={6}
            xl={6}
            md={6}
            xs={12}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <p className="chart-title">Daily Revenue By Type</p>
            <Column
              {...getDailyRevenueConfig(dailyRevenue)}
              color={colors}
              style={{ height: "200px" }}
            />
          </Grid>
          <Grid
            item
            lg={6}
            xl={6}
            md={6}
            xs={12}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <p className="chart-title">Daily Tickets By Type</p>
            <Column
              {...getDailyTicketsConfig(DayliTickets)}
              color={colors}
              style={{ height: "200px" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          style={{ marginTop: "30px" }}
        >
          <Grid
            item
            lg={6}
            xl={6}
            md={6}
            xs={12}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <p className="chart-title">Total Revenue</p>
            <Area
              {...getTotalRevenueConfig(totalRrevenue)}
              style={{ height: "200px" }}
            />
          </Grid>
          <Grid
            item
            lg={6}
            xl={6}
            md={6}
            xs={12}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <p className="chart-title">Total Tickets</p>
            <Area
              {...getTotalTicketsConfig(totalTickets)}
              style={{ height: "200px" }}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default EventAnalyticsPanel;
