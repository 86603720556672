import React, {ReactNode, useCallback, useState} from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loader from "../../Loader";



interface ConfirmModalProps {
    bodyText?: string,
    buttonProps?: object,
    children: ReactNode,
    modalProps?: object,
    heading?: string,
    confirmLabel?: string,
    submitButton?: boolean,
    onConfirm?: null | (() => Promise<void>) ,
}

const ConfirmModal = ( {bodyText = 'Please confirm to continue.', confirmLabel = 'Confirm', buttonProps = {}, children, heading = 'Please confirm', modalProps = {}, submitButton = false, onConfirm = null}: ConfirmModalProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(prev => !prev);
    const close = () => setIsOpen(false);
    const [loading, setLoading] = useState(false);

    const handleConfirm = useCallback(async () => {
        setLoading(true);
        if (onConfirm) {
            await onConfirm();
        }
        setLoading(false);
        close();
    }, [onConfirm, close]);

    return(
        <>
            <button style={{ backgroundColor: 'none', outline: 'none', border: 'none', cursor: 'pointer'}} onClick={() => setIsOpen(true)} {...buttonProps}>{children}</button>
            <Modal {...modalProps}  isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>{heading}</ModalHeader>
                <ModalBody >
                    <Row style={{margin: 0}}>
                       <p>{bodyText}</p>
                    </Row>
                    <Row>
                        <div style={{height: '32px'}}>
                            {loading && <Loader color='#000' loading={loading} />}
                        </div>
                    </Row>
                    <Row className="buttonRow">
                        <Col xs={6} className="pr-1">
                            <Button
                                onClick={close}
                                text="Cancel"
                                block
                                color="secondary"
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col xs={6} className="pl-1">
                            <Button className="hvr-grow-shadow" block color="primary" type={submitButton ? 'submit' : 'button'} onClick={handleConfirm}>{confirmLabel}</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export { ConfirmModal }
