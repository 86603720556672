import React, { CSSProperties } from "react";
import { BounceLoader } from "react-spinners";

type Props = {
  style?: CSSProperties;
  color: string;
  loading: boolean;
  size?: number;
};

function Loader(props: Props) {
  return (
    <div className="Loader" style={props.style}>
      <BounceLoader
        sizeUnit={"px"}
        size={props.size || 60}
        color={props.color}
        loading={props.loading}
      />
    </div>
  );
}

export default Loader;
