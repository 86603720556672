import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { getTicket, transferTicket } from "../Lib/RESTBlox";
import Loader from "../Components/Loader";
import Header from "../Components/Header";
import TicketTransferForm from "../Components/Forms/TicketTransferForm";
import { AWSAlternative, PublicImagePath } from "../constants/constants";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router";

type Props = {
  user: any;
};

const TicketDetail: React.FC<Props> = ({ user }) => {
  const [ticket, setTicket] = React.useState<any>();
  const [submissionComplete, setSubmissionComplete] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState(false);
  const [submissionErrorMessage, setSubmissionErrorMessage] =
    React.useState("");
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [transfering, setTransfering] = React.useState<boolean>(false);

  const setStateFromNewData = (data: any) => {
    setTicket(data);
    setLoading(false);
  };

  const loadData = (ticketId: any) => {
    setLoading(true);
    getTicket(ticketId)
      .then((data) => {
        setStateFromNewData(data);
      })
      .catch((err) => {
        console.error(err.toString());
        setLoading(false);
      });
  };

  const handleTicketTransfer = (data: any) => {
    console.log(data);
    if (params.id) {
      setTransfering(true);
      transferTicket(params.id, data.email)
        .then((data: any) => {
          setTicket(data.ticket);
          setSubmissionComplete(true);
          setTransfering(false);
        })
        .catch((err: any) => {
          setTransfering(false);
          setSubmissionError(true);
          setSubmissionErrorMessage(
            "Unexpected Error Submission could not be done"
          );
        });
    }
  };

  React.useEffect(() => {
    const ticketId = window.location.pathname.split("/").pop();
    if (ticketId) {
      loadData(ticketId);
    }
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      style={{ height: "100%", maxWidth: "1400px", margin: "0 auto" }}
    >
      <Grid
        item
        xs={12}
        lg={6}
        xl={6}
        md={6}
        justifyContent="center"
        alignContent="flex-start"
        container
        style={{ marginTop: "10px", padding: "20px" }}
      >
        {ticket && (
            <div className="" style={{marginTop: '70px'}}>
              <img src={ticket?.event?.image} style={{maxHeight: '275px', maxWidth: '95vw'}}/>
              <div
                  style={{
                    // backgroundImage:"url(" +PublicImagePath +ticket.ticket?.event?.image +"), url(" +AWSAlternative +ticket.ticket?.event?.image +"), url(" +ticket?.event?.image +")",
                  }}
              />
            </div>
        )}
        
        {ticket !== undefined ? (
          <div>
            <Header
              className="clearHeader"
              content="Your ticket"
              secondaryContent={"#" + ticket?.number}
            />
            <p>
              This is a {ticket?.ticket?.inventory?.name} ticket for the event{" "}
              {ticket?.event?.title}. It was purchased by{" "}
              {ticket?.purchaser?.first_name} {ticket?.purchaser?.last_name},
              and is currently held by {ticket?.holder?.first_name}{" "}
              {ticket?.holder?.last_name}.
            </p>
            <TicketTransferForm
              handleTicketTransfer={handleTicketTransfer}
              submissionError={submissionError}
              loading={transfering}
              submissionErrorMessage={submissionErrorMessage}
              submissionComplete={submissionComplete}
              ticket={ticket}
              ticketId={ticket?.id}
              user={user}
            />
          </div>
        ):<Loader loading={true} color="#000" style={{left:"75%"}}/>}
      </Grid>
    </Grid>
  );
};

export default TicketDetail;
