import React, { useEffect, MouseEventHandler } from "react";
import { Label, FormText } from "reactstrap";
import { getTicketTypes } from "../../../../../Lib/RESTBlox";
import {
  components,
  MultiValueGenericProps,
  MultiValueProps,
} from "react-select";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import CreatableSelect from "react-select/creatable";

const SortableMultiValue = SortableElement((props: MultiValueProps) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);

type Props2 = {
  field: any;
  form: any;
  label: boolean;
  required: boolean;
  help: any;
  placeholder: any;
  disabled: any;
};

const FormTicketTypePicker: React.FC<Props2> = ({
  field,
  form,
  label,
  required,
  help,
  placeholder,
  disabled,
}) => {
  const [value, setValue] = React.useState<any>(field.value);
  const [ticketTypes, setTicketTypes] = React.useState<any[]>([]);
  const [page, setPage] = React.useState<number>(1);

  const handleChange = (value: any) => {
    console.log(value);
    setValue(value);
    form.setFieldValue(field.name, value.value);
    return { value };
  };

  const handleGetMore = (value: any) => {
    getItems(page);
  };

  useEffect(() => {
    getItems();
  }, []);

  const getItems = (page = 0) => {
    getTicketTypes(page + 1)
      .then((data: any) => {
        var fetchedTicketType = data?.results?.map(
          (ticketType: any, i: number) => {
            return {
              value: ticketType.id,
              label: ticketType.name,
            };
          }
        );
        setPage(page + 1);
        setTicketTypes(ticketTypes.concat(fetchedTicketType));
        if (field.value) {
        }
      })
      .catch((err) => console.error(err));
  };

  const promiseOptions = (inputValue: string) => {
    getTicketTypes(1, inputValue)
      .then((data: any) => {
        var fetchedTicketType = data.results.map(
          (ticketType: any, i: number) => {
            return {
              value: ticketType.id,
              label: ticketType.name,
            };
          }
        );
        setTicketTypes(fetchedTicketType);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Label for={field.name}>
        {label}
        {required ? (
          <span className="required">*</span>
        ) : (
          <span className="optional">Optional</span>
        )}
      </Label>
      <CreatableSelect
        className="select-border"
        onMenuScrollToBottom={handleGetMore}
        options={ticketTypes}
        onInputChange={promiseOptions}
        value={value}
        onChange={handleChange}
        components={{
          MultiValue: SortableMultiValue,
          MultiValueLabel: SortableMultiValueLabel,
        }}
        closeMenuOnSelect={false}
      />
      <div
        className={
          form.touched[field.name] && form.errors[field.name]
            ? "error active"
            : "error inactive"
        }
      >
        {form.touched[field.name] && form.errors[field.name] ? (
          <span>{form.errors[field.name]}</span>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
      {help && <FormText>{help}</FormText>}
    </>
  );
};

export default FormTicketTypePicker;
