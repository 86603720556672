import { ColumnConfig, AreaConfig } from "@ant-design/plots";

export function getDailyRevenueConfig(DayliRevenue: any): ColumnConfig {
  const dailyRevenueConfig: ColumnConfig = {
    data: DayliRevenue,
    isStack: true,
    xField: "date",
    yField: "revenue",
    xAxis: {
      line: {
        style: {
          stroke: "#000000",
        },
      },
      label: { rotate: -1.24, offsetX: -25 },
    },
    yAxis: {
      line: {
        style: {
          stroke: "#aaaaaa",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "#aaaaaa",
          },
        },
      },
    },
    legend: {
      position: "top-right",
    },
    seriesField: "type",
    columnWidthRatio: 1,
  };
  return dailyRevenueConfig;
}

export function getDailyTicketsConfig(DayliTickets: any): ColumnConfig {
  const dailyTicketsConfig: ColumnConfig = {
    data: DayliTickets,
    isStack: true,
    xField: "date",
    yField: "quantity_sold",
    xAxis: {
      line: {
        style: {
          stroke: "#000000",
        },
      },
      label: { rotate: -1.24, offsetX: -25 },
    },
    yAxis: {
      line: {
        style: {
          stroke: "#aaaaaa",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "#aaaaaa",
          },
        },
      },
    },
    legend: {
      position: "top-right",
    },
    seriesField: "type",
    columnWidthRatio: 1,
  };
  return dailyTicketsConfig;
}

export function getTotalRevenueConfig(totalRrevenue: any): AreaConfig {
  const totalRevenueConfig: AreaConfig = {
    data: totalRrevenue,
    padding: "auto",
    xField: "Date",
    yField: "revenue",
    xAxis: {
      // tickCount: 20,
      grid: {
        line: {
          style: {
            stroke: "#aaaaaa",
          },
        },
      },
      label: { rotate: -1.24, offsetX: -20 },
    },
    color: "green",
  };
  return totalRevenueConfig;
}

export function getTotalTicketsConfig(totalTickets: any): AreaConfig {
  const totalTicketsConfig: AreaConfig = {
    data: totalTickets,
    padding: "auto",
    xField: "Date",
    yField: "tickets",
    xAxis: {
      // tickCount: 10,
      grid: {
        line: {
          style: {
            stroke: "#aaaaaa",
          },
        },
      },
      label: { rotate: -1.24, offsetX: -20 },
    },
    color: "gray",
  };
  return totalTicketsConfig;
}
