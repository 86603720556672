import React, { Component } from "react";
import ContentLayout from '../Layouts/ContentLayout'
import {getPressRelease} from '../Lib/RESTBlox'
import {Col} from 'reactstrap'
import {Helmet} from "react-helmet";
import { useParams } from "react-router-dom";

type Props = {
  whiteLabel: any;
};

const PressRelease: React.FC<Props> = (props) => {

  const [pressRelease, setPressRelease] = React.useState<any>();
  const params = useParams();
  React.useEffect(() => {
    loadData(params.id)
  }, []);

  const loadData = (pressReleaseId: any) => {
    getPressRelease(pressReleaseId)
      .then(data => {
        setPressRelease(data);
      })
  }
  
    return (
      props.whiteLabel && 
        <ContentLayout 
          title={pressRelease.title} 
          shortDescription={pressRelease.description}
        >
          <Col xs={12}>
            <Helmet key={window.location.href}>
              <title>Primary — {pressRelease.title}</title>
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content="article" />
              <meta property="og:title" content={"Primary — " + pressRelease.title} />
              <meta property="og:description" content={pressRelease.description} />
              <meta property="og:image" content={pressRelease.image} />
              <link rel="apple-touch-icon" sizes="180x180" href="/primary-apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/primary-favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/primary-favicon-16x16.png" />
              <link rel="manifest" href="/primary-site.webmanifest" />
              <link id="favicon" rel="icon" href="%PUBLIC_URL%/primary-favicon.ico" type="image/x-icon" />
            </Helmet>
            <div dangerouslySetInnerHTML={{__html: pressRelease.text}} />
          </Col>
        </ContentLayout>
    );
}

export default PressRelease;
