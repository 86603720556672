import React from "react";

type Props = {
  order: any;
};
const PurchaserDetails: React.FC<Props> = ({ order }) => {
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <h4 style={{fontSize: "1rem"}}>{order ? order[0]?.purchaser?.email : ""}</h4>
      <p>
        {order
          ? order[0]?.purchaser?.first_name + " " + order[0]?.purchaser?.last_name
          : ""}
      </p>
    </div>
  );
};
export default PurchaserDetails;
