import {Button, TextField } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Link } from "react-router-dom";
import React, {useCallback} from "react";
import {Row, Col} from 'reactstrap'

type Props = {
  event: any;
  handleCreateSubPromoter: (title: string) => void;
  subPromoters: any[];
};
const SubPromoterLinksV2: React.FC<Props> = ({
  event,
  handleCreateSubPromoter,
  subPromoters,
}) => {
  const [title, setTitle] = React.useState("");

  const getUrl = useCallback((subPromoterId: string) => {
    return `${window.location.origin}/events/${event?.event?.id}?spc=${subPromoterId}`;
  }, [event, window])

    const copyToClipboard = useCallback(async (subPromoterId: string) => {
        try {

        const perm = "clipboard-write" as PermissionName;
        const permQuery = await navigator.permissions.query({name: perm})
        if (permQuery.state === "granted" || permQuery.state === "prompt") {
            await navigator.clipboard.writeText(getUrl(subPromoterId));
            alert("Copied link to clipboard.");
        }
        } catch (error) {
            prompt("Copy the link below to your clipboard:", getUrl(subPromoterId));
        }

    }, [event, window])

  return (
    <>
      {/* TODO FILL THIS TABLE WITH SUBPROMOTOR LINKS LOGIC (NEEDS BACKEND AND A NEW TABLE DEFINITION IN API) */}
        <div style={{maxWidth: '100%', overflowX: 'auto', marginBottom: '1rem'}}>
      <table className="tickets-table" style={{borderSpacing: '0.8rem 4px', borderCollapse: 'separate'}}>
        <thead>
          <tr>
            <th className="subpromotors-tickets-name">Title</th>
            <th className="subpromotors-links-name">Code</th>
            <th className="subpromotors-tickets-name">Link</th>
            <th className="subpromotors-views-name">Views</th>
            <th className="subpromotors-views-name">Quantity Sold</th>
            <th className="subpromotors-views-name">Order Count</th>
            <th className="subpromotors-views-name">Revenue</th>
            <th className="subpromotors-actions-name">Actions</th>
          </tr>
        </thead>
        <tbody>
          {subPromoters?.map((subPromoter: any) => (
            <tr
              className="subpromotors-table-row"
              style={{ borderBottom: "1px solid #d9d8d8" }}
            >
            <td className="subpromotor-details">
              {subPromoter.title}
            </td>
            <td className="subpromotor-details">
              {subPromoter.code}
            </td>
              <td className="subpromotor-link">
                {getUrl(subPromoter?.code)}
              </td>
              <td className="subpromotor-view">{subPromoter?.num_page_views}</td>
              <td className="subpromotor-view">{subPromoter?.quantity_sold}</td>
              <td className="subpromotor-view">{subPromoter?.order_count}</td>
              <td className="subpromotor-view">{subPromoter?.total_revenue?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
              })}</td>
              <td className="subpromotor-actions">
                  <button
                  onClick={() => copyToClipboard(subPromoter?.code)}
                  style={{
                    background: "#17a2b8",
                    padding: "3px",
                    color: "white",
                  border: 'none',
                  }}
                >
                  <ContentCopyIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
        <form style={{padding: 0}} onSubmit={(e) => {
            e.preventDefault();
            if((title)) {
                handleCreateSubPromoter(title);
                setTitle('');
            }
        }}>
        <Row>
            <Col>
                <h3 style={{color: 'black', textAlign: 'center'}}>Create Subpromoter Links</h3>
                <p style={{ textAlign: 'center' }}>
                    Complete the form below to create a new subpromoter link. Use these links to track traffic and sales
                    through your various channels.
                </p>
            </Col>
        </Row>
        <Row>
            <Col style={{display: 'flex'}}>
                <TextField
                    label="Subpromoter Link Label"
                    fullWidth
                    size="small"
                    value={title}
                    style={{maxWidth: '550px', margin: '0 auto'}}
                    onChange={(e: any) => setTitle(e.target.value)}
                />
            </Col>
        </Row>
      <div style={{ justifyContent: "center", marginTop: '1rem', display: 'flex', flexDirection: 'column' }} >
        <Button
            style={{ width: "50%", margin: 'auto', background: (title) ? "#17a2b8" : '#cacaca' }}
            variant="contained"
            color="info"
            disabled={!title}
            type="submit"
        >
          Create
        </Button>
        <p
          style={{
            fontSize: "13px",
            color: "#999999",
            marginTop: "10px",
              alignSelf: 'stretch'
          }}
        >
          These are automatically generated event links that track views and
          orders. You can create a link with a custom description (used only for
          internal reference) or you can associate a link with a user. This is
          useful if you need to track subpromoter performance across multiple
          events.
        </p>
      </div>
        </form>

    </>
  );
};
export default SubPromoterLinksV2;
