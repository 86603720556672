import React from "react";
import { Row, Col, Button } from "reactstrap";
import ButtonLink from "../../../Components/ButtonLink";
import { Formik, Field, Form } from "formik";
import {
  FormField,
  FormCheckbox,
  FormDateTimePicker,
  FormDatePicker,
  FormTimePicker,
  FormAgeLimitPicker,
  FormTimeTypePicker,
  FormFileUploadField,
} from "../../../Components/Forms/Fields";
import FormPromoterPicker from "./Components/FormPromoterPicker/FormPromoterPicker";
import FormArtistPicker from "./Components/FormArtistPicker/FormArtistPicker";
import { addEvent, changeEvent, deleteEvent } from "../../../Lib/RESTBlox";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import Header from "../../../Components/Header";
import EventTile from "../../../Components/Admin/EventTile";
import TicketInventoryTable from "./Components/TicketsInventoryTable/TicketInventoryTable";
import { useNavigate } from "react-router-dom";
import { AWSAlternative, PublicImagePath } from "../../../constants/constants";
import Loader from "../../../Components/Loader";
import FormVenuePicker from "./Components/FormVenuePicker/FormVenuePicker";
import { FormText } from "reactstrap";
import VenueModal from "../../../Components/Admin/Modals/VenueModal";

Moment.locale("en");
momentLocalizer();

type Props = {
  event?: any;
  user: any;
  ticket_inventories?: any;
  prepopulateNew?: boolean;
};

const EventForm: React.FC<Props> = ({
  event,
  user,
  prepopulateNew,
  ticket_inventories,
}) => {
  const [alert, setAlert] = React.useState({
    type: "",
    message: "",
  });
  const [submissionComplete, setSubmissionComplete] =
    React.useState<boolean>(false);
  const [success, setSucess] = React.useState<boolean>(false);
  const [inprogress, setInProgress] = React.useState<boolean>(false);
  const [currentEvent, setCurrentEvent] = React.useState<any>(event || {});
  const [selectedImage, setSelectedImage] = React.useState<any>();
  const navigate = useNavigate();
  const isWhiteLabel = window.location.host.split(".")[0] !== "thebloxoffice";
  console.log(isWhiteLabel);
  const handleAddEvent = (values: any) => {
    setInProgress(true);
    addEvent(values)
      .then((json) => {
        setAlert({
          type: "success",
          message: "Event added.",
        });
        setInProgress(false);
        setSucess(true);
        setCurrentEvent(json);
      })
      .catch((err) => {
        setInProgress(false);
        setAlert({
          type: "danger",
          message: "There was an error adding the event.",
        });
      });
  };

  const handleChangeEvent = (values: any) => {
    if (values?.venue?.value) {
      values.venue = values?.venue?.value;
    }
    changeEvent(event.id, values)
      .then((json) => {
        setAlert({
          type: "success",
          message: "Event updated.",
        });

        setSucess(true);
        setCurrentEvent(json);
        console.log("/admin/events/upcoming/"); //TODO THIS IMPORTANT TASK
        // props.history.push("/admin/events/upcoming");
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: "There was an error updating the event.",
        });
      });
  };

  const handleDeleteEvent = (eventId: string) => {
    deleteEvent(eventId)
      .then((json) => {
        setAlert({
          type: "success",
          message: "Event deleted.",
        });
        console.log("/admin/events/upcoming/"); //TODO THIS IMPORTANT TASK
        navigate("/admin/events/upcoming");
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: "There was an error deleting the event.",
        });
      });
  };

  const setInitialValue = (keyName: string) => {
    if (keyName === "date") {
      if (
        "date" in currentEvent &&
        (prepopulateNew === false || !prepopulateNew)
      ) {
        var date = new Date(
          currentEvent.date.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1")
        );
        return date;
      } else {
        return new Date();
      }
    }

    if (keyName === "release_date") {
      if (
        "release_date" in currentEvent &&
        (prepopulateNew === false || !prepopulateNew)
      ) {
        const offset = new Date().getTimezoneOffset();
        const release_date = new Date(currentEvent.release_date);
        release_date.setHours(release_date.getHours() - offset / 60);
        return release_date;
      } else {
        return null;
      }
    }

    if (keyName === "time1") {
      if ("time1" in currentEvent) {
        var time1Parts = currentEvent.time1.split(":");
        var t1hh = parseInt(time1Parts[0]);
        var t1mm = parseInt(time1Parts[1]);
        var t1ss = parseInt(time1Parts[2]);
        var time1 = new Date();
        time1.setHours(t1hh, t1mm, t1ss);
        return time1;
      } else {
        return new Date();
      }
    }

    if (keyName === "time2") {
      if ("time2" in currentEvent) {
        if (currentEvent.time2 === null) {
          return null;
        }
        var time2Parts = currentEvent.time2.split(":");
        var t2hh = parseInt(time2Parts[0]);
        var t2mm = parseInt(time2Parts[1]);
        var t2ss = parseInt(time2Parts[2]);
        var time2 = new Date();
        time2.setHours(t2hh, t2mm, t2ss);
        return time2;
      } else {
        return null;
      }
    }

    if (keyName === "primary_artists") {
      if ("headliners" in currentEvent) {
        return currentEvent.headliners.map((object: any, i: number) => {
          if (object.artist) {
            return {
              value: object.artist?.id,
              label: object.artist?.name,
              sort_order: object?.sort_order,
            };
          }
        });
      } else {
        return [];
      }
    }

    if (keyName === "secondary_artists") {
      if ("undercard" in currentEvent) {
        return currentEvent.undercard.map((object: any, i: number) => {
          if (object.artist) {
            return {
              value: object.artist?.id,
              label: object.artist?.name,
              sort_order: object?.sort_order,
            };
          }
        });
      } else {
        return [];
      }
    }

    if (keyName === "primary_promoters") {
      if ("primary_promoters" in currentEvent) {
        return currentEvent.primary_promoters.map((object: any, i: number) => {
          return {
            value: object.promoter?.id,
            label: object.promoter?.name,
            sort_order: object?.sort_order,
          };
        });
      } else {
        return [];
      }
    }

    if (keyName === "secondary_promoters") {
      if ("secondary_promoters" in currentEvent) {
        return currentEvent.secondary_promoters.map(
          (object: any, i: number) => {
            return {
              value: object.promoter?.id,
              label: object.promoter?.name,
              sort_order: object?.sort_order,
            };
          }
        );
      } else {
        return [];
      }
    }

    if (keyName === "time1_type") {
      if ("time1_type" in currentEvent) {
        return currentEvent?.time1_type?.id;
      } else {
        return null;
      }
    }

    if (keyName === "time2_type") {
      if ("time2_type" in currentEvent) {
        if (currentEvent.time2 === null) {
          return null;
        }
        return currentEvent?.time2_type?.id;
      } else {
        return null;
      }
    }

    if (keyName === "venue") {
      if ("venue" in currentEvent) {
        // return currentEvent.venue?.id;
        return {
          value: currentEvent.venue?.id,
          label: currentEvent.venue?.name + ", " + currentEvent.venue?.address,
        };
      } else {
        return null;
      }
    }

    if (keyName === "age_limit") {
      if ("age_limit" in currentEvent) {
        if (currentEvent.age_limit === 21) {
          return { value: "21", label: "21+" };
        }
        if (currentEvent.age_limit === 18) {
          return { value: "18", label: "18+" };
        }
        if (currentEvent.age_limit === 0) {
          return { value: "0", label: "All ages" };
        }
      } else {
        return { value: "21", label: "21+" };
      }
    }

    if (keyName === "image") {
      if ("image" in currentEvent) {
        return currentEvent.image;
      } else {
        return "";
      }
    }

    if (
      keyName === "private" ||
      keyName === "venue_undisclosed" ||
      keyName === "draft"||
      keyName === "white_label_only"
    ) {
      if (keyName in currentEvent) {
        return currentEvent[keyName];
      } else {
        return false;
      }
    }

    if (keyName in currentEvent) {
      return currentEvent[keyName];
    } else {
      return "";
    }
  };

  function imageExists(image_url: string) {
    var http = new XMLHttpRequest();

    http.open("HEAD", image_url, false);
    // try {
    http.send();
    return http.status != 404;
    // } catch (error) {
    //   return false
    // }
  }
  const validate = (values: any) => {
    interface LooseObject {
      [key: string]: any;
    }

    setSelectedImage(values.image);

    let errors: LooseObject = {};

    if (!values.image) {
      errors.image = "You must choose an image for your event";
    }

    if (!values.time1_type) {
      errors.time1_type = "Required";
    }

    if (!values.venue) {
      errors.venue = "Required";
    }

    return errors;
  };

  const resetState = (e: any) => {
    e.preventDefault();

    setSucess(false);
    setCurrentEvent({});
  };

  const [errored, setErrored] = React.useState(false);
  const [src, setSrc] = React.useState(PublicImagePath);
  const onError = () => {
    if (!errored) {
      setErrored(true);
      setSrc(AWSAlternative);
    }
  };
  const [preview, setPreview] = React.useState<any>();
  React.useEffect(() => {
    if (!selectedImage) {
      setPreview(undefined);
      return;
    }

    try {
      const objectUrl = URL.createObjectURL(selectedImage);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } catch {
      setPreview(undefined);
      return;
    }
  }, [selectedImage]);

  const handleNewVenue = (venue: any) => {
    var new_venue = {
      value: venue.id,
      label: venue.name + ", " + venue.address,
    };
    // setVenues([...venues, new_venue]);
    // handleChange(new_venue);
  };

  const form = (
    <Formik
      initialValues={{
        title: setInitialValue("title"),
        venue: setInitialValue("venue"),
        description: setInitialValue("description"),
        date: setInitialValue("date"),
        release_date: setInitialValue("release_date"),
        time1: setInitialValue("time1"),
        time1_type: setInitialValue("time1_type"),
        time2: setInitialValue("time2"),
        time2_type: setInitialValue("time2_type"),
        age_limit: setInitialValue("age_limit"),
        image: setInitialValue("image"),
        primary_promoters: setInitialValue("primary_promoters"),
        secondary_promoters: setInitialValue("secondary_promoters"),
        primary_artists: setInitialValue("primary_artists"),
        secondary_artists: setInitialValue("secondary_artists"),
        private: setInitialValue("private"),
        draft: setInitialValue("draft"),
        white_label_only: setInitialValue("white_label_only"),
        venue_undisclosed: setInitialValue("venue_undisclosed"),
      }}
      onSubmit={(values, actions) => {
        if (
          Object.entries(currentEvent).length !== 0 &&
          (prepopulateNew === false || !prepopulateNew)
        ) {
          handleChangeEvent(values);
        } else {
          handleAddEvent(values);
        }
        actions.setSubmitting(false);
      }}
      validate={validate}
      render={(props) => (
        <Form>
          <Header content="Time and place" />
          <Row>
            <Col xs={12} sm={6}>
              <Field
                label="Event date"
                type="text"
                name="date"
                placeholder="Select the date..."
                component={FormDatePicker}
                required={true}
                help="The date the event will be held"
              />
            </Col>
            <Col xs={12} sm={6}>
              <Field
                label="Release date"
                type="text"
                name="release_date"
                placeholder="Select the date and time..."
                component={FormDateTimePicker}
                required={false}
                help="The date and time the event will be live on the site. If blank, will be set to the current date/time. Use your local time"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={3} lg={2}>
              <Field
                label="Start time"
                type="time"
                name="time1"
                placeholder="Select..."
                component={FormTimePicker}
                required={true}
                help="Use the local time of the event venue"
              />
            </Col>
            <Col xs={6} sm={3} lg={3}>
              <Field
                label="Start label"
                default="start"
                type="text"
                name="time1_type"
                placeholder="Select..."
                component={FormTimeTypePicker}
                required={true}
              />
            </Col>
            <Col xs={6} sm={3} lg={2}>
              <Field
                label="End time"
                defaultValue="start"
                type="time"
                name="time2"
                placeholder="Select..."
                component={FormTimePicker}
                required={true}
                help="Use the local time of the event venue"
              />
            </Col>
            <Col xs={6} sm={3} lg={3}>
              <Field
                label="End label"
                type="text"
                name="time2_type"
                placeholder="Select..."
                component={FormTimeTypePicker}
                required={false}
              />
            </Col>
            <Col xs={12} sm={12} lg={2}>
              <Field
                label="Age limit"
                type="number"
                name="age_limit"
                placeholder="Select..."
                component={FormAgeLimitPicker}
                required={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Field
                label="Venue"
                type="text"
                name="venue"
                placeholder="Type to select venue..."
                component={FormVenuePicker}
                required={true}
              />
            </Col>
            <Col className="venue-undisclosed-checkBox" xs={12} md={4}>
              <Field
                label={
                  <span>
                    <strong>Venue undisclosed</strong> — If selected, this
                    event's venue will be kept secret and released to ticket
                    holders privately.
                  </span>
                }
                type="checkbox"
                name="venue_undisclosed"
                component={FormCheckbox}
                checked={props.values.venue_undisclosed}
              />
            </Col>
            <Col className="nocknock-ad-link" xs={12} md={4}>
              <span></span>
            </Col>
          </Row>
          <Header content="Event details" />
          <Row>
            <Col xs={12} sm={6}>
              <Field
                label="Title"
                type="text"
                name="title"
                placeholder="Enter a title..."
                component={FormField}
                required={false}
                help="If you do not enter a tile, one will be created for you"
              />
            </Col>
            <Col xs={12} sm={6}>
              <Field
                label="Poster Image"
                type="file"
                name="image"
                placeholder="Upload..."
                component={FormFileUploadField}
                required={true}
              />
              {(currentEvent.image || preview) &&
                (prepopulateNew === false || !prepopulateNew) && (
                  <div className="currentEventImage">
                    <img
                      src={preview ? preview : currentEvent.image}
                      onError={onError}
                      style={{ marginTop: ".5rem" }}
                    />
                    <a
                      href={currentEvent.image}
                      download
                      className="hvr-grow-shadow btn btn-secondary btn-sm"
                      style={{
                        color: "white",
                        marginTop: ".5rem",
                        display: "block",
                      }}
                    >
                      Download
                    </a>
                  </div>
                )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                label="Description"
                type="textarea"
                name="description"
                placeholder="Enter a desctiption..."
                component={FormField}
                required={false}
                help="May contain URLs, emoji, and basic Markdown formatting."
              />
            </Col>
          </Row>
          <Header content="Promoters" />
          <Row>
            <Col xs={12} md={6}>
              <Field
                label="Primary promoters"
                type="text"
                name="primary_promoters"
                placeholder="Select promoters..."
                component={FormPromoterPicker}
                required={false}
              />
            </Col>
            <Col xs={12} md={6}>
              <Field
                label="Secondary promoters"
                type="text"
                name="secondary_promoters"
                placeholder="Select promoters..."
                component={FormPromoterPicker}
                required={false}
              />
            </Col>
          </Row>
          <Header content="Artists" />
          <Row>
            <Col xs={12} md={6}>
              <Field
                label="Headline artists"
                type="text"
                name="primary_artists"
                placeholder="Select artists..."
                component={FormArtistPicker}
                required={false}
              />
            </Col>
            <Col xs={12} md={6}>
              <Field
                label="Other artists"
                type="text"
                name="secondary_artists"
                placeholder="Select artists..."
                component={FormArtistPicker}
                required={false}
              />
            </Col>
          </Row>
          <Header content="Options" />
          <Row>
            <Col xs={12} md={4}>
              <Field
                label={
                  <span>
                    <strong>Draft</strong> — If selected, this event will be
                    saved as a draft and not released publicly.
                  </span>
                }
                type="checkbox"
                name="draft"
                component={FormCheckbox}
                checked={props.values.draft}
              />
            </Col>
            <Col xs={12} md={4}>
              <Field
                label={
                  <span>
                    <strong>Private event</strong> — If selected, this event
                    will not show in the public list of events, and will only be
                    accessible via direct link.
                  </span>
                }
                type="checkbox"
                name="private"
                component={FormCheckbox}
                checked={props.values.private}
              />
            </Col>
            {isWhiteLabel && (
              <Col xs={12} md={4}>
                <Field
                  label={
                    <span>
                      <strong>White Label Only</strong> — If selected, this
                      event will not show up on the main Blox site, and will
                      only display on your subdomain.
                    </span>
                  }
                  type="checkbox"
                  name="white_label_only"
                  component={FormCheckbox}
                  checked={props.values.white_label_only}
                />
              </Col>
            )}
          </Row>
          <Row className="buttonRow">
            <Col>
              <Button
                onClick={() => handleDeleteEvent(event.id)}
                block
                color="danger"
              >
                Delete
              </Button>
            </Col>
            <Col></Col>
            <Col className="pr-1">
              <ButtonLink
                to="/admin/events/upcoming/"
                text="Cancel"
                block
                color="secondary"
              />
            </Col>
            <Col className="pl-1">
              {Object.entries(currentEvent).length !== 0 &&
              (prepopulateNew === false || !prepopulateNew) ? (
                <Button
                  className="hvr-grow-shadow"
                  block
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              ) : inprogress ? (
                <Loader color="#000" loading={true} />
              ) : (
                <Button
                  className="hvr-grow-shadow"
                  block
                  color="primary"
                  type="submit"
                >
                  Add
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      )}
    />
  );

  const ticketInvetories = (
    <TicketInventoryTable
      event={event}
      ticket_inventories={ticket_inventories}
      // handleAlertMessage={handleAlertMessage}
    />
  );

  return (
    <div>
      {success ? (
        <div className="BloxAdminContentContainer formSuccess">
          <EventTile event={currentEvent} user={user} />
          <Row noGutters className="buttonRow">
            <Col className="pr-2">
              <ButtonLink
                text="Add another event"
                to="/admin/events/add/"
                onClick={resetState}
                block
              />
            </Col>
            <Col className="p1-2">
              <ButtonLink
                text="Add tickets for this event"
                to={"/admin/events/ticket-inventories/add/" + currentEvent.id}
                block
              />
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <div className="BloxAdminContentContainer">{form}</div>
          {Object.entries(currentEvent).length !== 0 &&
            (prepopulateNew === false || !prepopulateNew) && (
              <div className="BloxAdminContentContainer">
                {ticketInvetories}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default EventForm;
