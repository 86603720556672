import axios from 'axios';

// import {bloxTrack} from './RESTTrack'

var APIBaseURL = 'https://sendy.thebloxoffice.com/'

// INTERNAL METHODS
function fetchBloxSendyEndpoint(endpoint:string, method:string, data:string, condition:boolean) {
  return new Promise(function(resolve, reject) {
    var dataURL = APIBaseURL + endpoint

    // Handle the request
    axios({
      method: method,
      url: dataURL,
      data: data,
      config: { headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'} }
    }as any)
      .then(response => {
        console.info("      Sucessful Sendy API request (" + method + ") to " + endpoint)
        resolve(response)
      })
      .catch(err => {
        console.error("BloxOffice Sendy API Error (" + endpoint + "):\n", err.response)
        reject(err)
      })
  });
}

function subscribe(userName:string, userEmail:string, listId:string) {
  var endpoint = "subscribe"
  var bodyFormData = new Map();;
  bodyFormData.set('api_key', 'SFyQoXlBxEvHmbd6yMhM');
  bodyFormData.set('name', userName);
  bodyFormData.set('email', userEmail);
  bodyFormData.set('list', listId);
  bodyFormData.set('boolean', 'true');
  
  const requestBody = new URLSearchParams();
  const pairs = Array.from(bodyFormData.entries());
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i];
    requestBody.append(pair[0], pair[1].toString());
  }

  console.log(bodyFormData)
  return new Promise(function(resolve, reject) {
    fetchBloxSendyEndpoint(endpoint, 'post', requestBody.toString(), false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

export {
  subscribe
}
