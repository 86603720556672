import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Formik, Field, Form } from 'formik';
import { FormField } from '../../Components/Forms/Fields'
import {addVenue, changeVenue} from '../../Lib/RESTBlox'
import GoogleMapComponentWithMarker from '../../Components/Maps/Map'
import ButtonLink from '../../Components/ButtonLink'

class VenueForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      success: false,
      venue: this.props.venue || {}
    }
    this.handleAlertMessage = this.props.handleAlertMessage.bind(this)
    this.resetState = this.resetState.bind(this)
  }

  handleAddVenue(values) {
    addVenue(values)
      .then(json => {
        // this.handleAlertMessage({
        //   type: 'success',
        //   message: 'Venue added.'
        // })
        this.setState({
          success: true,
          venue: json
        })
        // this.props.history.push('/admin')
      })
      .catch(err => {
        // this.handleAlertMessage({
        //   type: 'danger',
        //   message: 'There was an error adding the venue.'
        // })
      })
  }

  handleChangeVenue(values) {
    changeVenue(this.state.venue.id, values)
      .then(json => {
        this.setState({
          alert: {
            type: 'success',
            message: 'Venue updated.'
          }
        })
        this.props.history.push('/admin/')
      })
      .catch(err => {
        this.setState({
          alert: {
            type: 'danger',
            message: 'There was an error updating the venue.'
          }
        })
      })
  }

  resetState(e) {
    e.preventDefault()
    this.setState({
      venue: {},
      success: false
    })
  }

  setInitialValue(keyName) {
    if (keyName in this.state.venue) {
      return this.state.venue[keyName]
    } else {
      return ''
    }
  }

  validate = (values) => {
    let errors = {};
    if (!values.name) {
      errors.event = 'Required';
    }
    if (!values.address) {
      errors.quantity = 'Required';
    }
    return errors;
  };

  render() {
    return (
      <div>

        {this.state.success ?

          <div className="BloxAdminContentContainer formSuccess">
            <GoogleMapComponentWithMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key="
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `250px`, marginBottom: `1rem` }} />}
              venue={this.state.venue}
            />
            <Row noGutters className="buttonRow">
              <Col className="pr-2">
                <ButtonLink
                  to="/admin/venues/add"
                  text="Add another venue"
                  onClick={this.resetState}
                />
              </Col>
              <Col className="p1-2">
                <ButtonLink
                  text="Add an event"
                  to="/admin/events/add"
                />
              </Col>
            </Row>
          </div>

          : <Formik
              initialValues={{
                title: this.setInitialValue('name'),
                address: this.setInitialValue('address'),
              }}
              onSubmit={(values, actions) => {
                if (Object.entries(this.state.venue).length !== 0) {
                  this.handleChangeVenue(values)
                } else {
                  this.handleAddVenue(values)
                }
                actions.setSubmitting(false);
              }}
              validate={this.validate}
              render={(props ) => (
                <Form>
                  <Row>
                    <Col>
                      <Field
                        label="Name"
                        type="text"
                        name="name"
                        placeholder="Enter the venue name..."
                        component={FormField}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        label="Address"
                        type="text"
                        name="address"
                        placeholder="Enter the venue address..."
                        component={FormField}
                        required={true}
                        help="Enter the complete venue address, such as 1234 Melrose Ave E, Seattle WA, 98102"
                      />
                    </Col>
                  </Row>
                  <Row className="buttonRow">
                    <Col className="d-none d-md-block" md={6}></Col>
                    <Col xs={6} md={3} className="pr-1">
                      <ButtonLink
                        to="/admin/"
                        text="Cancel"
                        block
                        color="secondary"
                      />
                    </Col>
                    <Col xs={6} md={3}>
                      {Object.entries(this.state.venue).length !== 0?
                        <Button className="hvr-grow-shadow" block color="primary" type="submit">Update</Button>
                        : <Button className="hvr-grow-shadow" block color="primary" type="submit">Save</Button>
                      }
                    </Col>
                  </Row>
                </Form>
              )}
            />
        }

      </div>
    );
  }
}

export default VenueForm
