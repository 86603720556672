import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

type Props = {
  handleSearchChange: (searchTerm: string) => void;
  objectTitle?: string;
};
const TableSearchForm: React.ComponentType<Props> = ({
  handleSearchChange,
}) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      handleSearchChange(query);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    <TextField
      label="Search"
      size="small"
      variant="outlined"
      fullWidth
      onChange={(event) => setQuery(event.target.value)} //introducing delay to let user finish typing
    />
  );
};

export default TableSearchForm;
