import React from 'react'

function NoEventsMessage(props) {
  return (
    <div class="noEventsMessage">
      <h1>
        <span>We’re sorry!</span>
        No events are on sale at this time.
        <br /><br />
      </h1>
    </div>
  )
}

export default NoEventsMessage
