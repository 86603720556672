import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import ButtonLink from '../../Components/ButtonLink'
import { Formik, Field, Form } from 'formik';
import {
  FormField,
  FormArtistPicker,
  FormFileUploadField
} from '../../Components/Forms/Fields'
import {changeWhiteLabel} from '../../Lib/RESTBlox'
import { validateEmail } from '../../Lib/FormValidation'
import { PublicImagePath } from "../../constants/constants";

class WhiteLabelForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submissionComplete: false,
      alert: {
        type: '',
        message: ''
      },
      whiteLabel: this.props.whiteLabel,
    }
  }

  handleChangeWhiteLabel(values) {
    console.log(values)
    changeWhiteLabel(this.state.whiteLabel.id, values)
    .then(json => {
      // this.handleAlertMessage({
      //   type: 'success',
      //   message: 'Site settings updated.'
      // })
      this.setState({
        success: true,
        event: json
      })
      this.props.history.push('/')
    })
    .catch(err => {
      // this.handleAlertMessage({
      //   type: 'danger',
      //   message: 'There was an error updating the site settings.'
      // })
    })
  }

  setInitialValue(keyName) {

    console.log(this.state.whiteLabel)
    if (keyName === 'resident_artists') {
      if ('resident_artists' in this.state.whiteLabel) {
        return this.state.whiteLabel.resident_artists.map((artist, i) => {
          return {
            value: artist.id,
            label: artist.name
          }
        })
      } else {
        return [];
      }
    }

    if (keyName === 'logo') {
      if ('logo' in this.state.whiteLabel) {
        return PublicImagePath + this.state.whiteLabel.logo
      } else {
        return ''
      }
    }

    if (keyName === 'contact_form_email') {
      return this.state?.whiteLabel?.client?.email ?? ''
    }

    if (keyName === 'slogan') {
      return this.state?.whiteLabel?.title ?? ''
    }

    if (keyName === 'description') {
      return this.state?.whiteLabel?.description ?? ''
    }

    if (keyName === 'short_description') {
      return this.state?.whiteLabel?.description ?? ''
    }

    if (keyName in this.state.whiteLabel) {
      return this.state.whiteLabel[keyName]
    } else {
      return ''
    }

  }

  validate = (values) => {
    let errors = {};

    return errors;
  };

  render() {
    const contactFormEmailClass = this.props.whiteLabel.display_contact_us_page? '' : 'd-none'
    const residentArtistsClass = this.props.whiteLabel.type === 'club'? '' : 'd-none'

    const form = (
      <Formik
          initialValues={{
            contact_form_email: this.setInitialValue('contact_form_email'),
            slogan: this.setInitialValue('slogan'),
            short_description: this.setInitialValue('short_description'),
            description: this.setInitialValue('description'),
            logo: this.setInitialValue('logo'),
            homepage_background_youtube_video: this.setInitialValue('homepage_background_youtube_video'),
            resident_artists: this.setInitialValue('resident_artists'),
          }}
          onSubmit={(values, actions) => {
            console.log('submitted')
            this.handleChangeWhiteLabel(values)
            actions.setSubmitting(false);
          }}
          validate = {this.validate}
          render={(props ) => (
            <Form>
              <Row>
                <Col xs={12} sm={4}>
                  <Field
                    label="Slogan"
                    type="text"
                    name="slogan"
                    placeholder="Enter a slogan..."
                    component={FormField}
                    required={false}
                    help="Optionally, enter a slogan to overlay your homepage video."
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <Field
                    label="YouTube Video ID"
                    type="text"
                    name="homepage_background_youtube_video"
                    placeholder="Enter a YouTube ID..."
                    component={FormField}
                    required={false}
                    help="Enter a YouTube video ID to display a video as the background of your home page. Please enter only the ID portion of the YouTube URL. It will look something like this: W0LHTWG-UmQ"
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <Field
                    label="Logo"
                    type="file"
                    name="logo"
                    placeholder="Upload..."
                    component={FormFileUploadField}
                    required={false}
                    help="Upload a logo for your site. For best results, it should be at least 200x200px, and should be a square."
                  />
                  {this.state.whiteLabel.logo &&
                    <div className="currentEventImage">
                      <img 
                        alt=""
                        src={this.state.whiteLabel.logo} 
                        style={{ marginTop: '.5rem'}}
                      />
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col className={contactFormEmailClass}>
                  <Field
                    label="Email"
                    type="email"
                    name="contact_form_email"
                    placeholder="Email address..."
                    component={FormField}
                    required={true}
                    validate={validateEmail}
                    help="Responses to your site's contact form will be sent to this address"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Field
                    label="Short description"
                    type="textarea"
                    name="short_description"
                    placeholder="Enter a short desctiption..."
                    component={FormField}
                    required={true}
                    help="Enter a short description of your company or organization. It will appear at the top of your about page. May contain URLs, emoji, and basic Markdown formatting."
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Field
                    label="Description"
                    type="textarea"
                    name="description"
                    placeholder="Enter a desctiption..."
                    component={FormField}
                    required={true}
                    help="Enter a longer description of your company or organization. It will appear further down the about page. May contain URLs, emoji, and basic Markdown formatting."
                  />
                </Col>
              </Row>
              <Row className={residentArtistsClass}>
                <Col xs={12}>
                  <Field
                    label="Resident artists"
                    type="text"
                    name="resident_artists"
                    placeholder="Select artists..."
                    component={FormArtistPicker}
                    required={false}
                    help="Select or add any artists that have residencies at your establishment."
                  />
                </Col>
              </Row>
              <Row className="buttonRow">
                <Col className="pr-1">
                  <ButtonLink
                    to="/admin/events/upcoming/"
                    text="Cancel"
                    block
                    color="secondary"
                  />
                </Col>
                <Col className="pl-1">
                  <Button className="hvr-grow-shadow" block color="primary" type="submit">Update</Button>
                </Col>
              </Row>
            </Form>
          )}
        />
    )

    return (
      <div className="BloxAdminContentContainer">
        {form}
      </div>
    );
  }
}

export default WhiteLabelForm
