import React, {Component, useEffect} from 'react'
import { bloxTrack } from "../Lib/RESTTrack"
import ReactPixel from 'react-facebook-pixel'
import { updateAdminEventSubPromoterLinks } from '../Lib/RESTBlox'
import {useLocation} from "react-router-dom";
class AppContainer extends Component {

  state = {
    lastPage: null
  }

  fbPixelInit() {
    // If we have a FB Pixel ID for the white label, set it up.
    if (this.props.whiteLabel.facebook_pixel_id) {
      const FBPixelOptions = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };
      ReactPixel.init(this.props.whiteLabel.facebook_pixel_id, {}, FBPixelOptions);
    }
  }

  fbPixelTrackPageView() {
    // If we have a FB Pixel ID for the white label, track this page view.
    if (this.props.whiteLabel.facebook_pixel_id) {
      ReactPixel.pageView();
    }
  }

  componentDidUpdate(prevProps, prevState) {


  if(!this.state.lastPage || this.state.lastPage !== window.location.href) {
    window?.analytics?.page(null, { path: window.location.pathname, url: window.location.href});
    this.setState({lastPage: window.location.href});
  }

    if (prevProps.location !== this.props.location) {
      bloxTrack('Web Page View', window.location.href, this.props.action)
    }

    if (prevProps.whiteLabel !== this.props.whiteLabel) {
      this.fbPixelInit()
      this.fbPixelTrackPageView()
    }
  }

  componentDidMount() {
    //if it is a subpromoter link then call the subpromoter update TODO update this to functional component
    if(window.location.pathname.split('/').length === 4 && window.location.pathname.split('/')[1]==='events'){
      updateAdminEventSubPromoterLinks(window.location.pathname.split('/')[3]).then(()=>{}).catch(()=>{})
    }
    bloxTrack('Web Page View', window.location.href, this.props.action)

    const urlParams = new URLSearchParams(window.location.search);
    const spc = urlParams.get('spc');
    if (spc) {
      const eventId = parseInt(window.location.pathname.split('/')[2], 10)

      const spcData = {
        code: spc,
        eventId,
        created: new Date()
      }
      localStorage.setItem('spc', JSON.stringify(spcData));
    }
  }

  render() {
     return (
         <div className={"App container-fluid"}>{this.props.children}</div>
      );
  }
}
export default (props) => {
  const location = useLocation();
  return <AppContainer {...props} location={location} />
}
