import React from 'react';

function EventPurchaseTableRow(props) {
  return (
    <tr>
      <td className="left-align purchase-row">{ props.inventory.type.name }</td>
      <td className="right-align purchase-row">{props.quantity}</td>
      <td className="right-align purchase-row">{ props.inventory.price_str.replace(",", "") }</td>
    </tr>
  )
}

export default EventPurchaseTableRow
