import React, { Component } from "react";
import EventForm from "../Forms/EventForm/EventForm";
import AdminLayout from "../../Layouts/AdminLayout";

type Props = {
  user: any;
};

const EventAdd: React.FC<Props> = ({ user }) => {
  return (
    <AdminLayout title="Add event">
      <EventForm user={user} />
    </AdminLayout>
  );
};

export default EventAdd
