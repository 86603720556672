import React, { Component } from "react";
import ContentLayout from '../Layouts/ContentLayout'
import {getPressRelease} from '../Lib/RESTBlox'
import { Row, Col } from 'reactstrap'
import { useParams } from "react-router-dom";

const GalleryLookup : any =
{
  '449': '<iframe src="https://thebloxoffice.blob.core.windows.net/primarychi/449.html" style="border:none;" width="100%" height="100%" title="Explore Primary Night Club" allowfullscreen onload="if (document.location.hash && this.src.indexOf(document.location.hash) < 0) this.src += document.location.hash;"></iframe>',
  '456': '<iframe src="https://thebloxoffice.blob.core.windows.net/primarychi/456.html" style="border:none;" width="100%" height="100%" title="Explore Primary Night Club" allowfullscreen onload="if (document.location.hash && this.src.indexOf(document.location.hash) < 0) this.src += document.location.hash;"></iframe>',
  '457': '<iframe src="https://thebloxoffice.blob.core.windows.net/primarychi/457.html" style="border:none;" width="100%" height="100%" title="Explore Primary Night Club" allowfullscreen onload="if (document.location.hash && this.src.indexOf(document.location.hash) < 0) this.src += document.location.hash;"></iframe>'
};

type Props = {
  whiteLabel: any;
};

const EventGallery: React.FC<Props> = (props) => {
  const [galleryId, setGalleryId] = React.useState<any>(0);
  const [galleryUrl, setGalleryUrl] = React.useState<any>("");
  const params = useParams();
  const loadData = (id:string) =>
  {
    console.log('EventGallery: ' + id);
    setGalleryId(id);
    setGalleryUrl(GalleryLookup[id]);
  }

  React.useEffect(() => {
    loadData(params.id as string)
  }, []);

    return (
      props.whiteLabel && 
        <Row className="clearHeader"> 
          <Col>
            <div className="galleryFrame" dangerouslySetInnerHTML={{__html: galleryUrl}} />
          </Col>
        </Row>
      );
}

export default EventGallery;
