import React, { Component } from "react";
import TableReservationsForm from '../Components/Forms/TableReservationsForm'
import ContentLayout from '../Layouts/ContentLayout'

type Props = {
  user: any;
  whiteLabel: any;
};

const TableReservations: React.FC<Props> = ({ user, whiteLabel }) => {
    return (
      <ContentLayout 
        title="Table reservations"
        shortDescription="Want to reserve a table? Fill out the form below and we'll get back to you as soon as possible."
      >
          { user ?
            <TableReservationsForm user={user} whiteLabel={whiteLabel} />
            :<TableReservationsForm user={undefined}  whiteLabel={whiteLabel}/>
          }

      </ContentLayout>
    );
}

export default TableReservations;
