import React from "react";
import { getAdminOrders } from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";
import SmartTable from "../../Components/SmartTable";
import {
  userFormatter,
  totalFormatter,
  orderDateCreatedFormatter,
  orderFormatter,
  ticketsFormatter,
  orderNumberFormatter,
} from "../../Lib/TableFormatters";
import { IOrdersIn } from "../../interfaces/orders/orders";
import TableSearchForm from "../../Components/Forms/TableSearchForm";
import OrdersTable from "./components/OrdersTable/OrdersTable";
import { useMediaQuery } from "react-responsive";

type Props = {};

const OrderList: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState(true);
  const [orders, setOrders] = React.useState<IOrdersIn[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [hasMore, setHasMore] = React.useState<boolean>(true)
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  
  React.useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    getAdminOrders(page+1)
      .then((data) => {
        setDataCount(data.count);
        setHasMore(data?.next?true:false)
        setPage(page + 1);
        setOrders(orders.concat(data.results));
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const handleSearchChange = (searchTerm: string) => {
    setLoading(true);
    getAdminOrders(1 as any, searchTerm as any, null, null)
      .then((data) => {
        setDataCount(data?.count);
        setHasMore(data?.next?true:false)
        setOrders(data?.results);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };
  return (
    <AdminLayout title="Orders" isWhite={true}>
      <div className="BloxAdminContentContainer">
      <div className="tableHeader">
          <p className="tableHeader-Name">Orders</p>
          <p className="tableHeader-Name">{dataCount}</p>
        </div>
        <TableSearchForm handleSearchChange={handleSearchChange} />
        <OrdersTable orders={orders} loadData={loadData} loading={loading} hasMore={hasMore} isMobile={isMobile}/>
      </div>
    </AdminLayout>
  );
};

export default OrderList;
