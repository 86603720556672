import React from "react";
import TicketInventoryForm from "../Forms/TicketInventoryForm/TicketInventoryForm";
import AdminLayout from "../../Layouts/AdminLayout";
import { getTicketInventory } from "../../Lib/RESTBlox";
import { useParams } from "react-router-dom";

type Props = {
  user: any;
};

const TicketInventoryChange: React.FC<Props> = () => {

  const { ticketInventoryId } = useParams();
  const [ticketInventory, setTicketInventory] = React.useState<any>();

  React.useEffect(() => {
    if (ticketInventoryId) {
      getTicketInventory(ticketInventoryId)
        .then((data) => setStateFromNewData(data))
        .catch((err) => console.error(err));
    }
  }, []);

  const setStateFromNewData = (data: any) => {
    setTicketInventory(data);
  };

  return (
    <AdminLayout
      title="Change ticket inventory"
      description="A ticket inventory is a type of tickets made available for the event. Each event may have multiple ticket inventories (for example, an inventory of Early Bird tickets, an inventory of VIP tickets, and an inventory of General Admission tickets.)"
    >
      {ticketInventory&&<TicketInventoryForm ticketInventory={ticketInventory} />}
    </AdminLayout>
  );
};

export default TicketInventoryChange;
