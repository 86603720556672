import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { getFanCampaign, createFanCampaignResponse } from "../Lib/RESTBlox";
import FanCampaignQuestion from "../Components/FanCampaigns/FanCampaignQuestion";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

const FanCampaign: React.FC<Props> = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [answers, setAnswers] = React.useState<any>({});
  const [activeQuestionIndex, setActiveQuestionIndex] = React.useState<any>(0);
  const [activeQuestion, setActiveQuestion] = React.useState<any>();
  const [campaign, setCampaign] = React.useState<any>();
  const [orderId, setOrderId] = React.useState<any>(params.orderId);
  const [loading, setLoading] = React.useState<boolean>(true);
  const setStateFromNewData = (data: any) => {
    setCampaign(data);
    setActiveQuestion(data.questions[activeQuestionIndex]);
    setLoading(false);
  };

  const loadData = (campaignId: any) => {
    getFanCampaign(campaignId)
      .then((data) => setStateFromNewData(data))
      .catch((err) => console.error(err.toString()));
  };

  const handleAnswer = (answer: any) => {
    // Save the answer to state
    var newAnswers = answers;
    newAnswers[answer.questionId] = answer.value;

    // Move to the next question, or finish if all questions answered
    var answersLength = Object.keys(newAnswers).length;

    var surveyLength = campaign.questions.length;

    if (answersLength === surveyLength) {
      // User has answered all questions, finish campaign.
      console.log("User has finished survey.");
      setAnswers(newAnswers);
      finishCampaign();
    } else {
      // User has not finished, show the next question
      var nextQuestionIndex = activeQuestionIndex + 1;

      var nextQuestion = campaign.questions[nextQuestionIndex];

      setActiveQuestionIndex(nextQuestionIndex);
      setActiveQuestion(nextQuestion);
      setAnswers(newAnswers);
    }
  };

  const finishCampaign = () => {
    var data = {
      declined: false,
      campaignId: campaign.id,
      answers: answers,
      order_id: orderId,
    };
    console.log(data);
    createFanCampaignResponse(campaign.id, data).then((data) => {
      var returnUrl = "/events/" + params.eventId + "/purchase/" + orderId;
      navigate(returnUrl);
    });
  };

  React.useEffect(() => {
    loadData(params.campaignId);
  }, []);

  return (
    <Row className="clearHeaderAndButtonGroup">
      <Col>
        {campaign && (
          <FanCampaignQuestion
            campaign={campaign}
            question={activeQuestion}
            questionIndex={activeQuestionIndex}
            handleAnswer={handleAnswer}
          />
        )}
      </Col>
    </Row>
  );
};

export default FanCampaign;
