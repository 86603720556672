import React, { Component } from "react";
import ContactForm from '../Components/Forms/ContactForm'
import ContentLayout from '../Layouts/ContentLayout'

type Props = {
  user: any;
  whiteLabel: any;
};


const ContactUs: React.FC<Props> = (props: Props) => {

    return (
      <ContentLayout 
        title="Contact us"
        shortDescription="Need to get in touch with us? Fill out the form below and we'll get back to you as soon as possible."
      >
          { props.user ?
            <ContactForm user={props.user} whiteLabel={props.whiteLabel} />
            :<ContactForm user={undefined}  whiteLabel={props.whiteLabel}/>
          }

      </ContentLayout>
    );
}

export default ContactUs;
