import React from "react";
import { OrderDateProcessor } from "../../../../Lib/Helpers";

type Props = {
  order: any;
};
const OrderDate: React.FC<Props> = ({ order }) => {
  return (
    <p
      style={{
        width: "100%",
        textAlign: "end",
        color: "#bac9c8",
        display: "block",
        fontFamily: "Titillium Web",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.5,
      }}
    >
      {order ? OrderDateProcessor(order[0]?.date_created) : ""}
    </p>
  );
};
export default OrderDate;
