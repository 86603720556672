import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import {
  shortslashdateFormater,
  userTicketFormatter,
} from "../../../Lib/TableFormatters";
import BodyBox from "./BodyBox";
import { slashdateFormater } from "../../../Lib/TableFormatters";

type Props = {
  event: string;
  type: string;
  date: string;
  purchaser: string;
  ticketId: string;
  image: string;
  eventId: string;
  isMobile: boolean;
  ticketNumber: string;
};

export default function OrdersTable(props: Props) {
  const { event, type, date, purchaser, ticketId, ticketNumber, image, eventId, isMobile } =
    props;
  const navigate = useNavigate();
  return (
    <Grid
      container
      key={ticketId}
      style={{ borderBottom: "solid 1px #d0d0d0" }}
    >
      <Grid item xs={7} xl={5} md={5} lg={5}>
        <TableCell
          align="left"
          style={{
            border: "none",
            paddingLeft: 0,
            display: "flex",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/events/${eventId}`);
          }}
        >
          <Grid item xs={3.5} xl={2.5} md={2} lg={3} sm={2}>
            <div
              style={{
                width: isMobile ? "50px" : "75px",
                height: isMobile ? "50px" : "75px",
                marginRight: "8px",
                backgroundColor: "black",
                display: "flex",
                alignContent: "center",
                justifyContent:"center"
              }}
            >
              <img
                src={image}
                style={{ width: "auto", objectFit:"contain", maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
          </Grid>
          <Grid item xs={8.5} xl={9.5} md={10} lg={9} sm={10}>
            <BodyBox
              texts={[event, type]}
              styles={[
                {
                  fontWeight: 600,
                  fontSize: "medium",
                  color: "#2c2f2f",
                  alignSelf: "flex-start",
                  textTransform: "uppercase",
                },
                {
                  fontSize: ".8rem",
                  color: "#99abaa",
                  alignSelf: "flex-start",
                },
              ]}
            />
          </Grid>
        </TableCell>
      </Grid>
      <Grid item xs={3} xl={3} md={3} lg={3} container justifyContent="center">
        {!isMobile ? (
          <TableCell align="right" style={{ border: "none" }}>
            <BodyBox
              texts={[slashdateFormater(date)]}
              styles={[
                {
                  fontWeight: 600,
                  fontSize: "medium",
                  color: "#2c2f2f",
                  alignSelf: "flex-end",
                },
              ]}
            />
          </TableCell>
        ) : (
          <TableCell align="right" style={{ border: "none" }}>
            {shortslashdateFormater(date)}
          </TableCell>
        )}
      </Grid>
      {!isMobile ? (
        <Grid
          item
          xs={2}
          xl={2}
          md={2}
          lg={2}
          container
          justifyContent="center"
        >
          <TableCell align="right" style={{ border: "none" }}>
            {userTicketFormatter("", purchaser)}
          </TableCell>
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={2} xl={2} md={2} lg={2} container justifyContent="center">
        <TableCell
          align="right"
          style={{ border: "none" }}
          onClick={() => navigate(`/tickets/${ticketId}`)}
        >
          <BodyBox
            texts={[ticketNumber]}
            styles={[
              {
                fontWeight: 600,
                fontSize: "medium",
                color: "#007bff",
                opacity: "0.75",
                cursor: "pointer",
              },
            ]}
          />
        </TableCell>
      </Grid>
    </Grid>
  );
}
