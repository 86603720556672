import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, Nav, Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import SignInModal from "../Modals/SignInModal";
import SignUpModal from "../Modals/SignUpModal";
import { signOut } from "../../Lib/RESTAuth";
import { getOrgSendyURL } from "../../Lib/RESTBlox";
import AdminMenu from "./AdminMenu/AdminMenu";
import ClientMenu from "./ClientMenu/ClientMenu";
import { Box, IconButton, Tab, TextField } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import logoImage from './images/logo_mini.png'
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import ButtonLink from "../ButtonLink";
import { useMediaQuery } from "react-responsive";

type Props = {
  user: any;
  postSignIn: any;
  postSignUp: any;
  postSignOut: any;
  postPasswordForgot: any;
  whiteLabel: any;
};

const NavBar: React.FC<Props> = ({
  user,
  postSignIn,
  postSignUp,
  postSignOut,
  postPasswordForgot,
  whiteLabel,
}) => {
  const [collapsed, setCollapsed] = React.useState(true);
  // const [user: this.props.user,
  const [tab, setTab] = React.useState("2");
  const [logo, setStateLogo] = React.useState(whiteLabel?.logo);
  const [clientAdminVisible, setClientAdminVisible] = React.useState(false);
  const [sendy, setSendy] = React.useState<any>();
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const closeNavbar = () => {
    setCollapsed(true);
  };

  const handleSignOut = () => {
    signOut()
      .then((json) => {
        // this.props.history.push('/')
        postSignOut();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLogo();
    loadSendyURL();
  }, []);

  const loadSendyURL = () => {
    if (user) {
      if (user.profile?.has_admin_access) {
        getOrgSendyURL().then((data: any) => {
          setSendy(data);
        });
      }
    }
  };

  const submitSendyForm = () => {
    return (document?.getElementById("sendyLogin") as any).submit();
  };

  const toggleClientDashboard = () => {
    setClientAdminVisible(!clientAdminVisible);
  };

  const setLogo = () => {
    setTimeout(function () {
      if (window.location.pathname.includes("/admin") && user !== undefined) {
        // The user is logged in and in the admin area;
        // show the logo of their organization's white label.
        setStateLogo(user?.profile?.organization?.white_label?.logo);
      } else if (!window.location.pathname.includes("/admin")) {
        // The user is not in the admin area;
        // show the logo of the current white label subdomain.
        setStateLogo(whiteLabel.logo);
      }
    }, 100);
  };
  const [value, setValue] = React.useState("2");
  return (
    <div className="container-fluid">
      <Navbar dark fixed="true" className="fixed-top">
        <div className="navbar-brand mr-auto">
          <Link
            to="/"
            className="siteLogo"
            style={{
              // backgroundImage: `url(https://cdn.thebloxoffice.com/images/menu/logo_mini.png)`
              backgroundImage: `url(${
                whiteLabel?.subdomain === "default"
                  ? logoImage
                  : whiteLabel.logo
              })`,
            }}
            onClick={closeNavbar}
          >
            {whiteLabel.title}
          </Link>
        </div>
        <Row noGutters className="navbar-structure">
          <Col xs="6" className="SignInUpOutButtons">
            {user ? (
              <>
                <Button
                  size="sm"
                  outline
                  color="primary"
                  onClick={handleSignOut}
                >
                  Sign out
                </Button>
                <Button
                  size="sm"
                  outline
                  color="primary"
                  to="/hello"
                  style={{ marginLeft: "10px" }}
                >
                  <Link className="tickets-nav-button" to="/tickets" onClick={closeNavbar}>
                    Tickets
                  </Link>
                </Button>
              </>
            ) : (
              <span>
                <SignInModal
                  postSignUp={postSignUp}
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  closeNavbar={closeNavbar}
                  outline
                />
                <SignUpModal
                  postSignUp={postSignUp}
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  closeNavbar={closeNavbar}
                  outline
                />
              </span>
            )}
          </Col>
          <Col xs="5" className="userGreeting">
            {user && (
              <p>
                <Link to="/change-profile">
                  {user.first_name} {user.last_name}
                </Link>
              </p>
            )}
          </Col>
          <Col xs="1">
            <IconButton
              onClick={toggleNavbar}
              style={{ color: "white", padding: 0 }}
            >
              {collapsed ? (
                <MenuIcon fontSize="large" />
              ) : (
                <CloseIcon fontSize="large" />
              )}
            </IconButton>
            {/* <NavbarToggler onClick={toggleNavbar} /> */}
          </Col>
        </Row>
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <TabContext value={tab}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <TabList
                  centered
                  textColor="secondary"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  style={{
                    borderBottom: "solid 1px white",
                    width: isMobile ? "60%" : "25%",
                  }}
                >
                  {user?.profile?.has_admin_access && (
                    <Tab label="Admin" value="1" style={{ color: "white" }} />
                  )}
                  <Tab
                    label="Site"
                    value="2"
                    style={{ color: value == "2" ? "white" : "wheat" }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" style={{ overflowY: "auto" }}>
                {user && user.profile?.has_admin_access && (
                  <AdminMenu
                    clientAdminVisible={clientAdminVisible}
                    sendy={sendy}
                    toggleClientDashboard={toggleClientDashboard}
                    submitSendyForm={submitSendyForm}
                    toggleNavbar={toggleNavbar}
                    user={user}
                    isMobile={isMobile}
                  />
                )}
              </TabPanel>
              <TabPanel value="2">
                <ClientMenu
                  user={user}
                  toggleNavbar={toggleNavbar}
                  whiteLabel={whiteLabel}
                  isMobile={isMobile}
                />
              </TabPanel>
            </TabContext>
            <div className="BloxMenuFooter">
              <ul>
                <li>
                  <Link to="/page/privacy-policy" onClick={toggleNavbar}>
                    Privacy
                  </Link>
                </li>
                <li>
                  <Link to="/page/website-terms" onClick={toggleNavbar}>
                    Terms
                  </Link>
                </li>
                <li>
                  <Link to="/page/purchase-terms" onClick={toggleNavbar}>
                    Purchase Terms
                  </Link>
                </li>
              </ul>
              {whiteLabel.id === 2 && (
                <p>Copyright © 2021 5 W Division Corp. All rights reserved.</p>
              )}
              {whiteLabel.id != 2 && (
                <p>
                  Copyright © 2022 The Blox Office LLC. All rights reserved.
                </p>
              )}
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
