import { Button as MuiButton, Grid } from "@mui/material";
import { Row, Col, Table, Button, ButtonGroup, Input, Label } from "reactstrap";

type Props = {
  handleTabChange: (tab: any) => void;
  tab: any;
  isMobile: boolean;
};

const GuestListTabs: React.FC<Props> = ({ handleTabChange, tab,isMobile }) => {
  return (
    <div className="guestlistButtonGroupContainer">
      <ButtonGroup>
        {!isMobile? <MuiButton className="buttonGuestlist-dummy">TTTTTTTTTTTT</MuiButton>:<MuiButton className="buttonGuestlist-dummy">T</MuiButton>}
        <MuiButton
          color="primary"
          disabled={tab === "guestlist" ? true : false}
          className={
            tab === "guestlist" ? "buttonGuestlist-selected" : "buttonGuestlist"
          }
          onClick={() => handleTabChange("guestlist")}
        >
          Check In Tickets
        </MuiButton>
        <MuiButton
          color="primary"
          disabled={tab === "comp_list" ? true : false}
          className={
            tab === "comp_list" ? "buttonGuestlist-selected" : "buttonGuestlist"
          }
          onClick={() => handleTabChange("comp_list")}
        >
          Manage Comps
        </MuiButton>
        {!isMobile? <MuiButton className="buttonGuestlist-dummy">TTTTTTTTTTTT</MuiButton>:<MuiButton className="buttonGuestlist-dummy">T</MuiButton>}
      </ButtonGroup>
    </div>
  );
};

export default GuestListTabs;
