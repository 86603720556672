import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import SignInModal from '../Components/Modals/SignInModal'

type Props = {
  postSignIn: any;
  postSignUp: any;
  postPasswordForgot: any;
};

const SignInRequired:  React.FC<Props> = ( props: Props ) => {
  const location = useLocation();
  const [nextPath, setNextPath] = React.useState<any>();

  React.useEffect(() => {
    if (location.state) {
      var nextPath = (location.state as any).nextPath
      setNextPath(nextPath);
    }
  }, []);

    return (
      <div>
        <Row className="clearHeader">
          <Col>
            <p>You must sign in to view this.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <SignInModal
              postSignIn = { props.postSignIn }
              postSignUp = {props.postSignUp}
              postPasswordForgot = {props.postPasswordForgot}
              nextPath = {nextPath}
              open
              displayButton = {false}
              closeNavbar = {() => {}}
            />
          </Col>
        </Row>
      </div>
    );
}

export default SignInRequired;
