import React from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import { FormField } from '../../Forms/Fields'
import {addVenue, changeVenue} from '../../../Lib/RESTBlox'
import GoogleMapComponentWithMarker from '../../Maps/Map'
import ButtonLink from '../../ButtonLink'



class VenueModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      buttonText: this.props.buttonText || "Add venue",
      block: this.props.block || false,
      outline: this.props.outline || false,
      alert: {
        type: '',
        message: ''
      },
      venue: this.props.venue || {}
    };
    this.toggle = this.toggle.bind(this)
    this.close = this.close.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNewVenue = this.props.handleNewVenue.bind(this)
  }

  _isMounted = false

  toggle() {
    if (this._isMounted) {
      this.setState({
        modal: !this.state.modal
      });
    }
  }

  close() {
    this.setState({
      modal: false
    });
  }

  handleSubmit() {
    this.form.submitForm()
  }

  onSubmitCallback = values => {
    this.handleAddVenue(values)
  }

  handleAddVenue(values) {
    addVenue(values)
      .then(json => {
        this.setState({
          venue: json,
        })
        this.handleNewVenue(json)
        this.toggle()
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleChangeVenue(values) {
    changeVenue(this.state.venue.id, values)
      .then(json => {
        this.setState({
          alert: {
            type: 'success',
            message: 'Venue updated.'
          }
        })
        this.props.history.push('/admin/')
      })
      .catch(err => {
        this.setState({
          alert: {
            type: 'danger',
            message: 'There was an error updating the venue.'
          }
        })
      })
  }

  resetState() {
    this.setState({
      venue: {},
      success: false
    })
  }

  setInitialValue(keyName) {
    if (keyName in this.state.venue) {
      return this.state.venue[keyName]
    } else {
      return ''
    }
  }

  validate = (values) => {
    let errors = {};
    if (!values.name) {
      errors.event = 'Required';
    }
    if (!values.address) {
      errors.quantity = 'Required';
    }
    return errors;
  };

  componentDidMount() {
    this._isMounted = true
    if (this.props.open) {
      this.toggle()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {


    const form = (
      <Formik
      initialValues={{
        title: this.setInitialValue('name'),
        address: this.setInitialValue('address'),
      }}
      ref={node => (this.form = node)}
      onSubmitCallback={this.onSubmitCallback}
      onSubmit={(values, actions) => {
        console.log('ran submit')
        this.onSubmitCallback(values)
      }}
      validate={this.validate}
      render={(props) => (
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Add venue</ModalHeader>
          <ModalBody>


          {this.state.success ?
            <div>
              <p><strong>{this.state.venue.name} added with address:</strong><br />{this.state.venue.address}</p>
              <GoogleMapComponentWithMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key="
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `250px`, marginBottom: `1rem` }} />}
                venue={this.state.venue}
              />
              <Row className="buttonRow">
                <Col xs={6}  className="pr-1">
                  <Button
                    type="button"
                    block
                    color="secondary"
                    className="hvr-grow-shadow"
                    onClick={() => this.resetState()}
                  >
                    Use another
                  </Button>
                </Col>
                <Col xs={6} className="pl-1">
                  <Button
                    type="button"
                    block
                    color="primary"
                    className="hvr-grow-shadow"
                  >
                    Use this venue
                  </Button>
                </Col>
              </Row>
            </div>
            :  <Form>
                  <Row>
                    <Col>
                      <Field
                        label="Name"
                        type="text"
                        name="name"
                        placeholder="Enter the venue name..."
                        component={FormField}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        label="Address"
                        type="text"
                        name="address"
                        placeholder="Enter the venue address..."
                        component={FormField}
                        required={true}
                        help="Enter the complete venue address, such as 1234 Melrose Ave E, Seattle WA, 98102"
                      />
                    </Col>
                  </Row>
                  <Row className="buttonRow">
                    <Col xs={6} className="pr-1">
                      <Button
                        onClick={this.close}
                        text="Cancel"
                        block
                        color="secondary"
                      >
                          Cancel
                      </Button>
                    </Col>
                    <Col xs={6} className="pl-1">
                      {Object.entries(this.state.venue).length !== 0?
                        <Button className="hvr-grow-shadow" block color="primary" type="submit">Update</Button>
                        : <Button 
                          className="hvr-grow-shadow" 
                          block 
                          color="primary" 
                          onClick={this.handleSubmit}
                          type="button"
                          >
                            Add venue
                          </Button>
                      }
                    </Col>
                  </Row>
                </Form>
              }
            </ModalBody>
          </Modal>
          )}
        />
    )


    return (
      <div>


        {this.props.displayButton !== false &&
          <Button
            className="hvr-grow-shadow"
            color="secondary"
            outline={this.state.outline}
            size="sm"
            block={this.state.block}
            onClick={this.toggle}
          >
              { this.state.buttonText }
          </Button>
        }
        <div>
          {form}
         </div>
      </div>
    )
  }
}

export default VenueModal
