import React from 'react';
import { Link, Navigate } from "react-router-dom";
import { Row, Col, Button, ButtonGroup } from "reactstrap";
import { Formik, Field, Form } from 'formik';
import {FormCheckbox} from '../../Components/Forms/Fields'
import {completeOrder,updateAdminEventSubPromoterLinks} from '../../Lib/RESTBlox'
import {subscribe} from '../../Lib/RESTSendy'
import FlashAlert from '../../Components/FlashAlert'
import {getStripeAPIKey} from '../../App/Configuration'
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaCcDinersClub,
  FaCcJcb,
  FaCreditCard,
  FaApple,
  FaGoogle,
  // FaMicrosoft
  FaStripe
} from 'react-icons/fa'
import ReactPixel from 'react-facebook-pixel'

export default class EventPurchaseForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      completePurchase: false,
      order: this.props.order,
      alert: {
        type: '',
        message: ''
      },
      ordering: false,
      paymentType: 'card',
      canUsePaymentsAPI: true,
    }
    this.completePurchase = this.completePurchase.bind(this)
    this.completeFreePurchase = this.completeFreePurchase.bind(this)
    this.getUser = this.props.getUser.bind(this)
    this.tokenizeCard = this.tokenizeCard.bind(this)
    this.StripeAPIKey = getStripeAPIKey() ?? ""
    console.log(this.StripeAPIKey)
  }

  subscribeToEmailList(userName, userEmail, listId) {
    subscribe(userName, userEmail, listId)
      .then(response => {
        console.log(response)
      })
  }

  fbPixelTrackPurchase(order) {
    if (this.props.whiteLabel.facebook_pixel_id) {
      ReactPixel.init(this.props.whiteLabel.facebook_pixel_id, {}, { autoConfig: true, debug: false });
      ReactPixel.track('Purchase', {value: this.props.order.subtotal, currency: 'USD'});
    }
  }

  completePurchase(token) {
    completeOrder(this.props.order, token)
      .then(json => {
        // this.getUser()
        this.setState({
          // order: json,
          completePurchase: true,
          ordering: false
        })
        this.fbPixelTrackPurchase(this.props.order)
      })
      .catch(err => {
        console.log(err.response.data)
        this.setState({
          alert: {
            type: 'danger',
            message: 'Transaction failed — ' + err.response.data.msg
          },
          ordering: false
        })
      })
  }

  completeFreePurchase() {
    this.setState({ordering: true})
    completeOrder(this.props.order, '')
      .then(json => {
        this.setState({
          order: json,
          completePurchase: true,
          ordering: false
        })
        if(window.location.pathname.split('/')[5]){
          updateAdminEventSubPromoterLinks(window.location.pathname.split('/')[5],true,this.props.order.tickets_requested[0]?.req_quantity).then(()=>{})
        }
      })
      .catch(err => {
        console.log(err.response.data)
        this.setState({
          alert: {
            type: 'danger',
            message: 'Transaction failed — ' + err.response.data.msg
          },
          ordering: false
        })
      })
  }

  handleToken(token, values) {
    // Add the token to the form
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    this.form.appendChild(hiddenInput);
    this.completePurchase(token)
    return true
  }

  async tokenizeCard(values) {
    // Tokenizes the credit card
    this.setState({ordering: true})
    const {token, error} = await this.stripe.createToken(this.card);
    if (error) {
      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = error.message;
      console.log(error)
      this.setState({ordering: false})
    } else {
      this.handleToken(token, values);
    }
  }

  validate = (values) => {
    let errors = {};

    if (!values.agreeToTerms) {
      errors.agreeToTerms = 'Required';
    }

    return errors;
  };

  handlePaymentTypeChange(type) {
    this.setState({
      paymentType: type
    })
  }

  componentDidMount() {
    // Set up Stripe
    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '18px',
        fontFamily: 'Titillium Web',
        color: "#32325d",
      }
    };
    this.form = document.getElementById('BloxPaymentForm');
    this.stripe = this.StripeAPIKey ? window.Stripe(this.StripeAPIKey) : null;
    this.elements = this.stripe?.elements({
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600',
          },
        ],
        locale: window.__exampleLocale
      });

    if (this.props.order.subtotal_str !== "$0.00") {
      // If the order is not free, add a Stripe Element for credit cards
      this.card = this.elements?.create('card', {style});
      this.card?.mount('#card-element');
      this.card?.addEventListener('change', ({error}) => {
        const displayError = document.getElementById('card-errors');
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = '';
        }
      });

      // And also add a Stripe Element for Google Pay/Apple Pay/MS Pay/Payment Request API
      console.log(this.props.order.total)
      this.paymentRequest = this.stripe?.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Tickets for ' + this.props.event.__str__,
          amount: this.props.order?.total?  parseInt(this.props.order.total * 100):0,
        }
      });

      this.prButton = this.elements?.create('paymentRequestButton', {
        paymentRequest: this.paymentRequest,
      });
      console.log(this.prButton)
      
      // Check the availability of the Payment Request API.
      var that=this
      this.paymentRequest?.canMakePayment().then(function(result) {
        if (result) {
          // We can use the Payment Requests API, create the button and set state appropriately.
          that.prButton?.mount('#payment-request-element');
          that.setState({canUsePaymentsAPI: true})
        } else {
          // The user's browser doesn't support these payment methods, don't show the button.
          // document.getElementById('payment-request-element').style.display = 'none';
          that.setState({canUsePaymentsAPI: false})
        }
      });


      this.paymentRequest?.on('token', async (ev) => {
        const response = this.handleToken(ev.token)
        if (response) {
          ev.complete('success');
        } else {
          ev.complete('fail');
        }
      });
    }

    // Set up for using a previous card is one is available
    if (this.props.user.profile.has_stripe_payment_card) {
      this.handlePaymentTypeChange('previous')
      var card_brand = this.props.user.profile.stripe_payment_card_brand
      var icon = <FaCreditCard />
      if (card_brand === 'American Express') icon = <FaCcAmex />
      else if (card_brand === 'Diners Club') icon = <FaCcDinersClub />
      else if (card_brand === 'Discover') icon = <FaCcDiscover />
      else if (card_brand === 'JCB') icon = <FaCcJcb />
      else if (card_brand === 'MasterCard') icon = <FaCcMastercard />
      else if (card_brand === 'Visa') icon = <FaCcVisa />
      else icon = <FaCreditCard />
      this.setState({
        previousCardIcon: icon
      })
    }
  }

  render(props) {

    if (this.state.completePurchase === true) {
      return <Navigate
        to = {{
          pathname: '/purchase/receipt/' + this.props.order.id,
          event: this.props.event,
          order: this.props.order
        }}
      />
    }

    return (
      <Formik
        initialValues={{
          agreeToTerms: false,
          agreeToEmails: false,
        }}
        onSubmit={(values, actions) => {
          
          if (values.agreeToEmails) {
            // console.log(this.props)
            // console.log('User agrees to emails, subscribing...')
            // console.log('White label is ' + this.props.whiteLabel.title)
            // console.log('Sendy list ID is ' + this.props.whiteLabel.sendy_list_id)
            this.subscribeToEmailList(this.props.user.profile.name, this.props.user.email, 'PoVh0lZA7le5716n6iVSyw')
            if (this.props.whiteLabel.sendy_list_id) {
              this.subscribeToEmailList(this.props.user.profile.name, this.props.user.email, this.props.whiteLabel.sendy_list_id)
            }
          }

          if (this.props.order.subtotal_str !== "$0.00") {
            if (this.state.paymentType === 'card') {
              this.setState({ordering: true})
              this.tokenizeCard(values)
            } else if (this.state.paymentType === 'previous') {
              this.setState({ordering: true})
              this.completePurchase()
            } else if (this.state.paymentType === 'other') {
              console.log('we dont currenty have an other payment type')
            }
          } else {
            this.completeFreePurchase()
          }
          // JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validate={this.validate}
        render={(props) => (

          <Form className="BloxForm" id="BloxPaymentForm">
            { this.props.order.subtotal_str === "$0.00" &&
              <p>There is no cost for your order! Just agree to the terms below and complete your purchase.</p>
            }
            { this.state.alert.message &&
              <Row>
                <Col>
                  <FlashAlert
                    color={this.state.alert.type}
                    text={this.state.alert.message}
                  />
                </Col>
              </Row>
            }
            {this.props.order.subtotal_str !== "$0.00" &&
              <div>
                <Row>
                  <div className="paymentButtonGroupContainer">
                    <ButtonGroup>
                      {this.props.user.profile.has_stripe_payment_card &&
                        <Button
                          color="primary"
                          disabled={this.state.paymentType === 'previous' ? true : false}
                          size="md"
                          onClick={() => this.handlePaymentTypeChange('previous')}
                        >
                          <span className="previousCard">{this.state.previousCardIcon}</span>
                          Previous card
                        </Button>
                      }
                      <Button
                        color="primary"
                        disabled={this.state.paymentType === 'card' ? true : false}
                        size="md"
                        onClick={() => this.handlePaymentTypeChange('card')}
                      >
                        <span className="creditCard"><FaCreditCard /></span>
                        {this.props.user.profile.has_stripe_payment_card ? <span>Another card</span> : <span>Card</span> }
                      </Button>
                      {/*
                        <Button
                          color="primary"
                          disabled={this.state.paymentType === 'other' ? true : false}
                          size="md"
                          onClick={() => this.handlePaymentTypeChange('other')}
                        >
                          <div>
                            <span className="applePay">
                              <FaApple />
                            </span>
                            <span className="googlePay">
                              <FaGoogle />  
                            </span> 
                            <span className="msPay">
                              <FaMicrosoft />
                            </span> 
                          </div>
                        </Button>
                      */}
                      <Button
                        color="primary"
                        disabled={this.state.paymentType === 'other' ? true : false}
                        size="md"
                        onClick={() => this.handlePaymentTypeChange('other')}
                      >
                        <div>
                          <span className="applePay">
                            <FaApple />
                          </span>
                          <span className="googlePay">
                            <FaGoogle />  
                          </span> 
                          <span className="linkPay">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 52 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.32711 2.08111C7.32711 0.930578 8.27753 0 9.45259 0C10.6276 0 11.5781 0.930578 11.5781 2.08111C11.5781 3.23164 10.6276 4.16222 9.45259 4.16222C8.27753 4.16222 7.32711 3.23164 7.32711 2.08111ZM0 0.218434H3.80167V22.7553H0V0.218434ZM51.4297 6.68172C49.2352 11.2669 46.7813 14.6001 46.7813 14.6001L52 22.7384H47.5071L44.293 17.7133C41.0788 21.3172 37.882 23.0768 34.8061 23.0768C31.0563 23.0768 29.5183 20.4543 29.5183 17.4764C29.5183 17.1436 29.523 16.7337 29.5274 16.3383V16.3381L29.5274 16.3378C29.5316 15.9695 29.5356 15.6138 29.5356 15.3445C29.5356 11.4023 29.1209 10.2856 27.7557 10.4717C25.1464 10.827 21.1892 16.6304 18.6145 22.7553H15.0374V6.68172H18.8391V14.6847C21.0164 11.1147 23.0037 8.03528 26.2005 6.85091C28.0495 6.15721 29.622 6.46176 30.4342 6.81707C33.3891 8.08604 33.3718 11.1992 33.3373 15.3615C33.32 15.9198 33.32 16.512 33.32 17.138C33.32 18.6608 33.752 19.3207 34.8061 19.4222C35.8256 19.5237 36.6033 19.033 36.6033 19.033V0.218434H40.4049V16.3597C40.4049 16.3597 43.7055 13.4157 47.1961 6.68172H51.4297ZM11.351 6.6833H7.54938V22.7569H11.351V6.6833Z" fill="currentColor"></path></svg>
                          </span> 
                        </div>
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
                { this.props.user.profile.has_stripe_payment_card &&
                  <Row className={this.state.paymentType === 'previous' ? 'paymentTypeRow show' : 'paymentTypeRow hide'}>
                    <Col>
                      <h4 className="previousPaymentMethod">
                        {this.state.previousCardIcon} 
                        <span>**** **** **** </span>
                        {this.props.user.profile.stripe_payment_card_last4}
                      </h4>
                    </Col>
                  </Row>
                }
                <Row className={this.state.paymentType === 'card' ? 'paymentTypeRow show' : 'paymentTypeRow hide'}>
                  <Col>
                    <label htmlFor="card-element">
                      Credit or debit card
                      <span className="required">*</span>
                    </label>
                    <div id="card-element" />
                    <div id="card-errors" role="alert" />
                  </Col>
                </Row>

                <Row className={this.state.paymentType === 'other' ? 'paymentTypeRow show' : 'paymentTypeRow hide'}>
                  <Col>
                    {this.state.ordering === false ?
                      <div id="payment-request-element" />
                      : <div id="payment-request-element" disabled />
                    }
                    {this.state.canUsePaymentsAPI ?
                      <p>By paying with Apple Pay, Google Pay, Stripe Link, or your browser's payment system, you agree to The Blox Office's <Link to='/page/website-terms'>website terms</Link>, <Link to='/page/purchase-terms'>purchase terms</Link>, and <Link to='/page/privacy-policy'>privacy policy</Link>. You also acknowledge that this event is {this.props.event.age_limit_display} and valid photo ID is required.</p>
                      : <strong>Your browser doesn't appear to be configured for Apple Pay, Google Pay, or Stripe Link. Please enter a credit or debit card instead.</strong>
                    }
                  </Col>
                </Row>

              </div>
            }
            
            {this.state.paymentType !== 'other' &&
              <div>
                { this.props.event && this.props.event.venue.id == 2 &&
                  <Field
                    label={<span>I agree to The Blox Office's <Link to='/page/website-terms'>website terms</Link>, <Link to='/page/purchase-terms'>purchase terms</Link>, and <Link to='/page/privacy-policy'>privacy policy</Link>. I also agree to Primary Night Club's <Link to='/page/covid-policy'>COVID-19 policy</Link>. I also acknowledge that this event is {this.props.event.age_limit_display} and valid photo ID is required.</span>}
                    type="checkbox"
                    name="agreeToTerms"
                    component={FormCheckbox}
                    required={true}
                  />
                }
                { this.props.event && this.props.event.venue.id != 2 &&
                  <Field
                    label={<span>I agree to The Blox Office's <Link to='/page/website-terms'>website terms</Link>, <Link to='/page/purchase-terms'>purchase terms</Link>, and <Link to='/page/privacy-policy'>privacy policy</Link>. I also acknowledge that this event is {this.props.event.age_limit_display} and valid photo ID is required.</span>}
                    type="checkbox"
                    name="agreeToTerms"
                    component={FormCheckbox}
                    required={true}
                  />
                }
                <Field
                  label={<span>I consent to receiving promotional emails from The Blox Office.</span>}
                  type="checkbox"
                  name="agreeToEmails"
                  component={FormCheckbox}
                />
                <Row>
                  <Col>
                    { this.props.order.subtotal_str === "$0.00" && this.state.ordering === false &&
                      <Button className="hvr-grow-shadow" color="primary" block type="submit">Complete Free Order</Button>
                    }
                    { this.props.order.subtotal_str !== "$0.00" && this.state.ordering === false &&
                      <Button className="hvr-grow-shadow" color="primary" block type="submit">Complete Purchase</Button>
                    }
                    { this.state.ordering === true &&
                      <Button className="hvr-grow-shadow" color="primary" block disabled type="submit">Ordering...</Button>
                    }
                  </Col>
                </Row>
              </div>
            }

          </Form>
        )}
      />
    );
  }
}
