import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Formik, Field, Form } from 'formik';
import { 
  FormField,
  FormDatePicker,
  FormCheckbox
} from './Fields'
import {addTableReservationsFormResponse} from '../../Lib/RESTBlox'
import FlashAlert from '../FlashAlert'
import { validateEmail } from '../../Lib/FormValidation'
import {subscribe} from '../../Lib/RESTSendy'

class TableReservationsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submissionComplete: false,
      alert: {
        type: '',
        message: ''
      },
    }
  }

  subscribeToEmailList(userName, userEmail, listId) {
    subscribe(userName, userEmail, listId)
      .then(response => {
        console.log(response)
      })
  }

  handleAddTableReservationsFormResponse(values) {
    values.message = "DATE: " + values.date.toDateString() + "; PARTY SIZE: " + values.party + "\r\n\r\n" + values.message;
    addTableReservationsFormResponse(values)
      .then(json => {
        this.setState({
          alert: {
            type: 'success',
            message: 'Your table inquiry has been sent!'
          }
        })
        var that = this
        setTimeout(function(){ that.props.history.push('/') }, 2000);
        
      })
      .catch(err => {
        this.setState({
          alert: {
            type: 'danger',
            message: 'There was an error sending your table inquiry.'
          }
        })
      })
  }

  getInitialValueFromUser(key) {
    if (this.props.user) {
      return this.props.user[key]
    } else {
      return ''
    }
  }

  getInitialValueFromUserProfile(key) {
    if (this.props.user) {
      return this.props.user.profile[key]
    } else {
      return ''
    }
  }

  render() {

    return (
      <Col>
        { this.state.alert.message &&
          <Row>
            <Col>
              <FlashAlert color={this.state.alert.type} text={this.state.alert.message} />
            </Col>
          </Row>
        }
        <Formik
          initialValues={{
            agreeToEmails: false,
            first_name: this.getInitialValueFromUser('first_name'),
            last_name: this.getInitialValueFromUser('last_name'),
            email: this.getInitialValueFromUser('email'),
            phone: this.getInitialValueFromUserProfile('phone'),
          }}
          onSubmit={(values, actions) => {
            if (values.agreeToEmails) {
              // console.log(this.props)
              // console.log('User agrees to emails, subscribing...')
              // console.log('White label is ' + this.props.whiteLabel.title)
              // console.log('Sendy list ID is ' + this.props.whiteLabel.sendy_list_id)
              if (this.props.whiteLabel.sendy_list_id) {
                this.subscribeToEmailList(this.props.user.profile.name, values.email, this.props.whiteLabel.sendy_list_id)
              }
            }

            this.handleAddTableReservationsFormResponse(values)
            actions.setSubmitting(false);
          }}
          render={(props ) => {
            return (
              <Form>
                <Row>
                  <Col className="pr-1">
                    <Field
                      label="First name"
                      type="text"
                      name="first_name"
                      placeholder="Your first name..."
                      component={FormField}
                      required={true}
                    />
                  </Col>
                  <Col className="pl-1">
                    <Field
                      label="Last name"
                      type="text"
                      name="last_name"
                      placeholder="Your last name..."
                      component={FormField}
                      required={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1">
                    <Field
                      label="Email"
                      type="email"
                      name="email"
                      placeholder="Your email address..."
                      component={FormField}
                      required={true}
                      validate={validateEmail}
                    />
                  </Col>
                  <Col className="pl-1">
                    <Field
                      label="Phone"
                      type="text"
                      name="phone"
                      placeholder="Your phone number..."
                      component={FormField}
                      required={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6}>
                    <Field
                      label="Date"
                      type="text"
                      name="date"
                      placeholder="Select the date..."
                      component={FormDatePicker}
                      required={true}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Field
                      label="Party size"
                      type="number"
                      name="party"
                      placeholder="0"
                      component={FormField}
                      required={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      label="Message"
                      type="textarea"
                      name="message"
                      placeholder="Your message..."
                      component={FormField}
                      required={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      label={<span>Subscribe me to promotional emails from The Blox Office.</span>}
                      type="checkbox"
                      name="agreeToEmails"
                      component={FormCheckbox}
                    />
                  </Col>
                </Row>
                <Row className="buttonRow">
                  <Col xs="12">
                    <Button className="hvr-grow-shadow" block color="primary" type="submit">Send</Button>
                  </Col>
                </Row>
              </Form>
          )}}
        />
      </Col>
    );
  }
}

export default TableReservationsForm
