import React from "react";

type Props = {
  order: any;
};
const EventTitle: React.FC<Props> = ({ order }) => {
  return (
    <p
      style={{
        width: "100%",
        textAlign: "center",
        fontStyle: "italic",
      }}
    >
      {order ? order[0]?.event?.title : ""}
    </p>
  );
};
export default EventTitle;
