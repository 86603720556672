import React from 'react';
import { Button, Row, Col, Table } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import EventDetailsPurchaseTableRow from './EventDetailsPurchaseTableRow'
import EventDetailsPurchaseTableDetailsRow from './EventDetailsPurchaseTableDetailsRow'
import {createOrder} from '../../Lib/RESTBlox'
import SignInModal from '../Modals/SignInModal'
import Loader from "../Loader";
var Shake = require('shake.js');

var OrderHacks =
{
  // ID => ORDER ARRAY (NON-MATCHES ARE LAST IN AN UNDEFINED ORDER)
  "436": [ 1073, 1074, 1075, 1076 ],
  "430": [ 1065, 1054, 1055, 1056, 1057, 1070, 1064 ],
  "434": [ 1099, 1068, 1069, 1078, 1079, 1080, 1088, 1085, 1087, 1086 ],
  "439": [ 1098, 1095, 1096, 1097 ],
  "421": [ 1016, 1017, 1018 ],
  "441": [ 1116, 1100, 1101, 1102, 1104, 1105, 1106 ],
  "444": [ 1110, 1111, 1112, 1113, 1117, 1123 ],
  "446": [ 1156, 1124, 1125, 1126, 1127, 1128 ],
  "449": [ 1141, 1142, 1143 ],
  "448": [ 1134, 1135, 1163 ],
  "455": [ 1153, 1154, 1155 ],
  "461": [ 1172, 1173, 1174 ],
  "462": [ 1178, 1175, 1176, 1177 ],
  "378": [ 771, 772, 773, 778 ],
  "465": [ 1181, 1182, 1183, 1184, 1185, 1186, 1187 ],
  "459": [ 1165, 1166, 1167 ],
  "471": [ 1202, 1206, 1208, 1241, 1277, 1203, 1207, 1209, 1242, 1278 ],
  "464": [ 1193, 1180 ],
  "473": [ 1212, 1213 ],
  "480": [ 1225, 1226, 1227 ],
  "495": [ 1262, 1263, 1264, 1265, 1266, 1269, 1268, 1267 ],
  "479": [ 1222, 1223, 1224 ],
  "499": [ 1280, 1281, 1282, 1283 ],
  "507": [ 1306, 1307, 1308 ],
  "508": [ 1309, 1310, 1311 ],
  "491": [ 1250, 1252, 1253 ],
  "504": [ 1299, 1300, 1301, 1302, 1303, 1326 ],
  "520": [ 1330, 1331, 1338, 1339 ],
  "544": [ 1378, 1380, 1379, 1381 ],
  "543": [ 1373, 1374, 1375, 1376 ],
  "542": [ 1372, 1371, 1370, 1369 ],
  "548": [ 1392, 1393, 1394, 1395, 1396 ],
  "560": [ 1415, 1416, 1422, 1431, 1424 ],
  "573": [ 1493, 1448, 1449, 1450, 1451 ],
  "570": [ 1440, 1437, 1438, 1456 ],
  "561": [ 1418, 1419, 1460, 1461 ],
  "571": [ 1441, 1442, 1443, 1454 ],
  "586": [ 1496, 1498, 1497, 1499 ],
  "585": [ 1488, 1490, 1489 ],
  "577": [ 1467, 1494, 1495 ],
  "509": [ 1312, 1313, 1314 ]
};

class EventDetailsPurchaseTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initPurchase: false,
      tickets: {},
      tickets_url: '',
      order: undefined,
      secretInventoryCodesEntered: [],
      secretInventoryCodes: [],
      purchasing: false,
    }
    this.initiatePurchase = this.initiatePurchase.bind(this)
    this.handleQuantityChange = this.handleQuantityChange.bind(this)
    this.hackInventoryOrder = this.hackInventoryOrder.bind(this)
    this.postSignIn = this.props.postSignIn.bind(this)
    this.postSignUp = this.props.postSignUp.bind(this)
    this.postPasswordForgot = this.props.postPasswordForgot.bind(this)
    this.listenForSecrectInventoryCodes = this.listenForSecrectInventoryCodes.bind(this)
    this.shakeEventDidOccur = this.shakeEventDidOccur.bind(this)
  }

  initiatePurchase() {
    this.setState({purchasing: true})
    createOrder(this.state.tickets)
      .then(json => {
        this.setState({
          order: json,
          initPurchase: true,
        })
      })
  }

  closeNavbar() {
  }

  handleQuantityChange(data) {
    var tickets = this.state.tickets
    if (data.quantity === 0) {
      delete tickets[data.inventory_id]
    } else {
      tickets[data.inventory_id] = data.quantity
    }
    this.setState({
      tickets: tickets
    })
  }

  hackInventoryOrder()
  {
    var orderHack = OrderHacks[this.props.event.id];
    if (!orderHack)
      return;
    
    this.props.event.ticket_inventories?.sort(function(ti1, ti2)
    {
      var idx1 = orderHack.indexOf(ti1.id);
      var idx2 = orderHack.indexOf(ti2.id);
      if (idx1 < 0 && idx2 < 0)
        return 0;

      if (idx1 < 0)
        return 1;
      if (idx2 < 0)
        return -1;

      if (idx2 < idx1)
        return 1;

      return -1;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.event?.id !== this.props.event?.id) {
      this.setState({
        tickets: {},
        secretInventoryCodes: [],
        secretInventoryCodesEntered: []
      })    
    }

    if (prevProps.event !== this.props.event) {
      this.setSecretInventoryCodeListeners()
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.event !== nextProps.event) {
      this.removeSecretInventoryCodeListeners()
    }
  }

  componentDidMount() {
    this.shakeEvent.start();
    this.setSecretInventoryCodeListeners();
    // Ensure the shake event listener is added
    window.addEventListener('shake', this.shakeEventDidOccur, false);
    console.log("Shake event listener added");
  }

  componentWillUnmount() {
    // Clean up the event listener to prevent memory leaks
    window.removeEventListener('shake', this.shakeEventDidOccur, false);
    console.log("Shake event listener removed");
  }

  eventHasTicketInventories() {
    return this.props.event.ticket_inventories?.length > 0
  }

  listenForSecrectInventoryCodes(word, callback, remove=false) {
    let matched = 0
    const onKeyPress = e => {
      // convert the keycode to the character
      const char = String.fromCharCode(e.which)
      // check if the current character is the expected character
      if (word.charAt(matched) === char) {
        // move on to the next character
        matched++
      }
      else {
        // reset the counter
        matched = 0
      }
      if (matched === word.length) {
        callback(e, word)
        matched = 0
      }
    }

    if (remove) {
      window.removeEventListener('keypress', onKeyPress, true)
      return
    }
    window.addEventListener('keypress', onKeyPress, true)
  }

  shakeEventDidOccur() {
    console.log("Shake event occurred");
    var enteredSecret = window.prompt("Tell me a secret.");
    var enteredCodeList = this.state.secretInventoryCodesEntered;
    enteredCodeList.push(enteredSecret);
    this.setState({ secretInventoryCodesEntered: enteredCodeList });
    if (this.state.secretInventoryCodes.includes(enteredSecret)) {
        alert('Booyah! You found a secret ticket type. Tap the event poster to check it out.');
    } else {
        alert('That is not a valid secret.');
    }
  }

  setSecretInventoryCodeListeners(remove=false) {
    this.setState({secretInventoryCodes: []})
    var that = this
    this.props.event.ticket_inventories?.forEach(function(inventory) {

      if (inventory.secret && inventory.secret_code) {
        // Add the code to the codeList in state
        var codeList = that.state.secretInventoryCodes
        codeList.push(inventory.secret_code)
        that.setState({secretInventoryCodes: codeList})

        // Add listener for konami-style code, for desktop
        that.listenForSecrectInventoryCodes(inventory.secret_code, (e, word) => {
          var enteredCodeList = that.state.secretInventoryCodesEntered
          enteredCodeList.push(word)
          that.setState({secretInventoryCodesEntered: enteredCodeList})
        }, remove)
      }
    })

    // Add listener for shake gesture on mobile
    if (this.props.event.ticket_inventories?.length > 0) {
      var shouldShake = false

      // See if we have a secret inventory; if so set shouldShake=true
      this.props.event.ticket_inventories.forEach(function(inventory) {
        if (inventory.secret && inventory.secret_code) {
          shouldShake = true
        }
      })

      // If we have a secret inventory, turn on the listener
      if (shouldShake) {
        window.addEventListener('shake', that.shakeEventDidOccur, false);
      }
    }
  }

  shakeEvent = new Shake({threshold: 15});

  removeSecretInventoryCodeListeners() {
    this.setSecretInventoryCodeListeners(true)
    window.removeEventListener('shake', this.shakeEventDidOccur, false)
  }
  
  render() {
    if (this.state.initPurchase === true) {
      if(window.location.pathname.split('/').length===4){
        return <Navigate
          to = {{
            pathname: '/events/' + this.props.event.id + '/purchase/' + this.state.order.id + '/' + window.location.pathname.split('/')[3],
          }}
        />
      }else{
        return <Navigate
          to = {{
            pathname: '/events/' + this.props.event.id + '/purchase/' + this.state.order.id,
          }}
        />
      }
    }
    this.hackInventoryOrder();
    return (
      <div className="BloxEventPurchase">
        <Row>
          <Col>
            {!this.eventHasTicketInventories() ?
              <div className="eventNotOnSale">
                Tickets for this event are not currently available.
              </div> :
              <div>
                <Table size="sm" responsive={true} className="BloxEventDetailsPurchaseTable">
                  <thead>
                    <tr>
                      <th scope="col">Ticket type</th>
                      <th scope="col" className="price right-align">Price</th>
                      <th scope="col" className="quantity right-align">Quantity</th>
                    </tr>
                  </thead>
                    { this.props.event.ticket_inventories?.sort((a, b) => {
                        return a.sort_order - b.sort_order;
                      }).map((inventory, i) => {
                        var classList = 'isSoldOut-' + inventory.sold_out + ' isInSaleWindow-' + inventory.in_sale_window + ' isBeforeSaleStartDate-' + inventory.before_sale_start_date + ' isAfterSaleEndDate-' + inventory.after_sale_end_date + ' isSecret-' + inventory.secret
                        if (inventory.secret) {
                          var inventoryFound = (this.state.secretInventoryCodesEntered.includes(inventory.secret_code))
                          if (inventoryFound) {
                            classList = classList + ' isSecretShow'
                          }
                        }
                        return (
                          <tbody className={"inner " + classList} key={"EventDetailsPurchaseTableRowTBody" + inventory.id}>
                            <EventDetailsPurchaseTableRow
                              inventory={inventory}
                              key={"EventDetailsPurchaseTableRow" + inventory.id}
                              handleQuantityChange={this.handleQuantityChange}
                            />
                            {/* <EventDetailsPurchaseTableDetailsRow
                              inventory={inventory}
                              key={"EventDetailsPurchaseTableDetailsRow" + inventory.id}
                            /> */}
                          </tbody>
                        )
                      })
                    }
                </Table>
                {this.props.event.ticket_inventories?.some(inventory => inventory.secret) &&
                  <Button 
                    className="hvr-grow-shadow"
                    color="primary"
                    size="sm"
                    block
                    style={{ marginBottom: '3px' }}
                    onClick={() => {
                      console.log("Button clicked, dispatching shake event");
                      const shakeEvent = new Event('shake', { bubbles: true, cancelable: true });
                      window.dispatchEvent(shakeEvent);
                    }}
                  >
                    View Secret Tickets
                  </Button>
                }
                {this.props.signedIn && Object.keys(this.state.tickets).length === 0 &&
                  <Button
                    color="primary"
                    size="sm"
                    block
                    onClick={this.initiatePurchase}
                    disabled
                  >
                    Purchase tickets
                  </Button>
                }
                {this.props.signedIn && Object.keys(this.state.tickets).length > 0 &&
                  (
                    this.state.purchasing ?
                    <Loader color="#000" />
                  :
                <Button
                  className="hvr-grow-shadow"
                  color="primary"
                  size="sm"
                  block
                  onClick={this.initiatePurchase}
                  disabled={this.state.purchasing}
                >
                  Purchase tickets
                </Button>
                  )
                }
                {!this.props.signedIn &&
                  <SignInModal
                    postSignUp = {this.postSignUp}
                    postSignIn = {this.postSignIn}
                    postPasswordForgot= {this.postPasswordForgot}
                    closeNavbar = {this.closeNavbar}
                    buttonText = "Sign in to purchase"
                    block
                    outline = {false}
                  />
                }
              </div>

            }

          </Col>
        </Row>
      </div>

    );
  }
}

export default EventDetailsPurchaseTable
