import React from "react";
import {Row, Col} from "reactstrap";
import Calendar from '../Components/EventCalendar/Calendar'
import {getFeedForWhiteLabelWithTypes} from '../Lib/RESTBlox'
import EventTile from '../Components/EventTile'
import ContentLayout from "../Layouts/ContentLayout";
import Loader from '../Components/Loader'
import EventCalendarMobile from "./EventCalendarMobile";

type Props = {
  whiteLabel: any;
};


const EventCalendar: React.FC<Props> = (props) => {

const [next, setNext] = React.useState<any>();
const [count, setCount] = React.useState<any>();
const [events, setEvents] = React.useState<any>([]);
const [calendarEvents, setCalendarEvents] = React.useState<any>([]);
const [feed, setFeed] = React.useState<any>({});
const [mounths, setMounths] = React.useState<any>({});
const [loading, setLoading] = React.useState<boolean>(true);

 const titleFormatter = (event:any) => {
    return (
      <div className="eventCalendarEvent">
        <p>{ event.title}</p>
        <span>{event.date}, {event.time1_display}</span>
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >{ event.venue}, { event.street_address}</span>
        <EventTile event={event} />
      </div>
    )
  }

  const setStateFromNewData = (data:any) => {
      var calendarEvents = data.map((event:any, i:any) => {
        // Assume sever's time is central timezone for now
        var startDateString = event.date + 'T' + event.time1 + '-06:00'
        if (event.time2) {
          var endDateString = event.date + 'T' + event.time2 + '-06:00'
        } else {
          // If we don't have a time2, just use the time1 again.
          endDateString = event.date + 'T' + event.time1 + '-06:00'
        }
        // This will convert to display in the visitor's local time.
        var startDate = new Date(startDateString);
        var endDate = new Date(endDateString);
        if (endDate < startDate) {
          endDate.setDate(endDate.getDate() + 1);
        }
        return (
          {
            title: titleFormatter(event),
            // title: event.title,
            start: startDate,
            end: endDate,
            // allDay: false,
          }
        )
      })

      setEvents(events)
      setCalendarEvents(calendarEvents)
      setLoading(false)
  }

  const getDateStr = (selectedDate = null) => {
    let date = selectedDate ? new Date(selectedDate) : new Date();
    let month = '' + (date.getMonth() + 1);
    let year = date.getFullYear();
    if (month.length < 2) month = '0' + month;

    return `${month}-${year}`;
  };

  const getCalendarEventsData = (date = null) => {
    let dateParam = getDateStr(date);
    getFeedForWhiteLabelWithTypes(props.whiteLabel.id, ['Event'], dateParam )
      .then(data => {
        setFeed(data);
        setLoading(false);
        setStateFromNewData(data);
      })
  }

  React.useEffect(() => {
    getCalendarEventsData()
  }, []);

  const changeIsLoading = (value:any) => {
    setLoading(value);
  }

    let mq = window.matchMedia("(min-width: 768px)")
    // console.log ('mq', mq);
    if (mq.matches) {
      return (
        <ContentLayout
          title="Event calendar"
          fullWidth={true}
        >
          <Col className="calendarContainer">
            <Calendar
              handleChangeDate={getCalendarEventsData}
              events={calendarEvents}
            />
          </Col>
        </ContentLayout>
    );
    } else {
      return (
        <ContentLayout>
          <Row style={{ width: '100%'}}>
            <Col style={{ width: '100%'}}>
              <Loader
                loading={loading}
                color='#fff'
                style={{
                  marginTop: '4rem',
                  position: 'relative',
                  left: '53%'
                }}
              />
              <EventCalendarMobile whiteLabel={props.whiteLabel} changeIsLoading={changeIsLoading}/>
              {/*<ActivityFeed feed={feed} title="Calendar" />*/}
            </Col>
          </Row>
        </ContentLayout>
      )
    }
}

export default EventCalendar;
