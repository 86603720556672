import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import EventPurchaseTable from "../Components/EventPurchase/EventPurchaseTable";
import Header from "../Components/Header";
import EventDetails from "../Components/EventDetails/EventDetails";
import TicketTable from "../Components/EventPurchase/TicketTable";
import { getOrder } from "../Lib/RESTBlox";
import scrollToElement from "scroll-to-element";
import { useLocation, useParams } from "react-router-dom";

type Props = {
  postSignIn: any;
  signedIn: any;
  postSignUp: any;
  postPasswordForgot: any;
  eventDetails: any;
};

const PurchaseReceipt: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const [event, setEvent] = React.useState<any>();
  const [order, setOrder] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const params = useParams();
  const setStateFromNewData = (data: any) => {
    setEvent({...data.event, venue_undisclosed: data?.tickets_issued[0]?.event.venue_undisclosed});
    setOrder(data);
    setLoading(true);
  };

  const loadData = (orderId: any) => {
    getOrder(orderId)
      .then((data) => setStateFromNewData(data))
      .catch((err) => console.error(err.toString()));
  };

  const hideTapOrScrollForMore = () => {
    var e = (
      document.getElementsByClassName(
        "TapOrScrollForMore"
      ) as HTMLCollectionOf<HTMLElement>
    )[0];
    e.style.visibility = "hidden";
  };

  React.useEffect(() => {
    loadData(params.orderId);
  }, []);

  return (
    <Row noGutters className="BloxEventsRow">
      <Col md={6} className="BloxEventsHeroImageColumn">
        <span
          className="eventDetailsLink"
          onClick={() => {
            hideTapOrScrollForMore();
            scrollToElement(props.eventDetails, {
              offset: -108,
              align: "top",
              duration: 600,
            });
          }}
        />
        {event && (
          <div className="eventHeroImage">
            <div style={{ backgroundImage: "url(" + event.image + ")" }} />
          </div>
        )}
        <button
          className="btn hvr-grow-shadow TapOrScrollForMore"
          onClick={() =>
            scrollToElement(props.eventDetails, {
              offset: -108,
              align: "top",
              duration: 600,
            })
          }
        >
          View receipt
        </button>
      </Col>
      <Col
        md={6}
        className={
          "BloxEventsDetailsColumn BloxEventsDetailsColumnPurchaseReceipt loading"
        }
      >
        <Row className="BloxEventPurchaseReceiptRow">
          <Col>
            <div
              // ref={(div) => {
              //   props.eventDetails = div;
              // }}
            >
              <Header content="You got tickets" className="clearHeader" />
            </div>
            {event && (
              <p>
                <strong>FOR: {event.__str__}</strong>
                <br />
                Your purchase is complete. At The Blox Office, tickets are 100%
                digital and we don't issue barcodes. When you arrive at the
                event, the name you registered with will be on our list. All you
                need for entry is your valid photo ID. You may transfer tickets
                to a friend now, or at anytime by navigating to your orders in
                the account section.
              </p>
            )}

            {order && (
              <Header
                content="Your order"
                secondaryContent={"#" + order.number}
              />
            )}
            {event && (
              <div>
                <EventPurchaseTable
                  orderComplete={true}
                  event={event}
                  order={order}
                  tickets={order?.tickets_issued}
                />
              </div>
            )}
            <Header content="Your tickets" />
            {order && (
              <TicketTable tickets={order.tickets_issued} loading={false} />
            )}
          </Col>
        </Row>

        <Header content="Event details" className="addMargins" />
        {event && (
          <EventDetails
            event={event}
            showPurchaseTable={false}
            signedIn={props.signedIn}
            postSignUp={props.postSignUp}
            postSignIn={props.postSignIn}
            postPasswordForgot={props.postPasswordForgot}
          />
        )}
      </Col>
    </Row>
  );
};

export default PurchaseReceipt;
