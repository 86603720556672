import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BodyBox from './BodyBox';
import { Link } from 'react-router-dom';

type Props = {
    name: string,
    status: string,
    date: string,
    time: string,
    id: string,
    total: number,
};

export default function UserOrdersTable(props: Props) {

    const { name, status, date, time, id, total } = props;

    return (
        <TableContainer sx={{ paddingStart: 1 }}>
            <Table sx={{ minWidth: 500, backgroundColor: '#FFF' }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <BodyBox texts={['DETAILS']}
                                styles={[
                                    { fontWeight: 600, color: '#99abaa', textAlign: 'start', alignSelf: 'flex-start' }
                                ]} />
                        </TableCell>
                        <TableCell align='right'>
                            <BodyBox texts={['TIME']}
                                styles={[
                                    { fontWeight: 600, color: '#99abaa', textAlign: 'right', alignSelf: 'right' }
                                ]}
                                boxStyle={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignContent: 'right',
                                    justifyContent: 'right',
                                    alignItems: 'right',
                                }} />
                        </TableCell>
                        <TableCell align='right'>
                            <BodyBox texts={['ORDER']}
                                styles={[
                                    { fontWeight: 600, color: '#99abaa', textAlign: 'center' }
                                ]} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        key={name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <BodyBox texts={[name, status.toLowerCase()]}
                                styles={[
                                    { fontWeight: 500, color: '#000', alignSelf: 'flex-start', fontSize: "large" },
                                    { fontSize: '0.8rem', color: '#99abaa', alignSelf: 'flex-start' }
                                ]} />
                        </TableCell>
                        <TableCell align='center'>
                            <BodyBox texts={[date, time]}
                                styles={[
                                    { fontWeight: 600, color: '#000', alignSelf: 'right', textAlign: 'right', justifySelf: 'right', alignContent: 'right' },
                                    { fontSize: '.8rem', color: '#99abaa', alignSelf: 'right', textAlign: 'right', justifySelf: 'right', alignContent: 'right' }
                                ]}
                                boxStyle={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignContent: 'right',
                                    justifyContent: 'right',
                                    alignItems: 'right',
                                    marginRight: '10px'
                                }}
                            />
                        </TableCell>
                        <TableCell align='center'>
                            <Link to={`/admin/orders/${id}`} style={{ fontWeight: "bolder" }}>
                                {id}
                            </Link>
                            <BodyBox texts={[ total + '']}
                                styles={[
                                    { fontSize: '.8rem', color: '#99abaa', alignSelf: 'center' }
                                ]} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}