import React, { Component, CSSProperties, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { Formik, Field, Form } from "formik";
import {
  FormField,
  FormDatePicker,
  // FormMultiEventPicker,
  FormCheckbox,
} from "../../../Components/Forms/Fields";
import FormMultiEventPicker from "./components/FormMultiEventPicker/FormMultiEventPicker";
import {
  addPromoCode,
  changePromoCode,
  deletePromoCode,
  changeTicketInventoriesForPromoCode,
} from "../../../Lib/RESTBlox";
import ButtonLink from "../../../Components/ButtonLink";
import EventTile from "../../../Components/Admin/EventTile";
import Header from "../../../Components/Header";
import { useNavigate } from "react-router";
import FlashAlert from "../../../Components/FlashAlert";
import Loader from "../../../Components/Loader";

type Props = {
  user: any;
  promoCode?: any;
  prepopulateNew?: boolean;
};

const PromoCodeForm: React.FC<Props> = ({
  promoCode,
  user,
  prepopulateNew,
}) => {
  const [success, setSuccess] = React.useState(false);
  const [currentPromoCode, setCurrentPromoCode] = React.useState<any>(
    promoCode || {}
  );
  const navigate = useNavigate();
  const [change, setChange] = React.useState<boolean>(false);
  const [alert, setAlert] = React.useState({
    type: "",
    message: "",
  });
  const [loading, setLoading] = React.useState(false);
  const handleAddPromoCode = (values: any) => {
    setLoading(true);
    addPromoCode(values)
      .then((json) => {
        setSuccess(true);
        setLoading(false);
        setCurrentPromoCode(json);
        setAlert({
          type: "success",
          message: "Promocode created.",
        });
        navigate("/admin/promo-codes/");
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data) {
          setAlert({
            type: "danger",
            message: err.response.data,
          });
        } else {
          setAlert({
            type: "danger",
            message: "Unexpected Error Adding new Promocode",
          });
        }
      });
  };

  const handleChangePromoCode = (values: any) => {
    setLoading(true);
    changePromoCode(currentPromoCode.id, values)
      .then((json) => {
        setLoading(false);
        setSuccess(true);
        setCurrentPromoCode(json);
        setAlert({
          type: "success",
          message: "Promocode updated.",
        });
        navigate("/admin/promo-codes/");
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message: "There was an error updating the promo code.",
        });
      });
  };

  const handleDeletePromoCode = (promoCodeId: any) => {
    setLoading(true);
    deletePromoCode(promoCodeId)
      .then((json) => {
        setLoading(false);
        navigate("/admin/promo-codes/");
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message: "There was an error deleting the promocode",
        });
      });
  };

  const handleChangePromoCodeTicketInventories = (values: any) => {
    setLoading(true);
    changeTicketInventoriesForPromoCode(currentPromoCode.id, values)
      .then((json) => {
        setLoading(false);
        setAlert({
          type: "success",
          message: "Ticket inventories updated.",
        });
        setCurrentPromoCode(json);
        navigate("/admin/promo-codes/");
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message: "There was an error updating the ticket inventories.",
        });
      });
  };

  const loaderStyle: CSSProperties = {
    margin: 0,
    marginTop: "-190px",
    position: "relative",
    left: 0,
    display: "flex",
    justifyContent: "center",
  };

  const validate = (values: any) => {
    interface LooseObject {
      [key: string]: any;
    }

    let errors: LooseObject = {};

    if (!values.events) {
      errors.events = "Required";
    }

    if (!values.code) {
      errors.code = "Required";
    }

    if (!values.number_available) {
      errors.number_available = "Required";
    }

    if (!values.discount_amount && !values.discount_percent) {
      errors.discount_amount =
        "You must specify either a discount percentage or a discount amount.";
    }

    if (values.discount_amount && values.discount_percent) {
      errors.discount_percent =
        "You must specify either a discount percentage or a discount amount. You may not specify both.";
    }

    return errors;
  };

  const setInitialValue = (keyName: any) => {
    if (keyName === "expiration_date") {
      if ("expiration_date" in currentPromoCode) {
        if (currentPromoCode.expiration_date !== null) {
          var date = new Date(
            currentPromoCode.expiration_date.replace(
              /(\d{4})-(\d{2})-(\d{2})/,
              "$2/$3/$1"
            )
          );
          return date;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }

    if (keyName === "events") {
      if ("events" in currentPromoCode) {
        return currentPromoCode.events.map((object: any, i: number) => {
          return {
            value: object.id,
            label: object.date_display + " — " + object.__str__,
          };
        });
      } else {
        return [];
      }
    }

    if (keyName in currentPromoCode) {
      return currentPromoCode[keyName];
    } else {
      return "";
    }
  };

  const setTicketInventoryInitialValues = () => {
    if (!currentPromoCode.events) {
      return {};
    }

    interface LooseObject {
      [key: string]: any;
    }

    var values: LooseObject = {};
    for (var i = 0; i < currentPromoCode.events.length; i++) {
      var thisEvent = currentPromoCode.events[i];

      // If this event doesn't have per-inventory promo codes, then all should be marked as true.
      if (!thisEvent.has_per_inventory_promo_codes) {
        console.log("event " + thisEvent.__str__ + " should have all checked");
        for (var y = 0; y < thisEvent.ticket_inventories.length; y++) {
          var thisInventory = thisEvent.ticket_inventories[y];
          var fieldName = "ticket_inventory-" + thisInventory.id;
          values[fieldName] = true;
        }
        // But if the event does have per-inventory promo codes, loop through them to determin if they're true or false.
      } else {
        for (y = 0; y < thisEvent.ticket_inventories.length; y++) {
          thisInventory = thisEvent.ticket_inventories[y];
          fieldName = "ticket_inventory-" + thisInventory.id;

          // Check if this promo code is in thisInventory.allowed_promo_codes
          var filteredAllowedPromoCodes =
            thisInventory.allowed_promo_codes.filter(
              (promo_code_id: any) => promo_code_id === currentPromoCode.id
            );
          if (filteredAllowedPromoCodes.length === 1) {
            values[fieldName] = true;
          } else {
            values[fieldName] = false;
          }
        }
      }
    }
    console.log(values);
    return values;
  };

  const setUpState = () => {
    // If we have a promoCode in props, then we are editing an existing promoCode.
    if (promoCode) {
      setChange(true);
      setCurrentPromoCode(promoCode);
    }
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps !== this.props) {
  //     this.setUpState()
  //   }
  // }

  useEffect(() => {
    setUpState();
  }, []);


  return !loading ? (
    <div>
      {alert.message && (
        <Row>
          <Col>
            <FlashAlert color={alert.type} text={alert.message} />
          </Col>
        </Row>
      )}
      <Formik
        initialValues={{
          code: setInitialValue("code"),
          discount_percent: setInitialValue("discount_percent"),
          discount_amount: setInitialValue("discount_amount"),
          number_available: setInitialValue("number_available"),
          expiration_date: setInitialValue("expiration_date"),
          events: setInitialValue("events"),
        }}
        onSubmit={(values, actions) => {
          if (Object.entries(currentPromoCode).length !== 0) {
            handleChangePromoCode(values);
          } else {
            handleAddPromoCode(values);
          }
          actions.setSubmitting(false);
        }}
        validate={validate}
        render={(props) => (
          <Form>
            <Header content="Promo code" />
            <Row>
              <Col xs={12} md={6}>
                <Field
                  label="Code"
                  type="text"
                  name="code"
                  placeholder="The promo code..."
                  component={FormField}
                  required={true}
                  help="The text a user will enter to redeem this discount. Note that this is case-sensitive. The customer's capitalization must match what you enter here."
                />
              </Col>
              <Col xs={12} md={6}>
                <Field
                  label="Expiration date"
                  type="date"
                  name="expiration_date"
                  placeholder="Select date..."
                  component={FormDatePicker}
                  required={false}
                  help="If this is filled in, the promo code will no longer be valid after this date"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Field
                  label="Discount as percentage"
                  type="number"
                  name="discount_percent"
                  placeholder="Percentage of discount..."
                  component={FormField}
                  required={false}
                  help='Fill in either this field or "Discount as amount." Enter a value representing a percentage, such as 25 for 25% or 75 for 75%'
                />
              </Col>
              <Col xs={12} md={4}>
                <Field
                  label="Discount as amount"
                  type="text"
                  name="discount_amount"
                  placeholder="Amount of discount..."
                  component={FormField}
                  required={false}
                  help='Fill in either this field or "Discount as percentage." Enter a value representing a dollar amount, such as 2.00 for $2.00 or 8.50 for $8.50'
                />
              </Col>
              <Col xs={12} md={4}>
                <Field
                  label="Quantity"
                  type="number"
                  name="number_available"
                  placeholder="Number available..."
                  component={FormField}
                  required={true}
                  help="Enter the number of times this promo code may be used before it is no longer valid"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  label="Events"
                  type="text"
                  name="events"
                  placeholder="Select events..."
                  component={FormMultiEventPicker}
                  required={true}
                  help="Select the event(s) for which this promo code will be eligible."
                />
              </Col>
            </Row>
            <Row className="buttonRow">
              <Col xs={4} md={3}>
                {change && (
                  <Button
                    onClick={() => handleDeletePromoCode(currentPromoCode.id)}
                    block
                    color="danger"
                  >
                    Delete
                  </Button>
                )}
              </Col>
              <Col className="d-none d-md-block" md={3}></Col>
              <Col xs={4} md={3} className="pr-1">
                <ButtonLink
                  to={"/admin/promo-codes/"}
                  text="Cancel"
                  block
                  color="secondary"
                />
              </Col>
              <Col xs={4} md={3} className="pl-1">
                <Button
                  className="hvr-grow-shadow"
                  block
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      />

      {currentPromoCode.events && (
        <Formik
          initialValues={setTicketInventoryInitialValues()}
          onSubmit={(values, actions) => {
            console.log(values);
            handleChangePromoCodeTicketInventories(values);
            actions.setSubmitting(false);
          }}
          render={(props) => (
            <Form className="promoCodeTicketInventoriesForm">
              <Header content="Ticket invetories for this promo code" />
              {currentPromoCode.events.map((event: any, i: number) => {
                return (
                  <Row>
                    <Col xs={7} md={4}>
                      <EventTile event={event} user={user} />
                    </Col>
                    <Col xs={5} md={8}>
                      <h5>{event.__str__}</h5>
                      {event.ticket_inventories.map(
                        (inventory: any, i: number) => {
                          return (
                            <Field
                              label={inventory.type.name}
                              type="checkbox"
                              name={"ticket_inventory-" + inventory.id}
                              component={FormCheckbox}
                            />
                          );
                        }
                      )}
                    </Col>
                  </Row>
                );
              })}
              <Row className="buttonRow">
                <Col>
                  <Button
                    className="hvr-grow-shadow"
                    block
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      )}
    </div>
  ) : (
    <Loader loading={loading} color="#000" style={loaderStyle} />
  );
};

export default PromoCodeForm;
