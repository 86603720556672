import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Formik, Field, Form } from 'formik';
import { FormField, FormGenderPicker } from './Fields'
import {changeProfile} from '../../Lib/RESTAuth'
import FlashAlert from '../FlashAlert'
import { validateEmail, validateDateString } from '../../Lib/FormValidation'
import Loader from "../Loader";

type Props = {
  user: any;
  postProfileChange: any;
};

const ProfileChangeForm  : React.FC<Props> = (props:Props) => {

  const [alert, setAlert] = React.useState<any>({type:'',message:''});
  const [fetching, setFetching] = React.useState(false);

  const handleChangeProfile = (values:any) => {
    setFetching(true)
    changeProfile(values)
      .then(json => {
        props.postProfileChange(json)
        setAlert({type: 'success', message: 'The profile has been changed.'});
        setFetching(false)
      })
      .catch(err => {
        setAlert({type: 'danger',message: 'There was an error updating your profile.'});
        setFetching(false)
      })
  }

  const formatBirthdateForDisplay = () => {
    if (props.user?.profile.birthdate) {
      var yyyyMmDd = props.user?.profile.birthdate
      var yyyyMmDdSplit = yyyyMmDd.split('-')
      var yyyy = yyyyMmDdSplit[0]
      var mm = yyyyMmDdSplit[1]
      var dd = yyyyMmDdSplit[2]
      return mm + '/' + dd + '/' + yyyy
    } else {
      return ''
    }
  }

    return (
      <div>
        { alert.message &&
          <Row>
            <Col>
              <FlashAlert color={alert.type} text={alert.message} />
            </Col>
          </Row>
        }
        <Formik
          initialValues={{
            first_name: props.user?.first_name,
            last_name: props.user?.last_name,
            email: props.user?.email,
            phone: props.user?.profile?.phone,
            gender: props.user?.profile?.gender,
            birthdate: props.user?.profile?.birthdate
          }}
          onSubmit={(values, actions) => {
            handleChangeProfile(values)
            actions.setSubmitting(false);
          }}
          render={(props ) => (
            <Form>
              <Row>
                <Col className="pr-1">
                  <Field
                    label="First name"
                    type="text"
                    name="first_name"
                    placeholder="Your first name..."
                    component={FormField}
                    required={true}
                  />
                </Col>
                <Col className="pl-1">
                  <Field
                    label="Last name"
                    type="text"
                    name="last_name"
                    placeholder="Your last name..."
                    component={FormField}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="pr-1">
                  <Field
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Your email address..."
                    component={FormField}
                    required={true}
                    validate={validateEmail}
                  />
                </Col>
                <Col className="pl-1">
                  <Field
                    label="Phone"
                    type="text"
                    name="phone"
                    placeholder="Your phone number..."
                    component={FormField}
                    required={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="pr-1">
                  <Field
                    label="Gender"
                    type="text"
                    name="gender"
                    placeholder="Select..."
                    component={FormGenderPicker}
                    required={true}
                  />
                </Col>
                <Col className="pl-1">
                  <Field
                    label="Birthdate"
                    type="text"
                    name="birthdate"
                    placeholder="YYYY-MM-DD"
                    validate={validateDateString}
                    component={FormField}
                    required={true}
                  />
                </Col>
              </Row>
              <Row className="buttonRow">
                <Col xs="12">
                  {
                    fetching ?
                    <Loader color="#000" loading={true} />
                    :
                    <Button className="hvr-grow-shadow" block color="primary" type="submit">Update</Button>
                  }
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    );
}

export default ProfileChangeForm;
