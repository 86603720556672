import React from "react";
import EventDetailsRow from "./EventDetailsRow";
import EventDetailsDescription from "./EventDetailsDescription";
import EventDetailsPurchaseTable from "./EventDetailsPurchaseTable";

export default class EventDetails extends React.Component {
  constructor(props) {
    super(props);
    this.postSignIn = this.props.postSignIn.bind(this);
    this.postSignUp = this.props.postSignUp.bind(this);
    this.postPasswordForgot = this.props.postPasswordForgot.bind(this);
  }

  fixPurchaseWidgetPadding() {
    var purchaseWidget =
      document.getElementsByClassName("BloxEventPurchase")[0];
    if (purchaseWidget) {
      var rightContent = document.getElementsByClassName("BloxEventDetails")[0];
      var purchaseWidgetHeight = purchaseWidget.clientHeight;
      rightContent.style.paddingBottom = purchaseWidgetHeight + "px";
    }
  }

  componentDidUpdate(prevProps, prevState) {
    var fix = this.fixPurchaseWidgetPadding;
    var w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    if (w > 768) {
      setTimeout(function () {
        fix();
      }, 500);
    }
  }

  componentDidMount(prevProps, prevState) {
    var fix = this.fixPurchaseWidgetPadding;
    var w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    if (w > 768) {
      setTimeout(function () {
        fix();
      }, 500);
    }
    var e = document.getElementsByClassName("BloxEventDetails")[0];
    e.style.opacity = 1;
  }

  eventHasPromoters() {
    return (
      this.props.event.primary_promoters?.length > 0 ||
      this.props.event.secondary_promoters?.length > 0
    );
  }

  eventHasArtists() {
    return (
      this.props.event.headliners?.length > 0 ||
      this.props.event.undercard?.length > 0
    );
  }

  eventHasTicketInventories() {
    return this.props.event.ticket_inventories.length > 0;
  }

  render() {
    return (
      <div className="event-details-container">
        {this.props.event && (
          <div className="event-details-inner-container">
            <div className="BloxEventDetails ">
              {this.props.event.title && (
                <EventDetailsRow
                  header="Title"
                  content={this.props.event.title}
                />
              )}
              {this.props.event.id === 361 && (
                <EventDetailsRow
                  header="Dates"
                  content="02.28.2020 — 03.02.2020"
                />
              )}
              {this.props.event.id === 363 && (
                <EventDetailsRow
                  header="Dates"
                  content="10.01.2020 — 10.04.2020"
                />
              )}
              {this.props.event.id === 443 && (
                <EventDetailsRow
                  header="Dates"
                  content="08.27.2021 — 08.29.2021"
                />
              )}
              {this.props.event.id === 443 && (
                <EventDetailsRow
                  header="On Sale"
                  content="Through 08.29.2021"
                />
              )}
              {this.props.event.id !== 361 &&
                this.props.event.id !== 363 &&
                this.props.event.id !== 443 && (
                  <EventDetailsRow
                    header="Date"
                    content={this.props.event.date_display}
                  />
                )}
              {this.props.event.id !== 361 &&
                this.props.event.id !== 363 &&
                this.props.event.id !== 443 && (
                  <EventDetailsRow
                    header="Times"
                    content={
                      <div className="timeDisplay">
                        <span className="timeData">
                          {this.props.event.time1_display}
                        </span>
                        <span className="timeLabel">
                          &middot; {this.props.event?.time1_type?.name}
                        </span>
                        {this.props.event.time2 && this.props.event.time2_type && (
                          <span className="time2">
                            <span className="timeData">
                              {this.props.event.time2_display}
                            </span>
                            <span className="timeLabel">
                              &middot; {this.props.event.time2_type.name}
                            </span>
                          </span>
                        )}
                      </div>
                    }
                  />
                )}
              {this.eventHasPromoters() && (
                <EventDetailsRow
                  header="Production"
                  content={
                    <div>
                      {this.props.event.primary_promoters.map(
                        (promotion, i) => {
                          return <div key={i}>{promotion.promoter.name}</div>;
                        }
                      )}
                    </div>
                  }
                  secondaryContent={
                    <div>
                      {this.props.event.secondary_promoters.map(
                        (promotion, i) => {
                          return <div key={i}>{promotion.promoter.name}</div>;
                        }
                      )}
                    </div>
                  }
                />
              )}
              {this.eventHasArtists() && (
                <EventDetailsRow
                  header="Talent"
                  content={
                    <div>
                      {this.props.event.headliners.map((act, i) => {
                        return <div key={i}>{act.artist.name}</div>;
                      })}
                    </div>
                  }
                  secondaryContent={
                    <div>
                      {this.props.event.undercard.map((act, i) => {
                        return <div key={i}>{act.artist.name}</div>;
                      })}
                    </div>
                  }
                />
              )}

              {this.props.event.id === 361 && (
                <EventDetailsRow
                  header="Location"
                  content="Rocking J's Beach Hostel"
                  secondaryContent={
                    <div>
                      {"Puerto Viejo de Talamanca"}
                      <br />
                      {"Costa Rica"}
                    </div>
                  }
                />
              )}
              
              {this.props.event.id === 838 && (
                <EventDetailsRow
                  header="Location"
                  content="SURFSIDE BEACH BAR"
                  secondaryContent={
                    <div>
                      {"LG Smith Boulevard z/n Surfside Beach,"}
                      <br />
                      {"Oranjestad, Aruba"}
                    </div>
                  }
                />
              )}

              {this.props.event.id === 476 && (
                <EventDetailsRow
                  header="Location"
                  content="Northern Illinois"
                />
              )}

              {this.props.event.id === 1245 && (
                <EventDetailsRow
                  header="Location"
                  content="COCO LOCO (Eagle Beach)"
                  secondaryContent={
                    <div>
                      {"J.E. Irausquin Boulevard # 67A"}
                      <br />
                      {"Oranjestad, Aruba"}
                    </div>
                  }
                />
              )}

              {this.props.event.id !== 361 &&
                this.props.event.id !== 476 &&
                this.props.event.id !== 838 &&
                this.props.event.id !== 1245 &&
                !this.props.event.venue_undisclosed && (
                  <EventDetailsRow
                    header="Location"
                    content={this.props?.event?.venue?.name}
                    secondaryContent={
                      <div>
                        {this.props.event?.venue?.street_address}
                        <br />
                        {this.props.event?.venue?.city_state_zip}
                      </div>
                    }
                  />
                )}

              {this.props.event.id !== 361 &&
                this.props?.event.id !== 476 &&
                this.props?.event.id !== 1245 &&
                this.props.event?.venue_undisclosed && (
                  <EventDetailsRow header="Location" content="Undisclosed" />
                )}

              <EventDetailsRow
                header="Age"
                content={this.props.event?.age_limit_display}
              />
              {this.props.event.description && (
                <EventDetailsDescription
                  header="Description"
                  content={this.props.event?.description_html}
                />
              )}
            </div>
            {this.props.showPurchaseTable && (
              <EventDetailsPurchaseTable
                event={this.props.event}
                signedIn={this.props.signedIn}
                postSignUp={this.postSignUp}
                postSignIn={this.postSignIn}
                postPasswordForgot={this.postPasswordForgot}
                secretInventoryCodesEntered={
                  this.props.secretInventoryCodesEntered
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
