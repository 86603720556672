import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { NavItem } from "reactstrap";

import {
  FaRegCalendarAlt,
  FaRegCalendarPlus,
  FaRegCalendarTimes,
  FaTicketAlt,
  FaUserCircle,
  FaMoneyBillAlt,
  FaPlusCircle,
  FaChevronDown,
    FaUserPlus,
  FaMailBulk,
} from "react-icons/fa";
import { MdSettings } from "react-icons/md";

type Props = {
  user: any;
  clientAdminVisible: any;
  toggleClientDashboard: any;
  toggleNavbar: any;
  sendy: any;
  submitSendyForm: any;
  isMobile:Boolean
};

const AdminMenu: React.FC<Props> = ({
  user,
  clientAdminVisible,
  toggleClientDashboard,
  toggleNavbar,
  sendy,
  submitSendyForm,
  isMobile
}) => {
  return (
    <>
      <NavItem>
        <Link className="nav-link" to="/homepage" onClick={toggleNavbar}>
          Dashboard
        </Link>
      </NavItem>

      <h1
        style={{
          borderBottom: "1px solid #585f5f80",
          color: "#585f5f",
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "20px",
          margin: "1rem auto 0.25rem",
          maxWidth: isMobile?"50%":"25%",
          width: "auto",
          fontWeight: "300 !important",
          lineHeight: "36px",
        }}
      >
        Events
      </h1>
      <Row style={{ justifyContent: "center" }}>
        {(user.profile.permissions_list?.includes("add-event") || user.profile.permissions_list?.length == 0) && ( //remove the second condition
          <NavItem>
            <Link
              className="nav-link"
              to="/admin/events/add"
              onClick={toggleNavbar}
            >
              <FaRegCalendarPlus /> Add
            </Link>
          </NavItem>
        )}
        {(user.profile.permissions_list?.includes("view-upcoming-events") || user.profile.permissions_list?.length == 0) && (
          <NavItem>
            <Link
              className="nav-link"
              to="/admin/events/upcoming"
              onClick={toggleNavbar}
            >
              <FaRegCalendarAlt /> Upcoming
            </Link>
          </NavItem>
        )}
        {(user.profile.permissions_list?.includes("view-past-events") || user.profile.permissions_list?.length == 0) && (
          <NavItem>
            <Link
              className="nav-link"
              to="/admin/events/past"
              onClick={toggleNavbar}
            >
              <FaRegCalendarTimes /> Past
            </Link>
          </NavItem>
        )}
      </Row>
      <h1
        style={{
          borderBottom: "1px solid #585f5f80",
          color: "#585f5f",
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "20px",
          margin: "1rem auto 0.25rem",
          maxWidth: isMobile?"50%":"25%",
          width: "auto",
          fontWeight: "300 !important",
          lineHeight: "36px",
        }}
      >
        Payouts
      </h1>
      <Row style={{ justifyContent: "center" }}>
        {(user.profile.permissions_list?.includes("view-orders") || user.profile.permissions_list?.length == 0) && (
          <NavItem>
            <Link className="nav-link" to="/admin" onClick={toggleNavbar}>
              <FaPlusCircle /> Stripe Connect
            </Link>
          </NavItem>
        )}
      </Row>
      <h1
        style={{
          borderBottom: "1px solid #585f5f80",
          color: "#585f5f",
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "20px",
          margin: "1rem auto 0.25rem",
          maxWidth: isMobile?"50%":"25%",
          width: "auto",
          fontWeight: "300 !important",
          lineHeight: "36px",
        }}
      >
        Customers
      </h1>
      <Row style={{ justifyContent: "center" }}>
        {(user.profile.permissions_list?.includes("view-orders") || user.profile.permissions_list?.length == 0) && (
          <NavItem>
            <Link
              className="nav-link"
              to="/admin/orders"
              onClick={toggleNavbar}
            >
              <FaTicketAlt /> Orders
            </Link>
          </NavItem>
        )}
        {(user.profile.permissions_list?.includes("view-customers") || user.profile.permissions_list?.length == 0) && (
          <NavItem>
            <Link className="nav-link" to="/admin/users" onClick={toggleNavbar}>
              <FaUserCircle /> Users
            </Link>
          </NavItem>
        )}
      </Row>
      <h1
        style={{
          borderBottom: "1px solid #585f5f80",
          color: "#585f5f",
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "20px",
          margin: "1rem auto 0.25rem",
          maxWidth: isMobile?"50%":"25%",
          width: "auto",
          fontWeight: "300 !important",
          lineHeight: "36px",
        }}
      >
        Promo Codes
      </h1>
      <Row style={{ justifyContent: "center" }}>
        {(user.profile.permissions_list?.includes("add-promo-code") || user.profile.permissions_list?.length == 0) && (
          <NavItem>
            <Link
              className="nav-link"
              to="/admin/promo-codes/add"
              onClick={toggleNavbar}
            >
              <FaPlusCircle /> Add
            </Link>
          </NavItem>
        )}
        {(user.profile.permissions_list?.includes("view-promo-codes") || user.profile.permissions_list?.length == 0) && (
          <NavItem>
            <Link
              className="nav-link"
              to="/admin/promo-codes"
              onClick={toggleNavbar}
            >
              <FaMoneyBillAlt /> View
            </Link>
          </NavItem>
        )}
      </Row>
      {( (user?.profile?.permissions_list?.includes(
        "edit-white-label-site-settings"
      ) && user?.profile?.organization?.white_label) || user?.is_superuser) && (
        <div>
          <h1
            style={{
              borderBottom: "1px solid #585f5f80",
              color: "#585f5f",
              textAlign: "center",
              textTransform: "uppercase",
              fontSize: "20px",
              margin: "1rem auto 0.25rem",
              maxWidth: isMobile?"50%":"25%",
              width: "auto",
              fontWeight: "300 !important",
              lineHeight: "36px",
            }}
          >
            Account Settings
          </h1>
            {(user.profile.permissions_list?.includes("edit-staff")) && (
                <Row style={{ justifyContent: "center" }}>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/admin/staff"
                      onClick={toggleNavbar}
                    >
                      <FaUserPlus /> Staff
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/admin/site/edit"
                      onClick={toggleNavbar}
                    >
                      <MdSettings /> Site
                    </Link>
                  </NavItem>
                </Row>
            )}
        </div>
      )}
      {(user.profile.permissions_list?.includes("access-sendy-admin") || user.profile.permissions_list?.length == 0) && (
        <div style={{ textAlign: "center" }}>
          <form
            style={{ display: "none" }}
            id="sendyLogin"
            method="post"
            action={sendy?.sendy_url}
          >
            <input
              type="hidden"
              name="email"
              value={sendy?.sendy_email_address}
            />
            <input
              type="hidden"
              name="password"
              value={sendy?.sendy_password}
            />
          </form>
          <a
            href="https://sendy.thebloxoffice.com/login"
            onClick={submitSendyForm}
            className="nav-link"
          >
            <NavItem>
              <FaMailBulk /> Email campaigns
            </NavItem>
          </a>
        </div>
      )}
    </>
  );
};

export default AdminMenu;
