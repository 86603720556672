import React from 'react';
import { Row, Col, Button } from "reactstrap";
import { Formik, Field, Form } from 'formik';
import {FormField} from '../../Components/Forms/Fields'
import {applyPromoCode} from '../../Lib/RESTBlox'
import FlashAlert from '../../Components/FlashAlert'

export default class EventPurchaseForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: this.props.order,
      event: this.props.event,
      alert: {
        type: '',
        message: ''
      },
      applying: false,
    }
    this.updatedOrderHandler = this.props.updatedOrderHandler.bind(this)
  }
  
  resetAlert() {
    setTimeout(() => this.setState({...this.state, alert: { type: '', message: ''}}), 2000)
  }

  handleApplyPromoCode(values) {
    this.setState({
      applying: true,
    })
    values.event_id = this.state.event.id
    values.order_id = this.state.order.id
    applyPromoCode(values)
      .then(json => {
        this.setState({
          order: json,
          applying: false,
          alert: {
            type: 'success',
            message: 'Your promo code has been applied!'
          }
        })
        this.resetAlert();
        this.updatedOrderHandler(json)
      })
      .catch(err => {
        this.setState({
          applying: false,
          alert: {
            type: 'danger',
            message: err.response.data
          }
        })
        this.resetAlert();
        console.log(err.response.data)
      })
  }

  render(props) {
    return (
      <Formik
        initialValues={{ promo_code: '' }}
        onSubmit={(values, actions) => {
          this.handleApplyPromoCode(values)
          actions.setSubmitting(false);
        }}
        render={(props) => (
          <Form className="promoCodeForm">
            { this.state.alert.message &&
              <Row>
                <Col>
                  <FlashAlert
                    color={this.state.alert.type}
                    text={this.state.alert.message}
                  />
                </Col>
              </Row>
            }
            <Row>
              <Col xs={9} className="pr-1">
                <Field
                  label="Promo Code"
                  type="text"
                  name="promo_code"
                  placeholder="Enter promo code..."
                  component={FormField}
                  required={false}
                />
              </Col>
              <Col xs={3} className="pl-1">
                <Button className="hvr-grow-shadow" color="primary" block type="submit">Apply</Button>
              </Col>
            </Row>
          </Form>
        )}
      />
    );
  }
}
