import { IAdminEventDetailsIn } from "../interfaces/events/adminEventDetails";

export function OrderDateProcessor(dateString: string): string {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  try {
    const date = new Date(dateString);
    const dayOfWeek = days[date.getDay()];
    const month = date.getMonth()+1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const min = date.getMinutes();
    return `${dayOfWeek}, ${month}/${day}/${year} @ ${hours}:${min}`;
  } catch (error) {
    throw error;
  }
}

export function getOrderNumberFromPurchaser(
  orders: any[],
  currentOrderId: number
): string[] {
  if (orders?.length === 0) {
    return ["No orders", ""];
  } else if (orders?.length === 1) {
    return ["First Order", "No Order Since"];
  } else {
    const index = orders?.findIndex((order: any) => {
      return order.id === currentOrderId;
    });
    if (index + 1 === orders?.length) {
      return [`${index}th order`, "No Order Since"];
    } else {
      return [`${index}th order`, `${orders?.length - index} Order Since`];
    }
  }
}

//this five functions should be in a context
export const DayliRevenueFetch = (
  event: IAdminEventDetailsIn,
  setDayliRevenue: React.Dispatch<any>
) => {
  var temp: any = [];
  const salesDaysRemaining = event.sales.sale_days_remaining;
  for (let index = 0; index < event.sales.by_inventory?.length; index++) {
    const element = event.sales.by_inventory[index];
    if (salesDaysRemaining < 0) {
      const UpperLimit = element.data.length + salesDaysRemaining+2;
      const elementsUntilsellDate: any[] = element.data.slice(0, UpperLimit);
      const temp2 = element.data.map((sellData: any) => {
        return {
          date: sellData.date_graph_label,
          revenue: sellData.revenue,
          type: element.label,
        };
      });
      temp = temp.concat(temp2);
    } else {
      const temp2 = element.data.map((sellData: any) => {
        return {
          date: sellData.date_graph_label,
          revenue: sellData.revenue,
          type: element.label,
        };
      });
      temp = temp.concat(temp2);
    }
  }
  setDayliRevenue(temp.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()));
};

export const DayliTicketsFetch = (
  event: IAdminEventDetailsIn,
  setDayliTickets: React.Dispatch<any>
) => {
  var temp: any = [];
  const salesDaysRemaining = event.sales.sale_days_remaining;
  for (let index = 0; index < event.sales.by_inventory?.length; index++) {
    const element = event.sales.by_inventory[index];
    if (salesDaysRemaining < 0) {
      //slice the graph only to the end sale date
      const UpperLimit = element.data.length + salesDaysRemaining+2;
      const elementsUntilsellDate: any[] = element.data.slice(0, UpperLimit);
      const temp2 = element.data.map((sellData: any) => {
        return {
          date: sellData.date_graph_label,
          quantity_sold: sellData.count,
          type: element.label,
        };
      });
      temp = temp.concat(temp2);
    } else {
      const temp2 = element.data.map((sellData: any) => {
        return {
          date: sellData.date_graph_label,
          quantity_sold: sellData.count,
          type: element.label,
        };
      });
      temp = temp.concat(temp2);
    }
  }
  setDayliTickets(temp.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()));
};

export const totalRevenuesFetch = (
  event: IAdminEventDetailsIn,
  setTotalRrevenue: React.Dispatch<any>
) => {

  // [{ Date: dategraphvalue, revenue: totalPerDay }]
  console.log(event);

  // A map of the total revenue per day
  const salesMap: Record<string, number> = {};

  if (event.sales.by_inventory?.length > 0) {
    for (let index = 0; index < event.sales.by_inventory?.length; index++) {
      const inventory = event.sales.by_inventory[index];
        for (let index = 0; index < inventory.data.length; index++) {
          const element = inventory.data[index];
          salesMap[element.date_graph_label] = salesMap[element.date_graph_label] ? salesMap[element.date_graph_label] + element.revenue : element.revenue;
        }
    }
  }

  const result = Object.keys(salesMap).map((key) => ({Date: key, revenue: salesMap[key]})).sort((a: any, b: any) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
  setTotalRrevenue(result);
};

export const totalTicketsFetch = (
  event: IAdminEventDetailsIn,
  setTotalTickets: React.Dispatch<any>
) => {


  // [{ Date: dategraphvalue, revenue: totalPerDay }]
  console.log(event);

  // A map of the total revenue per day
  const salesMap: Record<string, number> = {};

  if (event.sales.by_inventory?.length > 0) {
    for (let index = 0; index < event.sales.by_inventory?.length; index++) {
      const inventory = event.sales.by_inventory[index];
      for (let index = 0; index < inventory.data.length; index++) {
        const element = inventory.data[index];
        salesMap[element.date_graph_label] = salesMap[element.date_graph_label] ? salesMap[element.date_graph_label] + element.count : element.count;
      }
    }
  }

  const result = Object.keys(salesMap).map((key) => ({Date: key, tickets: salesMap[key]})).sort((a: any, b: any) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
  setTotalTickets(result);
};

export const getTotalRevenueByTypePIE = (
  salesByInventory: any[],
  colors: string[]
): any[] => {
  const grphData = salesByInventory?.map((sale: any, index: number) => {
    return { title: sale.label, value: sale.revenue, color: colors[index] };
  });
  return grphData;
};

export const getTotalTicketsByTypePIE = (
  salesByInventory: any[],
  colors: string[]
): any[] => {
  const grphData = salesByInventory?.map((sale: any, index: number) => {
    return {
      title: sale.label,
      value: sale.quantity_sold,
      color: colors[index],
    };
  });
  return grphData;
};
//this five functions above should be in a context ^
