function validateEmail(value:string) {
  let error;
  if (!value) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
}

function formatPhoneNumber(phoneNumberString:string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '+1')
    return [intlCode, match[2], match[3], match[4]].join('')
  }
  return null
}

function validateDateString(dateString:string) {
  let error
  let newDate = new Date(dateString);
  let lastYear = new Date();
  lastYear.setFullYear(lastYear.getFullYear()-1)
  var dateParts = dateString.split("-")
  if (!dateString) {
    error = 'Required'
  } else if (dateParts.length !== 3) {
    error = 'Invalid date format'
  } else if (newDate.getTime() > lastYear.getTime()) {
    error = 'Invalid birthdate'
  }
  return error
}

function formatDateString(dateString:string) {
  var x = new Date(dateString)
  var y = x.getFullYear().toString();
  var m = (x.getMonth() + 1).toString();
  var d = x.getDate().toString();
  (d.length === 1) && (d = '0' + d);
  (m.length === 1) && (m = '0' + m);
  return y + '-' + m + '-' + d;
}

export {
  validateEmail,
  formatPhoneNumber,
  validateDateString,
  formatDateString
};
