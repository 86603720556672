import {Helmet} from "react-helmet";
import React, { Component } from "react";
import { Row, Col, CardColumns } from "reactstrap";
import { getResidentArtistsForWhiteLabel } from '../Lib/RESTBlox'
import ArtistTile from '../Components/Artists/ArtistTile'
import ContentLayout from '../Layouts/ContentLayout'

type Props = {
  whiteLabel: any;
};

const ArtistList: React.FC<Props> = (props) => {

const [artists, setArtists] = React.useState<any>([]);
const [loading, setLoading] = React.useState<boolean>(true);


 const setStateFromNewData = (data:any) => {
   setArtists(artists);
   setLoading(false);
  }

  const loadData = () => {
    getResidentArtistsForWhiteLabel()
      .then(data => setStateFromNewData(data))
      .catch(err => console.error(err.toString()))
  }

  React.useEffect(() => {
    loadData()
  }, []);
  
    if (props.whiteLabel.id === 27) {
      var title = "Featured Artists"
    } else {
      title = "Resident Artists"
    }
    if (props.whiteLabel.id === 2) {
      return (
        <ContentLayout 
        title={title}
      >
        <Row className="ArtistList">
          <Helmet key={window.location.href}>
            <title>Primary — Residents</title>
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Primary — Residents" />
            <meta property="og:description" content={"Chicago's Home for Underground Dance Music"} />
            <meta property="og:image" content="https://cdn-images.threadless.com/threadless-media/artist_shops/shops/primarychi/products/1953075/original-1613329070-c616be45fb523d270fb50d838c9677a2.png?v=3&d=eyJvbmx5X21ldGEiOiBmYWxzZSwgImZvcmNlIjogZmFsc2UsICJvcHMiOiBbWyJ0cmltIiwgW2ZhbHNlLCBmYWxzZV0sIHt9XSwgWyJyZXNpemUiLCBbXSwgeyJ3aWR0aCI6IDk5Ni4wLCAiYWxsb3dfdXAiOiBmYWxzZSwgImhlaWdodCI6IDk5Ni4wfV0sIFsiY2FudmFzX2NlbnRlcmVkIiwgWzEyMDAsIDEyMDBdLCB7ImJhY2tncm91bmQiOiAiZmZmZmZmIn1dLCBbInJlc2l6ZSIsIFs4MDBdLCB7fV0sIFsiY2FudmFzX2NlbnRlcmVkIiwgWzgwMCwgODAwLCAiI2ZmZmZmZiJdLCB7fV0sIFsiZW5jb2RlIiwgWyJqcGciLCA4NV0sIHt9XV19" />
            <link rel="apple-touch-icon" sizes="180x180" href="/primary-apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/primary-favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/primary-favicon-16x16.png" />
            <link rel="manifest" href="/primary-site.webmanifest" />
            <link id="favicon" rel="icon" href="%PUBLIC_URL%/primary-favicon.ico" type="image/x-icon" />
          </Helmet>
          <Col>
            <CardColumns>
              {artists.map((artist:any, i:any) => {
                  return (
                    <ArtistTile key={i} artist={artist} />
                  )
                })
              }
            </CardColumns>
          </Col>
        </Row>
      </ContentLayout>
      )
    } else {
      return (
        <ContentLayout 
        title={title}
      >
        <Row className="ArtistList">
          <Helmet key={window.location.href}>
            <title>Residents</title>
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Residents" />
            <meta property="og:description" content={"Underground Dance Music"} />
            <meta property="og:image" content="https://cdn-images.threadless.com/threadless-media/artist_shops/shops/primarychi/products/1953075/original-1613329070-c616be45fb523d270fb50d838c9677a2.png?v=3&d=eyJvbmx5X21ldGEiOiBmYWxzZSwgImZvcmNlIjogZmFsc2UsICJvcHMiOiBbWyJ0cmltIiwgW2ZhbHNlLCBmYWxzZV0sIHt9XSwgWyJyZXNpemUiLCBbXSwgeyJ3aWR0aCI6IDk5Ni4wLCAiYWxsb3dfdXAiOiBmYWxzZSwgImhlaWdodCI6IDk5Ni4wfV0sIFsiY2FudmFzX2NlbnRlcmVkIiwgWzEyMDAsIDEyMDBdLCB7ImJhY2tncm91bmQiOiAiZmZmZmZmIn1dLCBbInJlc2l6ZSIsIFs4MDBdLCB7fV0sIFsiY2FudmFzX2NlbnRlcmVkIiwgWzgwMCwgODAwLCAiI2ZmZmZmZiJdLCB7fV0sIFsiZW5jb2RlIiwgWyJqcGciLCA4NV0sIHt9XV19" />
          </Helmet>
          <Col>
            <CardColumns>
              {artists.map((artist:any, i:any) => {
                  return (
                    <ArtistTile key={i} artist={artist} />
                  )
                })
              }
            </CardColumns>
          </Col>
        </Row>
      </ContentLayout>
      )
    }
}

export default ArtistList;
