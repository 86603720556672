import React from 'react'
import {Link} from 'react-router-dom'
import {
  Card,
  CardImgOverlay,
  CardFooter
} from 'reactstrap'
import { FaTicketAlt, FaLink, FaCopy } from 'react-icons/fa'
import { MdLock } from 'react-icons/md'
import { GoPencil } from 'react-icons/go'
import { AWSAlternative, AWSAlternativeOld, PublicImagePath } from '../../constants/constants'

type Props = {
  event: any;
  user: any;
};


const EventTile: React.FC<Props> = ({ event, user }) => {
  const cardLinkUrl = () => {
    if (user.profile.permissions_list.includes('view-event-overview') || user.profile.permissions_list.length == 0) {
      return "/admin/events/" + event?.id
    } else if (user.profile.permissions_list.includes('view-event-guestlist') || user.profile.permissions_list.length == 0) {
      return "/admin/events/guestlist/" + event?.id + "/v2/"
    } else {
      return ''
    }
  }

  return (
      <Card className="EventTile hvr-grow-shadow">
        <Link to={cardLinkUrl()}>
          <div className="cardImage" style={{
              // backgroundImage: 'url('+ PublicImagePath + event?.image + ') , url('+AWSAlternative+event?.image+'), url('+event?.image+')',
              backgroundImage: 'url('+event?.image+')',
            }}
          />
          <CardImgOverlay>
          </CardImgOverlay>
        </Link>
        <CardFooter>
          <Link to={cardLinkUrl()}>
            <span className="title">
              {event?.private &&
                <MdLock />
              }
              {event?.__str__}
            </span>
            <span className="date">{event?.date_display ?? ""}, {event?.time1_display ?? ""}</span>
            {!event?.venue_undisclosed && <span className="venue">{event?.venue?.name}, {event?.venue?.city}</span>}
            {event?.venue_undisclosed && <span className="venue">{"Secret Location"}</span>}
          </Link>
          <div className="cardIcons">
            {(user.profile.permissions_list.includes('edit-event') && event?.in_past || user.profile.permissions_list.length == 0) === false &&
              <Link
                to={"/admin/events/edit/" + event?.id}
                title="Edit event"
              >
                <GoPencil />
              </Link>
            }
            {(user.profile.permissions_list.includes('add-event')  && event?.in_past || user.profile.permissions_list.length == 0) === true &&
              <Link
                to={"/admin/events/duplicate/" + event?.id}
                title="Duplicate event"
              >
                <FaCopy />
              </Link>
            }
            {/* {(event?.on_sale && user.profile.permissions_list.includes("view-event-guestlist") || user.profile.permissions_list.length == 0) && */}
            {(user.profile.permissions_list.includes("view-event-guestlist") || user.profile.permissions_list.length == 0) &&
              <Link
                to={"/admin/events/guestlist/" + event?.id + "/v2/"}
                title="View guestlist"
              >
                <FaTicketAlt />
              </Link>
            }
            {!event?.draft &&
              <Link
                to={"/events/" + event?.id}
                title="View event on public site"
              >
                <FaLink />
              </Link>
            }
          </div>
        </CardFooter>
      </Card>
  );
};

export default EventTile;
