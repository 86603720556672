import React from "react";
import GoogleMapComponentWithMarker from "../../../../Components/Maps/Map";
import { Row, Col } from "reactstrap";
type Props = {
  event: any;
};
const EventMap: React.FC<Props> = ({ event }) => {
  return (
    <Row noGutters className="googleMapContainer">
      <Col>
        <GoogleMapComponentWithMarker
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAE2bGtUX6D7BvusIGxqpWSCLANsBpYEVE"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `155px`, marginBottom: `0` }} />}
          venue={event.event.venue}
        />
      </Col>
    </Row>
  );
};
export default EventMap;
