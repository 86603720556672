import React, { Component } from "react";
import PromoCodeForm from "../Forms/PromoCodeForm/PromoCodeForm";
import AdminLayout from "../../Layouts/AdminLayout";

type Props = {
  user: any;
};

const PromoCodeAdd: React.FC<Props> = ({ user }) => {
  return (
    <AdminLayout title="Add promo code">
      <PromoCodeForm user={user} />
    </AdminLayout>
  );
};

export default PromoCodeAdd;
