import React from "react";

type Props = {
  order: any;
};
const PriceTable: React.FC<Props> = ({ order }) => {
  return (
    <>
      <table className="tickets-table">
        <thead>
          <tr>
            <th className="tickets-name">Ticket Type</th>
            <th className="qty-name">Quantity</th>
            <th className="price-name">Price</th>
          </tr>
        </thead>
        <tbody>
          {order &&
            order.tickets_requested &&
            order.tickets_requested.map((ticket: any) => (
              <tr>
                <td className="tickets-type">{ticket.inventory?.type.name}</td>
                <td>{ticket.req_quantity}</td>
                <td>{ticket.req_price_str}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <table>
        <tbody>
          <tr className="total-table-subtotal-row">
            <th className="row-name">Subtotal</th>
            <td>{order ? order?.subtotal_str : ""}</td>
          </tr>
          <tr className="total-table-fee-row">
            <th className="row-name">Service Fee</th>
            <td>{order ? order?.fee_str : ""}</td>
          </tr>
          <tr className="total-table-total-row">
            <th className="row-name">Total</th>
            <td>{order ? order?.total_str : ""}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default PriceTable;
