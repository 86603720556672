import React from "react";
import { Col } from "reactstrap";
import {
  AWSAlternative,
  PublicImagePath,
} from "../../../../constants/constants";

type Props = {
  event: any;
};
const EventPicAndDetail: React.FC<Props> = ({ event }) => {
  return (
    <Col xs={12} md={4} className="leftColumn" style={{ alignSelf: "center" }}>
      <div
        className="img"
        style={{
          backgroundImage: "url(" + event.event?.image + ")",
        }}
      />
      <h4>
        <span className="date">
          {event.event?.date_display},{event.event?.time1_display}
        </span>
        {event.__str__}
        <span className="venue">
          {!event?.event?.venue_undisclosed ? (
            <strong>{event?.event?.venue?.name}</strong>
          ) : (
            <strong>No Venue Provided</strong>
          )}
        </span>
        {!event?.event?.venue_undisclosed ? (
          <span>{event.event?.venue?.address}</span>
        ) : (
          <span>Unknown Location</span>
        )}
      </h4>
    </Col>
  );
};
export default EventPicAndDetail;
