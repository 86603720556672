import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import ButtonLink from '../Components/ButtonLink';
import {Helmet} from "react-helmet";

type Props = {
  whiteLabel: any;
};

declare global {
  interface Window {
    FSS: any;
    $: any;
    anime: any;
  }
}

const Home: React.FC<Props> = (props) => {

  const [hasBackgroundVideo, setHasBackgroundVideo] = React.useState<any>(false);

  const textAnimate = () => {

    window.$('.ml12').each(function(){
      window.$().html(window.$().text().replace(/([^x00-x80]|\w)/g, "<span class='letter'>$&</span>"));
    });

    window.$('.ml12').css('color', '#99abaa')

    window.anime.timeline({loop: false})
      .add({
        targets: '.ml12 .letter',
        translateX: [40,0],
        translateZ: 0,
        opacity: [0,1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: function(el:any, i:any) {
          return 500 + 30 * i;
        }
      })
  }

  const FSSAnimation = () => {
    var container = document.getElementById('fss');
    var renderer = new window.FSS.WebGLRenderer();
    var scene = new window.FSS.Scene();
    var light = new window.FSS.Light('#000000', '#CC081B');
    var geometry = new window.FSS.Plane(2000, 2000, 45, 45);
    var material = new window.FSS.Material('#000000', '#CC081B');
    var mesh = new window.FSS.Mesh(geometry, material);
    var now, start = Date.now();
    function initialise() {
      scene.add(mesh);
      scene.add(light);
      container?.appendChild(renderer.element);
      window.addEventListener('resize', resize);
    }
    function resize() {
      renderer.setSize(container?.offsetWidth, container?.offsetHeight);
    }
    function animate() {
      now = Date.now() - start;
      light.setPosition(300*Math.sin(now*0.001), 200*Math.cos(now*0.0005), 60);
      renderer.render(scene);
      requestAnimationFrame(animate);
    }
    initialise();
    resize();
    animate();
  }

  const showBackground = () => {
    if (props.whiteLabel) {
      var mq = window.matchMedia("(min-width: 768px)")
      if (props.whiteLabel.homepage_background_youtube_video && mq.matches) {
        setHasBackgroundVideo(true);
      } else {
        if (!hasBackgroundVideo) {
          setTimeout(function(){
            textAnimate()
            FSSAnimation()
          }, 50)
        }
      }
    }
  }


  React.useEffect(() => {
    showBackground()
  }, []);

    var buttonText = "Browse Events"
    var buttonUrl = "/events"

    if (props.whiteLabel.display_about_page && props.whiteLabel.id !== 2) {
      buttonText = "Enter"
      buttonUrl = "/about"
    }
    
    if (props.whiteLabel.display_about_page && props.whiteLabel.id === 2) {
      buttonText = "Enter"
      buttonUrl = "/homepage"
    }
    
    if (props.whiteLabel.id !== 2) {
      return (
        <Row noGutters className="BloxHomeRow" id="fss">
        
          <Helmet key={window.location.href}>
            <title>The Blox Office</title>
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content="The Blox Office" />
            <meta property="og:description" content="Your Home for Underground Dance Music" />
            <meta name="facebook-domain-verification" content="exka8nix6e1dzjftgacmgds45gu5dw" />
          </Helmet>
    
          <Col>
            {hasBackgroundVideo &&
              <div className="video-background">
                <div className="video-foreground">
                  <iframe
                    title="Background Video"
                    src={"https://www.youtube.com/embed/" + props.whiteLabel.homepage_background_youtube_video + "?autoplay=1&color=white&controls=1&showinfo=0&rel=0&loop=1&mute=1&playsinline=1&playlist=" + props.whiteLabel.homepage_background_youtube_video}
                    frameBorder="0"
                    allow="autoplay; encrypted-media;"
                  >
                  </iframe>
                </div>
              </div>}
            <div className="HomeContent">
              <h1 className="ml12">{props.whiteLabel.slogan}</h1>
              <ButtonLink
                className="hvr-grow-shadow"
                size="lg"
                color="primary"
                to={buttonUrl}
                text={buttonText}
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (props.whiteLabel.id === 2) {
      return (
        <Row noGutters className="BloxHomeRow" id="fss">
        
          <Helmet key={window.location.href}>
            <title>Primary Nightclub</title>
            <meta name="facebook-domain-verification" content="age2wwdjlhke83figjl8wzn9l5tcs7" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Primary — Chicago" />
            <meta property="og:description" content="Your Home for Underground Dance Music" />
            <meta property="og:image" content="https://cdn-images.threadless.com/threadless-media/artist_shops/shops/primarychi/products/1953075/original-1613329070-c616be45fb523d270fb50d838c9677a2.png?v=3&d=eyJvbmx5X21ldGEiOiBmYWxzZSwgImZvcmNlIjogZmFsc2UsICJvcHMiOiBbWyJ0cmltIiwgW2ZhbHNlLCBmYWxzZV0sIHt9XSwgWyJyZXNpemUiLCBbXSwgeyJ3aWR0aCI6IDk5Ni4wLCAiYWxsb3dfdXAiOiBmYWxzZSwgImhlaWdodCI6IDk5Ni4wfV0sIFsiY2FudmFzX2NlbnRlcmVkIiwgWzEyMDAsIDEyMDBdLCB7ImJhY2tncm91bmQiOiAiZmZmZmZmIn1dLCBbInJlc2l6ZSIsIFs4MDBdLCB7fV0sIFsiY2FudmFzX2NlbnRlcmVkIiwgWzgwMCwgODAwLCAiI2ZmZmZmZiJdLCB7fV0sIFsiZW5jb2RlIiwgWyJqcGciLCA4NV0sIHt9XV19" />
          </Helmet>
        
          <Col>
            {hasBackgroundVideo &&
              <div className="video-background">
                <div className="video-foreground">
                  <iframe
                    title="Background Video"
                    src={"https://www.youtube.com/embed/" + props.whiteLabel.homepage_background_youtube_video + "?autoplay=1&color=white&controls=1&showinfo=0&rel=0&loop=1&mute=1&playsinline=1&playlist=" + props.whiteLabel.homepage_background_youtube_video}
                    frameBorder="0"
                    allow="autoplay; encrypted-media;"
                  >
                  </iframe>
                </div>
              </div>}
            <div className="HomeContent">
              <ButtonLink
                className="hvr-grow-shadow"
                size="lg"
                color="primary"
                to={buttonUrl}
                text={buttonText}
              />
            </div>
          </Col>
        </Row>
      );
    }

    return (<></>);
}

export default Home;
