import { Box, Button, Grid, Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GoogleMapComponentWithMarker from "../../Components/Maps/Map";
import { getAdminUserDetails } from "../../Lib/RESTBlox";
import BodyBox from "./common/BodyBox";
import HeaderBox from "./common/HeaderBox";
import UserOrderList from "./common/UserOrderList";
import Loader from "../../Components/Loader";
import { PublicImagePath } from '../../constants/constants'
import UserOrderListDetail from "./UserOrderListDetail";

var platform = require('platform');

const UserDetails: React.FC<any> = (props) => {
  const [user, setUser] = useState<any>();
  const [ip, setIP] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showUserOrders, setShowOrders] = useState(false);
  const [orders, setOrders] = useState<any>();

  const getIPData = async () => {
    fetch('https://api.ipify.org?format=json').then(response => {
      return response.json();
    }).then((res: any) => {
      setIP(res.ip);
    }).catch((err: any) => console.error('Problem fetching my IP', err))
  }

  useEffect(() => {
    setIsLoading(true);
    getIPData();
    getAdminUserDetails(+(window.location.pathname?.split("/")?.pop() as string))
      .then((data) => {
        setUser(data.results[0]);
        setOrders(data.results[0].orders);
        setLatitude(data.results[0].latitude ?? "34.0551664");
        setLongitude(data.results[0].longitude ?? "-118.2401387");
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);
  return (
    !showUserOrders ?
      isLoading ?
        <Loader
          loading={true}
          color='#000'
        />
        :
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          fontFamily="Titillium Web"
          style={{
            height: "100%",
            maxHeight: '100%',
            display: 'flex',
            position: 'absolute',
            left: 0,
            right: 0
          }}
        >
          <Grid item xs={12} lg={6} xl={6} md={6}
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            style={{ display: 'flex', height: "100%", padding: 0, margin: 0, backgroundColor: '#000' }}
          >
            <Box sx={{
              width: '75%',
              height: '85%'
            }}>
              {
                user?.orders && user?.orders[0]?.event &&
                user?.orders[0]?.event?.image &&
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 0
                  }}
                  src={user?.orders[0]?.event?.image}
                />
              }
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} xl={6} md={6}
            container
            style={{
              display: 'flex',
              height: "100%",
              width: '100%',
              paddingLeft: 15,
              paddingRight: 15,
              backgroundColor: '#FFF',
              maxHeight: '100vh',
              overflow: 'auto'
            }}
          >
            <Stack spacing={2} sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              paddingLeft: '1.rem',
              paddingRight: '1.rem',
              marginTop: '6.75rem',
              marginBottom: '1.25rem',
            }}>
              <HeaderBox leftText="USER" rightText={ip} />
              <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '100%',
                alignContent: 'center',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}>
                <BodyBox texts={[user?.first_name ?? "" + ' ' + user?.last_name ?? "", user?.email ?? ""]}
                  styles={[
                    { fontWeight: 600, textTransform: 'uppercase' },
                    { fontSize: '.8rem', color: '#99abaa' }
                  ]} />
                <BodyBox texts={['LAST ACTIVE', moment(user?.last_login ?? Date.now()).fromNow()]}
                  styles={[
                    { fontWeight: 400 },
                    { fontSize: '.8rem', color: '#99abaa' }
                  ]} />
              </Stack>
              <Box sx={{ paddingRight: 7, paddingLeft: 7 }}>
                <GoogleMapComponentWithMarker
                  googleMapURL={
                    "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAE2bGtUX6D7BvusIGxqpWSCLANsBpYEVE"
                  }
                  loadingElement={(<div style={{ height: `100%` }} />) as any}
                  containerElement={(<div style={{ height: `100%` }} />) as any}
                  mapElement={
                    (<div style={{ height: `120px`, marginBottom: `1rem` }} />) as any
                  }
                  venue={
                    { lat: latitude ?? "0", lng: longitude ?? "0" } as any
                  }
                />
              </Box>

              <HeaderBox leftText="HARDWARE" rightText="" />
              <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '100%',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <BodyBox texts={['OS: ' + platform.os, 'Device: ' + (platform.product ?? 'Desktop'), 'Browser: ' + platform.name]}
                  styles={[
                    { fontWeight: 400, color: '#bac9c8', margin: 1 },
                    { fontWeight: 400, color: '#bac9c8', margin: 1 },
                    { fontWeight: 400, color: '#bac9c8', margin: 1 }
                  ]} />
              </Stack>

              <HeaderBox leftText="ORDERS" rightText={user?.orders.length ?? 0} />
              {
                user?.orders?.length > 0 && user?.orders[0]?.event ?
                <>
                  <UserOrderList
                    name={user?.orders[0]?.event?.title ?? ""}
                    status={user?.orders[0]?.status ?? ""}
                    date={user?.orders[0]?.date_created_display?.trim().split(',')[0] ?? ""}
                    time={user?.orders[0]?.date_created_display?.trim().split(',')[1] ?? ""}
                    id={user?.orders[0]?.id ?? ""}
                    total={user?.orders[0]?.total_str ?? ""} />
                  <Button sx={{
                    width: '30%',
                    alignSelf: 'center',
                    backgroundColor: '#17a2b8',
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    borderRadius: '.25rem',
                    padding: 1.25,
                    fontWeight: 400,
                    textTransform: 'none',
                  }}
                    onClick={() => { setShowOrders(true) }}
                    variant="contained"
                    disableElevation >View All</Button>
                </>
                :
                <p className="table-header" style={{textAlign:'center', flex:1, marginTop:'2%'}}>User doesn't have any order</p>
              }
            </Stack>
          </Grid>
        </Grid>
      :
      <UserOrderListDetail orders={orders} setCloseShowOrders={()=>{setShowOrders(false)}} />
  );
};

export default UserDetails;
