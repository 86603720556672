import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

function ButtonLink(props: any) {
  return (
    <Button
      className="hvr-grow-shadow"
      color="primary"
      size={props.size}
      tag={Link}
      to={props.to}
      {...props}
    >
      {props.icon}
      {props.text}
    </Button>
  );
}

export default ButtonLink;
