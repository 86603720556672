import React from 'react';

export default class EventDetailsPurchaseTableDetailsRow extends React.Component {
  render() {
    return (
      <tr>
        <td colSpan="3" className="inventoryDetails">
          <span dangerouslySetInnerHTML={{__html: this.props.inventory.description_html}} />
        </td>
      </tr>
    );
  }
}
