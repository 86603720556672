import React, { Component, useEffect } from "react";
import { Row, Col } from "reactstrap";
import ButtonLink from "../../../../Components/ButtonLink";
import { GoPencil } from "react-icons/go";
import { FaLink, FaTicketAlt, FaMoneyBill } from "react-icons/fa";
type Props = {
  user: any;
  event: any;
};
const MetricsOptions: React.FC<Props> = ({ user, event }) => {
  return (
    <Row className="metrics">
      {event?.event?.in_past ? (
        <Col xs={12} md={4} className="buttonLink">
          {/* {user.profile.permissions_list.includes("edit-event") || user?.profile?.permissions_list?.length === 0 && ( */}
          <ButtonLink
            to={"/admin/orders/events/" + event?.event?.id}
            text="Orders"
            icon={<FaMoneyBill />}
            block
          />
          {/* )} */}
        </Col>
      ) : (
        <Col xs={12} md={4} className="buttonLink">
          {/* {user.profile.permissions_list.includes("edit-event") || user?.profile?.permissions_list?.length === 0 && ( */}
          <ButtonLink
            to={"/admin/events/edit/" + event.event.id}
            text="Edit"
            icon={<GoPencil />}
            block
          />
          {/* )} */}
        </Col>
      )}
      <Col xs={6} md={4} className="buttonLink">
        <ButtonLink
          to={"/events/" + event.event.id}
          // to={"/events"}
          text="View"
          icon={<FaLink />}
          block
        />
      </Col>
      <Col xs={6} md={4} className="buttonLink">
        {/* {user.profile.permissions_list.includes("view-event-guestlist") || user?.profile?.permissions_list?.length === 0 && ( */}
        <ButtonLink
          to={"/admin/events/guestlist/" + event.event.id + "/v2/"}
          text="Guests"
          icon={<FaTicketAlt />}
          block
        />
        {/* )} */}
      </Col>
    </Row>
  );
};
export default MetricsOptions;
