import axios from 'axios';
import {getBloxAPIUrl} from '../App/Configuration'
import {formatPhoneNumber } from './FormValidation'

// import {bloxTrack} from './RESTTrack'


var APIBaseURL = getBloxAPIUrl()

// INTERNAL METHODS
function fetchEndpoint(endpoint, method, data=null, useToken=true) {
  return new Promise(function(resolve, reject) {
    var dataURL = APIBaseURL+ '/'  + endpoint

    // If we have a token and we've been asked to use it, use it.
    if (localStorage.getItem('token') && useToken === true) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }

    // Handle the request
    axios({
      url: dataURL,
      method: method,
      data: data,
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.error("API Error (" + dataURL + "):\n", err.response)
        if(!err.response){
          err.response = {errorMessage: "The API server did not respond."}
        }
        if (err.response.status === 401) {
          console.error("Visitor's session has expired. Refreshing token.")
          refreshToken()
        }
        reject(err)
      })

    // Track the request
    // bloxTrack('API Request', endpoint, method, data)
  });
}

// EXTERNAL METHODS
function signUp(email, password, firstName, lastName, phone, gender, birthdate) {
  var endpoint = "auth/registration/"
  var data = {
    'email': email,
    'password1': password,
    'password2': password,
    'first_name': firstName,
    'last_name': lastName,
    'phone': phone,
    'gender': gender,
    'birthdate': birthdate
  }
  console.log(data)
  return new Promise(function(resolve, reject) {
    fetchEndpoint(endpoint, 'post', data, false)
      .then(data => {
        localStorage.setItem('token', data.token)
        resolve(data)
      })
      .catch(err => {
        if (err.response.status === 400 && 'email' in err.response.data) {
          err.response.errorMessage = "A user with that email address already exists."
        }
        if (err.response.status === 400 && 'password1' in err.response.data) {
          err.response.errorMessage = "The password you selected is not secure enough."
        }
        reject(err)
      })
  });
}


function signIn(email, password) {
  var endpoint = "auth/login/"
  var data = {
    'email': email,
    'password': password
  }
  return new Promise(function(resolve, reject) {
    localStorage.removeItem('token')
    fetchEndpoint(endpoint, 'post', data, false)
      .then(data => {
        localStorage.setItem('token', data.token)
        console.info("User sucessfully signed in as " + data.user.username + ". Token set.")
        resolve(data)
      })
      .catch(err => {
        if (err.response.status === 400 && err.response.data.non_field_errors[0] === "Unable to log in with provided credentials.") {
          err.response.errorMessage = "Your email address or password was incorrect."
        }
        reject(err)
      })
  });
}

function refreshToken() {
  var endpoint = "auth/token-refresh/"
  var data = {
    'token': localStorage.getItem('token'),
  }
  return new Promise(function(resolve, reject) {
    fetchEndpoint(endpoint, 'post', data)
      .then(data => {
        localStorage.setItem('token', data.token)
        resolve(data)
      })
      .catch(err => {
        if (err.response.status === 400 && err.response.data.non_field_errors[0] === "Signature has expired.") {
          err.response.errorMessage = "Your session has expired. Please sign in again."
        }
        reject(err)
      })
  });
}

function getCurrentUser() {
  var endpoint = "auth/user/"
  return new Promise(function(resolve, reject) {
    fetchEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        if (err.response.status === 401 && err.response.data.detail === "Signature has expired.") {
          err.response.errorMessage = "Your session has expired. Please sign in again."
        }
        console.log(err.response.errorMessage)
        reject(err)
      })
  });
}


function signOut() {
  var endpoint = "auth/logout/"
  return new Promise(function(resolve, reject) {
    fetchEndpoint(endpoint, 'post', null, false)
      .then(data => {
        localStorage.removeItem('token')
        console.info("User sucessfully signed out. Token removed.")
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
    resolve()
  });
}

function changePassword(oldPassword, newPassword) {
  var endpoint = "auth/password/change/"
  var data = {
    'old_password': oldPassword,
    'new_password1': newPassword,
    'new_password2': newPassword,
  }
  return new Promise(function(resolve, reject) {
    fetchEndpoint(endpoint, 'post', data, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function forgotPassword(email) {
  var endpoint = "auth/password/reset/"
  var data = {
    'email': email
  }
  return new Promise(function(resolve, reject) {
    fetchEndpoint(endpoint, 'post', data, false)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function resetPassword(values) {
  var endpoint = "auth/password/reset/confirm/"
  return new Promise(function(resolve, reject) {
    fetchEndpoint(endpoint, 'post', values, false)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        if (err.response.status === 400) {
          err.response.errorMessage = "This password reset has already been used or has expired."
        }
        reject(err)
      })
  });
}

function changeProfile(values) {
  var endpoint = "current-user/update/"

  // Django expects a username, but we just user email address.
  values.username = values.email

  var formattedPhone = formatPhoneNumber(values.phone)
  values.phone = formattedPhone
  return new Promise(function(resolve, reject) {
    fetchEndpoint(endpoint, 'put', values, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

export {
  signUp,
  signIn,
  signOut,
  refreshToken,
  getCurrentUser,
  changePassword,
  forgotPassword,
  resetPassword,
  changeProfile,
};
