import React from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import { FormField } from '../Forms/Fields'
import FlashAlert from '../FlashAlert'
import { validateEmail } from '../../Lib/FormValidation'
import { forgotPassword } from '../../Lib/RESTAuth'


class PasswordForgotModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      alert: {
        type: '',
        message: ''
      },
      submiting:false
    };
    this.toggle = this.toggle.bind(this)
    this.toggleParent = this.props.toggleParent.bind(this)
    this.setpreventLogin = this.props.setpreventLogin.bind(this)
    this.postPasswordForgot = this.props.postPasswordForgot.bind(this)
  }

  _isMounted = false

  toggle() {
    if (this._isMounted) {
      this.setpreventLogin(!this.state.modal)
      this.setState({
        modal: !this.state.modal
      });
    }
  }

  handlePasswordForgot(email) {
    this.setState({submiting:true})
    forgotPassword(email)
      .then(json => {
        this.setState({submiting:false})
        this.postPasswordForgot(json)
        this.toggle()
        this.toggleParent()
      })
      .catch(err => {
        this.setState({submiting:false})
        console.log(err)
        this.setState({
          alert: {
            type: 'danger',
            message: 'There was an error submitting your password reset request.'
          }
        })
      })
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <div>
        <Button
          className="hvr-grow-shadow"
          block
          color="secondary"
          onClick={this.toggle}
        >
          Forgot password
        </Button>
        <Formik
          initialValues={{ email: '', }}
          onSubmit={(values, actions) => {
            this.handlePasswordForgot(values.email)
            actions.setSubmitting(false);
          }}
          render={(props ) => (

            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
              <ModalHeader toggle={this.toggle}>Forgot password</ModalHeader>
              <ModalBody>
                <p>Enter your email address, and we'll send you a link to reset your password. Note that
                  these emails may end up in your Spam/Junk folder, and also that if you find them there, the link
                  may be disabled. Mark the message as "Not spam" or move it to your inbox.
                </p>
                <Form>
                  { this.state.alert.message &&
                    <Row>
                      <Col>
                        <FlashAlert color={this.state.alert.type} text={this.state.alert.message} />
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col>
                      <Field
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Your email address..."
                        component={FormField}
                        required={true}
                        validate={validateEmail}
                      />
                    </Col>
                  </Row>
                  <Row class="buttonRow">
                    <Col>
                      <Button className="hvr-grow-shadow" block color="primary" disabled={this.state.submiting} type="submit">Reset password</Button>{' '}
                    </Col>
                  </Row>
                </Form>
            </ModalBody>
          </Modal>
          )}
        />
      </div>
    );
  }
}

export default PasswordForgotModal;
