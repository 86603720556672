import axios, {AxiosResponse} from 'axios';
import Moment from 'moment'
import {getBloxAPIUrl} from '../App/Configuration'
import { IAdminEventDetailsIn } from '../interfaces/events/adminEventDetails';
import { IPaginatedEventIn } from '../interfaces/events/events';
import { IPaginatedOrderIn } from '../interfaces/orders/orders';
import { IPaginatedUsersIn } from '../interfaces/user/user';
import { IPaginatedVenueIn, IVenueIn } from '../interfaces/club/venue'
import { IPaginatedPromocodeIn } from '../interfaces/promoCodes/promoCodes';
import { IPaginatedTicketTypeIn } from '../interfaces/tickets/ticketType';
import { v4 as uuidv4 } from 'uuid';
import {IDoorOrder, ITerminal} from "../Components/Modals/DoorOrderProcessor";
import {ITicketV3} from "../RoutesAdmin/EventGuestListV2/EventGuestListV2";

// import {bloxTrack} from './RESTTrack'

var APIBaseURL = getBloxAPIUrl()

// INTERNAL METHODS
function fetchBloxEndpoint(endpoint:string, method:string, data={}, useToken=true) {
  return new Promise(function(resolve, reject) {
    var dataURL = APIBaseURL +'/' + endpoint

    // If we have a token and we've been asked to use it, use it.
    if (localStorage.getItem('token') && useToken === true) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }

    // Handle the request
    axios({
      method: method,
      url: dataURL,
      data: data,
    })
      .then(response => {
        // console.info("      Sucessful API request (" + method + ") to " + endpoint)
        resolve(response.data)
      })
      .catch(err => {
        // console.error("BloxOffice API Error (" + endpoint + "):\n", err.response)
        reject(err)
      })

    // Track the request
    // bloxTrack('API Request', endpoint, method, data)
  });
}

function getCurrentWhiteLabel() {
  var endpoint = "current-white-label/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getFeedForWhiteLabel(whiteLabelId:number, pageNum=1, tense:"past"|"upcoming"="past") {
  var endpoint = `v1/white-label-feed/${tense}/` + whiteLabelId + `/?page=${pageNum}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getFeedForWhiteLabelWithTypes(whiteLabelId:number, types:any, date:string) {
  var typesUrlStr = types.join(',')
  var endpoint = `v1/white-label-feed/${whiteLabelId}/${typesUrlStr}/?date=${date}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getMobileFeedForWhiteLabelWithTypes(whiteLabelId:number, types:any) {
  var typesUrlStr = types.join(',')
  var endpoint = `v1/white-label-feed/${whiteLabelId}/${typesUrlStr}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function changeWhiteLabel(whiteLabelId:number, values:any) {
  var endpoint = "admin/white-label-update/" + whiteLabelId + '/'

  if (values.logo.name) {
    console.log('we have a new logo')
    values.logo_filename = values.logo.name
  }

  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'put', values, true)
      .then(data => {
        if (values.logo_filename) {
          console.log('uploading')
          uploadFile(values.logo)
        }
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function getOrgSendyURL() {
  var endpoint = "admin/org-sendy-url/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

const getCurrentUserOrders = (page = 1) => {
  var endpoint = `v1/current-user/order-history/?page=${page}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getCurrentUserTickets(page = 1) {
  var endpoint = `v1/current-user/tickets/?page=${page}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getEvents() {
  var endpoint = "events/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getEvent(eventId:string) {
  var endpoint = "events/" + eventId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getOnSaleEvents() {
  var endpoint = "on-sale-events/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getOnSaleEvent(eventId:string) {
  var endpoint = "on-sale-events/" + eventId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getEventGuestList(eventId:string,pageNum=1, ordering?:string, search?:string) {
  // var endpoint = `v1/admin/event-guestlist/${eventId}/alltypes/?ordering=${ordering}&q=${search}&page=${pageNum}`
  var endpoint = `v1/admin/event-guestlist/${eventId}/guests/?ordering=${ordering}&q=${search}&page=${pageNum}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getEventCompList(eventId:string,pageNum=1, ordering:string, search:string) {
  var endpoint = `v1/admin/event-guestlist/${eventId}/comps/?ordering=${ordering}&q=${search}&page=${pageNum}`;
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getEventTypes(eventId:string) {
  var endpoint = "v1/admin/event-guestlist/" + eventId + '/types';
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function addEvent(values:any) {
  var endpoint = "admin/event-create/"

  var payload = JSON.parse(JSON.stringify(values));
  payload.date = Moment(payload.date).format()
  payload.release_date = Moment(payload.release_date).format()
  payload.time1 = Moment(payload.time1).format()
  if (payload.time2) {
    payload.time2 = Moment(payload.time2).format()
  }
  const fileExt = values.image.name.split('.')?.pop()
  const uniqueImageName = uuidv4()+'.'+fileExt
  // payload.image_filename = values.image.name
  payload.image_filename = uniqueImageName

  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', payload, true)
      .then(data => {
        uploadFile(values.image,uniqueImageName).then(()=>{
          resolve(data)
        })
      })
      .catch(err => {
        reject(err)
      })
  });
}

function changeEvent(eventId:string, values:any) {
  var endpoint = "admin/event-update/" + eventId + '/'

  values.date = Moment(values.date).format()
  values.time1 = Moment(values.time1).format()
  if (values.time2) {
    values.time2 = Moment(values.time2).format()
  }
  if (values.image?.name) {
    const fileExt = values.image.name.split('.')?.pop()
    const uniqueImageName = uuidv4()+'.'+fileExt
    values.image_filename = uniqueImageName
  }

  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'put', values, true)
      .then(data => {
        if (values.image_filename) {
          uploadFile(values.image,values.image_filename).then(()=>{
            resolve(data)
          })
        }else{
          resolve(data)
        }
      })
      .catch(err => {
        reject(err)
      })
  });
}

function deleteEvent(eventId:string) {
  var endpoint = "admin/event-delete/" + eventId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'delete', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getTicketInventory(ticketInventoryId:string) {
  var endpoint = "admin/ticket-inventories/" + ticketInventoryId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function addTicketInventory(values:any) {
  var endpoint = "admin/ticket-inventory-create/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', values, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function changeTicketInventory(ticketInventoryId:string, values:any) {
  var endpoint = "admin/ticket-inventory-update/" + ticketInventoryId + '/'
  console.log(values)
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'put', values, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function deleteTicketInventory(ticketInventoryId:string) {
  var endpoint = "admin/ticket-inventory-delete/" + ticketInventoryId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'delete', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getOrder(orderId:string) {
  var endpoint = "orders/" + orderId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getTicket(ticketId:string) {
  var endpoint = "tickets/" + ticketId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function transferTicket(ticketId:string, email:string) {
  var endpoint = "tickets/transfer/"
  var data = {
    ticket_id: ticketId,
    to_user_email: email
  }
  console.log(data)
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', data, true)
      .then(data => resolve(data))
      .catch(err => {
        if (err.response.status === 401) {
          err.response.errorMessage = "You are not authorized to transfer this ticket. It is held by someone else."
        }
        reject(err)
      })
  });
}

function getPage(pageSlug:any) {
  var endpoint = "pages/" + pageSlug + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getPressRelease(pressReleaseId:string) {
  var endpoint = "press-releases/" + pressReleaseId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function createOrder(ticketsRequested:any) {
  var endpoint = "order-create/"
  var data = {tickets: []}as any
  const spc = localStorage.getItem('spc');
  if (spc) {
    try {
      const spcData = JSON.parse(spc);
      const spcCode = spcData.code;
      const spcDate = new Date(spcData.created);
      if(spcDate.getTime() > (new Date().getTime() - (2 * 24 * 60 * 60 * 1000))) data.spc = spcCode;
    } catch (error) {
        console.log(error);
    }

  }

  // Input looks like this: {1: 2, 2: 1}
  // (Where the key is the TicketInventory ID and the value is the Quantity)
  // The backend wants it to look like this: [{'inventory_id': 1, 'quantity': 2}, {'inventory_id':2, 'quantity': 1}]
  for (var key in ticketsRequested) {
    data.tickets.push({inventory_id: key, quantity: ticketsRequested[key]})
  }

  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', data, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function completeOrder(values:any, stripeToken:string) {
  var endpoint = "order-complete/" + values.id
  var data = values
  if (stripeToken === '') {
    data.token = {
      id: '',
    }
  } else {
    data.token = stripeToken
  }
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'put', data, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        if (err.response.status === 400) {
          console.log(err.response.data.msg)
          err.response.errorMessage = err.response.data.msg
        }
        reject(err)
      })
  });
}

function getClubs(page=1,search='') {
  var endpoint = `clubs/?page=${page}&search=${search}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getTimeTypes() {
  var endpoint = "time-types/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getPromoters(page=1,search='') {
  var endpoint = `promoters/?page=${page}&search=${search}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getArtists(page=1,search='') {
  var endpoint = `artists/?page=${page}&search=${search}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getArtist(artistSlug:string) {
  var endpoint = "artists/" + artistSlug + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getTicketTypes(page=1,search=''):Promise<IPaginatedTicketTypeIn> {
  var endpoint = `ticket-types/?page=${page}&search=${search}`
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data as IPaginatedTicketTypeIn))
      .catch(err => {
        reject(err)
      })
  });
}

function getFanCampaign(campaignId:string) {
  var endpoint = "fan-campaigns/" + campaignId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getCurrentUserFanCampaignResponses() {
  var endpoint = "current-user/fan-campaign-responses/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}


/*
saveStreamCSV(filename, text) {
    this.setState({downloadingCSV: false})
    if(window.navigator.msSaveBlob) {
        // IE 10 and later, and Edge.
        var blobObject = new Blob([text], {type: 'text/csv'});
        window.navigator.msSaveBlob(blobObject, filename);
    } else {
        // Everthing else (except old IE).
        // Create a dummy anchor (with a download attribute) to click.
        var anchor = document.createElement('a');
        anchor.download = filename;
        if(window.URL.createObjectURL) {
            // Everything else new.
            var blobObject = new Blob([text], {type: 'text/csv'});
            anchor.href = window.URL.createObjectURL(blobObject);
        } else {
            // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
            // Load up the data into the URI for "download."
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text);
        }
        // Now, click it.
        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            anchor.dispatchEvent(event);
        }
        else {
            anchor.click();
        }
    }
}
handleDownloadClick(e) {
    this.setState({downloadingCSV: true})
    fetch(`/api/admin/studies/${this.props.study.id}/csv`
    ,   {
            headers: {
                "Authorization": "Bearer " + this.props.authAPI.getToken()
            ,   "Accept": "text/csv"
            }
        }
    )
    .then((response) => response.text())
    .then((responseText) => this.saveStreamCSV(`study${this.props.study.id}.csv`, responseText))
    .catch((error) => {
        this.setState({downloadingCSV: false})
        console.error("CSV handleDownloadClick:", error)
    })
}
 */

async function fetchGuestListCsv(eventId: number) {
  const endpoint = `v1/admin/event-guestlist/${eventId}/export/`
  const dataURL = APIBaseURL +'/' + endpoint

  // If we have a token and we've been asked to use it, use it.
  const headers: any = {}
  if (localStorage.getItem('token')) {
    headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  }

  console.log('headers', headers)

  const res =  await fetch(dataURL, {headers})
  return await res.text();
}

function getCurrentUserFanCampaignResponse(campaignId:string) {
  var endpoint = "current-user/fan-campaign-responses/" + campaignId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function createFanCampaignResponse(campaignId:string, data:any) {
  var endpoint = "current-user/fan-campaign-responses/" + campaignId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', data, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getAdminEvents(eventType?:string, page?:number):Promise<IPaginatedEventIn> {
  var endpoint = `admin/events/?page=${page||1}`
  if (eventType === 'Past') {
    endpoint = `admin/past-events/?page=${page||1}`
  }
  else if (eventType === 'Upcoming') {
    endpoint = `admin/upcoming-events/?page=${page||1}`
  }
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then((data) => resolve(data as IPaginatedEventIn))
      .catch(err => {
        reject(err)
      })
  });
}

function getAdminEvent(eventId:string, old:boolean):Promise<IAdminEventDetailsIn> {
  // var endpoint = "admin/events/" + eventId + '/';
  var url = "admin/events/" + eventId + '/'
  if (old === true) {
    url = "admin/events/" + eventId + '/'
  }
  if (old === false) {
    url = `v1/admin/event-guestlist/event/${eventId}/`
  }
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(
      url,
      'get',
      {},
      true)
      .then(data => resolve(data as IAdminEventDetailsIn))
      .catch(err => {
        reject(err)
      })
  });
}

function getAdminEventGuestEmailList(eventId:string):Promise<any> {
  var url = `v1/admin/event-guestemaillist/${eventId}/`
  
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(
      url,
      'get',
      {},
      true)
      .then(data => resolve(data as any))
      .catch(err => {
        reject(err)
      })
  });
}

function createAdminEventSubPromoterLinks(eventId:string,description:string, email:string):Promise<any> {
  var url = `admin/event-subpromoters-add/`
  
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(
      url,
      'post',
      {description:description, email:email, event:eventId},
      true)
      .then(data => resolve(data as any))
      .catch(err => {
        reject(err)
      })
  });
}

function createAdminEventSubPromoterLinkV2(eventId:string, title:string):Promise<any> {
  var url = `admin/events/${eventId}/sub-promoters`

  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(
      url,
      'post',
      {title},
      true)
      .then(data => resolve(data as any))
      .catch(err => {
        reject(err)
      })
  });
}

function updateAdminEventSubPromoterLinks(subpromoterId:string, order?:boolean, tickets?:number):Promise<any> {
  console.log(subpromoterId)
  var url = `admin/event-subpromoters-update-view/${subpromoterId}/`
  const requestBody = order===true?{order:order, tickets:tickets}:{}
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(
      url,
      'put',
      requestBody,
      true)
      .then(data => resolve(data as any))
      .catch(err => {
        reject(err)
      })
  });
}

function getSubpromoterEventData(subpromoterId:string):Promise<any> {
  var url = `admin/event-subpromoters-view/${subpromoterId}/`
  
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(
      url,
      'get',
      {},
      true)
      .then(data => resolve(data as any))
      .catch(err => {
        reject(err)
      })
  });
}

function uploadFile(file:any,uniqueImageName?:any) {
  var endpoint = "/file-upload/"

  // If we have a token and we've been asked to use it, use it.
  if (localStorage.getItem('token')) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  let formData = new FormData();
  formData.append('file', file);
  if(uniqueImageName) formData.append('name',uniqueImageName)

  return axios.put(APIBaseURL + endpoint, formData,
    {headers: {
      'Content-Type': 'multipart/form-data'
    }})
    .then(data => {
      // console.log(data)
    })
    .catch(err => {
      // console.log(err)
    })
}

function addCompTickets(values:any, eventId:string) {
  var endpoint = "admin/ticket-comp-add/" + eventId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', values, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function checkInTicket(ticketId:string) {
  var endpoint = "admin/ticket-check-in/" + ticketId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'put', {}, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function checkInCompTicket(ticketId:string) {
  var endpoint = "admin/ticket-comp-check-in/" + ticketId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'put', {}, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function applyPromoCode(values:any) {
  var endpoint = "order-apply-promo-code/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', values, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function createClientAccount(values:any) {
  var endpoint = "create-client-account/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', values, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function getStaff():Promise<any> {
  var endpoint = "admin/staff/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function getRoles():Promise<any> {
    var endpoint = "admin/staff/roles"
    return new Promise(function(resolve, reject) {
        fetchBloxEndpoint(endpoint, 'get', {}, true)
        .then(data => {
            resolve(data)
        })
        .catch(err => {
            reject(err)
        })
    });
}

function addStaffMember(staffMember: {email: string, role: string}):Promise<any> {
    var endpoint = "admin/staff/"
    return new Promise(function(resolve, reject) {
        fetchBloxEndpoint(endpoint, 'post', staffMember, true)
        .then(data => {
            resolve(data)
        })
        .catch(err => {
            reject(err)
        })
    });
}

function deleteStaffMember(staffMemberId: string):Promise<any> {
    var endpoint = "admin/staff/" + staffMemberId
    return new Promise(function(resolve, reject) {
        fetchBloxEndpoint(endpoint, 'delete', {}, true)
        .then(data => {
            resolve(data)
        })
        .catch(err => {
            reject(err)
        })
    });
}

function addVenue(values:any):Promise<IVenueIn> {
  var endpoint = "admin/club-create/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', values, true)
      .then(data => {
        resolve(data as IVenueIn)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function changeVenue(venueId:string, values:any):Promise<IVenueIn> {
  var endpoint = "admin/event-update/" + venueId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'put', values, true)
      .then(data => {
        resolve(data as IVenueIn)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function getAdminOrders(pageNum=1, search=null, sortField=null, sortOrder=null):Promise<IPaginatedOrderIn> {
  var endpoint = "admin/orders/"
  endpoint = addPageSearchSortParams(endpoint, pageNum, search, sortField, sortOrder)
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data as IPaginatedOrderIn))
      .catch(err => {
        reject(err)
      })
  });
}

function getAdminOrderDetails(orderId:number):Promise<IPaginatedOrderIn> {
  var endpoint = "admin/orders/"+orderId+'/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data as IPaginatedOrderIn))
      .catch(err => {
        reject(err)
      })
  });
}

function getAdminVenues(pageNum=null, search=null, sortField=null, sortOrder=null):Promise<IPaginatedVenueIn> {
  var endpoint = "admin/clubs/"
  endpoint = addPageSearchSortParams(endpoint, pageNum, search, sortField, sortOrder)
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data as IPaginatedVenueIn))
      .catch(err => {
        reject(err)
      })
  });
}

function getAdminPromoCodes(pageNum=null, search=null, sortField=null, sortOrder=null):Promise<IPaginatedPromocodeIn> {
  var endpoint = "admin/promo-codes/"
  endpoint = addPageSearchSortParams(endpoint, pageNum, search, sortField, sortOrder)
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data as IPaginatedPromocodeIn))
      .catch(err => {
        reject(err)
      })
  });
}

function addContactFormResponse(values:any) {
  var endpoint = "contact-form-response-create/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', values, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function addTableReservationsFormResponse(values:any) {
  var endpoint = "contact-form-response-create/"//var endpoint = "table-reservations-form-response-create/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', values, true)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

function getResidentArtistsForWhiteLabel() {
  var endpoint = "white-label-resident-artists/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getAdminUsers(pageNum=null, search=null, sortField=null, sortOrder=null):Promise<IPaginatedUsersIn> {
  var endpoint = "admin/users/"
  endpoint = addPageSearchSortParams(endpoint, pageNum, search, sortField, sortOrder)
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data as IPaginatedUsersIn))
      .catch(err => {
        reject(err)
      })
  });
}

function getOrdersByEvent(eventId:number, pageNum=1, search=null, sortField=null, sortOrder=null):Promise<IPaginatedOrderIn>{
  var endpoint = "admin/orders/event/"+eventId+'/'
  endpoint = addPageSearchSortParams(endpoint, pageNum, search, sortField, sortOrder)
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data as IPaginatedOrderIn))
      .catch(err => {
        reject(err)
      })
  });
}

function getAdminUserDetails(userId:number):Promise<IPaginatedUsersIn>  {
  var endpoint = "admin/users/"+userId+"/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data as IPaginatedUsersIn))
      .catch(err => {
        reject(err)
      })
  });
}

function addPageSearchSortParams(endpoint:string, pageNum:number|null, search:string|null, sortField:string|null, sortOrder:string|null) {
  endpoint = endpoint + '?'
  if (pageNum) {
    endpoint = endpoint + '&page=' + pageNum
  }
  if (sortField) {
    sortField = sortField.replace(/\./g, "__")
    if (sortOrder === 'asc') {
      endpoint = endpoint + '&ordering=' + sortField
    } else if (sortOrder === 'desc') {
      endpoint = endpoint + '&ordering=-' + sortField
    }
  }
  if (search) {
    var searchSplit = search.split(':')
    if (searchSplit.length === 1) {
      endpoint = endpoint + '&search=' + search
    } else {
      endpoint = endpoint + '&' + searchSplit[0] + '=' + searchSplit.slice(1)
    }
  }
  return endpoint
}


function getPromoCodesForEvent(eventId:string):Promise<IPaginatedPromocodeIn> {
  var endpoint = "admin/promo-codes-for-event/" + eventId + "/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data as IPaginatedPromocodeIn))
      .catch(err => {
        reject(err)
      })
  });
}

function getPromoCode(promoCodeId:string) {
  var endpoint = "admin/promo-codes/" + promoCodeId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function addPromoCode(values:any) {
  var endpoint = "admin/promo-code-create/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', values, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function changePromoCode(promoCodeId:string, values:any) {
  var endpoint = "admin/promo-code-update/" + promoCodeId + '/'
  console.log(values)
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'put', values, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function deletePromoCode(promoCodeId:string) {
  var endpoint = "admin/promo-code-delete/" + promoCodeId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'delete', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function changeTicketInventoriesForPromoCode(promoCodeId:string, values:any) {
  var endpoint = "admin/promo-code-ticket-inventories/" + promoCodeId + '/'
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'put', values, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function processStripeConnect(code:string) {
  var values = {code: code}
  var endpoint = "admin/stripe-connect/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'post', values, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getStripeExpressDashboardURL() {
  var endpoint = "admin/stripe-connect/"
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

function getReferralCode(referralCode:string) {
  var endpoint = "get-referral-code/" + referralCode
  return new Promise(function(resolve, reject) {
    fetchBloxEndpoint(endpoint, 'get', {}, true)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

async function getTerminals(): Promise<ITerminal[]> {
  const endpoint = "admin/my-terminals"
  return await fetchBloxEndpoint(endpoint, 'get', {}, true) as ITerminal[]
}

async function createDoorOrder(doorOrder: {headcount: number, subtotal: number, terminal_id: string, order_type?: "card" | "cash" }, eventId: number ): Promise<IDoorOrder> {
  const endpoint = `admin/events/${eventId}/door-orders/`
  return await fetchBloxEndpoint(endpoint, 'post', doorOrder, true) as IDoorOrder
}

async function getDoorOrder(doorOrderId: number): Promise<IDoorOrder> {
  const endpoint = `admin/door-orders/${doorOrderId}`
  return await fetchBloxEndpoint(endpoint, 'get', {}, true) as IDoorOrder
}

async function getEventGuestsV2(eventId: number): Promise<{ tickets: ITicketV3[], door_order_count: number, door_order_headcount: number }> {
    const endpoint = `v3/admin/events/${eventId}/guests/`
    return await fetchBloxEndpoint(endpoint, 'get', {}, true) as { tickets: ITicketV3[], door_order_count: number, door_order_headcount: number }
}

export {
  getCurrentWhiteLabel,
  getFeedForWhiteLabel,
  changeWhiteLabel,
  getFeedForWhiteLabelWithTypes,
  getMobileFeedForWhiteLabelWithTypes,
  getCurrentUserOrders,
  getCurrentUserTickets,
  getEvents,
  getEvent,
  getOnSaleEvents,
  getOnSaleEvent,
  getEventGuestList,
  addEvent,
  changeEvent,
  deleteEvent,
  getTicketInventory,
  addTicketInventory,
  changeTicketInventory,
  deleteTicketInventory,
  getOrder,
  getTicket,
  transferTicket,
  getPage,
  getPressRelease,
  createOrder,
  completeOrder,
  getClubs,
  getTimeTypes,
  getPromoters,
  getArtists,
  getArtist,
  getTicketTypes,
  getAdminEvents,
  getAdminEvent,
  getFanCampaign,
  getCurrentUserFanCampaignResponses,
  getCurrentUserFanCampaignResponse,
  createFanCampaignResponse,
  addCompTickets,
  checkInTicket,
  checkInCompTicket,
  applyPromoCode,
  addVenue,
  changeVenue,
  getAdminOrders,
  getAdminVenues,
  addContactFormResponse,
  addTableReservationsFormResponse,
  getResidentArtistsForWhiteLabel,
  getAdminUsers,
  getAdminPromoCodes,
  getPromoCodesForEvent,
  getPromoCode,
  addPromoCode,
  changePromoCode,
  deletePromoCode,
  changeTicketInventoriesForPromoCode,
  processStripeConnect,
  getStripeExpressDashboardURL,
  getOrgSendyURL,
  createClientAccount,
  getEventCompList,
  getEventTypes,
  getAdminOrderDetails,
  getAdminUserDetails,
  getOrdersByEvent,
  getAdminEventGuestEmailList,
  createAdminEventSubPromoterLinks,
  updateAdminEventSubPromoterLinks,
  getSubpromoterEventData,
  getStaff,
  getRoles,
  addStaffMember,
  deleteStaffMember,
  createAdminEventSubPromoterLinkV2,
  fetchGuestListCsv,
  getTerminals,
  createDoorOrder,
  getDoorOrder,
  getEventGuestsV2
};
