import React from "react"
import { Row, Col } from "reactstrap"
import FlashAlert from '../Components/FlashAlert'
import GoogleMapComponentWithMarker from '../Components/Maps/Map'

type Props = {
  title?: any;
  type?: any;
  shortDescription?: string;
  longDescription?: string;
  tags?: string[];
  children?: any;
  fullWidth?: any;
  image?: any;
  venue?: any;
};

const ContentLayout: React.FC<Props> = (props) => {

  return (
    <Row noGutters className="BloxContentRow clearHeader">
      <Col className="BloxContent">
        <h3 className={props.fullWidth ? 'fullWidth' : ''}>
          {props.title}
        </h3>
        {props.shortDescription &&
          <p className="contentPageDescription">{props.shortDescription}</p>
        }
        <div className={"BloxContentContainer " + (props.fullWidth ? 'fullWidth' : '')}>
          <Row>
            <Col xs={12} md={6}>
              {props.image &&
                <div
                  style={{ backgroundImage: 'url(' + props.image + ')' }}
                  className="contentPagePrimaryImage"
                />
              }
              {props.venue &&
                <div>
                  <div className="contentGoogleMapContainer">
                    <GoogleMapComponentWithMarker
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBpVKhi7cJXLEPf4JAh3NqbM2ORTRbwxxs"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `300px`, marginBottom: `0` }} />}
                      venue={props.venue}
                    />
                  </div>

                  <p className="contentAddress">
                    <strong>{props.venue.name}</strong><br />
                    {props.venue.housenumber} {props.venue.street}<br />
                    {props.venue.city}, {props.venue.state} {props.venue.zip} <br />
                    {props.venue.phone_number}
                  </p>
                </div>
              }
            </Col>
            <Col xs={12} md={props.venue || props.image ? 6 : 12}>
              <div dangerouslySetInnerHTML={{ __html: props.longDescription ?? "" }} />
              {props.tags &&
                <ul className="tagList">
                  {props.tags.map((tag: any, i: number) => {
                    return (<li>{tag.name}</li>)
                  })}
                </ul>
              }
            </Col>
          </Row>
          <Row>
            {props.children}
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default ContentLayout;
