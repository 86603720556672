import React from "react";
import { Row, Col, Button } from "reactstrap";
import { getPage } from "../Lib/RESTBlox";
import { FormCheckbox } from "../Components/Forms/Fields";
import { Formik, Field, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
};

const Page: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const params = useParams();
  const handleSubmit = (values: any) => {
    console.log(values);
    if (values.agreeToTerms) {
      navigate("/admin");
    }
  };

  const setStateFromNewData = (data: any) => {
    setPage(data);
    setLoading(false);
  };

  const loadData = (pageSlug: any) => {
    getPage(pageSlug)
      .then((data) => setStateFromNewData(data))
      .catch((err) => console.error(err.toString()));
  };

  React.useEffect(() => {
    loadData(params.slug ?? "event-provider-agreement");
  }, []);

  return (
    <Row className="clearHeaderAndButtonGroup">
      <Col>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <h2>Please agree to the terms</h2>
            <p>
              Please read through the terms and check the agreement box at the
              bottom to contiue onboarding.
            </p>
          </Col>
        </Row>
        <Row>
          {page && (
            <Col
              md={{ size: 6, offset: 3 }}
              dangerouslySetInnerHTML={{ __html: page.content_html }}
            />
          )}
        </Row>
        <Row>
          <Formik
            initialValues={{ agreeToTerms: false }}
            onSubmit={(values, actions) => {
              handleSubmit(values);
              actions.setSubmitting(false);
            }}
            render={(props) => (
              <Form className="EventProviderAgreementForm">
                <Row>
                  <Col>
                    <Field
                      label={
                        <span>
                          I agree to The Blox Office Event Provider Agreement.
                        </span>
                      }
                      type="checkbox"
                      name="agreeToTerms"
                      component={FormCheckbox}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button color="primary" block type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default Page;
