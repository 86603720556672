import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Col, Row, Input} from "reactstrap";
import { useParams } from "react-router-dom";
import FlashAlert from "../../Components/FlashAlert";
import {checkInCompTicket, checkInTicket, getEventGuestsV2, getTerminals} from "../../Lib/RESTBlox";
import {GuestCheckInRow} from "./GuestCheckInRow";
import {QrCodeScanner} from "../EventGuestListNew/components/QrCodeScanner";
import {DoorOrderProcessor, ITerminal} from "../../Components/Modals/DoorOrderProcessor";
import {CompTicketManager} from "../../Components/Modals/CompTicketManager";


interface EventGuestListV2Props {
    user: any
}

export interface ITicketV3 {
    id: number;
    name: string,
    ticket_type: string,
    was_used: boolean,
    is_comp: boolean,
    email?: string;
}

export type ITicketState = ITicketV3 & {loading: boolean} & {promo_codes?: string[] | string};

const EventGuestListV2 = ({user}: EventGuestListV2Props) => {
    const { eventId } = useParams();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [allTickets, setAllTickets] = useState<ITicketState[]>([]);
    const [doorOrderStats, setDoorOrderStats] = useState<{ orderCount: number; headcount: number }>({orderCount: 0, headcount: 0});
    const [doorOrderHeadCount, setDoorOrderHeadCount] = useState<number>(0);
    const [lastFetched, setLastFetched] = useState(0);
    const [visibleType, setVisibleType] = useState<'all' | 'tickets' | 'comp'>("all");
    const [query, setQuery] = useState("");


    const fetchTickets = async () => {
        setError("");
        setLoading(true);
        try {
            const data = await getEventGuestsV2(parseInt(eventId + '', 10));
            setAllTickets(data.tickets.map(t => ({...t, loading: false})));
            setDoorOrderStats({orderCount: data.door_order_count, headcount: data.door_order_headcount});
            setLastFetched(Date.now());
            setLoading(false);
        } catch(e) {
            console.error(e);
            setError("Error fetching tickets");
            setAllTickets(prev => [...prev]);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchTickets();
    }, [eventId]);

    const filteredTickets = useMemo(() => {
        const trimmed = query.trim();
        return allTickets.filter(ticket => ticket.name.toLowerCase().includes(trimmed.toLowerCase()) || (ticket?.email && ticket?.email.toLowerCase().includes(trimmed.toLowerCase())))
    }, [allTickets, visibleType, query]);

    const guestMap = useMemo(() => {
        const temp: Record<string, ITicketState[]> = {};

        filteredTickets.forEach(ticket => {
            if(!temp[ticket.name]) {
                temp[ticket.name] = [];
            }
            temp[ticket.name].push(ticket);
        })
        return temp
    }, [filteredTickets])

    const handleCheckIn = useCallback(async (ticketId: number) => {
        setError("")
        const ticket = allTickets.find(t => t.id === ticketId);
        if(!ticket) throw new Error("Ticket not found");
        setAllTickets(prev => prev.map(t => t.id === ticketId ? {...t, loading: true} : t));
        try {
            if(ticket.is_comp) {
                await checkInCompTicket(ticketId + "");
            } else {
                await checkInTicket(ticketId + "");
            }
            setAllTickets(prev => prev.map(t => t.id === ticketId ? {...t, loading: false, was_used: !t.was_used} : t));
            // fetchTickets();
        } catch(e) {
            console.error(e);
            setError("Error checking in ticket");
            setAllTickets(prev => prev.map(t => t.id === ticketId ? {...t, loading: false} : t));
        }
    }, [allTickets]);

    const checkedInCount = allTickets.filter(ticket => ticket.was_used).length
    const totalCount = (eventId === "744") ? 550 : allTickets.length

    const [terminals, setTerminals] = React.useState<ITerminal[]>([]);
    useEffect(() => {
        getTerminals().then((res) => {
            console.log(res)
            setTerminals(res);
        })
    }, [])

    useEffect(() => {
        if (lastFetched && (Date.now() - lastFetched) > 10 * 1000 && !loading) fetchTickets();
    }, [query])

    return (
        <Row className="BloxAdminRow">
            <Col className="BloxAdminContent-white">
                {error && (
                    <Row>
                        <Col>
                            {/*<FlashAlert color={alert.type} text={alert.message} />*/}
                        </Col>
                    </Row>
                )}
                <div className="guest-table-v2--page__header">
                    <h1>Guestlist</h1>
                    <div className="aside">
                        <p>
                            {checkedInCount} / {totalCount} checked in

                            {(doorOrderStats.orderCount > 0 || doorOrderStats.headcount > 0) && (
                                <>
                                    <br/>
                                    {doorOrderStats.headcount} door tickets
                                </>
                            )}
                        </p>
                    </div>
                </div>
                <div className="BloxAdminContentContainer">
                    <div className="guest-table-v2--page__actions">
                        <div>
                            <CompTicketManager eventId={parseInt(eventId + '', 10)} onClose={() => fetchTickets()}/>
                        </div>
                        {terminals.length > 0 && (
                            <div>
                                <DoorOrderProcessor terminals={terminals} onCreate={() => fetchTickets()} eventId={parseInt(eventId || '')} />
                            </div>
                        )}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}} className="guest-table-v2--page__search">
                        <Input value={query} onChange={(e: React.FormEvent<HTMLInputElement>) => setQuery(e.currentTarget.value)}/>
                        <QrCodeScanner onResult={result => {
                            setQuery(result);
                        }} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}} className="guest-table-v2">
                        {Object.keys(guestMap).sort((name1, name2) => name1.localeCompare(name2) ).map(name => (<GuestCheckInRow handleCheckIn={handleCheckIn} name={name} tickets={guestMap[name]} key={name} />))}
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export {EventGuestListV2}
