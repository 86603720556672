import React from "react";
import TicketInventoryForm from "../Forms/TicketInventoryForm/TicketInventoryForm";
import AdminLayout from "../../Layouts/AdminLayout";

type Props = {
  user: any;
};

const TicketInventoryAdd: React.FC<Props> = ({ user }) => {
  const [eventId, setEventId] = React.useState<string | null>(
    window.location.pathname.split("/").pop() || null
  );

  return (
    <AdminLayout
      title="Add ticket inventory"
      description="A ticket inventory is a type of tickets made available for the event. Each event may have multiple ticket inventories (for example, an inventory of Early Bird tickets, an inventory of VIP tickets, and an inventory of General Admission tickets.)"
    >
      <TicketInventoryForm eventId={eventId} />
    </AdminLayout>
  );
};

export default TicketInventoryAdd
