import React, {useCallback, useEffect, useState} from "react";
import { Button, Modal, Input, Label, ModalHeader, ModalBody } from 'reactstrap';
import PersonIcon from '@mui/icons-material/Person';
import {addCompTickets, createDoorOrder, getDoorOrder} from "../../Lib/RESTBlox";
import Loader from "../Loader";

interface CompTicketManagerProps {
    eventId: number;
    onClose?: () => void;
}

const CompTicketManager = ({eventId, onClose = () => {}}: CompTicketManagerProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const toggleModal = useCallback(() => {
        if(modalIsOpen) resetForm();
        setModalIsOpen(prev => !prev);
    }, [modalIsOpen]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [ticketCount, setTicketCount] = useState(1);
    const [isVip, setIsVip] = useState(false);

    useEffect(() => {
        console.log(firstName)
        console.log(lastName)
    }, [firstName, lastName])
    useEffect(() => console.log(isVip), [isVip]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const resetForm = () => {
        setError('')
        setLoading(false)
        setFirstName('')
        setLastName('')
        setTicketCount(1)
        setIsVip(false)
    }
    useEffect(() => {
        if(!modalIsOpen) {
            onClose();
            resetForm();
        }
    }, [modalIsOpen])


    const handleAddTicket = useCallback(async () => {
        const payload = {
            first_name: firstName,
            last_name: lastName,
            quantity: ticketCount,
            is_vip: isVip,
        }
        try {
            setLoading(true);
            const res = await addCompTickets(payload, eventId + '');
            resetForm()
            setLoading(false)
        } catch (e) {
            console.log(e);
            setError("There was an error adding the tickets");
        }
    }, [firstName, lastName, ticketCount, isVip]);


    return (
        <>
            <Button
                type="button"
                block
                color="secondary"
                className="hvr-grow-shadow"
                onClick={() => setModalIsOpen(true)}>Add Comp Tickets</Button>
            <Modal isOpen={modalIsOpen} toggle={toggleModal} className="comp-ticket-modal" >
                <ModalHeader toggle={toggleModal}>Add Comp Ticket</ModalHeader>
                <ModalBody style={{display: 'flex', flexDirection: 'column', fontFamily: '"Titillium Web", "Helvetica Neue", "Lucida Grande", Arial, Verdana, sans-serif'}}>
                    <p>Enter your guest&apos;s details below.</p>
                    <div className="comp-ticket-modal__input-container">
                        <Label>
                            First name
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <PersonIcon />
                                <Input  type="text" value={firstName} onChange={(e: any) => setFirstName(e.target.value)} />
                            </div>
                        </Label>
                        <Label>
                            Last name
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <PersonIcon />
                                <Input  type="text" value={lastName} onChange={(e: any) => setLastName(e.target.value)} />
                            </div>
                        </Label>
                    </div>
                    <div className="comp-ticket-modal__input-container">
                        <Label>
                            Count
                            <Input  type="number" min="0.01" step="1" value={ticketCount} onChange={(e: any) => setTicketCount(parseInt(e.target.value, 10))} />
                        </Label>
                        <div>
                            Comp Type:
                            <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                                <label>
                                    <input type="radio" value="regular" name="order_type" onChange={(e)=> e.target.value === 'regular' ? setIsVip(false) : setIsVip(true)} checked={ !isVip }/> Regular
                                </label>
                                <label>
                                    <input type="radio" value="vip" name="order_type" onChange={(e)=> e.target.value === 'vip' ? setIsVip(true) : setIsVip(false)} checked={ isVip }  /> VIP
                                </label>
                            </div>
                        </div>
                    </div>
                    <div style={{position: 'relative', height: '64px'}}>
                        { (loading ) && <Loader color="#000" loading={loading} />}
                    </div>
                    <div style={{textAlign: 'center', fontSize: '1.7rem'}}>

                    </div>
                    {error && <div style={{color: 'red'}}>{error}</div>}
                    <Button color="primary" onClick={handleAddTicket} disabled={!firstName || !lastName || ticketCount === 0}>Create Order</Button>
                    <Button onClick={() => {
                        setModalIsOpen(false)
                    }} style={{marginTop: '0.6rem'}}>Close</Button>
                </ModalBody>
            </Modal>
        </>
    )
}

export {CompTicketManager}