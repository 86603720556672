import React, { CSSProperties } from "react";
import {
  genderFormatter,
  detailsFormatter,
  dateJoinedFormatter,
  lastActivityFormatter,
  orderFormatter,
  userFormatter,
  orderDateCreatedFormatter,
  totalFormatter,
  ticketsFormatter,
  orderNumberFormatter,
} from "../../../../Lib/TableFormatters";
import InfiniteScroll from "react-infinite-scroll-component";
import { IUser } from "../../../../interfaces/user/user";
import { Grid } from "@mui/material";
import Loader from "../../../../Components/Loader";
import { IOrdersIn } from "../../../../interfaces/orders/orders";

type Props = {
  orders: IOrdersIn[];
  loadData: () => void;
  loading: boolean;
  hasMore: boolean;
  isMobile:boolean
};

const loaderStyle: CSSProperties = {
  margin: 0,
  position: "relative",
  left: 0,
  display: "flex",
  justifyContent: "center",
};

const OrdersTable: React.FC<Props> = ({
  orders,
  loadData,
  loading,
  hasMore,
  isMobile
}) => {
  return orders?.length > 0 ? (
    <InfiniteScroll
      dataLength={orders?.length}
      next={loadData}
      hasMore={hasMore}
      loader={<Loader loading={loading} color="#000" style={loaderStyle} />}
    >
      <Grid container style={{ marginTop: "15px" }}>
        <Grid container className="user-table-header-row">
          <Grid item xs={3} xl={3} md={3} lg={3}>
            <p className="table-header">DETAILS</p>
          </Grid>
          <Grid item xs={2} xl={1} md={1} lg={1} container justifyContent="end">
            <p className="table-header">TIME</p>
          </Grid>
          {!isMobile?<Grid
            item
            xs={4}
            xl={4}
            md={4}
            lg={4}
            container
            justifyContent="center"
          >
            <p className="table-header">PURCHASER</p>
          </Grid>:<></>}
          <Grid item xs={2} xl={1} md={1} lg={1} container justifyContent="end">
            <p className="table-header">TOTAL</p>
          </Grid>
          <Grid
            item
            xs={2}
            xl={1}
            md={1}
            lg={1}
            container
            justifyContent="center"
          >
            <p className="table-header">TICKETS</p>
          </Grid>
          <Grid
            item
            xs={3}
            xl={2}
            md={2}
            lg={2}
            container
            justifyContent="center"
          >
            <p className="table-header">ORDER #</p>
          </Grid>
        </Grid>
        {orders?.map((order, index: number) => {
          return (
            <Grid container className="user-table-row" key={index} style={{alignItems: 'start'}}>
              <Grid
                item
                xs={3}
                xl={3}
                md={3}
                lg={3}
                container
                justifyContent="start"
                style={{ textAlign: "start", flexDirection: "column" }}
              >
                {orderFormatter("", order)}
              </Grid>
              <Grid
                item
                xs={2}
                xl={1}
                md={1}
                lg={1}
                container
                justifyContent="end"
                style={{ textAlign: "end", flexDirection: "column" }}
              >
                {orderDateCreatedFormatter("", order)}
              </Grid>
              {!isMobile?<Grid
                item
                xs={4}
                xl={4}
                md={4}
                lg={4}
                container
                justifyContent="center"
                style={{ textAlign: "center", flexDirection: "column" }}
              >
                {userFormatter("", order)}
              </Grid>:<></>}
              <Grid
                item
                xs={2}
                xl={1}
                md={1}
                lg={1}
                container
                justifyContent="end"
              >
                {totalFormatter("", order)}
              </Grid>
              <Grid
                item
                xs={2}
                xl={1}
                md={1}
                lg={1}
                container
                justifyContent="center"
              >
                {ticketsFormatter("", order)}
              </Grid>
              <Grid
                item
                xs={3}
                xl={2}
                md={2}
                lg={2}
                container
                justifyContent="center"
              >
                {orderNumberFormatter("", order)}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </InfiniteScroll>
  ) : (
    <Loader loading={loading} color="#000" style={loaderStyle} />
  );
};

export default OrdersTable;
