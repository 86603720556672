import React from "react";

type Props = {
  order: any;
};
const SavedLabel: React.FC<Props> = ({ order }) => {
  return (
    <p
      style={{
        display: "block",
        color: "#fff",
        marginBottom: "0.5rem",
        padding: "6px",
        borderRadius: "12px",
        background: "#080",
        textAlign: "center",
        width: "100%",
        marginTop: "1rem",
      }}
    >
      Saved {order.discount}$
    </p>
  );
};
export default SavedLabel;
