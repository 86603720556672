import React, {useState, useEffect, useRef} from 'react'
import {Row, Col} from 'reactstrap'
import {getEvent, getOnSaleEvents} from '../Lib/RESTBlox'
import {Helmet} from "react-helmet";
import EventCarousel from '../Components/EventCarousel/EventCarousel'
import DummyCarousel from '../Components/EventCarousel/DummyCarousel'
import Loader from '../Components/Loader'
import EventDetails from '../Components/EventDetails/EventDetails'
import NoEventsMessage from '../Components/EventDetails/NoEventsMessage'
import scrollToElement from 'scroll-to-element';
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  // match: any;
  // history: any;
  // url: any;
  whiteLabel: any;
  signedIn: any;
  postSignUp: any;
  postSignIn: any;
  postPasswordForgot: any;
};

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;

function falseIfSafari() {
  var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if(isSafari) return false
  return true
}

function falseIfiOS() {
//   var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
//   if(iOS) return false;
  return true;
}

const Events: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true)
  const params = useParams();
  const [activeEventId, setActiveEventId] = useState(parseInt(params?.id as string) || null)
  const [activeEvent, setActiveEvent] = useState<any>(undefined)
  const [activeEventIndex, setActiveEventIndex] = useState(0)
  const [onSaleEvents, setOnSaleEvents] = useState<any>([])
  const [singleEvent, setSingleEvent] = useState(false)
  const [displayCarousel, setDisplayCarousel] = useState(false)
  const [sliding, setSliding] = useState(false)
  const [text, setText] = useState(true)
  var x = 0;
  var y = 0;
  
  const eventDetailsElem = useRef(null);
  const navigate = useNavigate();
  function scrollToTopOfEventDetails() {
    scroll.scrollToTop({ // Happens on carousel change in the desktop view
      duration: 600,
      delay: 100,
      smooth: "easeOut",
      containerId: 'BloxEventsDetailsColumn',
    });
  }

  function scrollToEventDetails() {
    enableScrolling() // On tap to scroll, enable scrolling
    scrollToElement(eventDetailsElem.current ?? '', { // And then scroll down for the user
      ease: 'outCirc',
      offset: -78,
      align: 'top',
      duration: 600
    })
  }
  
  const scrollToPurchaseTickets = (e:any) => {
    const yNow = (e.nativeEvent as TouchEvent).changedTouches[0].clientY
    const xNow = (e.nativeEvent as TouchEvent).changedTouches[0].clientX
    if (Math.abs(x-xNow)<Math.abs(y-yNow)){
      enableScrolling() // On tap to scroll, enable scrolling
      window.scroll({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    }); 
    }
  }
  const onSwipeStart = (e:any)=> {
    y = (e.nativeEvent as TouchEvent)?.touches?.[0]?.clientY
    x = (e.nativeEvent as TouchEvent)?.touches?.[0]?.clientX
  }

  useEffect(() => {
    if (activeEventId) handleEventId(activeEventId) // URL has event ID
    else handleNoEventID() // URL does not have event ID
  }, [])

  useEffect(() => {
    var mq = window.matchMedia("(max-width: 768px)")
    if (mq.matches) {
      disableScrolling() // Disable scrolling on this page to make mobile carousel work cleaner
      scrolledToTopListener() // After a tap to scroll, we want to disable scrolling again when the user hits the top of the page 
    }
    return function cleanup() {
      enableScrolling() // Renable scrolling upon unmounting component
      window.removeEventListener('scroll', scrolledToTop) // And remove the event listener
    };
  }, [])

  function disableScrolling() {
    document.getElementsByClassName('App')[0].classList.add('noScroll')
  }

  function enableScrolling() {
    document.getElementsByClassName('App')[0].classList.remove('noScroll')
  }

  function scrolledToTop() {
    if(window.pageYOffset === 0) {
        disableScrolling()
    }
  }

  function scrolledToTopListener() {
    window.addEventListener('scroll', scrolledToTop)
  }


  function canUseCarousel(event_data:any) {
    if (event_data.on_sale && !event_data.private) return true
    return false
  }

  function handleEventId(activeEventId:any) {
    getEvent(activeEventId)
      .then((event_data:any) => {
        setActiveEvent(event_data)
//         if (canUseCarousel(event_data)) { handleMultipleEvents(event_data) } //CHANGE THIS i COMENT THIS OUT BECAUSE IT WAS GIVING TROUBLES cmm
//         else { handleSingleEvent() }
        if (canUseCarousel(event_data)) { handleSingleEvent() } //REPLACED 13--132 WITH THIS
        else { handleSingleEvent() }           
      })
      .catch(err => {
        if (err.response.status === 404) navigate('/')
        console.error('error')
      })
  }

  function handleNoEventID() {
    handleMultipleEvents()
  }

  function handleMultipleEvents(event_data=null) {
    getOnSaleEvents()
      .then((on_sale_events_data : any) => {
        setOnSaleEvents(on_sale_events_data)
        setSingleEvent(false)
        setActiveEventOrUseFirstEvent(event_data, on_sale_events_data)
        setDisplayCarousel(true)
        setLoading(false)
        if (on_sale_events_data.length === 1) setSingleEvent(true)
      })
  }

  function handleSingleEvent() {
    setSingleEvent(true)
    setDisplayCarousel(true)
    setLoading(false)
  }

  function setActiveEventOrUseFirstEvent(event_data:any, on_sale_events_data:any) {
    var index = 0
    var event = on_sale_events_data[0]
    navigate(`/events/${on_sale_events_data[0]?.id}`)
    if (event_data) {
      event = event_data
      index = on_sale_events_data.map((event_data:any) => event_data.id).indexOf(event_data.id)
      navigate(`/events/${event_data?.id}`)
    }
    setActiveEvent(event)
    setActiveEventIndex(index)
  }

  useEffect(()=>{
    const timeout = setInterval(()=>{
      setText(text => !text);
    },3500)
    return () => clearInterval(timeout);
  },[])

  function handleCarouselChange(newIndex:any) {
    setSliding(true)
    if (window.matchMedia("(min-width: 768px)")) {
      // LARGER SCREENS
      scrollToTopOfEventDetails()
      setTimeout(function(){
        setActiveEvent(onSaleEvents[newIndex])
        setActiveEventId(onSaleEvents[newIndex].id)
        navigate(`/events/${onSaleEvents[newIndex].id}`)
        setSliding(false)
        // props.history.push('/events/' + onSaleEvents[newIndex].id)
      }, 200)
    } else {
      // SMALLER SCREENS
      setActiveEvent(onSaleEvents[newIndex])
      setActiveEventId(onSaleEvents[newIndex].id)
      navigate(`/events/${onSaleEvents[newIndex].id}`)
      setSliding(false)
    //  props.history.push('/events/' + onSaleEvents[newIndex].id)
    }
  }

  function createHelmet() {
    return (
      activeEvent &&
        <Helmet key={window.location.href}>
          <title>{activeEvent.title} — The Blox Office</title>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={activeEvent.__str__ +  " — The Blox Office"} />
          <meta property="og:description" content={`${activeEvent?.date_display} @ ${activeEventId === 1245 ? 'Coco Loco Beach Club, Oranjestad, Aruba' : activeEvent?.venue_undisclosed ? 'an Undisclosed Location' : activeEvent?.venue?.name}`} />
          <meta property="og:image" content={activeEvent.metadata_src ? activeEvent.metadata_src : activeEvent.src} />
        </Helmet>
    )
  }

  function createCarousel() {

    if (!singleEvent && onSaleEvents.length === 0 && loading === false) {
      return (
        <div className="noEventsLogo" style={{backgroundImage: `url(${props.whiteLabel.logo})`}} />
      )
    }
    
    // if (displayCarousel) {
    //   const flyers = document.createElement('script');
    //   flyers.src = "https://thebloxoffice.blob.core.windows.net/scripts/flyers.js";

    //   const head = document.getElementsByTagName('head')[0];
    //   head.appendChild(flyers);
    // }

    if (singleEvent && displayCarousel) {
      return (
        <DummyCarousel
          event={activeEvent}
          clickHandler={() => scrollToEventDetails()}
          swipeHandler={(e:any)=>scrollToPurchaseTickets(e)}
        />
      )
    }
    
    if (!singleEvent && displayCarousel) {
      return (
        <DummyCarousel
          event={activeEvent}
          clickHandler={() => scrollToEventDetails()}
          swipeHandler={(e:any)=>scrollToPurchaseTickets(e)}
        />
      )
    }
    
  }
//     if (!singleEvent && displayCarousel) {
//       return (
//         <EventCarousel
//           events={onSaleEvents}
//           activeIndex={activeEventIndex}
//           changeHandler= { handleCarouselChange }
//           clickHandler={() => scrollToEventDetails()}
//           onSwipeStartProps={(e:any)=> onSwipeStart(e)}
//           onScrollToTicketsProps={(e:any)=>scrollToPurchaseTickets(e)}
//           allowSwipe = {falseIfSafari()}
//         />
//       )
//     }
//   }

  
  function createNoEventsMessage() {
    if (loading === false && onSaleEvents.length === 0 && !activeEvent) {
      return (
        <NoEventsMessage />
      )
    }
  }

  function createEventDetails() {
    return (
      <div ref={eventDetailsElem} className="event-details-outer-container">
        { activeEvent &&
          <EventDetails
            event={ activeEvent }
            showPurchaseTable={activeEvent.on_sale || activeEvent.private}
            signedIn={props.signedIn}
            postSignUp = {props.postSignUp}
            postSignIn = {props.postSignIn}
            postPasswordForgot= {props.postPasswordForgot}
          />
        }
      </div>
    )
  }

  if (loading === false && onSaleEvents.length === 0 && !activeEvent) {
    return (
      <Row noGutters className="BloxEventsRow">
        { createHelmet() }
        <Col md={6} className="BloxEventsCarouselColumn">
          <Loader loading={loading} color='#fff' />
          { createCarousel() }  
          <div className="TapOrScrollForMore">There are no events on sale</div>
        </Col>

        <Col md={6} id="BloxEventsDetailsColumn" className={"BloxEventsDetailsColumn loading-"  + loading + " sliding-" + sliding }>
          <Loader loading={loading} color='#fff' />
          { createNoEventsMessage() }
          { createEventDetails() }
        </Col>
      </Row>
    )
  }
  else {
    return (
      <Row noGutters className="BloxEventsRow">
        { createHelmet() }
        <Col md={6} className="BloxEventsCarouselColumn">
          <Loader loading={loading} color='#fff' />
          { createCarousel() }  
          <div className="TapOrScrollForMore">{text? "Tap image for more details":"Tap image to see tickets"}</div>
        </Col>

        <Col md={6} id="BloxEventsDetailsColumn" className={"BloxEventsDetailsColumn loading-"  + loading + " sliding-" + sliding }>
          <Loader loading={loading} color='#fff' />
          { createNoEventsMessage() }
          { createEventDetails() }
        </Col>
      </Row>
    )
  }

}

export default Events
