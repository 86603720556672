import React, { useEffect, MouseEventHandler } from "react";
import { FormGroup, Label, FormText } from "reactstrap";
import Multiselect from "react-widgets/lib/Multiselect";
import { getArtists } from "../../../../../Lib/RESTBlox";
import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from "react-select";
import CreatableSelect from "react-select/creatable";

import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from "react-sortable-hoc";

//this option is sorteable////////////////
function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement((props: MultiValueProps) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(CreatableSelect);

type Props2 = {
  field: any;
  form: any;
  label: boolean;
  required: boolean;
  help: any;
  placeholder: any;
  disabled: any;
};

const FormArtistPicker: React.FC<Props2> = ({
  field,
  form,
  label,
  required,
  help,
  placeholder,
  disabled,
}) => {
  // const [value, setValue] = React.useState<any[]>([
  //   colourOptions[4],
  //   colourOptions[5],
  // ]);

  const onChange = (selectedOptions: any) => setValue(selectedOptions);

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(value, oldIndex, newIndex);
    newValue.map((i: any, index: number) => (i.sort_order = index));
    setValue(newValue);
    form.setFieldValue(field.name, value);
    console.log(
      "Values sorted:",
      newValue.map((i: any) => i.value)
    );
    return { value };
  };

  const [value, setValue] = React.useState<any>(
    (field.value as []).sort((a: any, b: any) => {
      return a.sort_order - b.sort_order;
    })
  );
  const [artists, setArtists] = React.useState<any[]>([]);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);

  const handleChange = (value: any) => {
    setValue(value);
    form.setFieldValue(field.name, value);
    return { value };
  };

  const handleGetMore = (value: any) => {
    getItems(page);
  };

  useEffect(() => {
    getItems();
  }, []);

  const handleCreate = (name: any) => {
    let newOption = {
      label: name,
      value: artists.length + 10000000, // Arbitrary large number to ensure it won't collide with existing IDs
    };

    const newValue = [...value, newOption];
    const newArtists = [...artists, newOption];

    setValue(newValue);
    setArtists(newArtists);

    // Set the field value
    form.setFieldValue(field.name, value);
  };

  const getItems = (page = 0) => {
    getArtists(page + 1)
      .then((data: any) => {
        if (data.next) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        var fetchedartists = data.results.map((artist: any, i: number) => {
          return {
            value: artist.id,
            label: artist.name,
            sort_order: null,
          };
        });
        setPage(page + 1);
        setArtists(artists.concat(fetchedartists));
      })
      .catch((err) => console.error(err));
  };

  const promiseOptions = (inputValue: string) => {
    getArtists(1, inputValue)
      .then((data: any) => {
        var fetchedartists = data.results.map((artist: any, i: number) => {
          return {
            value: artist.id,
            label: artist.name,
            sort_order: null,
          };
        });
        setArtists(fetchedartists);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Label for={field.name}>
        {label}
        {required ? (
          <span className="required">*</span>
        ) : (
          <span className="optional">Optional</span>
        )}
      </Label>
      <SortableSelect
        useDragHandle
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti
        onMenuScrollToBottom={handleGetMore}
        options={artists}
        onInputChange={promiseOptions}
        value={value}
        onChange={handleChange}
        components={{
          MultiValue: SortableMultiValue,
          MultiValueLabel: SortableMultiValueLabel,
        }}
        closeMenuOnSelect={false}
      />
    </>
  );
};

export default FormArtistPicker;
