import React from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import { FormField, FormGenderPicker, FormTextDateField, FormTextPhoneNumberField } from '../Forms/Fields'
import { validateEmail, formatPhoneNumber, formatDateString } from '../../Lib/FormValidation'
import FlashAlert from '../FlashAlert'
import { signUp } from '../../Lib/RESTAuth'
import SignInModal from '../Modals/SignInModal'
import ReactPixel from 'react-facebook-pixel'


class SignUpModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      buttonText: this.props.buttonText || "Sign up",
      block: this.props.block || false,
      outline: this.props.outline || false,
      alert: {
        type: '',
        message: ''
      }
    };
    this.toggle = this.toggle.bind(this)
    this.handleSignUp = this.handleSignUp.bind(this)
    this.postSignIn = this.props.postSignIn.bind(this)
    this.postSignUp = this.props.postSignUp.bind(this)
    this.postPasswordForgot = this.props.postPasswordForgot.bind(this)
    if ('closeNavbar' in this.props) {
      this.closeNavbar = this.props.closeNavbar.bind(this)
    }
  }

  _isMounted = false

  toggle() {
    if (this._isMounted) {
      this.setState({
        modal: !this.state.modal
      });
    }
  }

  fbPixelTrackRegistration(order) {
    if (this.props.whiteLabel.facebook_pixel_id) {
      ReactPixel.init(this.props.whiteLabel.facebook_pixel_id, {}, { autoConfig: true, debug: false });
      ReactPixel.track('CompleteRegistration');
    }
  }

  handleSignUp = (data) => {
    signUp(data.email, data.password1, data.firstName, data.lastName, data.phone, data.gender, data.birthdate)
      .then(json => {
        this.postSignUp(json)
        this.toggle()
        if ('closeNavbar' in this.props) {
          this.closeNavbar()
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({
          alert: {
            type: 'danger',
            message: err.response.errorMessage
          }
        })
      })
  };

  validate = (values) => {
    let errors = {};

    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    if (!values.password1) {
      errors.password1 = 'Required';
    }

    if (!values.password2) {
      errors.password2 = 'Required';
    }

    if (values.gender.length === 0) {
      errors.gender = 'Required';
    }

    if (values.birthdate.length === 0) {
      errors.birthdate = 'Required';
    }

    if (values.password1 !== values.password2) {
      errors.password2 = 'Passwords do not match';
    }

    return errors;
  };

  componentDidMount() {
    this._isMounted = true
    // if (this.props.onOpen) {
    //   this.props.onOpen();
    // }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <div>
        {this.props.displayButton !== false &&
          <Button
            className="hvr-grow-shadow"
            color="primary"
            outline={this.state.outline}
            size="sm"
            block={this.state.block}
            onClick={this.toggle}
          >
              { this.state.buttonText }
          </Button>
        }
      <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password1: '',
            password2: '',
            phone: '',
            gender: '',
            birthdate: ''
          }}
          validate = {this.validate}
          onSubmit={(values, actions) => {
            var formattedPhone = formatPhoneNumber(values.phone)
            var formattedBirthdate = formatDateString(values.birthdate)
            var payload = {
              'username': values.email,
              'password1': values.password1,
              'email': values.email,
              'firstName': values.firstName,
              'lastName': values.lastName,
              'phone': formattedPhone,
              'gender': values.gender,
              'birthdate': formattedBirthdate
            }
            console.log(payload)
            this.handleSignUp(payload)
            actions.setSubmitting(false)
          }}
          render={(props ) => (

            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
              <ModalHeader toggle={this.toggle}>
                Sign up or
                <SignInModal
                  postSignIn = {this.postSignIn}
                  postSignUp = {this.postSignUp}
                  postPasswordForgot = {this.postPasswordForgot}
                  closeNavbar = {this.closeNavbar}
                  outline
                  onOpen = {() => this.setState({
                    modal: false
                  })}
                />
              </ModalHeader>
              <ModalBody>
              <Form>
                { this.state.alert.message && (this.state.alert.type === 'info' ||  this.state.alert.type === 'danger') &&
                  <Row>
                    <Col>
                      <FlashAlert
                        color={this.state.alert.type}
                        text={this.state.alert.message}
                        dontDismiss
                      />
                    </Col>
                  </Row>
                }
                <Row>
                  <Col className="pr-1">
                    <Field
                      label="First name"
                      type="text"
                      name="firstName"
                      placeholder="Your first name..."
                      component={FormField}
                      required={true}
                    />
                  </Col>
                  <Col className="pl-1">
                    <Field
                      label="Last name"
                      type="text"
                      name="lastName"
                      placeholder="Your last name..."
                      component={FormField}
                      required={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      label="Email"
                      type="email"
                      name="email"
                      placeholder="Your email address..."
                      component={FormField}
                      required={true}
                      validate={validateEmail}
                      autoComplete="username"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1">
                    <Field
                      label="Password"
                      type="password"
                      name="password1"
                      placeholder="Your password..."
                      component={FormField}
                      required={true}
                      autoComplete="new-password"
                    />
                  </Col>
                  <Col className="pl-1">
                    <Field
                      label="Confirm password"
                      type="password"
                      name="password2"
                      placeholder="Your password again..."
                      component={FormField}
                      required={true}
                      autoComplete="new-password"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1">
                    <Field
                      label="Gender"
                      type="text"
                      name="gender"
                      placeholder="Select..."
                      component={FormGenderPicker}
                      required={true}
                    />
                  </Col>
                  <Col className="pl-1">
                    <Field
                      label="Birthdate"
                      type="text"
                      name="birthdate"
                      placeholder="MM/DD/YYYY"
                      // validate={validateDateString}
                      component={FormTextDateField}
                      required={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      label="Phone number"
                      type="phone"
                      name="phone"
                      placeholder="Your phone number..."
                      component={FormTextPhoneNumberField}
                      required={false}
                    />
                  </Col>
                </Row>
                <Row className="buttonRow">
                  <Col>
                    <Button className="hvr-grow-shadow" block color="primary" type="submit">Sign up</Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          )}
        />
      </div>
    );
  }
}

export default SignUpModal;
