import React, { CSSProperties } from "react";
import {
  genderFormatter,
  detailsFormatter,
  dateJoinedFormatter,
  lastActivityFormatter,
  lastPageFormater,
} from "../../../../Lib/TableFormatters";
import InfiniteScroll from "react-infinite-scroll-component";
import { IUser } from "../../../../interfaces/user/user";
import { Grid } from "@mui/material";
import Loader from "../../../../Components/Loader";

type Props = {
  users: IUser[];
  loadData: () => void;
  loading: boolean;
  hasMore: boolean;
  isMobile: boolean;
};

const loaderStyle: CSSProperties = {
  margin: 0,
  position: "relative",
  left: 0,
  display: "flex",
  justifyContent: "center",
};

const UsersTable: React.FC<Props> = ({
  users,
  loadData,
  loading,
  hasMore,
  isMobile,
}) => {
  return users?.length > 0 ? (
    <InfiniteScroll
      dataLength={users?.length}
      next={loadData}
      hasMore={hasMore}
      loader={<Loader loading={loading} color="#000" style={loaderStyle} />}
    >
      <Grid container style={{ marginTop: "15px" }}>
        <Grid container className="user-table-header-row">
          <Grid item xs={4} xl={4} md={4} lg={4}>
            <p className="table-header">DETAILS</p>
          </Grid>
          <Grid item xs={4} xl={2} md={2} lg={2} container justifyContent="end">
            <p className="table-header">REGISTERED</p>
          </Grid>
          <Grid
            item
            xs={4}
            xl={2}
            md={2}
            lg={2}
            container
            justifyContent="center"
          >
            <p className="table-header">LAST ACTIVITY</p>
          </Grid>
          {!isMobile && (
            <Grid item xs={4} xl={4} md={4} lg={4}>
              <p className="table-header">LAST PAGE</p>
            </Grid>
          )}
        </Grid>
        {users?.map((user, index: number) => {
          return (
            <Grid container className="user-table-row" key={index}>
              <Grid
                item
                xs={4}
                xl={4}
                md={4}
                lg={4}
                container
                justifyContent="start"
                style={{ textAlign: "start", flexDirection: "column" }}
              >
                {detailsFormatter("", user)}
              </Grid>
              <Grid
                item
                xs={4}
                xl={2}
                md={2}
                lg={2}
                container
                justifyContent="end"
                style={{ textAlign: "end", flexDirection: "column" }}
              >
                {dateJoinedFormatter("", user)}
              </Grid>
              <Grid
                item
                xs={4}
                xl={2}
                md={2}
                lg={2}
                container
                justifyContent="center"
                style={{ textAlign: "center", flexDirection: "column" }}
              >
                {lastActivityFormatter("", user)}
              </Grid>
              {!isMobile && (
                <Grid item xs={4} xl={4} md={4} lg={4}>
                  {lastPageFormater("", user)}
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    </InfiniteScroll>
  ) : (
    <Loader loading={loading} color="#000" style={loaderStyle} />
  );
};

export default UsersTable;
