import React, { Component, CSSProperties } from "react";
import { Helmet } from "react-helmet";
import ContentLayout from '../Layouts/ContentLayout'
import { getFeedForWhiteLabel } from '../Lib/RESTBlox'
import axios from 'axios'
import ActivityFeed from '../Components/ActivityFeed'
import moment from 'moment'
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaSnapchatGhost,
} from 'react-icons/fa'

import { ReactComponent as RALogo } from '../Components/Admin/Analytics/images/ra_logo.svg'
import Loader from "../Components/Loader";

const loaderStyle: CSSProperties = {
  margin: 0,
  position: "relative",
  left: 0,
  display: "flex",
  justifyContent: "center",
};

type Props = {
  whiteLabel: any;
};


const About: React.FC<Props> = (props) => {

  const [data, setData] = React.useState<any>();
  const [next, setNext] = React.useState<any>();
  const [count, setCount] = React.useState<any>();
  const [primaryImage, setPrimaryImage] = React.useState<any>();
  const [feed, setFeed] = React.useState<any>([]);
  const [mounths, setMounths] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [genres, setGenres] = React.useState<any>([]);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [isPast, setIsPast] = React.useState<boolean>(false);

  const loadData = () => {
    setIsLoading(true)
    getFeedForWhiteLabel(props.whiteLabel.id, page, isPast?"past":"upcoming")
      .then((data: any) => {
        setIsLoading(false)
        setData(data);
        setNext(data.next);
        setCount(data.count);
        if(page > 1) data.result.shift()
        setFeed(feed.concat(data.result));
        setHasMore(data?.next ? true : false);
        setPage(page + 1);
        const dataToCompose = feed.concat(data.result)
        let newState: Record<any, any> = [];
        dataToCompose.map((item: any, i: any, arr: any) => {
          if (!newState[moment(item.date).format("MMMM YYYY")]) {
            newState[moment(item.date).format("MMMM YYYY")] = []
            newState[moment(item.date).format("MMMM YYYY")].push(item)
          } else {
            newState[moment(item.date).format("MMMM YYYY")].push(item)
          }
        })
        setMounths(newState);
      }).then(() => {
        // document.addEventListener("scroll", infinityScroll)
        // compareDate()
      })
  }

  const addTypedContent = () => {
    var image = ''
    var genres = []
    if (props.whiteLabel.type === 'artist') {
      image = props.whiteLabel.artist.image
      genres = props.whiteLabel.artist.genres
    }
    if (props.whiteLabel.type === 'club') {
      image = ''
      genres = props.whiteLabel.club.genres
    }
    if (props.whiteLabel.type === 'promoter') {
      image = props.whiteLabel.promoter.image
      genres = props.whiteLabel.promoter.genres
    }
    setPrimaryImage(image);
    setGenres(genres);
  }

  React.useEffect(() => {
      loadData()
      addTypedContent()
  }, [props.whiteLabel]);

  React.useEffect(() => {
      setData([])
      setMounths([])
      setIsLoading(true)
      getFeedForWhiteLabel(props.whiteLabel?.id, 1 ,isPast?"past":"upcoming")
      .then((data: any) => {
        setIsLoading(false)
        setData(data);
        setNext(data.next);
        setCount(data.count);
        setFeed(data.result);
        setHasMore(data?.next ? true : false);
        setPage(2);
        const dataToCompose = data.result
        let newState: Record<any, any> = [];
        dataToCompose.map((item: any, i: any, arr: any) => {
          if (!newState[moment(item.date).format("MMMM YYYY")]) {
            newState[moment(item.date).format("MMMM YYYY")] = []
            newState[moment(item.date).format("MMMM YYYY")].push(item)
          } else {
            newState[moment(item.date).format("MMMM YYYY")].push(item)
          }
        })
        setMounths(newState);
      })
      addTypedContent()
  }, [isPast]);

  const whiteLabel = props.whiteLabel
  var title;
  if (whiteLabel.title !== '') {
    title = (
      <div>
        <span>{whiteLabel.title}</span>
        {whiteLabel.type === 'club' && !(whiteLabel.id === 27) &&
          <span className="whiteLabelType">Venue</span>
        }
        {whiteLabel.type === 'artist' &&
          <span className="whiteLabelType">Artist</span>
        }
        {whiteLabel.type === 'promoter' &&
          <span className="whiteLabelType">Promoter</span>
        }
        {whiteLabel.id === 27 &&
          <span className="whiteLabelType">Event</span>
        }
        {whiteLabel.social_links?.map((link: any, i: any) => {
          return (
            <a href={link.url} key={i} className="a-social-media">
              {link.service === 'twitter' && <FaTwitter />}
              {link.service === 'facebook' && <FaFacebook />}
              {link.service === 'instagram' && <FaInstagram />}
              {link.service === 'snapchat' && <FaSnapchatGhost />}
              {link.service === 'resident-advisor' && <RALogo />}
            </a>
          )
        })}
      </div>
    )
  }

  if (props.whiteLabel.id !== 2) {
    return (
      props.whiteLabel &&
      <ContentLayout
        title={title}
        type={whiteLabel.type}
        shortDescription={whiteLabel.short_description}
        longDescription={whiteLabel.description}
        image={primaryImage}
        venue={whiteLabel.club}
        tags={genres}
      >
        <Helmet key={window.location.href}>
          <title>The Blox Office</title>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="The Blox Office" />
          <meta property="og:description" content="Your Home for Underground Dance Music" />
          <meta name="facebook-domain-verification" content="exka8nix6e1dzjftgacmgds45gu5dw" />
        </Helmet>
        {
          feed.length > 0 && Object.keys(mounths).length >0 ?
          <div style={{width:"100%"}}>
            <InfiniteScroll
              dataLength={feed?.length}
              next={loadData}
              hasMore={hasMore}
              loader={isLoading ? <Loader loading={isLoading} color="#000" style={loaderStyle} /> : <></>}
            >
              <ActivityFeed
                title="What's happening"
                count={count}
                next={next}
                feed={feed}
                mounths={mounths}
                tenseSelector={isPast?"View Upcoming Events":"View Past Events"}
                changeTense={()=>{setIsPast(!isPast)}}
              />
            </InfiniteScroll>
          </div>
          :
          <Loader loading={isLoading} color="#000" />
        }
      </ContentLayout>
    )
  }

  if (props.whiteLabel.id === 2) {
    return (
      props.whiteLabel &&
      <ContentLayout
        title={title}
        type={whiteLabel.type}
        shortDescription={whiteLabel.short_description}
        longDescription={whiteLabel.description}
        image={primaryImage}
        venue={whiteLabel.club}
        tags={genres}
      >
      </ContentLayout>
    )
  }
}

export default About;
