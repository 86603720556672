import React from 'react';
import { Row, Col } from 'reactstrap';

export default class EventDetailsDescription extends React.Component {

  render() {
    return (
      <Row>
        <Col xs="12" lg="3" className="BloxEventDetailsHeader offset-md-1 offset-lg-1">
          { this.props.header !== '' &&
            <h4>{ this.props.header }</h4>
          }
        </Col>
        <Col
          xs="11"
          lg="7"
          className="BloxEventDetailsContent BloxEventDescription offset-md-1 offset-lg-0"
          dangerouslySetInnerHTML={{__html: this.props.content}}
        />
      </Row>
    );
  }
}
