import React from 'react';
import Cleave from 'cleave.js/react';
import { FormGroup, Input, Label, FormText } from "reactstrap";
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import DropdownList from 'react-widgets/lib/DropdownList'
import Combobox from 'react-widgets/lib/Combobox'
import Multiselect from 'react-widgets/lib/Multiselect'
import {
  getClubs,
  getTimeTypes,
  getTicketTypes,
  getPromoters,
  getResidentArtistsForWhiteLabel,
  getAdminEvents,
  getPromoCodesForEvent
} from '../../Lib/RESTBlox'
import VenueModal from '../Admin/Modals/VenueModal';

require('cleave.js/dist/addons/cleave-phone.i18n');

// STANDARD FORM FIELD
const FormField = ({
  field, // { name, type, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props // { label, required, placeholder }
}) => (
  <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
    <Label for={field.name}>
      {props.label}
      {props.required ?
        <span className="required">*</span>
        : <span className="optional">Optional</span>
      }
    </Label>
    <Input {...field} {...props} value={field.value}/>
    <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
      {touched[field.name] && errors[field.name] ?
        <span>{errors[field.name]}</span>
        : <span>&nbsp;</span>
      }
    </div>
    {props.help &&
      <FormText>{props.help}</FormText>
    }
  </FormGroup>
);



// STANDARD FORM CHECKBOX
// const FormCheckbox = ({
//   field, // { name, value, onChange, onBlur }
//   form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
//   ...props
// }) => (
//   <FormGroup check>
//     <Label check>
//       <Input
//         type="checkbox"
//         {...field}
//         {...props}
//         checked={field.value}
//       />
//       {props.label}
//     </Label>
//     <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
//       {touched[field.name] && errors[field.name] ?
//         <span>{errors[field.name]}</span>
//         : <span>&nbsp;</span>
//       }
//     </div>
//     {props.help &&
//       <FormText>{props.help}</FormText>
//     }
//   </FormGroup>
// );



// DATETIMEPICKER WIDGET
class FormDateTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: this.props.field.value};
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    console.log(this.props.field.name, value)
    this.props.form.setFieldValue(this.props.field.name, value)
    return {value}
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={'DateTimePicker ' + field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <DateTimePicker
          time={true}
          name={field.name}
          onChange={this.handleChange}
          placeholder={props.placeholder}
          value={field.value}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}



// DATEPICKER WIDGET
class FormDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: this.props.field.value};
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value)
    return {value}
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors

    if (typeof field.value === 'string') {
      var date = new Date(field.value)
      field.value = date
    }

    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <DateTimePicker
          time={false}
          name={field.name}
          onChange={this.handleChange}
          placeholder={props.placeholder}
          value={field.value}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}



// TIME PICKER WIDGET
class FormTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: this.props.field.value};
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value)
    return {value}
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    
    if (typeof field.value === 'string') {
      var date = new Date(field.value)
      field.value = date
    }

    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <DateTimePicker
          date={false}
          name={field.name}
          onChange={this.handleChange}
          placeholder={props.placeholder}
          value={field.value}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}


// GENDER SELECT WIDGET

const genderOptions = [
  { value: 'F', label: 'Female'},
  { value: 'M', label: 'Male'},
  { value: 'NB', label: 'Non-binary'},
]

class FormGenderPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: this.props.field.value};
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value.value)
    return {value}
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <DropdownList
          name={field.name}
          textField='label'
          valueField='value'
          data={genderOptions}
          onChange={this.handleChange}
          placeholder={props.placeholder}
          value={this.props.field.value}
          required={true}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}



// AGE LIMIT SELECT WIDGET

const ageLimitOptions = [
  { value: '0', label: 'All ages'},
  { value: '18', label: '18+'},
  { value: '21', label: '21+'},
]

class FormAgeLimitPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: this.props.field?.value?.value};
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value?.value)
    return {value}
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <DropdownList
          name={field.name}
          textField='label'
          valueField='value'
          data={ageLimitOptions}
          onChange={this.handleChange}
          placeholder={props.placeholder}
          value={this.props.field?.value}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}


// VENUE SELECT WIDGET
class FormVenuePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.field.value,
      venues: this.getVenues()
    };
    this.handleChange = this.handleChange.bind(this)
    this.handleNewVenue = this.handleNewVenue.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value.value)
    return {value}
  }

  getVenues() {
    getClubs()
      .then(data => {
        var venues = data.results.map((venue, i) => {
          return {
            value: venue.id,
            label: venue.name + ', ' + venue.address,
          }
        })
        this.setState({venues: venues})
      })
      .catch(err => console.error(this.props.url, err.toString()))
  }

  handleNewVenue(venue) {
    var new_venue = {
      value: venue.id,
      label: venue.name + ', ' + venue.address,
    }
    this.setState({
      venues: [...this.state.venues, new_venue]
    })
    this.handleChange({value: venue.id})
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <Combobox
          name={field.name}
          textField='label'
          valueField='value'
          data={this.state.venues}
          onChange={this.handleChange}
          caseSensitive={false}
          minLength={3}
          filter='startsWith'
          suggest={true}
          placeholder={props.placeholder}
          value={field.value}
        />
        <FormText>
          Don't see your venue?
          <VenueModal
            buttonText = "Add venue"
            block = {false}
            outline = {false}
            handleNewVenue = {this.handleNewVenue}
          />
          {props.help}
        </FormText>
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}



// EVENT SELECT WIDGET
class FormEventPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.field.value,
      events: this.getEventOptions()
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value.value)
    return {value}
  }

  getEventOptions() {
    getAdminEvents()
      .then(data => {
        var events = data.results.map((event, i) => {
          return {
            value: event.id,
            label: event.__str__ + ', ' + event.date,
          }
        })
        this.setState({
          events: events
        })

        // If we were passed an initial value, set it.
        if (this.props.field.value) {
          this.props.form.setFieldValue(this.props.field.name, parseInt(this.props.field.value))
        }
      })
      .catch(err => console.error(this.props.url, err.toString()))
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <Combobox
          name={field.name}
          textField='label'
          valueField='value'
          data={this.state.events}
          onChange={this.handleChange}
          caseSensitive={false}
          minLength={3}
          filter='startsWith'
          suggest={true}
          placeholder={props.placeholder}
          value={this.props.field.value}
          disabled={props.disabled}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}




// TIME TYPE SELECT WIDGET
class FormTimeTypePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.field.value,
      timeTypes: this.getTypes()
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value.value)
    return {value}
  }

  getTypes() {
    getTimeTypes()
      .then(data => {
        var timeTypes = data.results.map((timeType, i) => {
          return {
            value: timeType.id,
            label: timeType.name,
          }
        })
        this.setState({timeTypes: timeTypes})
            if(this.props.field.name === 'time1_type') {
              this.props.form.setFieldValue(this.props.field.name, 9)
          } else {
              this.props.form.setFieldValue(this.props.field.name, 8)
          }
      })
      .catch(err => console.error(this.props.url, err.toString()))
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <DropdownList
          name={field.name}
          textField='label'
          valueField='value'
          data={this.state.timeTypes}
          onChange={this.handleChange}
          placeholder={props.placeholder}
          value={field.value}
          disabled={props.disabled}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}



// TICKET TYPE SELECT WIDGET
class FormTicketTypePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.field.value,
      ticketTypes: this.getTicketTypeOptions()
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    if (typeof value  === 'string') {
      this.props.form.setFieldValue(this.props.field.name, value)
    } else {
      this.props.form.setFieldValue(this.props.field.name, value.value)
    }
    return {value}
  }

  getTicketTypeOptions() {
    getTicketTypes()
      .then(data => {
        var ticketTypes = data.results.map((ticketType, i) => {
          return {
            value: ticketType.id,
            label: ticketType.name,
          }
        })
        this.setState({ticketTypes: ticketTypes})
      })
      .catch(err => console.error(this.props.url, err.toString()))
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <Combobox
          name={field.name}
          textField='label'
          valueField='value'
          data={this.state.ticketTypes}
          onChange={this.handleChange}
          placeholder={props.placeholder}
          value={field.value}
          disabled={props.disabled}
          required={props.required}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}




// PROMOTER SELECT WIDGET
class FormPromoterPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      promoters: this.getItems()
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value)
    return {value}
  }

  handleCreate(name) {
    var { promoters, value } = this.state;

    let newOption = {
      label: name,
      value: promoters.length + 10000000 // Arbitrary large number to ensure it won't collide with existing IDs
    }

    value = [...value, newOption]
    promoters = [...promoters, newOption]

    this.setState({
      value: value,  // select new option
      promoters: promoters // add new option to our dataset
    })

    // Set the field value
    this.props.form.setFieldValue(this.props.field.name, value)
  }

  getItems() {
    getPromoters()
      .then(data => {
        var promoters = data.map((promoter, i) => {
          return {
            value: promoter.id,
            label: promoter.name,
          }
        })
        this.setState({promoters: promoters})
      })
      .catch(err => console.error(this.props.url, err.toString()))
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <Multiselect
          name={field.name}
          textField='label'
          valueField='value'
          data={this.state.promoters}
          onChange={this.handleChange}
          onCreate={name => this.handleCreate(name)}
          filter='startsWith'
          placeholder={props.placeholder}
          value={field.value}
          disabled={props.disabled}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}



// ARTIST SELECT WIDGET
class FormArtistPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      artists: this.getItems()
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value)
    return {value}
  }

  handleCreate(name) {
    var { artists, value } = this.state;

    let newOption = {
      label: name,
      value: artists.length + 10000000 // Arbitrary large number to ensure it won't collide with existing IDs
    }

    value = [...value, newOption]
    artists = [...artists, newOption]

    this.setState({
      value: value,  // select new option
      artists: artists // add new option to our dataset
    })

    // Set the field value
    this.props.form.setFieldValue(this.props.field.name, value)
  }

  getItems() {
    getResidentArtistsForWhiteLabel()
      .then(data => {
        var artists = data.results?.map((artist, i) => {
          return {
            value: artist.id,
            label: artist.name,
          }
        })
        this.setState({artists: artists})
      })
      .catch(err => console.error(this.props.url, err.toString()))
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <Multiselect
          name={field.name}
          textField='label'
          valueField='value'
          data={this.state.artists}
          onChange={this.handleChange}
          onCreate={name => this.handleCreate(name)}
          filter='startsWith'
          placeholder={props.placeholder}
          value={field.value}
          disabled={props.disabled}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}


// PROMO CODE MULTISELECT WIDGET
class FormPromoCodePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      promoCodes: this.getItems()
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value)
    return {value}
  }

  getItems() {
    getPromoCodesForEvent(this.props.eventId)
      .then(data => {
        var promoCodes = data.results.map((promoCode, i) => {
          return {
            value: promoCode.id,
            label: promoCode.code,
          }
        })
        this.setState({promoCodes: promoCodes})
      })
      .catch(err => console.error(this.props.url, err.toString()))
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <Multiselect
          name={field.name}
          textField='label'
          valueField='value'
          data={this.state.promoCodes}
          onChange={this.handleChange}
          filter='startsWith'
          placeholder={props.placeholder}
          value={field.value}
          disabled={props.disabled}
          eventId={props.eventId}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}


// EVENT MULTISELECT WIDGET
class FormMultiEventPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      events: this.getItems()
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.form.setFieldValue(this.props.field.name, value)
    return {value}
  }

  getItems() {
    getAdminEvents()
      .then(data => {
        var events = data.map((event, i) => {
          return {
            value: event.id,
            label: event.date_display + ' — ' + event.__str__,
          }
        })
        this.setState({events: events})
      })
      .catch(err => console.error(this.props.url, err.toString()))
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <Multiselect
          name={field.name}
          textField='label'
          valueField='value'
          data={this.state.events}
          onChange={this.handleChange}
          filter='startsWith'
          placeholder={props.placeholder}
          value={field.value}
          disabled={props.disabled}
        />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}




// FILE UPLOAD WIDGET
class FormFileUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: []
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.props.form.setFieldValue(this.props.field.name, event.currentTarget.files[0])
    return {event}
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
        <Label for={field.name}>
          {props.label}
          {props.required ?
            <span className="required">*</span>
            : <span className="optional">Optional</span>
          }
        </Label>
        <Input
          type="file"
          onChange={this.handleChange}
          {...props}
          />
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}


// STANDARD FORM CHECKBOX WIDGET
class FormCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.setState({
      value: value
    })
    this.props.form.setFieldValue(this.props.field.name, value)
    return {value}
  }

  componentDidMount() {
    this.setState({
      value: this.props.form.values[this.props.field.name]
    })
  }

  render() {
    const field   = this.props.field
    const props   = this.props
    const touched = this.props.form.touched
    const errors  = this.props.form.errors
    return (
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            {...field}
            {...props}
            checked={this.props.form.values[this.props.field.name]}
          />
          {props.label}
        </Label>
        <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
          {touched[field.name] && errors[field.name] ?
            <span>{errors[field.name]}</span>
            : <span>&nbsp;</span>
          }
        </div>
        {props.help &&
          <FormText>{props.help}</FormText>
        }
      </FormGroup>
    );
  }
}


// TEXT ENTRY DATE WIDGET
const FormTextDateField = ({
  field, // { name, type, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props // { label, required, placeholder }
}) => (
  <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
    <Label for={field.name}>
      {props.label}
      {props.required ?
        <span className="required">*</span>
        : <span className="optional">Optional</span>
      }
    </Label>
    <Cleave placeholder="MM/DD/YYYY"
      options={{
        date: true,
        datePattern: ['m', 'd', 'Y']
      }}
      onChange={field.onChange}
      value={field.value}
      {...field} {...props}
      className="form-control"
    />
    <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
      {touched[field.name] && errors[field.name] ?
        <span>{errors[field.name]}</span>
        : <span>&nbsp;</span>
      }
    </div>
    {props.help &&
      <FormText>{props.help}</FormText>
    }
  </FormGroup>
);


// TEXT ENTRY PHONE NUMBER WIDGET
const FormTextPhoneNumberField = ({
  field, // { name, type, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props // { label, required, placeholder }
}) => (
  <FormGroup className={field.name + ' ' + (errors[field.name] ? 'invalid' : '')}>
    <Label for={field.name}>
      {props.label}
      {props.required ?
        <span className="required">*</span>
        : <span className="optional">Optional</span>
      }
    </Label>
    <Cleave placeholder="MM/DD/YYYY"
      options={{
        phone: true,
        phoneRegionCode: 'US',
        delimiter: '-'
      }}
      onChange={field.onChange}
      value={field.value}
      {...field} {...props}
      className="form-control"
    />
    <div className={touched[field.name] && errors[field.name] ? 'error active' : 'error inactive'}>
      {touched[field.name] && errors[field.name] ?
        <span>{errors[field.name]}</span>
        : <span>&nbsp;</span>
      }
    </div>
    {props.help &&
      <FormText>{props.help}</FormText>
    }
  </FormGroup>
);


export {
  FormField,
  FormCheckbox,
  FormDateTimePicker,
  FormDatePicker,
  FormTimePicker,
  FormGenderPicker,
  FormAgeLimitPicker,
  FormVenuePicker,
  FormEventPicker,
  FormTimeTypePicker,
  FormTicketTypePicker,
  FormPromoterPicker,
  FormArtistPicker,
  FormPromoCodePicker,
  FormMultiEventPicker,
  FormFileUploadField,
  FormTextDateField,
  FormTextPhoneNumberField
};
