import React from "react";
import { Row, Col } from 'reactstrap'

type Props = {
};

const Explore: React.FC<Props> = (props) => {

    return (
      <Row className="clearHeader"> 
        <Col>
          <iframe src="http://primarychi-explore.s3-website-us-east-1.amazonaws.com/" width="600" height="400" title="Explore Primary Night Club"></iframe>
        </Col>
      </Row>
    );
}

export default Explore;
