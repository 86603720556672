import React from "react";
import GoogleMapComponentWithMarker from "../../../../Components/Maps/Map";

type Props = {
  order: any;
};
const OrderMap: React.FC<Props> = ({ order }) => {
  if (order && order[0]?.event?.venue) return (
    <div
      style={{
        backgroundColor: "#333333",
        height: "120px",
        width: "100%",
        overflow: "hidden",
        opacity: 1,
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      {order && order[0]?.event?.venue && (
        <GoogleMapComponentWithMarker
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAE2bGtUX6D7BvusIGxqpWSCLANsBpYEVE"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `155px`, marginBottom: `0` }} />}
          venue={order[0]?.event?.venue}
        />
      )}
    </div>
  );

  return <></>
};
export default OrderMap;
