import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class RouteNotFound extends Component {
  render() {
    return (
      <Row className="clearHeader">
        <Col>
          <h3>There is no view with that URL.</h3>
        </Col>
      </Row>
    );
  }
}

export default RouteNotFound;
