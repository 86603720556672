// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

const awsPath = "/Prod";
export const rootPath = window.location.href.includes(awsPath)
  ? `${awsPath}/`
  : "/";

const routes = {
  HOME: `${rootPath}`,
  RESET_PASSWORD: `${rootPath}reset-password/:uid/:token`,
  ABOUT: `${rootPath}about`,
  HOME_PAGE: `${rootPath}homepage`,
  EVENT_CALENDAR: `${rootPath}events/calendar`,
  ARTIST: `${rootPath}artists`,
  ARTIST_SLUG: `${rootPath}artists/:slug`,
  PAGE_SLUG: `${rootPath}page/:slug`,
  PRESS: `${rootPath}press/:id`,
  EXPLORE: `${rootPath}explore`,
  GALLERY: `${rootPath}gallery`,
  EVENT_GALLERY: `${rootPath}event-gallery/:id`,
  CUNTACT_US: `${rootPath}contact-us/`,
  TABLE_RESERVATIONS: `${rootPath}table-reservations/`,
  ONBOARDING_ID: `${rootPath}onboarding/:id`,
  ONBOARDING: `${rootPath}onboarding`,
  ONBOARDING_TERMS: `${rootPath}onboarding-terms`,
  EVENT_PURCHASE: `${rootPath}events/:id/purchase`,
  EVENT_PURCHASE_ORDER: `${rootPath}events/:id/purchase/:orderId`,
  PURCHASE_RECEIPT: `${rootPath}purchase/receipt/:orderId`,
  ORDERS: `${rootPath}orders`,
  ORDER_DETAILS: `${rootPath}orders/:id`,
  TICKETS: `${rootPath}tickets`,
  TICKETS_DETAILS: `${rootPath}tickets/:id`,
  CHANGE_PROFILE: `${rootPath}change-profile`,
  CHANGE_PASSWORD: `${rootPath}change-password`,
  FAN_CAMPAIN: `${rootPath}fan-campaign/:campaignId/:eventId/:orderId`,
  ADMIN_HOME: `${rootPath}admin`,
  ADMIN_EVENTS_UPCOMING: `${rootPath}admin/events/upcoming`,
  ADMIN_EVENTS_PAST: `${rootPath}admin/events/past`,
  ADMIN_SITE_EDIT: `${rootPath}admin/site/edit/`,
  ADMIN_EVENT_ADD: `${rootPath}admin/events/add`,
  ADMIN_EVENT_EDIT: `${rootPath}admin/events/edit/:eventId`,
  ADMIN_EVENT_DUPLICATE: `${rootPath}admin/events/duplicate/:eventId`,
  ADMIN_EVENT_DETAILS: `${rootPath}admin/events/:eventId`,
  ADMIN_EVENT_TICKETS_INVENTORY: `${rootPath}admin/events/ticket-inventories/add`,
  ADMIN_EVENT_TICKETS_INVENTORY_ADD: `${rootPath}admin/events/ticket-inventories/add/:eventId`,
  ADMIN_EVENT_TICKETS_INVENTORY_EDIT: `${rootPath}admin/events/ticket-inventories/edit/:ticketInventoryId`,
  ADMIN_PROMOCODES: `${rootPath}admin/promo-codes/`,
  ADMIN_PROMOCODES_ADD: `${rootPath}admin/promo-codes/add/`,
  ADMIN_PROMOCODES_EDIT: `${rootPath}admin/promo-codes/edit/:promoCodeId`,
  ADMIN_EVENTS_GUESLIST: `${rootPath}admin/events/guestlist/:eventId`,
  ADMIN_VENUES: `${rootPath}admin/venues/`,
  ADMIN_VENUES_ADD: `${rootPath}admin/venues/add`,
  ADMIN_ORDERS: `${rootPath}admin/orders/`,
  ADMIN_ORDERS_DETAILS: `${rootPath}admin/orders/:id`,
  ADMIN_USERS: `${rootPath}admin/users/`,
  ADMIN_USERS_DETAILS: `${rootPath}admin/users/:id`,
  ADMIN_ORDERS_BY_EVENT: `${rootPath}admin/orders/events`
};

export default routes;
