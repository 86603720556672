import React, { useEffect, MouseEventHandler } from "react";
import { Label } from "reactstrap";
import { getAdminEvents, getArtists } from "../../../../../Lib/RESTBlox";
import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from "react-select";

import { AsyncPaginate } from "react-select-async-paginate";

import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from "react-sortable-hoc";

//this option is sorteable////////////////
function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement((props: MultiValueProps) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(AsyncPaginate);

type Props2 = {
  field: any;
  form: any;
  label: boolean;
  required: boolean;
  help: any;
  placeholder: any;
  disabled: any;
};

const FormMultiEventPicker: React.FC<Props2> = ({
  field,
  form,
  label,
  required,
  help,
  placeholder,
  disabled,
}) => {

  const onChange = (selectedOptions: any) => setValue(selectedOptions);

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(value, oldIndex, newIndex);
    setValue(newValue);
    form.setFieldValue(field.name, value);
    console.log(
      "Values sorted:",
      newValue.map((i: any) => i.value)
    );
    return { value };
  };
  const [value, setValue] = React.useState<any>(field.value);
  const [events, setEvents] = React.useState<any>();

  const handleChange = (value: any) => {
    setValue(value);
    form.setFieldValue(field.name, value);
    return { value };
  };

  const handleCreate = (name: any) => {
    let newOption = {
      label: name,
      value: events.length + 10000000, // Arbitrary large number to ensure it won't collide with existing IDs
    };

    const newValue = [...value, newOption];
    const newArtists = [...events, newOption];

    setValue(newValue);
    setEvents(newArtists);

    // Set the field value
    form.setFieldValue(field.name, value);
  };

  async function loadOptions(search: any, loadedOptions: any, { page }: any) {
    const data = await getAdminEvents('Upcoming')
    var events = data.results.map((event: any, i: number) => {
        return {
          value: event.id,
          label: event.date_display + " — " + event.__str__,
        };
      });
    setEvents(events);
    return {
      options: events,
      hasMore: data.next? true:false,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <>
      <Label for={field.name}>
        {label}
        {required ? (
          <span className="required">*</span>
        ) : (
          <span className="optional">Optional</span>
        )}
      </Label>
      <SortableSelect
        useDragHandle
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        loadOptions={loadOptions}
        additional={{
          page: 1,
        }}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti
        options={events}
        value={value}
        onChange={handleChange}
        components={{
          MultiValue: SortableMultiValue,
          MultiValueLabel: SortableMultiValueLabel,
        }}
        closeMenuOnSelect={false}
      />
    </>
  );
};

export default FormMultiEventPicker;
