import React from 'react';
import { Row, Col } from 'reactstrap';

export default class EventDetailsRow extends React.Component {

  render() {
    return (
      <Row>
        <Col xs="4" md="3" className="BloxEventDetailsHeader offset-md-1">
          { this.props.header !== '' &&
            <h4>{ this.props.header }</h4>
          }
        </Col>
        <Col xs="8" className="BloxEventDetailsContent">
          { this.props.content !== '' &&
            <h4>{ this.props.content }</h4>
          }
          { this.props.secondaryContent !== '' &&
            <h5>{ this.props.secondaryContent }</h5>
          }
        </Col>
      </Row>
    );
  }
}
