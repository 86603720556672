import React from "react";
import {getFeedForWhiteLabel} from '../Lib/RESTBlox'
import ActivityFeed from '../Components/ActivityFeed'
import axios from "axios";
import moment from "moment";

type Props = {
  whiteLabel: any;
  changeIsLoading: any;
};

const EventCalendarMobile: React.FC<Props> = (props) => {

const [next, setNext] = React.useState<any>();
const [count, setCount] = React.useState<any>();
const [feed, setFeed] = React.useState<any>({});
const [mounths, setMounths] = React.useState<any>({});
const [isLoading, setIsLoading] = React.useState<boolean>(true);


const compareDate = () => {
  let newState : any;
  feed.map((item:any, i:any, arr:any) => {
    if(!newState[moment(item.date).format("MMMM YYYY")]){
      newState[moment(item.date).format("MMMM YYYY")] = []
      newState[moment(item.date).format("MMMM YYYY")].push(item)
    }else{
      newState[moment(item.date).format("MMMM YYYY")].push(item)
    }
  })
  setMounths(newState);
}

const infinityScroll = (e:any) => {
  let distance = - document.getElementsByClassName("BloxContentRow")[0].getBoundingClientRect().top
  let bottom = document.getElementsByClassName("BloxContentRow")[0].getBoundingClientRect().height - document.documentElement.clientHeight
  if(!isLoading && count > feed.length && bottom - distance < 500){
    setIsLoading(true);
    axios.get(next).then(resp => {
      setIsLoading(false);
      return resp.data
    }).then(data => {
      if (typeof data != 'object')
        data = {};
      if (!data.result)
        data.result = [];
        setFeed([...feed, ...data.result]);
        setNext(data.next);
    }).then(() => {
      compareDate()
    })
  }
}

  const loadData = () => {
    getFeedForWhiteLabel(props.whiteLabel.id)
    .then((data:any) => {
      setNext(data.next);
      setCount(data.count);
      setFeed(data.result);
    }).then(() => {
      compareDate()
    })
    setIsLoading(false);
  }

  React.useEffect(() => {
    setIsLoading(true);
    props.changeIsLoading(isLoading);
    loadData()
  }, []);

    return (
      <ActivityFeed
        title="Calendar"
        count={count}
        next={next}
        feed={feed}
        mounths={mounths}
      />
    )
}

export default EventCalendarMobile
