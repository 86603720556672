import React from 'react'
import {
  Card,
  CardFooter
} from 'reactstrap'

const ArtistTile = (props) => {
  return (
      <Card className="ArtistTile">
        <div className="cardImage" style={{
            backgroundImage: 'url(' + props.artist.image + ')',
          }}
        />
        <CardFooter>
          <span className="title">
            {props.artist.name}
          </span>
          <div
            className="description"
            dangerouslySetInnerHTML={{__html: props.artist.description_html}}
          />
        </CardFooter>
      </Card>
  );
};

export default ArtistTile;
