import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { FormField, FormCheckbox } from "./Fields";
import { transferTicket } from "../../Lib/RESTBlox";
import { validateEmail } from "../../Lib/FormValidation";
import FlashAlert from "../FlashAlert";
import Header from "../Header";
import Loader from "../Loader";

class TicketTransferForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {this.props.submissionComplete && (
          <Row>
            <Col>
              <FlashAlert
                color="success"
                text="An email has been sent to your friend with instructions on how to accept their ticket."
              />
            </Col>
          </Row>
        )}
        {this.props.submissionError && (
          <Row>
            <Col>
              <FlashAlert
                color="danger"
                text={this.props.submissionErrorMessage}
              />
            </Col>
          </Row>
        )}
        {this.props.ticket.holder?.email !== this.props.user?.email && (
          <p>
            You no longer hold this ticket, so you are not able to transfer it
            to a friend.
          </p>
        )}
        {this.props.ticket.holder?.email === this.props.user?.email && (
          <div>
            <Header content="Transfer ticket to a friend" />
            <Formik
              initialValues={{ email: "", agreeToTerms: false }}
              onSubmit={(values, actions) => {
                this.props.handleTicketTransfer(values);
                actions.setSubmitting(false);
              }}
              render={(props) => (
                <Form className="BloxForm">
                  <Row>
                    <Col>
                      <Field
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Your friend's email address..."
                        component={FormField}
                        required={true}
                        validate={validateEmail}
                      />
                    </Col>
                  </Row>
                  <Field
                    label={
                      <span>
                        I understand that all ticket transfers are final and
                        that I am soley responsible for collecting any
                        negotiated payment from the recipient.
                      </span>
                    }
                    type="checkbox"
                    name="agreeToTerms"
                    component={FormCheckbox}
                    required={true}
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Required";
                      } else if (value === false) {
                        error = "Required";
                      }
                      return error;
                    }}
                  />
                  <Row>
                    <Col>
                      {!this.props.loading ? (
                        <Button
                          className="hvr-grow-shadow"
                          color="primary"
                          block
                          type="submit"
                        >
                          Transfer ticket
                        </Button>
                      ) : (
                        <Loader color="#000" loading={true} />
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            />
          </div>
        )}
      </div>
    );
  }
}

export default TicketTransferForm;
