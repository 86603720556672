import { Box, Paper } from "@mui/material";

type Props = {
    leftText: string,
    rightText: string,
};

const HeaderBox = (props: Props) => {
    const { leftText, rightText } = props;

    return (
        <Paper style={
            {
                backgroundColor: '#2c2f2f',
                display: 'flex',
                flexDirection: 'row',
                border: 0,
                borderRadius: 0,
                paddingTop: 16,
                paddingLeft: 8,
                paddingBottom: 16,
                paddingRight: 8,
                textAlign: 'left',
            }
        }>
            <Box sx={{
                width: '100%',
                fontSize: '1.5rem',
                fontWeight: 700,
                color: '#FFFFFF',
            }}>
                {leftText}
            </Box>
            <Box sx={{
                fontSize: '1.25rem',
                fontWeight: 700,
                color: '#CCCCCC'
            }}>
                {rightText}
            </Box>
        </Paper>
    );
};

export default HeaderBox;