import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import PasswordResetForm from '../Components/Forms/PasswordResetForm'

class PasswordReset extends Component {
  render() {
    return (
      <Row className="clearHeaderAndButtonGroup">
        <Col>
          <PasswordResetForm />
        </Col>
      </Row>
    );
  }
}

export default PasswordReset;
