import React, { useEffect } from "react";
import EventForm from "../Forms/EventForm/EventForm";
import { getAdminEvent, getSubpromoterEventData } from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";
import { Row, Col } from "reactstrap";
import EventAnalyticsPanel from "../../Components/Admin/Analytics/EventAnalyticsPanel";
import Loader from "../../Components/Loader";
import Header from "../../Components/Header";
import EventMap from "./EventMap/EventMap";
import TraficMetrics from "./TraficMetrics/TraficMetrics";
import GeneralMetrics from "./GeneralMetrics/GeneralMetrics";
import { FaGenderless } from "react-icons/fa";
import MetricsOptions from "./MetricsOptions/MetricsOptions";
import EventPicAndDetail from "./EventPicAndDetail/EventPicAndDetail";
import { useParams } from "react-router-dom";

type Props = {
  user: any;
};

const EventPromoterDetails: React.FC<Props> = ({ user }) => {
  const [event, setEvent] = React.useState<any>();
  const [eventSubpromoterData, setEventSubpromoterData] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const { eventId, subpromoterId } = useParams();

  useEffect(() => {
    getAdminEvent(eventId as string, true)
      .then((data) => {
        getSubpromoterEventData(subpromoterId as string).then((data: any) => {
          setEventSubpromoterData(data);
        });
        setEvent(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  return (
    <AdminLayout title="Event Link">
      <div className="BloxAdminContentContainer BloxAdminEventDetails">
        <Loader loading={loading} color="#000" />
        <Row>
          <Col>
            <Header content="subpromoter overview" />
            {event && eventSubpromoterData&&(
              <Row
                className="BloxAdminEventDetailsHeader"
                style={{ backgroundColor: "#000" }}
              >
                <EventPicAndDetail event={event} />
                <Col
                  xs={12}
                  md={8}
                  className="rightColumn"
                  style={{ alignSelf: "center" }}
                >
                  <Row>
                    <Col>
                      <MetricsOptions user={user} event={event} />
                      <GeneralMetrics
                        event={event}
                        genderIcon={<FaGenderless />}
                        eventSubpromoterData={eventSubpromoterData}
                      />
                      <TraficMetrics
                        event={event}
                        eventSubpromoterData={eventSubpromoterData}
                      />
                      <EventMap event={event} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </AdminLayout>
  );
};

export default EventPromoterDetails;
