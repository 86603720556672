import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { FormField } from "./Fields";
import { resetPassword } from "../../Lib/RESTAuth";
import FlashAlert from "../FlashAlert";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

const PasswordResetForm: React.FC<Props> = (props) => {
  const [submissionComplete, setSubmissionComplete] = React.useState(false);
  const [alert, setAlert] = React.useState({
    type: "",
    message: "",
  });
  const params = useParams();
  const navigate = useNavigate();
  
  const handleResetPassword = (data: any) => {
    data.uid = params.uid;
    data.token = params.token;
    console.log(params);
    resetPassword(data)
      .then((json) => {
        console.log("heliiiiiiiiiiiiiiii");

        setSubmissionComplete(true);
        setAlert({
          type: "success",
          message: "Your password has been reset.",
        });
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          type: "danger",
          message: err.response.errorMessage,
        });
      });
  };

  return (
    <div>
      {alert.message && (
        <Row>
          <Col>
            <FlashAlert
              color={alert.type}
              text={alert.message}
            />
          </Col>
        </Row>
      )}
      <Formik
        initialValues={{ new_password1: "", new_password2: "" }}
        onSubmit={(values, actions) => {
          handleResetPassword(values);
          actions.setSubmitting(false);
        }}
        render={(props) => (
          <Form>
            <Row>
              <Col>
                <Field
                  label="New password"
                  type="password"
                  name="new_password1"
                  placeholder="Enter your new password..."
                  component={FormField}
                  required={true}
                  autoComplete="new-password"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  label="Confirm password"
                  type="password"
                  name="new_password2"
                  placeholder="Enter your new password again..."
                  component={FormField}
                  required={true}
                  autoComplete="new-password"
                />
              </Col>
            </Row>
            <Row className="buttonRow">
              <Col>
                <Button
                  className="hvr-grow-shadow"
                  block
                  color="primary"
                  type="submit"
                >
                  Set new password
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      />
    </div>
  );
};

export default PasswordResetForm;
