import React from "react";
import { ButtonGroup } from 'reactstrap';
import ButtonLink from './ButtonLink'

function AccountButtonGroup(props) {
  return (
    <div className="buttonGroupContainer">
      <ButtonGroup>
        <ButtonLink
          to="/change-profile"
          text="Change profile"
          disabled={props.location === '/change-profile' ? true : false}
          size="md"
        />
        <ButtonLink
          to="/change-password"
          text="Change password"
          disabled={props.location === '/change-password' ? true : false}
          size="md"
        />
      </ButtonGroup>
    </div>
  )
}

export default AccountButtonGroup;
