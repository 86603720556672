import React, {SyntheticEvent, useCallback, useEffect, useMemo, useState} from "react";
import { Button, Modal, FormGroup, Input, Label, ModalHeader, ModalBody } from 'reactstrap';
import DollarIcon from "@mui/icons-material/AttachMoney";
import {createDoorOrder, getDoorOrder} from "../../Lib/RESTBlox";
import Loader from "../Loader";

export interface ITerminal {
    id: number
    date_created: string,
    date_modified: string,
    display_name: string
    device_id: string,
    organization: number
}

export interface IDoorOrder {
    subtotal: number;
    headcount: number;
    order_type?: 'cash' |'card';
    terminal_id: string;
    status?: string;
    payment_intent_status?: "requires_payment_method" | "requires_confirmation" | "requires_action" | "succeeded" | "requires_source" | "processing" | "requires_capture" | "canceled"
    id?: number;
    total?: number;
    tip?: number;
}

interface DoorOrderProcessorProps {
    eventId: number;
    terminals: ITerminal[];
    onCreate?: (response: IDoorOrder) => void;
}

const DoorOrderProcessor = ({eventId, terminals, onCreate = () => {}}: DoorOrderProcessorProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const toggleModal = () => setModalIsOpen(prev => !prev);

    const [headcount, setHeadcount] = useState(1);
    const [subtotal, setSubtotal] = useState<number | null>();
    const [terminal, setTerminal] = useState(terminals[0]);

    const [orderType, setOrderType] = useState<'cash' | 'card'>('card');

    const [doorOrder, setDoorOrder] = useState<IDoorOrder>({
        subtotal: 0,
        headcount: 1,
        terminal_id: '',
        status: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [waitingForPayment, setWaitingForPayment] = useState(false);


    const [closeTimer, setCloseTimer] = useState(0);

    const createOrder = useCallback(async () => {
        const order: IDoorOrder = {
            subtotal: subtotal || 0,
            order_type: orderType,
            headcount,
            terminal_id: terminal.device_id
        }
        setError("")
        setLoading(true);
        try {
            const response = await createDoorOrder(order, eventId)
            setLoading(false)
            if(orderType === 'card') {
                setWaitingForPayment(true);
                setDoorOrder(response);
            } else {
                setCloseTimer(3);
                setTimeout(() => {
                    resetForm()
                    setModalIsOpen(false);
                }, 3000)
            }
            onCreate(response)
        } catch (error: any) {
            setLoading(false);
            console.log(error.response.data)
            setError(`There was a problem creating the order${error?.response?.data?.error ? `: ${error?.response?.data?.error}` : ""}. Please try again.`);
        }
    }, [subtotal, headcount, terminal, orderType]);

    const resetForm = () => {
        setHeadcount(1);
        setSubtotal(null);
        setWaitingForPayment(false)
        setDoorOrder({
            subtotal: 0,
            headcount: 1,
            terminal_id: '',
            status: '',
        })
        setError('')
        setCloseTimer(0);
        setLoading(false)
        setOrderType('card');
    }
    useEffect(() => {
        resetForm();
    }, [modalIsOpen])


    useEffect(() => console.log(orderType), [orderType])
    useEffect(() => {
        setTimeout(() => {
            if (closeTimer > 0) setCloseTimer(prev => prev - 1);
        }, 1000)
    }, [closeTimer])

    useEffect(() => {
        if(doorOrder.id) {
        if(doorOrder.payment_intent_status == "requires_source") setWaitingForPayment(true);
        if(doorOrder.payment_intent_status == "succeeded" && closeTimer === 0) {
            setWaitingForPayment(false);
            setCloseTimer(3);
            setTimeout(() => {
                resetForm()
                setModalIsOpen(false);
            }, 3000)
        }
        if(doorOrder.payment_intent_status == "canceled") setWaitingForPayment(false);
        if(waitingForPayment && modalIsOpen) {
            setTimeout(async () => {
                const response = await getDoorOrder(doorOrder.id as number);
                setDoorOrder(response);
            }, 1000)
        }
      }
    }, [doorOrder])

    const mainMessage = useMemo(() => {
        // if doororder.id
        if(orderType === 'cash' && closeTimer > 0) return "Success!!";

        if (loading && !doorOrder.id) return "Creating Order..."
        if(doorOrder.id) {
            if (doorOrder.payment_intent_status === 'requires_source') return "Waiting for payment...";
            if (doorOrder.payment_intent_status === 'succeeded') return "Success!!";
            if (doorOrder.status === "failed") return "Payment Failed - please try again.";
            return `Something went wrong: ${doorOrder.payment_intent_status}`

        }

    }, [loading, doorOrder, closeTimer])
    return (
        <>
            <Button
                type="button"
                block
                color="secondary"
                className="hvr-grow-shadow"
                onClick={() => setModalIsOpen(true)}>Door Sales</Button>
            <Modal isOpen={modalIsOpen} toggle={toggleModal} onClose={() => {
                console.log('closing');
                resetForm();
            }}>
                <ModalHeader toggle={toggleModal}>Door Sales</ModalHeader>
                <ModalBody style={{display: 'flex', flexDirection: 'column', fontFamily: '"Titillium Web", "Helvetica Neue", "Lucida Grande", Arial, Verdana, sans-serif'}}>
                    <p>Enter the order details below.</p>
                    <p>Remember to enter the order <strong>subtotal</strong> along with the number of patrons granted entry from the order.</p>
                   <Label>
                       Order Subtotal
                       <div style={{display: 'flex', alignItems: 'center'}}>
                           <DollarIcon />
                           <Input  type="number" min="0.01" step="0.01" value={subtotal} onChange={(e: any) => setSubtotal(parseFloat(e.target.value))} />
                       </div>
                   </Label>
                    <Label>
                        Headcount
                        <Input  type="number" min="0.01" step="1" value={headcount} onChange={(e: any) => setHeadcount((e.target.value))} />
                    </Label>
                    Order Type:
                    <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                        <label>
                            <input type="radio" value="card" name="order_type" onChange={(e)=> setOrderType(e.target.value as 'cash' | 'card')} checked={ orderType === "card" }/> Credit Card
                        </label>
                        <label>
                            <input type="radio" value="cash" name="order_type" checked={ orderType === "cash" } onChange={(e)=> setOrderType(e.target.value as 'cash' | 'card')}  /> Cash
                        </label>
                    </div>
                    <div style={{position: 'relative', height: '64px'}}>
                    { (loading || waitingForPayment) && <Loader color="#000" loading={loading || waitingForPayment} />}
                    </div>
                        <div style={{textAlign: 'center', fontSize: '1.7rem'}}>
                            {mainMessage}
                            {closeTimer > 0 && <div style={{fontSize: '1.2rem'}}>Closing in {closeTimer}...</div>}
                        </div>
                    {error && <div style={{color: 'red'}}>{error}</div>}
                    <Button color="primary" onClick={createOrder} disabled={subtotal === 0}>Create Order</Button>
                    <Button onClick={() => {
                        setModalIsOpen(false)
                    }} style={{marginTop: '0.6rem'}}>Cancel</Button>
                </ModalBody>
            </Modal>
            </>
    )
}

export {DoorOrderProcessor}