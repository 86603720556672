import React from "react";
import { getOrderNumberFromPurchaser } from "../../../../Lib/Helpers";

type Props = {
    order: any;
  };
const OrderNumberPurchaser: React.FC<Props> = ({ order }) => {
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <p>
        {order[0]?.purchaser?.orders && order[0]?.purchaser?.orders.length + ' Order' + (order[0]?.purchaser?.orders.length > 1 && 's')}
      </p>
    </div>
  );
};
export default OrderNumberPurchaser;
