import React from "react";
import AdminLayout from "../../Layouts/AdminLayout";
import OrdersTable from "../OrderList/components/OrdersTable/OrdersTable";
import { Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";

type Props = {
  orders: [],
  setCloseShowOrders: any,
};

const UserOrderListDetail: React.FC<Props> = ({orders, setCloseShowOrders}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <AdminLayout title="Orders" isWhite={true}>
      <div className="BloxAdminContentContainer">
      <div className="tableHeader">
          <p className="tableHeader-Name">Orders</p>
          <p className="tableHeader-Name">{orders.length}</p>
        </div>
       {/* <TableSearchForm handleSearchChange={handleSearchChange} /> */}
        <OrdersTable orders={orders} loadData={()=>{}} loading={false} hasMore={false} isMobile={isMobile}/>
        <Button sx={{
                    width: '100%',
                    alignSelf: 'center',
                    backgroundColor: '#000000',
                    fontSize: '1rem',
                    color:'#99abaa',
                    lineHeight: 1,
                    borderRadius: '.25rem',
                    padding: 1.25,
                    marginTop:5,
                    fontWeight: 400,
                    textTransform: 'none',
                    msAlignSelf:'center'
                  }}
                    onClick={() => { setCloseShowOrders() }}
                    variant="contained"
                    disableElevation >Back</Button>
      </div>
    </AdminLayout>
  );
};

export default UserOrderListDetail;
