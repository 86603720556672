import React, {Component} from "react";
import {Row, Col} from "reactstrap";
import EventPurchaseTable from '../Components/EventPurchase/EventPurchaseTable'
import EventPurchaseForm from '../Components/EventPurchase/EventPurchaseForm'
import PromoCodeForm from '../Components/EventPurchase/PromoCodeForm'
import Header from '../Components/Header'
import {getEvent, getOrder, createOrder, getCurrentUserFanCampaignResponse} from '../Lib/RESTBlox'
import Loader from '../Components/Loader'
import FanCampaignRequestModal from '../Components/Modals/FanCampaignRequestModal'
import scrollToElement from "scroll-to-element";
import {useLocation, useNavigate, useParams} from "react-router-dom";

type Props = {
    user: any;
    whiteLabel: any;
    getUser: any;
    eventDetails: any;
};

const EventPurchase: React.FC<Props> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [event, setEvent] = React.useState<any>((location as any).event);
    const [order, setOrder] = React.useState<any>((location as any).order);
    const [tickets, setTickets] = React.useState<any>({});
    const [showFanCampaignInvite, setShowFanCampaignInvite] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const params = useParams();

    const setStateFromNewData = (data: any) => {
        setEvent(data);
    }

    const createNewOrderFromTicketData = () => {
        var tickets_url = (location as any).search.replace('?', '')
        if (tickets_url) {
            var tickets_json = JSON.parse('{"' + decodeURI(tickets_url).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            setTickets(tickets_json);
            createNewOrder(tickets_json)
        }
    }

    const loadData = async (eventId: any) => {
        setLoading(true);
        if (order) {
            var urlWithOrderNumber = "/events/" + params.id + "/purchase/" + order.id
            navigate(urlWithOrderNumber)
            return
        }

        try {

        if (params.orderId) {
            await Promise.all([getEvent(eventId).then(setStateFromNewData).catch(console.error), loadOrderData(params.orderId)])
        } else {
            // If we do not have an order already and no order ID is present, create one.
            createNewOrderFromTicketData()
            await getEvent(eventId).then(setStateFromNewData)
        }
        } catch (err) {
           console.error(err);
        } finally {
            setLoading(false);
        }

    }

    const loadOrderData = async (orderId: any) => {
        setLoading(true);
        return getOrder(orderId)
            .then(data => {
                setOrder(data);
                // inviteToFanCampaign(event)
            })
    }

    const createNewOrder = (tickets_json: any) => {
        createOrder(tickets_json)
            .then(json => {
                console.log(json)
                setOrder(json);
            })
    }

    const recevieUpdatedOrder = (order: any) => {
        setOrder(order);
    }

    const hideTapOrScrollForMore = () => {
        var e = (document.getElementsByClassName('TapOrScrollForMore') as HTMLCollectionOf<HTMLElement>)[0]
        e.style.visibility = 'hidden'
    }

    React.useEffect(() => {
        loadData(params.id)
    }, []);

    return (
        <Row noGutters className="BloxEventsRow">
            <Col md={6} className="BloxEventsHeroImageColumn">
          <span
              className="eventDetailsLink"
              onClick={() => {
                  hideTapOrScrollForMore()
                  //scrollToComponent(eventDetails, { offset: -108, align: 'top', duration: 600})
              }
              }
          />
                {event &&
                    <div className="eventHeroImage">
                        <div style={{backgroundImage: 'url(' + event.image + ')'}}/>
                    </div>
                }
                <button
                    className="btn hvr-grow-shadow TapOrScrollForMore"
                    onClick={() => scrollToElement(
                        props.eventDetails, {offset: -108, align: 'top', duration: 600}
                    )}
                >
                    Order details
                </button>
            </Col>
            <Col md={6} className={"BloxEventsDetailsColumn BloxEventPurchasePage loading"}>
                <Loader
                    loading={loading}
                    color='#000'
                />
                <Row className="BloxEventPurchaseFormRow">
                    <Col>
                        <Header content="Your purchase" className="noTopMargin"/>
                        {event && order &&
                            <div>
                                <p className="p-event-purchase">{event.__str__}</p>
                                <EventPurchaseTable
                                    orderComplete={false}
                                    orderInitiated={true}
                                    event={event}
                                    order={order}
                                />
                                <Header content="Promo Code"/>
                                <PromoCodeForm
                                    event={event}
                                    order={order}
                                    updatedOrderHandler={recevieUpdatedOrder}
                                />
                                <Header content="Payment"/>
                                <EventPurchaseForm
                                    event={event}
                                    order={order}
                                    user={props.user}
                                    getUser={props.getUser}
                                    whiteLabel={props.whiteLabel}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default EventPurchase;
