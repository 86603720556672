import React, { Component } from "react";
import EventForm from "../Forms/EventForm/EventForm";
import { getAdminEvent } from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";

type Props = {
  user: any;
};

const EventDuplicate: React.FC<Props> = ({ user }) => {
  const [event, setEvent] = React.useState<any>();

  React.useEffect(() => {
    const eventId = window.location.pathname.split("/").pop();
    if (eventId) {
      getAdminEvent(eventId, true)
        .then((data) => setStateFromNewData(data))
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const setStateFromNewData = (data: any) => {
    setEvent(data);
  };

  return (
    <AdminLayout title="Duplicate event">
      {event && <EventForm event={event} user={user} prepopulateNew={true} />}
    </AdminLayout>
  );
};

export default EventDuplicate;
