import React from 'react';
import { Row, Col } from 'reactstrap';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import bloxDropTargetImage from './images/blox_drop_target.png'
import bloxDropTargetImageSelected1 from './images/blox_drop_target_selected_medium_dark.png'
import bloxDropTargetImageSelected2 from './images/blox_drop_target_selected_medium.png'
import bloxDropObjectImage1 from './images/blox_drop_object_medium_dark.png'
import bloxDropObjectImage2 from './images/blox_drop_object_medium.png'
import bloxDropObjectImageMask from './images/hex_mask.png'

export default class FanCampaignQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      campaign: this.props.campaign,
      answer: {}
    };
    this.dropHandler = this.dropHandler.bind(this)
    this.handleAnswer = this.props.handleAnswer.bind(this)
  }

  dropHandler(event) {
    var answer = {}
    answer[event.dragData.questionId] = event.dragData.value
    
    // var question = this.state.campaign.questions.filter((question, i) => question.id=event.dragData.questionId)
    // console.log(question)

    // Set the answer in component state
    this.setState({
      answer: answer
    })

    // Set visual styles
    var draggedElement = event.srcElement
    var dropTargetElement = event.dropElem
    var dropTargetInnerDiv = dropTargetElement.firstChild
    draggedElement.classList.add("chosen");

    if (event.dragData.value === 1) {
      // Set the visual style of the drop target
      dropTargetInnerDiv.style["background-image"] = 'url(' + bloxDropTargetImageSelected1 + ')'

      // Reset the visual state of the other choice
      var otherChoice = document.getElementsByClassName("fanCampaignQuestionChoice-2")[0].getElementsByClassName("ddcontainer")[0]
      otherChoice.classList.remove("chosen")

    }

    if (event.dragData.value === 2) {
      // Set the visual style of the drop target
      dropTargetInnerDiv.style["background-image"] = 'url(' + bloxDropTargetImageSelected2 + ')'

      // Reset the visual state of the other choice
      otherChoice = document.getElementsByClassName("fanCampaignQuestionChoice-1")[0].getElementsByClassName("ddcontainer")[0]
      otherChoice.classList.remove("chosen")
    }

    // Set the class to animate out.
    var questionElement = document.getElementById("fanCampaignQuestion-" + event.dragData.questionId)
    questionElement.classList.add('animate-out')
    questionElement.classList.remove('animate-in')

    // Pass the answer to parent state
    var that = this
    setTimeout(function(){
      that.handleAnswer(event.dragData);
    }, 300);

  }

  animateIn() {
    var that=this
    setTimeout(function(){
      // Set the class to animate in.
      var questionElement = document.getElementById("fanCampaignQuestion-" + that.props.question.id)
      questionElement.classList.add('animate-in')
      questionElement.classList.remove('animate-out')
    }, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.question !== this.props.question) {
      this.animateIn()
    }
  }

  componentDidMount() {
    this.animateIn()
  }

  render() {
    console.log(this.props)
    const campaignId = this.props.campaign.id
    const questionId = this.props.question.id
    const question = this.props.question
    var choice1DragData = { questionId: questionId, value: 1}
    var choice2DragData = { questionId: questionId, value: 2}
    if (window.matchMedia("(min-width: 768px)").matches) {
      // LARGER SCREENS
      return (
        <Row className="fanCampaignQuestion" key={'fanCampaignQuestion-' + question.id} id={'fanCampaignQuestion-' + question.id}>
          <Col>
            <Row className="fanCampaignQuestionQuestion">
              <Col><h3>{question.text}</h3></Col>
            </Row>
            <Row className="fanCampaignQuestionChoices">
              <Col xs={3} className="fanCampaignQuestionChoice fanCampaignQuestionChoice-1">
                <h4>{question.choice_1}</h4>
                <div>
                  <DragDropContainer
                    targetKey={'fanCampaignChoice-' + campaignId + '-' + question.id}
                    className="fanCampaignChoice"
                    onDrop={this.dropHandler}
                    dragData={choice1DragData}
                  >
                    <h5 style={{backgroundImage: `url(${question.choice_1_image})`}}>{question.choice_1}</h5>
                    <div className="hexMask" style={{backgroundImage: `url(${bloxDropObjectImageMask})`}} />
                  </DragDropContainer>
                  <p style={{ marginTop: '1rem' }}>{question.choice_1_description}</p>
                </div>
              </Col>

              <Col xs={6} className="fanCampaignQuestionChoiceTarget">
                <DropTarget
                  targetKey={'fanCampaignChoice-' + campaignId + '-' + question.id}
                  onDrop={this.dropHandler}
                >
                  <div style={{backgroundImage: `url(${bloxDropTargetImage})`}} />
                </DropTarget>
              </Col>

              <Col xs={3} className="fanCampaignQuestionChoice fanCampaignQuestionChoice-2">
                <h4>{question.choice_2}</h4>
                <div>
                  <DragDropContainer
                    targetKey={'fanCampaignChoice-' + campaignId + '-' + question.id}
                    className="fanCampaignChoice"
                    onDrop={this.dropHandler}
                    dragData={choice2DragData}
                  >
                    <h5 style={{backgroundImage: `url(${question.choice_2_image})`}}>{question.choice_2}</h5>
                    <div className="hexMask" style={{backgroundImage: `url(${bloxDropObjectImageMask})`}} />
                  </DragDropContainer>
                  <p style={{ marginTop: '1rem' }}>{question.choice_2_description}</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else {
      // SMALLER SCREENS
      return (
        <Row className="fanCampaignQuestion" key={'fanCampaignQuestion-' + question.id} id={'fanCampaignQuestion-' + question.id}>
          <Col>
            <Row className="fanCampaignQuestionQuestion">
              <Col><h3>{question.text}</h3></Col>
            </Row>
            <Row>
              <Col xs={6} className="fanCampaignQuestionChoice fanCampaignQuestionChoice-1"> 
                  <div>
                    <h4>{question.choice_1}</h4>
                    <p style={{ marginTop: '1rem' }}>{question.choice_1_description}</p>
                    <DragDropContainer
                      targetKey={'fanCampaignChoice-' + campaignId + '-' + question.id}
                      className="fanCampaignChoice"
                      onDrop={this.dropHandler}
                      dragData={choice1DragData}
                    >
                      <h5 style={{backgroundImage: `url(${question.choice_1_image})`}}>{question.choice_1}</h5>
                      <div className="hexMask" style={{backgroundImage: `url(${bloxDropObjectImageMask})`}} />
                    </DragDropContainer>
                  </div>
              </Col>
            </Row>

            <Row className="fanCampaignQuestionChoiceTarget">
              <Col>
                <DropTarget
                  targetKey={'fanCampaignChoice-' + campaignId + '-' + question.id}
                  onDrop={this.dropHandler}
                >
                  <div style={{backgroundImage: `url(${bloxDropTargetImage})`}} />
                </DropTarget>
              </Col>
            </Row>

            <Row>
              <Col xs={6} className="fanCampaignQuestionChoice fanCampaignQuestionChoice-2"> 
                <div>
                  <DragDropContainer
                    targetKey={'fanCampaignChoice-' + campaignId + '-' + question.id}
                    className="fanCampaignChoice"
                    onDrop={this.dropHandler}
                    dragData={choice2DragData}
                  >
                    <h5 style={{backgroundImage: `url(${question.choice_2_image})`}}>{question.choice_2}</h5>
                    <div className="hexMask" style={{backgroundImage: `url(${bloxDropObjectImageMask})`}} />
                  </DragDropContainer> 
                  <p style={{ marginTop: '1rem' }}>{question.choice_2_description}</p>
                  <h4>{question.choice_2}</h4>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  }
}
