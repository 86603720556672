import React from 'react';
import { Table } from "reactstrap";
import TicketTableRow from './TicketTableRow'
import Loader from '../Loader'

export default class TicketTable extends React.Component {
  render() {
    return (
      <div>
        <Table responsive={true}>
          <thead>
          <tr>
            <th scope="col">Event</th>
            <th scope="col">Holder</th>
            <th scope="col" className="d-none d-md-table-cell">Purchaser</th>
            <th scope="col" className="d-none d-md-table-cell">Type</th>
            <th scope="col" className="right-align">Transfer</th>
          </tr>
          </thead>
          <tbody>
          <Loader
            loading={this.props.loading}
            color='#000'
          />
          { this.props.tickets.map((ticket, i) => {
            return <TicketTableRow
              ticket={ticket}
              key={"ticket" + ticket.id}
            />
          })
          }
          </tbody>
        </Table>
      </div>

    );
  }
}