import React, { Component } from "react";
import { getAdminVenues } from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";
import SmartTable from "../../Components/SmartTable";
import { mapFormatter } from "../../Lib/TableFormatters";

class VenueList extends Component {
  render() {
    const columns = [
      { dataField: "name", text: "Name" },
      { dataField: "street_address", text: "Address" },
      { dataField: "city", text: "City" },
      { dataField: "state", text: "State" },
      { dataField: "zip", text: "Zip" },
      {
        dataField: "address",
        text: "Map",
        formatter: mapFormatter,
        classes: "map-col",
      },
    ];

    return (
      <AdminLayout title="Venues">
        <div className="BloxAdminContentContainer">
          <SmartTable columns={columns} loadData={getAdminVenues} />
        </div>
      </AdminLayout>
    );
  }
}

export default VenueList
