import React, { Component } from "react";
import { Col } from "reactstrap";
import CountUp from "react-countup";

type Props = {
  overline: any;
  icon: any;
  valueType: any;
  value: any;
  valuePrefix: any;
  valueSuffix: any;
  underline: any;
};

const Metric: React.FC<Props> = ({
  overline,
  icon,
  valueType,
  value,
  valuePrefix,
  valueSuffix,
  underline,
}) => {
  return (
    <Col className="metric" xs={6} md={4}>
      <span className="overline">{overline}</span>
      {icon}
      {valueType === "number" ? (
        <CountUp
          end={value}
          prefix={valuePrefix}
          suffix={valueSuffix}
          separator=","
          duration={1}
          delay={1}
        />
      ) : (
        <div>
          {valuePrefix}
          {value}
          {valueSuffix}
        </div>
      )}
      <span className="underline">{underline}</span>
    </Col>
  );
};

export default Metric;
