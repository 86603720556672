import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { TicketNumberFormatter } from "../../../../Lib/TableFormatters";

type Props = {
  order: any;
};
const TicketTable: React.FC<Props> = ({ order }) => {
  const columns = [
    {
      dataField: "inventory.type.name",
      text: "Type",
      align: "right",
      sort: true,
      classes: "order-col",
    },
    {
      dataField: "inventory.price_str",
      text: "Price",
      align: "right",
      sort: true,
      classes: "order-date-col",
    },
    {
      dataField: "id",
      text: "Ticket #",
      align: "center",
      sort: true,
      formatter: TicketNumberFormatter,
      classes: "user-col",
    },
  ];
  return order[0]?.tickets_issued ? (
    <BootstrapTable
      remote
      keyField="id"
      data={order[0]?.tickets_issued}
      columns={columns}
      bordered={false}
      condensed={true}
    />
  ) : (
    <></>
  );
};
export default TicketTable;
