import React from 'react'
import {Link} from 'react-router-dom'
import {
  Card,
  CardFooter
} from 'reactstrap'

function EventTile(props) {
  return (
    <Card className="EventTile hvr-grow-shadow">
      <Link to={"/events/" + props.event.id}>
        <div className="cardImage" style={{
            backgroundImage: 'url(' + props.event.image + ')',
          }}
        />
      </Link>
      <CardFooter>
        <Link to={"/events/" + props.event.id}>
          <span className="title">
            {props.event.title}
          </span>
          <span className="date">{props.event.date}, {props.event.time1_display}</span>
          <span
            style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
            }}
            className="venue">{props.event.venue}, {props.event.street_address}</span>
        </Link>
      </CardFooter>
    </Card>
  );
}

export default EventTile
