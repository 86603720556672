import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAdminOrderDetails } from "../../Lib/RESTBlox";
import CardPay from "./components/CardPay/CardPay";
import EventTitle from "./components/EventTitle/EventTitle";
import OrderImage from "./components/OrderDate/OrderDate";
import OrderDate from "./components/OrderImage/OrderImage";
import OrderMap from "./components/OrderMap/OrderMap";
import OrderNumberPurchaser from "./components/OrderNumberPurcaser/OrderNumberPurchaser";
import PriceTable from "./components/PriceTable/PriceTable";
import PurchaserDetails from "./components/PurchaserDetails/PurchaserDetails";
import SavedLabel from "./components/SavedLabel/SavedLabel";
import SectionHeader from "./components/SectionHeader/SectionHeader";
import TicketTable from "./components/TicketTable/TicketTable";

const OrderDetails: React.FC<any> = () => {
  const [order, setOrder] = useState<any>();
  useEffect(() => {
    getAdminOrderDetails(
      +(window.location.pathname?.split("/")?.pop() as string)
    )
      .then((data) => {
        console.log(data);
        setOrder(data.results);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  return order ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%", maxWidth: "1400px", margin: "0 auto" }}
    >
      <Grid item xs={12} lg={6} xl={6} md={6} justifyContent="center" container>
        <div className="" style={{marginTop: '90px', marginBottom: '20px'}}>

          {(order && order[0]?.event) && <img
              alt=""
              src={order[0]?.event?.image}
              style={{maxWidth: "95vw", maxHeight: "275px"}}
          />}
        </div>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <SectionHeader rigthText="Order" leftText={order[0]?.number} />
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <OrderDate order={order} />
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <EventTitle order={order} />
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <PriceTable order={order} />
        </Grid>
        {
          order.discount &&
          <Grid item xs={11} lg={11} xl={11} md={11}>
            <SavedLabel order={order} />
          </Grid>
        }
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <CardPay order={order} />
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <SectionHeader
            rigthText="Purchaser"
            leftText={order[0]?.purchaser?.pk}
          />
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11} container>
          <Grid item xs={6} lg={6} xl={6} md={6}>
            <PurchaserDetails order={order} />
          </Grid>
          <Grid item xs={6} lg={6} xl={6} md={6}>
            <OrderNumberPurchaser order={order} />
          </Grid>
        </Grid>
        <Grid item xs={10} lg={10} xl={10} md={10}>
          <OrderMap order={order} />
        </Grid>
        {order[0]?.promo_code_uses?.length > 0 && (
          <Grid item xs={11} lg={11} xl={11} md={11}>
            <SectionHeader rigthText="Promocodes" leftText="Number" />
          </Grid>
        )}
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <SectionHeader
            rigthText="Tickets"
            leftText={order[0]?.tickets_issued?.length}
          />
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <TicketTable order={order} />
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default OrderDetails
