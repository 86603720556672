import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import SignInModal from "../Components/Modals/SignInModal";
import SignUpModal from "../Components/Modals/SignUpModal";
import { validateEmail } from '../Lib/FormValidation'
import { Formik, Field, Form } from 'formik';
import { FormField } from '../Components/Forms/Fields'
import { createClientAccount } from "../Lib/RESTBlox";
import AdminLayout from '../Layouts/AdminLayout'
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";

type Props = {
  postSignIn: any;
  postSignUp: any;
  postPasswordForgot: any;
  getUser: any;
  user: any;
  closeNavbar: any;
};

const ClientOnboarding: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [refCode, setRefCode] = React.useState<any>("");
  const [user, setUser] = React.useState<any>();

  React.useEffect(() => {
    setRefCode(window.location.pathname.split("/").length > 2 ? window.location.pathname.split("/").pop() : "");
    setUser(props.user);
  }, []);


  const handleCreateOrganization = (values: any, setFieldError: any, setSubmitting: any) => {
    if (values.contact_form_referral_code) {
      createClientAccount(values)
          .then(json => {
            console.log(json)
            props.getUser()
            setTimeout(function () {
              navigate('/onboarding-terms')
            }, 300)
          })
        .catch((err: any) => {
          console.log(err);
          setFieldError("contact_form_referral_code", "Referral code does not exist.")
        });
    }
    else {
      createClientAccount(values)
        .then(json => {
          console.log(json)
          props.getUser()
          setTimeout(function () {
            navigate('/onboarding-terms')
          }, 300)
        })
    }
  }

  if (props.user) {
    return (
      <AdminLayout title="Client Onboarding">
        <div className="BloxAdminContentContainer clientOnboardingContainer">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  name: '',
                  contact_form_email: user ? user.email : '',
                  contact_form_referral_code: refCode,
                }}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                  handleCreateOrganization(values, setFieldError, setSubmitting)
                }}
                render={(props) => (
                  <Form>
                    <Row>
                      <Col className="">
                        <Field
                          label="Organization Name"
                          type="text"
                          name="name"
                          placeholder="Your company or org name..."
                          component={FormField}
                          required={true}
                        />
                      </Col>
                      <Col className="">
                        <Field
                          label="Organization email"
                          type="email"
                          name="contact_form_email"
                          placeholder="Contact email address..."
                          component={FormField}
                          required={true}
                          validate={validateEmail}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="">
                        <Field
                          label="Referral Code"
                          type="text"
                          name="contact_form_referral_code"
                          placeholder="An optional referral code..."
                          component={FormField}
                          required={false}
                        />
                      </Col>
                    </Row>
                    <Row className="buttonRow">
                      <Col xs="12">
                        <Button className="hvr-grow-shadow" block type="submit">Create Client Account</Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              />
            </Col>
          </Row>
        </div>
      </AdminLayout>
    )
  } else {
    return (
      <AdminLayout title="Client Onboarding">
        <div className="BloxAdminContentContainer clientOnboardingContainer">
          <Row>
            <Col>
              <Header content="Welcome to The Blox Office!" />
              <p>Before we can get your organization set up to sell tickets, we'll need you to sign up or sign in.</p>

              <div className="clientOnboardingSignInSignUpButtons">
                <SignInModal
                  postSignUp={props.postSignUp}
                  postSignIn={props.postSignIn}
                  postPasswordForgot={props.postPasswordForgot}
                  closeNavbar={props.closeNavbar}
                />
                <SignUpModal
                  postSignUp={props.postSignUp}
                  postSignIn={props.postSignIn}
                  postPasswordForgot={props.postPasswordForgot}
                  closeNavbar={props.closeNavbar}
                />
              </div>

            </Col>
          </Row>
        </div>
      </AdminLayout>
    )
  }
}

export default ClientOnboarding;
