import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import {
  getAdminEvent,
  getAdminEventGuestEmailList,
  createAdminEventSubPromoterLinks, createAdminEventSubPromoterLinkV2,
} from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";
import EventAnalyticsPanel from "../../Components/Admin/Analytics/EventAnalyticsPanel";
import Loader from "../../Components/Loader";
import Header from "../../Components/Header";
import { FaGenderless } from "react-icons/fa";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import EventMap from "./components/EventMap/EventMap";
import TraficMetrics from "./components/TraficMetrics/TraficMetrics";
import GeneralMetrics from "./components/GeneralMetrics/GeneralMetrics";
import MetricsOptions from "./components/MetricsOptions/MetricsOptions";
import EventPicAndDetail from "./components/EventPicAndDetail/EventPicAndDetail";
import PromoCodeMetrics from "./components/PromoCodeMetrics/PromoCodeMetrics";
import SubPromoterLink from "./components/SubPromoterLink/SubPromoterLink";
import RecentOrders from "./components/RecentOrders/RecentOrders";
import { IAdminEventDetailsIn } from "../../interfaces/events/adminEventDetails";
import LoadingButton from "@mui/lab/LoadingButton";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import SubPromoterLinksV2 from "./components/SubPromoterLinksV2/SubPromoterLinksV2";

type Props = {
  user: any;
};

const headers = ["Name", "Email"];

const EventDetails: React.FC<Props> = ({ user }) => {
  const [event, setEvent] = React.useState<IAdminEventDetailsIn>();
  const [loading, setLoading] = React.useState(true);
  const { eventId } = useParams();
  const [loadingDownloadCSV, setLoadingDownloadCSV] = React.useState(false);
  const [emailList, setEmailList] = React.useState<any>([]);
  const [genderIcon, setGenderIcon] = React.useState<Element>();
  const [subpromotors, setSubpromotors] = React.useState<any[]>([]);
  const csvLinkEl = React.useRef(null);

  useEffect(() => {
    getAdminEvent(window.location.pathname?.split("/")?.pop() as string, true)
      .then((data) => {
        setStateFromNewData(data);
        setSubpromotors(data.event?.sub_promoters)
      })
      .catch((err) => console.error(err));
  }, []);

  const handleCreateSubPromoter = (title: string) => {
    createAdminEventSubPromoterLinkV2(
      event?.event?.id as unknown as string,
      title
    )
      .then((data: any) => {
        console.log(data);
        let temp = [...subpromotors];
        temp.push(data);
        setSubpromotors(temp.sort((a, b) => a.title - b.title));
      })
      .catch((err: any) => {
        alert("User not found");
      });
  };

  const setStateFromNewData = (data: IAdminEventDetailsIn) => {
    var average_gender = data.customer_demographics.average_gender;
    if (average_gender === "male") {
      var genderIcon = <IoMdMale />;
    } else if (average_gender === "female") {
      genderIcon = <IoMdFemale />;
    } else if (average_gender === "non_binary") {
      genderIcon = <FaGenderless />;
    } else if (average_gender === "unknown") {
      genderIcon = <FaGenderless />;
    }
    setEvent(data);
    setLoading(false);
  };

  const downloadCSVFile = (e: any, event: any) => {
    setLoadingDownloadCSV(true);
    getAdminEventGuestEmailList(eventId as string)
      .then((data) => {
        setEmailList(data);
        downloadEmailList(data);
      })
      .catch((err) => {
        console.error(err);
        setLoadingDownloadCSV(false);
      });
    e.stopPropagation();
  };

  const downloadEmailList = (data: any) => {
    //TODO remove log after download the data
    (csvLinkEl?.current as any).link.click();
    setLoadingDownloadCSV(false);
  };

  return (
    <AdminLayout title="Event details">
      <div className="BloxAdminContentContainer BloxAdminEventDetails">
        <Loader loading={loading} color="#000" />
        <Row>
          <Col>
            <Header content="Event overview" />
            {event && (
              <Row
                className="BloxAdminEventDetailsHeader"
                style={{ backgroundColor: "#000" }}
              >
                <EventPicAndDetail event={event} />
                <Col
                  xs={12}
                  md={8}
                  className="rightColumn"
                  style={{ alignSelf: "center" }}
                >
                  <Row>
                    <Col>
                      <MetricsOptions user={user} event={event} />
                      <GeneralMetrics
                        event={event}
                        genderIcon={<FaGenderless />}
                      />
                      <TraficMetrics event={event} />
                      <EventMap event={event} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {event && event?.sales?.by_inventory?.length > 0 && (
          <Row>
            <Col>
              <Header content="Sales analytics" />
              {event && event?.sales?.by_inventory?.length > 0 && (
                <EventAnalyticsPanel event={event} />
              )}
            </Col>
          </Row>
        )}


        {event && (
            <Row>
              <Col>
                <Header content="Promo code uses" />
                <PromoCodeMetrics event={event} />
              </Col>
            </Row>
        )}

        {event && (
          <Row>
            <Col>
              <Header content="Recent Orders" />
              <RecentOrders event={event} />
            </Col>
          </Row>
        )}

        {event && (
          <Row>
            <Col>
              <Header content={<div style={{display: 'flex', gap: '0.5rem'}}>Subpromoter Links <div style={{fontSize: '8px', fontWeight: 700, backgroundColor: '#cacaca', color: 'black', padding: '2px', textTransform: 'uppercase', borderRadius: '4px', alignSelf: 'flex-start', marginTop: '4px'}}>Beta</div></div>} />
              <SubPromoterLinksV2
                event={event}
                subPromoters={subpromotors}
                handleCreateSubPromoter={handleCreateSubPromoter}
              />
            </Col>
          </Row>
        )}

        {event && (
          <Row>
            <Col>
              <Header content="Marketing Data" />
              <div style={{ width: "100%", textAlign: "center" }}>
                <LoadingButton
                  style={{ width: "50%", background: "#17a2b8" }}
                  variant="contained"
                  color="info"
                  loading={loadingDownloadCSV}
                  onClick={(e: any) => {
                    downloadCSVFile(e, event);
                  }}
                >
                  Email List
                </LoadingButton>
                <CSVLink
                  headers={headers}
                  filename="Blox_Email_Export.csv"
                  data={emailList}
                  ref={csvLinkEl}
                />
                <p
                  style={{
                    fontSize: "13px",
                    color: "#999999",
                    marginTop: "10px",
                  }}
                >
                  Click the button above to download an email list of all users
                  that placed an order for this event. You can import this CSV
                  file into Google Docs or Sendy to manage your own customer
                  mailing list or order history.
                </p>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </AdminLayout>
  );
};

export default EventDetails;
