import React, {ReactNode} from 'react'

type Props = {
  content: ReactNode;
  secondaryContent?: string;
  className?: string;
};

function Header(props:Props) {
  return (
    <h4 className={props.className? 'Header ' + props.className : 'Header'}>
      { props.content }
      { props.secondaryContent &&
        <span>{ props.secondaryContent }</span>
      }
    </h4>
  )
}

export default Header
