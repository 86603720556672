import React from "react";
import WhiteLabelForm from "../Forms/WhiteLabelForm";
import AdminLayout from "../../Layouts/AdminLayout";

type Props = {
  user: any;
  whiteLabel: any;
};
const WhiteLabelChange: React.FC<Props> = ({ user, whiteLabel }) => {
  return (
    <AdminLayout title="Site settings">
      {whiteLabel && <WhiteLabelForm whiteLabel={whiteLabel} user={user} />}
    </AdminLayout>
  );
};

export default WhiteLabelChange
