import React, { Component } from "react"
import BigCalendar from 'react-big-calendar'
import moment from 'moment'

const localizer = BigCalendar.momentLocalizer(moment)

class Calendar extends Component {
  render() {
    // console.log('events', this.props.events);
    return (
      <BigCalendar
        localizer={localizer}
        events={this.props.events}
        startAccessor="start"
        endAccessor="end"
        views={['month',]}
        onNavigate={(date) => this.props.handleChangeDate(date)}
      />
    );
  }
}

export default Calendar
