import React from 'react'
import {Link} from 'react-router-dom'
import {
  Card,
  CardFooter
} from 'reactstrap'
import { FaRegCalendarPlus } from 'react-icons/fa'

const EventTileNew = (props) => {
  return (
      <Card className="EventTile hvr-grow-shadow">
        <Link to={"/admin/events/add/"}>
          <div className="cardImage cardImageAdd">
            <FaRegCalendarPlus />
          </div>
        </Link>
        <Link to={"/admin/events/add/"}>
          <CardFooter>
            <span className="title">Add new event</span>
            <span className="date"></span>
            <span className="venue"></span>
          </CardFooter>
        </Link>
      </Card>
  );
};

export default EventTileNew;
