import React from "react";
import Metric from "../../../../Components/Admin/Analytics/Metric";
import { Row } from "reactstrap";
import { FaMoneyBillAlt } from "react-icons/fa";
import MetricCode from "../../../../Components/Admin/Analytics/MetricCode";

type Props = {
  event: any;
};

const PromoCodeMetrics: React.FC<Props> = ({ event }) => {
  return (
    <Row className="metrics">
      {event?.sales?.promo_code_uses && Object.keys(event.sales.promo_code_uses).map((key, i) => {
        return (
          <MetricCode
            overline={key}
            icon={<FaMoneyBillAlt />}
            value={event.sales.promo_code_uses[key].num_used}
            valueType="number"
            valuePrefix={+event.sales.promo_code_uses[key].discount?.replace('%','').replace('$','')}
            valueSuffix={`${event?.sales?.promo_code_uses[key]?.num_used}/${event?.sales.promo_code_uses[key]?.total}`}
            underline="Saved"
          />
        );
      })}
    </Row>
  );
};

export default PromoCodeMetrics;
