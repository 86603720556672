import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import AdminLayout from "../../Layouts/AdminLayout";
// import { getStripeAPIKey } from "../../App/Configuration";
import {
  processStripeConnect,
  getStripeExpressDashboardURL,
} from "../../Lib/RESTBlox";
import queryString from "query-string";
import Header from "../../Components/Header";
import { useLocation } from "react-router-dom";

type Props = {
  user: any;
};

const Dashboard: React.FC<Props> = ({ user }) => {
  const location = useLocation();
  const StripeClientID = "ca_EAv2vidLbZTWckkMj2dZ8nFReRtS5bGX";
  // const StripeAPIKey = getStripeAPIKey();
  const StripeState = "allow_stripe_connect";
  // this.StripeRedirectURL = "https://bloxoffice-frontend-staging.herokuapp.com/admin"
  const StripeRedirectURL = "https://www.thebloxoffice.com/admin";
  // const StripeOAuthURL =
  //   "https://connect.stripe.com/express/oauth/authorize?redirect_uri=" +
    // StripeRedirectURL +
    // "&client_id=" +
    // StripeClientID +
    // "&state=" +
    // StripeState;

  const [receivedStripeConnectCode, setReceivedStripeConnectCode] =
    React.useState(false);
  const [stripeConnectCode, setStripeConnectCode] = React.useState("");

  const buildStripeOAuthURI = () => {
    var url =
      "https://connect.stripe.com/express/oauth/authorize?redirect_uri=";
    url += StripeRedirectURL;
    url += "&client_id=" + StripeClientID;
    url += "&state=" + StripeState;
    url += "&stripe_user[email]=" + user.email;
    url += "&stripe_user[first_name]=" + user.first_name;
    url += "&stripe_user[last_name]=" + user.last_name;
    // url += "&stripe_user[phone_number]" + this.props.user.profile.phone.replace('+1', '')
    //url += "&stripe_user[business_type]=company"
    url += "&stripe_user[business_name]=" + user?.profile.organization?.name;
    url += "&suggested_capabilities[]=platform_payments";
    url += "&suggested_capabilities[]=tax_reporting_us_1099_k";
    return url;
  };

  const receiveStripeConnectCode = (code: any) => {
    setReceivedStripeConnectCode(true);
    setStripeConnectCode(code);
    processStripeConnect(code).then((json) => {
      console.log(json);
      window.location.href = "/admin";
    });
  };

  const handleExpressDashboardLogin = () => {
    getStripeExpressDashboardURL().then((json: any) => {
      console.log(json.url);
      window.location.href = json.url;
    });
  };

  const stripeButton = () => {
    if (user.profile.organization?.has_stripe_credentials) {
      return (
        <button
          className="btn btn-primary"
          onClick={handleExpressDashboardLogin}
        >
          Stripe Dashboard
        </button>
      );
    } else if (!user?.profile.organization?.is_the_blox_office) {
      return (
        <a className="btn btn-primary" href={buildStripeOAuthURI()}>
          Connect with Stripe
        </a>
      );
    } else {
      return "";
    }
  };

  useEffect(() => {
    let params = queryString.parse(location.search);
    if ("code" in params) {
      receiveStripeConnectCode(params.code);
    }
  }, []);

  return (
    <AdminLayout title="Dashboard">
      <div className="BloxAdminContentContainer">
        <Row>
          <Col>
            {user.profile.organization?.is_the_blox_office ? (
              <p>
                You are logged into an account under The Blox Office's
                organization. Therefore, no need for Stripe stuff.
              </p>
            ) : (
              <div>
                <Header content="Let's get you set up with Stripe." />
                {user.profile.organization?.has_stripe_credentials ? (
                  <p>
                    The button below will connect you to the Stripe dashboard
                    for your account, where you can see your balance and
                    payouts.
                  </p>
                ) : (
                  <p>
                    The Blox Office uses Stripe for processing customer charges
                    and paying our clients. In order to get paid for your
                    transactions, you need to set up a Stripe account. Use the
                    button below to get started.
                  </p>
                )}
                {stripeButton()}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
