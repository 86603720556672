import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { AWSAlternative, PublicImagePath } from "../constants/constants";

type Props = {
  title: any;
  feed: any;
  count: any;
  next: any;
  mounths: any;
  tenseSelector?: "View Upcoming Events" | "View Past Events";
  changeTense?: () => void;
};

const ActivityFeed: React.FC<Props> = (props) => {
  const formatDate = (date: any) => {
    // Takes a string YYYY-MM-DD and converts it to MM.DD.YYYY
    var dateParts = date.split("-");
    return dateParts[1] + "." + dateParts[2] + "." + dateParts[0];
  };

  const getWeekDay = (date: any) => {
    // Takes a string YYYY-MM-DD and get day of week
    try {
      const day = new Date(date).getDay();
      switch (day) {
        case 0:
          return "Monday";
        case 1:
          return "Tuesday";
        case 2:
          return "Wednesday";
        case 3:
          return "Thursday";
        case 4:
          return "Friday";
        case 5:
          return "Saturday";
        case 6:
          return "Sunday";
        default:
          return "";
      }
    } catch {
      return "";
    }
  };

  const renderFeedItem = (item: any) => {
    if (item.type === "Event") {
      if (item.venue_undisclosed) {
        return (
          <Row className="feedItem">
            <Col xs={4} style={{ paddingLeft: 0 }}>
              <a href={"/events/" + item.id}>
                <div
                  className="feedItemImage"
                  style={{
                    // backgroundImage: 'url('+ PublicImagePath  + item.image + '),url('+ AWSAlternative  + item.image + '),url('+ item.image + ')'
                    backgroundImage: "url(" + item.image + ")",
                  }}
                />
              </a>
            </Col>
            <Col xs={8} className="feedItemDetails">
              <div className="feedItemType">Event</div>
              <h4>
                <a href={"/events/" + item.id}>{item.title}</a>
              </h4>
              <h6>{item.id === 1245 ? "Coco Loco Beach Club, Oranjestad, Aruba" : "Secret Location · Chicago, IL"}</h6>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="feedItem">
            <Col xs={4} style={{ paddingLeft: 0 }}>
              <a href={"/events/" + item.id}>
                <div
                  className="feedItemImage"
                  style={{
                    // backgroundImage: 'url(' + PublicImagePath + item.image + '),url('+ AWSAlternative  + item.image + '),url('+ item.image + ')'
                    backgroundImage: "url(" + item.image + ")",
                  }}
                />
              </a>
            </Col>
            <Col xs={8} className="feedItemDetails">
              <div className="feedItemType">Event</div>
              <h4>
                <a href={"/events/" + item.id}>{item.title}</a>
              </h4>
              <h6>{item.id === 1245 ? "Coco Loco Beach Club, Oranjestad, Aruba" : item.address}</h6>
            </Col>
          </Row>
        );
      }
    }

    if (item.type === "Gallery") {
      if (item.venue_undisclosed) {
        return (
          <Row className="feedItem">
            <Col xs={4} style={{ paddingLeft: 0 }}>
              <a href={"/event-gallery/" + item.id}>
                <div
                  className="feedItemImage"
                  style={{
                    // backgroundImage: 'url(' + PublicImagePath + item.image + '),url('+ AWSAlternative  + item.image + '),url('+ item.image + ')'
                    backgroundImage: "url(" + item.image + ")",
                  }}
                />
              </a>
            </Col>
            <Col xs={8} className="feedItemDetails">
              <div className="feedItemType">Gallery</div>
              <h4>
                <a href={"/event-gallery/" + item.id}>{item.title}</a>
              </h4>
              <h6>Secret Location &middot; Chicago, IL</h6>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="feedItem">
            <Col xs={4} style={{ paddingLeft: 0 }}>
              <a href={"/event-gallery/" + item.id}>
                <div
                  className="feedItemImage"
                  style={{
                    // backgroundImage: 'url(' + PublicImagePath + item.image + '),url('+ AWSAlternative  + item.image + '),url('+ item.image + ')'
                    backgroundImage: "url(" + item.image + ")",
                  }}
                />
              </a>
            </Col>
            <Col xs={8} className="feedItemDetails">
              <div className="feedItemType">Gallery</div>
              <h4>
                <a href={"/event-gallery/" + item.id}>{item.title}</a>
              </h4>
              <h6>{item.address} </h6>
            </Col>
          </Row>
        );
      }
    }

    if (item.type === "PressRelease") {
      return (
        <Row className="feedItem">
          <Col xs={4} style={{ paddingLeft: 0 }}>
            {/* <a href={"/press/" + item.id}> */}
            <a href="https://www.sadboitees.com/og/shop/home">
              <div
                className="feedItemImage"
                style={{
                  // backgroundImage: 'url(' + PublicImagePath + item.image + '),url('+ AWSAlternative  + item.image + '),url('+ item.image + ')'
                  backgroundImage: "url(" + item.image + ")",
                }}
              />
            </a>
          </Col>
          <Col xs={8} className="feedItemDetails">
            <div className="feedItemType">Merch</div>
            <h4>
              {/* <a href={"/press/" + item.id}>{item.title}</a> */}
              <a href="https://www.sadboitees.com/og/shop/home"></a>
            </h4>
            <div
              className="feedItemDescription"
              dangerouslySetInnerHTML={{ __html: item.description_html }}
            />
          </Col>
        </Row>
      );
    }

    if (item.type === "SoundCloudShare") {
      return (
        <Row className="feedItem">
          <Col xs={4} style={{ paddingLeft: 0 }}>
            <a href={item.soundcloud_url}>
              <div
                className="feedItemImage"
                style={{
                  // backgroundImage: 'url(' + PublicImagePath + item.image + '),url('+ AWSAlternative  + item.image + '),url('+ item.image + ')'
                  backgroundImage: "url(" + item.image + ")",
                }}
              />
            </a>
          </Col>
          <Col xs={8} className="feedItemDetails">
            <div className="feedItemType">Music</div>
            <div className="soundCloudPlayer">
              <h4>{item.artist.name}</h4>
              <div
                className="feedItemDescription"
                dangerouslySetInnerHTML={{ __html: item.description_html }}
              />
              <iframe
                title="SoundCloud Share"
                width="100%"
                height="166"
                scrolling="no"
                src={item.soundcloud_embed_src_url}
              />
            </div>
            <div className="soundCloudLink">
              <h4>{item.artist.name}</h4>
              <div
                className="feedItemDescription"
                dangerouslySetInnerHTML={{ __html: item.description_html }}
              />
              <a href={item.soundcloud_url} className="btn btn-primary btn-sm">
                Play on SoundCloud
              </a>
            </div>
          </Col>
        </Row>
      );
    }
  };

  return (
    <>
      {typeof props.mounths !== "undefined" &&
        Object.keys(props.mounths).length > 0 && (
          <div className="feedContainer">
            <span className="feedHeder">
              <h4>{props.title}</h4>{" "}
              {props.tenseSelector && props.changeTense && (
                <h5
                  className="tenseSelector"
                  onClick={() => {
                    if (props.changeTense) props.changeTense();
                  }}
                >
                  {props.tenseSelector}
                </h5>
              )}
            </span>
            <br />
            {/* Render Press Releases at the top */}
            {Object.keys(props.mounths).map((key, i) => {
              const items = props.mounths[key];
              const pressReleases = items.filter((item: any) => item.type === "PressRelease");

              return pressReleases.map((item: any, i: number) => (
                <Row className="feedDateContainer" key={i}>
                  <Col xs={3} className="feedDate">
                    {"Announcement"} <br />{" "}
                    <span className="feedDay">{"Just launched"}</span>
                  </Col>
                  <Col xs={9} className="feedItems">
                    {renderFeedItem(item)}
                  </Col>
                </Row>
              ));
            })}
            {/* Render other items in month containers */}
            {Object.keys(props.mounths).map((key, i) => {
              const items = props.mounths[key];
              const otherItems = items.filter((item: any) => item.type !== "PressRelease");

              return (
                <div className="feedMonthContainer" key={i}>
                  <h5>{key}</h5>
                  {otherItems.map((item: any, i: number) => (
                    <Row className="feedDateContainer" key={i}>
                      <Col xs={3} className="feedDate">
                        {formatDate(item.date)} <br />{" "}
                        <span className="feedDay">{getWeekDay(item.date)}</span>
                      </Col>
                      <Col xs={9} className="feedItems">
                        {renderFeedItem(item)}
                      </Col>
                    </Row>
                  ))}
                </div>
              );
            })}
          </div>
        )}
    </>
  );
};

export default ActivityFeed;
