import React from "react";
import { Row, Col } from "reactstrap";
import { Form } from "formik";
import Header from "../../../../../Components/Header";
import ButtonLink from "../../../../../Components/ButtonLink";
import {
  changeTicketInventory,
  deleteTicketInventory,
} from "../../../../../Lib/RESTBlox";
import SortableList from "./SortableList";
import { arrayMoveImmutable } from "array-move";
import { Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";

type Props = {
  event: any;
  ticket_inventories: any;
};

const TicketInventoryTable: React.FC<Props> = ({
  event,
  ticket_inventories,
}) => {
  const [currentEvent, setCurrentEvent] = React.useState<any>(event || {});

  const setStateFromNewData = (data: any) => {
    setCurrentEvent(data);
  };

  const handleDeleteTicketInventory = (ticketInventoryId: string) => {
    deleteTicketInventory(ticketInventoryId)
      .then((json: any) => {
        setStateFromNewData(json);
        setItems(json.ticket_inventories);
        console.log("success");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const updateTickets = async (items: any) => {
    for (let i in items) {
      try {
        console.log(items[i]);
        const values = {
          event: currentEvent.id,
          ticket_type: items[i]?.type?.id,
          quantity: items[i]?.quantity,
          sale_start_date: items[i]?.sale_start_date,
          sale_end_date: items[i]?.sale_end_date,
          description: items[i]?.description,
          secret: items[i]?.secret,
          secret_code: items[i]?.secret_code,
          sort_order: items[i]?.sort_order,
        };
        await changeTicketInventory(items[i]?.id, values);
      } catch (error) {}
    }
  };

  const [items, setItems] = React.useState(
    ticket_inventories?.sort((a: any, b: any) => {
      return a.sort_order - b.sort_order;
    })
  );
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobileSmart = useMediaQuery({ query: "(max-width: 510px)" });
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    try {
      const newValue = arrayMoveImmutable(items, oldIndex, newIndex);
      newValue.map((i: any, index: number) => (i.sort_order = index));
      setItems(newValue);
      updateTickets(newValue);
    } catch (error) {}
  };

  return (
    <div>
      <Form>
        <Header content="Ticket inventories" />
      </Form>
      {Object.entries(currentEvent).length !== 0 && (
        <Grid container >
          <Grid
            item
            lg={isMobileSmart ? 4.25 : 1.5}
            xl={isMobileSmart ? 4.25 : 1.5}
            md={isMobileSmart ? 4.25 : 1.5}
            xs={isMobileSmart ? 4.25 : 1.5}
            style={{ color: "gray" }}
          >
            TYPE
          </Grid>
          <Grid
            item
            lg={2}
            xl={2}
            md={2}
            xs={2}
            style={{ color: "gray", textAlign: "center" }}
          >
            #
          </Grid>
          <Grid
            item
            lg={isMobileSmart ? 2.375 : 1}
            xl={isMobileSmart ? 2.375 : 1}
            md={isMobileSmart ? 2.375 : 1}
            xs={isMobileSmart ? 2.375 : 1}
            style={{ color: "gray", textAlign: "center" }}
          >
            PRICE
          </Grid>
          {!isMobileSmart && (
            <Grid
              item
              lg={2.75}
              xl={2.75}
              md={2.75}
              xs={2.75}
              style={{ color: "gray", textAlign: "center" }}
            >
              {isMobile ? "START" : "SALES START"}
            </Grid>
          )}
          {!isMobileSmart && (
            <Grid
              item
              lg={2.75}
              xl={2.75}
              md={2.75}
              xs={2.75}
              style={{ color: "gray", textAlign: "center" }}
            >
              {isMobile ? "END" : "SALES END"}
            </Grid>
          )}
          {isMobileSmart ? (
            <>
              <Grid
                itemScope
                lg={3.375}
                xl={3.375}
                md={3.375}
                xs={3.375}
                style={{ color: "gray", textAlign: "end" }}
              >
                ACTIONS
              </Grid>
            </>
          ) : (
            <>
              <Grid
                itemScope
                lg={1}
                xl={1}
                md={1}
                xs={1}
                style={{ color: "gray", textAlign: "center" }}
              >
                EDIT
              </Grid>
              <Grid
                item
                lg={1}
                xl={1}
                md={1}
                xs={1}
                style={{ color: "gray", textAlign: "center" }}
              >
                DELETE
              </Grid>
            </>
          )}
          <SortableList
            items={items}
            handleDeleteTicketInventory={handleDeleteTicketInventory}
            onSortEnd={onSortEnd}
            isMobile={isMobile}
            isMobileSmart={isMobileSmart}
          />
        </Grid>
      )}
      <Row className="buttonRow">
        <Col xs="9"></Col>
        <Col>
          <ButtonLink
            to={"/admin/events/ticket-inventories/add/" + currentEvent.id}
            text="Add new ticket inventory"
            block
          />
        </Col>
      </Row>
    </div>
  );
};

export default TicketInventoryTable;
