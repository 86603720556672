import {ITicketState, ITicketV3} from "./EventGuestListV2";
import React, {useMemo} from "react";
import {FaCheckCircle, FaTicketAlt} from "react-icons/fa";
import {  Button, Col, Row } from "reactstrap";
import Loader from "../../Components/Loader";

interface GuestCheckInRowProps {
    name: string;
    tickets: ITicketState[];
    handleCheckIn: (ticketId: number) => void;
}

const GuestCheckInRow = ({name, tickets, handleCheckIn}: GuestCheckInRowProps) => {
    const ticketTypes = useMemo(() => {
        const temp: Record<string, ITicketState[]> = {};
        tickets.forEach(ticket => {
            if(!temp[ticket.ticket_type]) {
                temp[ticket.ticket_type] = [];
            }
            temp[ticket.ticket_type].push(ticket);
        });
        return temp;
    }, [tickets]);
    const renderPromoCode = (ticket: ITicketState) => {
        if (ticket.promo_codes) {
            if (typeof ticket.promo_codes === 'string') {
                // Handle the case when promo_codes is a single string
                return <div className="promo-code">Used Code: {ticket.promo_codes}</div>;
            } else if (Array.isArray(ticket.promo_codes) && ticket.promo_codes.length > 0) {
                // Handle the case when promo_codes is an array with elements
                return <div className="promo-code">Used Codes: {ticket.promo_codes.join(", ")}</div>;
            }
        }
        return null; // No promo codes present
    };
    return (
        <Row className="guest-table-v2--row">
            <Col md={3} sm={12} className="guest-table-v2--row__name">{name}</Col>
            <Col md={9} sm={12} className="guest-table-v2--row__ticket-types">
                {Object.keys(ticketTypes).map(ticketType => (
                    <div key={ticketType} className="guest-table-v2--row__ticket-type">
                        <div className="guest-table-v2--row__ticket-label">{ticketType}</div>
                        <div className="guest-table-v2--row__tickets-list">
                            {ticketTypes[ticketType].map((ticket, i) => (
                                <div key={i} className="ticket-and-promo-code">
                                    {ticket.was_used ? (
                                        <Button
                                            key={i}
                                            color="secondary"
                                            className={`guest-table-v2--row__ticket-button disabled ${ticket.loading ? 'loading' : ''}`}
                                            onClick={() => {
                                                handleCheckIn(ticket.id)
                                            }}
                                            disabled={ticket.loading}
                                        >
                                            <FaCheckCircle />
                                            <span className="guestlistTicketBadge">
                                                {i + 1}
                                            </span>
                                            {ticket.loading && <Loader size={20} loading={ticket.loading} color="#000" style={{
                                                position: 'absolute',
                                                top: '100%',
                                                left: '35%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }} />}
                                        </Button>
                                    ) : (
                                        <Button
                                            key={i}
                                            disabled={ticket.loading}
                                            onClick={() => {
                                                handleCheckIn(ticket.id)
                                            }}
                                            className={`guest-table-v2--row__ticket-button hvr-grow-shadow ${ticket.loading ? 'loading' : ''}`}
                                            color="link"
                                            style={{color: 'white'}}
                                        >
                                        <FaTicketAlt />
                                        <span className="guestlistTicketBadge">
                                            {i + 1}
                                        </span>
                                        {ticket.loading && <Loader size={20} loading={ticket.loading} color="#000" style={{
                                            position: 'absolute',
                                            top: '100%',
                                            left: '35%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }} />}
                                        </Button>
                                    )}
                                    {/* Display Promo Code if available */}
                                    {ticket.promo_codes && ticket.promo_codes.length > 0 && (
                                        <div className="promo-code">
                                            Used Code: {Array.isArray(ticket.promo_codes) ? ticket.promo_codes.join(", ") : ticket.promo_codes}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </Col>
        </Row>
    );
}

export {GuestCheckInRow}
