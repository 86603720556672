import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import FlashAlert from "../Components/FlashAlert";

type Props = {
  title: string;
  description?: string;
  linkText?: string;
  to?: string;
  isWhite?:boolean;
  onClick?:() => void
};

const AdminLayout: React.FC<Props> = ({
  title,
  description,
  children,
  linkText,
  to,
  isWhite,
  onClick
}) => {
  const [alert, setAlert] = React.useState<any>();
  const receiveAlertMessage = (message: any) => {
    setAlert(message);
  };

  // Add some props to every child
  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child, {
      // handleAlertMessage: receiveAlertMessage,
    })
  );

  return (
    <Row className="BloxAdminRow">
      <Col className={`BloxAdminContent${isWhite ? "-white":""}`}>
        {alert && (
          <Row>
            <Col>
              <FlashAlert color={alert.type} text={alert.message} />
            </Col>
          </Row>
        )}
        <h3 style={{ display: "flex", justifyContent: "space-between" }}>
          {title}{" "}
          {to && linkText && (
            <Link className="link-upcomming-events" to={to} onClick={onClick}>
              {linkText}
            </Link>
          )}
        </h3>

        {description && <p className="adminPageDescription">{description}</p>}
        {childrenWithProps}
      </Col>
    </Row>
  );
};

export default AdminLayout;
