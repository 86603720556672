import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ContentLayout from '../Layouts/ContentLayout'
import { getFeedForWhiteLabel } from '../Lib/RESTBlox'
import axios from 'axios'
import ActivityFeed from '../Components/ActivityFeed'
import moment from 'moment'
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaSnapchatGhost,
} from 'react-icons/fa'

import { ReactComponent as RALogo } from '../Components/Admin/Analytics/images/ra_logo.svg'

type Props = {
  whiteLabel: any;
};

const Gallery: React.FC<Props> = (props) => {

  const [primaryImage, setPrimaryImage] = React.useState<any>();
  const [next, setNext] = React.useState<any>();
  const [count, setCount] = React.useState<any>();
  const [feed, setFeed] = React.useState<any>({});
  const [mounths, setMounths] = React.useState<any>({});
  const [genres, setGenres] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const loadData = () => {
    getFeedForWhiteLabel(props.whiteLabel.id)
      .then((data: any) => {
        console.log(data)
        console.log(data.result.filter(function (el: any) {
          return el.id == 466 ||
            el.id == 467 ||
            el.id == 468 ||
            el.id == 457;
        }))
        console.log(data)
        setNext(data.next);
        setCount(3);
        setFeed(data.result.filter((x: any) => x.type == "Event" && (x.id == 449 || x.id == 456 || x.id == 457)).map((y: any) => y.type == "Event" ? { ...y, type: "Gallery" } : y));
      }).then(() => {
        compareDate()
      })
  }

  const addTypedContent = () => {
    var image = ''
    var genres = []
    if (props.whiteLabel.type === 'artist') {
      image = props.whiteLabel.artist.image
      genres = props.whiteLabel.artist.genres
    }
    if (props.whiteLabel.type === 'club') {
      image = ''
      genres = props.whiteLabel.club.genres
    }
    if (props.whiteLabel.type === 'promoter') {
      image = props.whiteLabel.promoter.image
      genres = props.whiteLabel.promoter.genres
    }
    setPrimaryImage(image);
    setGenres(genres);
  }

  React.useEffect(() => {
    loadData()
    addTypedContent()
  }, []);

  const infinityScroll = (e: any) => {
    let distance = - document.getElementsByClassName("BloxContentRow")[0].getBoundingClientRect().top
    let bottom = document.getElementsByClassName("BloxContentRow")[0].getBoundingClientRect().height - document.documentElement.clientHeight
    if (!isLoading && count > feed.length && bottom - distance < 500) {
      setIsLoading(true);
      axios.get(next).then(resp => {
        setIsLoading(false);
        return resp.data
      }).then(data => {
        setFeed([...feed, ...data.result].filter(x => x.type == "Event" && (x.id == 449 || x.id == 456 || x.id == 457)).map(y => y.type == "Event" ? { ...y, type: "Gallery" } : y))
        setNext(data.next);
      }).then(() => {
        compareDate()
      })
    }
  }


  const compareDate = () => {
    let newState: any;
    feed.map((item: any, i: any, arr: any) => {
      if (!newState[moment(item.date).format("MMMM YYYY")]) {
        newState[moment(item.date).format("MMMM YYYY")] = []
        newState[moment(item.date).format("MMMM YYYY")].push(item)
      } else {
        newState[moment(item.date).format("MMMM YYYY")].push(item)
      }
    })
    setMounths(newState);
  }

  const whiteLabel = props.whiteLabel
  var title;
  if (props.whiteLabel.title !== '') {
    title = (
      <div>
        <span>{whiteLabel.title}</span>
        {props.whiteLabel.type == 'club' && !(props.whiteLabel.id === 27) &&
          <span className="whiteLabelType">Venue</span>
        }
        {props.whiteLabel.type == 'artist' &&
          <span className="whiteLabelType">Artist</span>
        }
        {props.whiteLabel.type == 'promoter' &&
          <span className="whiteLabelType">Promoter</span>
        }
        {props.whiteLabel.id === 27 &&
          <span className="whiteLabelType">Event</span>
        }
        {whiteLabel.social_links?.map((link: any, i: any) => {
          return (
            <a href={link.url}>
              {link.service === 'twitter' && <FaTwitter />}
              {link.service === 'facebook' && <FaFacebook />}
              {link.service === 'instagram' && <FaInstagram />}
              {link.service === 'snapchat' && <FaSnapchatGhost />}
              {link.service === 'resident-advisor' && <RALogo />}
            </a>
          )
        })}
      </div>
    )
  }

  if (props.whiteLabel.id != 2) {
    return (
      props.whiteLabel &&
      <ContentLayout
        title={title}
        type={whiteLabel.type}
        shortDescription={whiteLabel.short_description}
        longDescription={whiteLabel.description}
        image={primaryImage}
        venue={whiteLabel.club}
        tags={genres}
      >
        <ActivityFeed
          title="What's happening"
          count={count}
          next={next}
          feed={feed}
          mounths={mounths}
        />
      </ContentLayout>
    )
  }

  if (props.whiteLabel.id === 2) {
    return (
      props.whiteLabel &&
      <ContentLayout
        title={title}
        type={whiteLabel.type}
        shortDescription={whiteLabel.short_description}
        longDescription={whiteLabel.description}
        //image={primaryImage}
        //venue={whiteLabel.club}
        tags={genres}
      >
        <Helmet key={window.location.href}>
          <title>Primary — Homepage</title>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Primary — Homepage" />
          <meta property="og:description" content="Chicago's Home for Underground Dance Music" />
          <meta property="og:image" content="https://cdn-images.threadless.com/threadless-media/artist_shops/shops/primarychi/products/1953075/original-1613329070-c616be45fb523d270fb50d838c9677a2.png?v=3&d=eyJvbmx5X21ldGEiOiBmYWxzZSwgImZvcmNlIjogZmFsc2UsICJvcHMiOiBbWyJ0cmltIiwgW2ZhbHNlLCBmYWxzZV0sIHt9XSwgWyJyZXNpemUiLCBbXSwgeyJ3aWR0aCI6IDk5Ni4wLCAiYWxsb3dfdXAiOiBmYWxzZSwgImhlaWdodCI6IDk5Ni4wfV0sIFsiY2FudmFzX2NlbnRlcmVkIiwgWzEyMDAsIDEyMDBdLCB7ImJhY2tncm91bmQiOiAiZmZmZmZmIn1dLCBbInJlc2l6ZSIsIFs4MDBdLCB7fV0sIFsiY2FudmFzX2NlbnRlcmVkIiwgWzgwMCwgODAwLCAiI2ZmZmZmZiJdLCB7fV0sIFsiZW5jb2RlIiwgWyJqcGciLCA4NV0sIHt9XV19" />
          <link rel="apple-touch-icon" sizes="180x180" href="/primary-apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/primary-favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/primary-favicon-16x16.png" />
          <link rel="manifest" href="/primary-site.webmanifest" />
          <link id="favicon" rel="icon" href="%PUBLIC_URL%/primary-favicon.ico" type="image/x-icon" />
        </Helmet>
        <ActivityFeed
          title="Photo Galleries"
          count={count}
          next={next}
          feed={feed}
          mounths={mounths}
        />
      </ContentLayout>
    )
  }
}

export default Gallery;
